import React, { useState } from 'react'
import style from '../../../theme/web/Servicios.module.css'
import Selector from '../../entrenamiento/componentes/Selector'
import { paises } from '../../../datos/paises'
import { httpsCallable } from 'firebase/functions'
import { useToast } from '../../../hooks/useToast'
import { functions } from '../../../firebase'

interface Props{
    setLoading:any;
}

const Contactanos = ({setLoading}:Props) => {
  const [pais, setPais] = useState('+57')
  const [nombre, setNombre] = useState('')
  const [correo, setCorreo] = useState('')
  const [telefono, setTelefono] = useState('')
  const [mensaje, setMensaje] = useState('')

  const { presentMensajePersonalizado } = useToast()

  const enviarMensajeCorreo = async () => {
    setLoading(true)
    if(nombre && correo && telefono && pais){
        try {
            const enviarCorreoInformacion = httpsCallable(functions, 'enviarCorreoInformacion');
            const result = await enviarCorreoInformacion({nombre:nombre,correo:correo,mensaje:mensaje,telefono:`${pais} ${telefono}`});
            console.log("Respuesta de la función en la nube:", result.data);
            setNombre('')
            setCorreo('')
            setPais('+57')
            setTelefono('')
            setMensaje('')
            if(result.data){
                presentMensajePersonalizado("Mesaje enviado correctamente")
            }else{
                presentMensajePersonalizado("Ups!!! Parecer haber un error, vuelve a intentarlo.")
            }
            setLoading(false)
        } catch (error) {
            presentMensajePersonalizado("Ups!!! Parecer haber un error, vuelve a intentarlo.")
            console.error("Error al llamar a la función:", error);
            setLoading(false)
        }
    }else{
        presentMensajePersonalizado('Falta algo para enviar el correo, revisa')
    }
    }

  return (
    <div className={style.formCorreo}>
        <div className={style.boxInputs}>
            <h1>¿TE INTERESA? ¡CONTÁCTANOS YA!</h1>
            <div className={style.styleInput} style={{width:'100%'}}>
                <label className={style.itemBoxInput}>NOMBRE COMPLETO*</label>
                <input className={style.itemBoxInput} type='text' value={nombre} onChange={(e) => setNombre(e.target.value)}></input>
            </div>
            <div className={style.styleInput} style={{width:'35%'}}>   
                <label className={style.itemBoxInput}>PAÍS*</label>
                <Selector setSelection={setPais} selection={pais} opciones={paises} style={{color:'var(--color-Dark-Grey)',fontSize:'2svh',height:'5svh'}}/>
            </div>
            <div className={style.styleInput} style={{width:'calc(68% - 5svw)'}}>
                <label className={style.itemBoxInput}>NÚMERO DE TELÉFONO*</label>
                <input className={style.itemBoxInput} type='telefono' value={telefono} onChange={(e) => setTelefono(e.target.value)}></input>
            </div>
            <div className={style.styleInput} style={{width:'100%'}}>
                <label className={style.itemBoxInput}>CORREO ELECTRÓNICO*</label>
                <input className={style.itemBoxInput} type='text' value={correo} onChange={(e) => setCorreo(e.target.value)}></input>
            </div>
            <div className={style.styleInput} style={{width:'100%'}}>
                <label className={style.itemBoxInput}>MENSAJE</label>
                <textarea className={style.itemBoxInput} rows={5} value={mensaje} onChange={(e) => setMensaje(e.target.value)}></textarea>
            </div>
            <p style={{width:'100%',textAlign:'end',fontSize:'70%',color:'white',margin:'0'}}>*Campos obligatorios</p>
            <button className={style.buttonCorreo} onClick={enviarMensajeCorreo}>ENVIAR CORREO</button>
        </div>
    </div>
  )
}

export default Contactanos