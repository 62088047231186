import { useState, useEffect, ReactNode } from 'react';

import style from "../../../theme/Juego2.module.css";
import estiloJuegos from "../../../theme/Juegos.module.css";
import estiloNeuronas from "../../../theme/PopoverNeuronas.module.css";

import { titulo, nombresHombres, nombresMujeres, niveles,cantRostrosHombres,cantRostrosMujeres } from '../../../datos/DatosMemNombres';
import { ArrayDeNumeros } from '../../../utils/ArrayDeNumeros' ;
import Toolbar from '../componentes/Toolbar';
import SegundaPantalla from './componentes/SegundaPantalla';
import TerceraPantallaConImagenes from './componentes/TerceraPantallaConImagenes';
import { nomProgMemNombres, nomTestMemNombresInicial } from '../../../datos/nombresBD';
import { useDato } from "../../../auth";
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { useAppDispatch, useAppSelector } from '../../../store';
import { test_general, test_inicial } from '../../../datos/DatosTests';
import Resultados from '../resultados/componentes/Resultados';
import TerceraPantallaConBotones from './componentes/TerceraPantallaConBotones';

import { initialNeuronas } from '../../../hooks/reducer/reducerAuth';
import { obtenerNeuronas } from '../../../utils/neuronasUtil';
import { ArrayDeNumerosParaBotones } from '../../../utils/ArrayDeNumerosParaBotones';
import BotonesJuegos from '../componentes/BotonesJuegos';
import { obtenerMensaje } from '../../../utils/resultadoUtil';
import { changeCondicion, changePrograma, newActivity } from '../../../hooks/reducer/reducerActividad';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SelectorNivel from '../componentes/SelectorNivel';
import Loading from '../../entrenamiento/componentes/Loading';
import { terminadoJuego, changePuntaje } from '../../../hooks/reducer/reducerJuegoSimultaneo';
import { aCalificacionUsuario } from '../../../modelos/Calificacion';
import { Timestamp } from 'firebase/firestore';
import MostrarResultados from '../resultados/componentes/MostrarResultados';

const Game4 = () => {

    const userId = useAppSelector((state) => state.auth.userId);
    const testId = useAppSelector((state) => state.activity.id);
    const esJuego = useAppSelector((state) => state.activity?.id === test_general);
    const esEntrenamiento = useAppSelector((state) => state.activity?.id == "entrenamiento");
    const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento?.id);
    const dispatch = useAppDispatch();
    const neuronas = useAppSelector((state) => state.auth.neuronas);
    const [nuevasNeuronas, setNuevasNeuronas] = useState<number>(0); 
	const {				
		state,
	} = useDato();

    const navigate = useNavigate()

	const {
        guardarDatosCalificacion,	
        guardarUsuarioTestPrograma,
        guardarDatosCalificacionTestGeneral,	        
    } = useJuegos();


    const primeraEtapa = 1;
    const segundaEtapa = 2;
    const terceraEtapa = 3;
    const inicialValorNumero = -1;
    const inicialValorArrayString: string[] = [];
    const inicialValorArrayBoolean: boolean[] = [];
    const inicialValorArrayAny: any[] = [];
    
    const [tiempo, setTiempo] = useState<number>(0);
    const [etapa, setEtapa] = useState<number>(1);
    const [nivel, setNivel] = useState<number>(1);
    
    const [cantidadCorrecta, setCantidadCorrecta] = useState<number>(inicialValorNumero);
    const [cantidadDeObjetos, setCantidadDeObjetos] = useState<number>(inicialValorNumero);
    const [maxTiempo, setMaxTiempo] = useState<number>(inicialValorNumero);
    const [userInputs, setUserInputs] = useState<string[]>(inicialValorArrayString);
    const [calificaciones, setCalificaciones] = useState<boolean[]>(inicialValorArrayBoolean);
    const [inputRefs, setInputRefs] = useState<any[]>(inicialValorArrayAny);
    // array de los nombres de las personas [nombre][genero]
    const [objetoIds, setObjetoIds] = useState<string[][]>([]);
    // indice de la persona que se está mostrando
    const [objetoIdIndice, setObjetoIdIndice] = useState<number>(0);
    // array de indices aleatorios
    const [objetoIdAleator, setObjetoIdAleator] = useState<string[]>([]);
    // contador de objetos mostrados
    const [contadorObjetos, setContadorObjetos] = useState<number>(1);
    // indices de imagenes
    const [rostrosHombresIds,setRostrosHombresIds]=useState<string[]>([]);
    const [rostrosMujeresIds,setRostrosMujeresIds]=useState<string[]>([]);
    const [rostrosMujeresIndice, setRostrosMujeresIndice] = useState<number>(0);
    const [rostrosHombresIndice, setRostrosHombresIndice] = useState<number>(0);
    

    // uso para cuando son botones y no inputs
    const [totalOpciones,setTotalOpciones] =  useState<string[][]>([]);
    const [opcionesRespuesta,setOpcionesRespuesta] = useState<string[]>([]);

    useEffect(() => {
      if(testId === 'juego_simultaneo'){
        HandlePlay()
      }
    }, [])

    const guardaOpcionSeleccionada = (opcion:string, index:number) => {
        
        const indice = index;
        const userInputsTemporal = userInputs.slice();
        userInputsTemporal[indice] = opcion;
        setUserInputs(userInputsTemporal);
    };

    // esta funcion es para limpiar los acentos y mayusculas de las palabras
    const compararPalabras = (palabra1: string, palabra2: string) => {
        const limpiarString = (str: string) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
        }

        if (limpiarString(palabra1) === limpiarString(palabra2)) { 
            return true;
        }else{
            return false;
        }
    }

    //Función de verificacion individual para cada respuesta
    const ChecarRespuesta = (numeroIndice: number, calificacionesTemporal: boolean[]) => {
        let calificacionIndice = numeroIndice;
        const palabraOriginal = getPalabraWrapper(numeroIndice);        
        const palabraMemorizada = userInputs[numeroIndice];
      
        if (compararPalabras(palabraOriginal, palabraMemorizada)) {            
            calificacionesTemporal[calificacionIndice++] = true;            
        }
    }

    // Verificar las respuestas suministradas por el usuario para saber si se guarda
    const ChecarRespuestas = () => {
        const calificacionesTemporal = calificaciones.slice();
                
        for (let indice = 0; indice < userInputs.length; indice++) {
            ChecarRespuesta(indice, calificacionesTemporal);
        }
        setCalificaciones(calificacionesTemporal);

        const cantidadCorrectaTemporal = calificacionesTemporal.reduce((x, y) => x + Number(y), 0);
        setCantidadCorrecta(cantidadCorrectaTemporal);

        let neuronasNuevas = 0;
        // obtener neuronas
        if(testId === test_general){   
            neuronasNuevas = obtenerNeuronas(nomProgMemNombres, nivel, cantidadCorrectaTemporal)
            setNuevasNeuronas(neuronasNuevas);   
        }else{
            neuronasNuevas = obtenerNeuronas(nomTestMemNombresInicial, nivel, cantidadCorrectaTemporal)
            setNuevasNeuronas(neuronasNuevas);
        }
        const neuronasTotales = neuronas + neuronasNuevas;
        dispatch(initialNeuronas({cantidad:neuronasTotales}))
        console.log("🚀 ~ ChecarRespuestas ~ tiempo:", tiempo/1000)

        // Guarda la configuracion cuando es el juego o cuando es un test
        if(testId === test_general){        
            var total = cantidadCorrectaTemporal * 10;
            total = total + (total === 100 ? (tiempo/1000)*10 : 0);    
            guardarDatosCalificacionTestGeneral(userId, nomProgMemNombres, neuronasNuevas, nivel, total, tiempo/1000, false, state.nombres, state.pais,contadorObjetos,total);
        }else{
            if(testId === 'juego_linea'){
                console.log("second")
                var total = cantidadCorrectaTemporal * 10;
                total = total + (total === 100 ? (30-(tiempo/100)) : 0);
                dispatch(terminadoJuego({ultimoResultado:total,tiempo:30-Math.abs(tiempo)/100,finalizado:true}))
            }else{
                const calificacion = aCalificacionUsuario(cantidadCorrectaTemporal, cantidadCorrectaTemporal, Timestamp.now());
                console.log("🚀 ~ ChecarRespuestas ~ calificacion:", calificacion)
                guardarUsuarioTestPrograma(userId, testId, nomProgMemNombres, calificacion);
            }
        }
    }

    //Función que llamada al cabo del termino del tiempo para el cambio de etapa
    const HandleTiempo = () => {
        if (etapa === segundaEtapa) {
            ConstruirTerceraPantalla();
        } else if (etapa === terceraEtapa) {
            ConstruirCuartaPantalla();
        } else {
            throw new Error("tiempo iniciado fuera de lugar");
        }
    }

    const ResetearIndices = () => {
        setObjetoIdIndice(0);
        auxSubmit(objetoIdAleator[0]);
        getOpciones(parseInt(objetoIdAleator[0]));
    }
    
    const auxSubmit = (objetoId) =>{
        let contHombres=0;
        let contMujeres=0;
        for (let index = 0; index< objetoId; index++) {
            if(objetoIds[index][1] === 'hombre'){
                contHombres++;
            }else {
                contMujeres++;
            }
        }
        if( contHombres<rostrosHombresIds.length ){
            setRostrosHombresIndice(contHombres);
        }
        if( contMujeres<rostrosMujeresIds.length ){
            setRostrosMujeresIndice(contMujeres);
        }
    }

    const AumentarIndiceDeNavegacion = () => {
        const nextIndice = objetoIdIndice + 1;
        if (objetoIdIndice < cantidadDeObjetos - 1) {
            setObjetoIdIndice(nextIndice);
            if (nextIndice > contadorObjetos - 1 && etapa === 2){
                setContadorObjetos(contador => contador + 1);
            }
        }
        if (etapa === 2){
            auxSubmit(nextIndice);
        }else if(etapa === 3){
            auxSubmit(objetoIdAleator[nextIndice]);
            getOpciones(parseInt(objetoIdAleator[nextIndice]));
        }
        
    }

    const DisminuirIndiceDeNavegacion = () => {
        const nextIndice = objetoIdIndice-1;
        if (objetoIdIndice > 0) {
            setObjetoIdIndice(nextIndice); 
        }
        if (etapa === 2){
            auxSubmit(nextIndice);
        }else if(etapa === 3){
            auxSubmit(objetoIdAleator[nextIndice]);
            getOpciones(parseInt(objetoIdAleator[nextIndice]));
        }
    }

    //Ordena aleatoriamente los rostros hombre y mujeres
    const OrdenarIdsNombresAleatorios =(array) => {
        const copiaArraysSecundarios = array.map(arraySecundario => {
            return arraySecundario.slice();
            });
            
        // Mezclar aleatoriamente los índices de los arrays secundarios 
        return copiaArraysSecundarios.sort(() => Math.random() - 0.5);     
    }

    //Ordena aleatoriamente los nombres hombre y mujeres
    const OrdenarOpcionesAleatorias =(array) => {
        // Mezclar aleatoriamente los índices de los arrays secundarios 
        return array.sort(() => Math.random() - 0.5);     
    }

    //Establece los nombres aleatoriamente repartiendo entre hombre y mujeres en al variabel ObjetosIds
    const EstablecerNombresRostros = (numNombres) =>{
        const mitadNumNames = Math.ceil(numNombres / 2);
        const nombresHombresAleatorios = ArrayDeNumeros(mitadNumNames,nombresHombres);
        const nombresMujeresAleatorios = ArrayDeNumeros(numNombres-mitadNumNames,nombresMujeres);
        const rostrosHombresIdsTemporal = ArrayDeNumeros(mitadNumNames,cantRostrosHombres);
        const rostrosMujeresIdsTemporal  = ArrayDeNumeros(numNombres-mitadNumNames,cantRostrosMujeres);
        
        const nombresHombresIds = nombresHombresAleatorios.map(name => [name, 'hombre']);
        const nombresMujeresIds = nombresMujeresAleatorios.map(name => [name, 'mujer']);
        
        let nombresAleatoriosIds = nombresHombresIds.concat(nombresMujeresIds);
        
        nombresAleatoriosIds=OrdenarIdsNombresAleatorios(nombresAleatoriosIds);
        setObjetoIds(nombresAleatoriosIds);
        setRostrosHombresIds(rostrosHombresIdsTemporal)
        setRostrosMujeresIds(rostrosMujeresIdsTemporal)
    }

    //Funcion barril para guarda tiempo en etapa 2
    const TiempoMemorizando = (tiempoMem:number) =>{
        if(etapa === 2){
            setTiempo(tiempoMem);
        }
    }

    const getOpciones = (indice:number) => 
    {
        setOpcionesRespuesta(totalOpciones[indice]);
    }
    
    const HandlePlay = () => {		
		InitializarConfig();
		setEtapa(segundaEtapa);
	}
    

    const InitializarConfig = () => {
        const cantidadDePalabrasTemporal= niveles[nivel][1];
        
        EstablecerNombresRostros(cantidadDePalabrasTemporal);

        setCantidadDeObjetos(cantidadDePalabrasTemporal);
        setContadorObjetos(1);
        
        setObjetoIdIndice(0);
        setRostrosMujeresIndice(0);
        setRostrosHombresIndice(0);
        
        setUserInputs(Array(cantidadDePalabrasTemporal).fill(""));
        setCalificaciones(Array(cantidadDePalabrasTemporal).fill(false));

        setMaxTiempo(niveles[nivel][2]);
        
        setInputRefs(Array(cantidadDePalabrasTemporal).fill(null));
        setCalificaciones(Array(niveles[nivel][1]).fill(false));
    }   

    const ConstruirTerceraPantalla = () => {
        console.log("tiempo ", tiempo)
        setMaxTiempo(niveles[nivel][3]);
        setObjetoIdIndice(0);
        let cantidadNombresAleatorios = contadorObjetos;
        
        let objetoIdAleato=[];
            
        const arrayAleator = Array(cantidadNombresAleatorios);
        
        objetoIdAleato= ArrayDeNumeros(cantidadNombresAleatorios,arrayAleator);
        setObjetoIdAleator(objetoIdAleato);
        let array = [];
        
        for (let indice = 0; indice < cantidadNombresAleatorios; indice++) {
            let nombreCorrecto =[];
            let nombresAleatorios;
            if(objetoIds[objetoIdAleato[indice]][1] === "hombre"){

                nombresAleatorios = ArrayDeNumerosParaBotones(3,nombresHombres,objetoIds[objetoIdAleato[indice]][0]);
                nombreCorrecto.push(nombresHombres[objetoIds[objetoIdAleato[indice]][0]]);

                for (let indice = 0; indice < nombresAleatorios.length; indice++) {
                    nombresAleatorios[indice] = nombresHombres[ nombresAleatorios[indice]]
                    
                }
            }else{
                nombresAleatorios = ArrayDeNumerosParaBotones(3,nombresMujeres,objetoIds[objetoIdAleato[indice]][0]);
                
                nombreCorrecto.push(nombresMujeres[objetoIds[objetoIdAleato[indice]][0]]);
                for (let indice = 0; indice < nombresAleatorios.length; indice++) {
                    nombresAleatorios[indice] = nombresMujeres[nombresAleatorios[indice]]
                    
                }
                
            }
            let nombresOpciones = nombreCorrecto.concat(nombresAleatorios)

            array[parseInt(objetoIdAleato[indice])]=OrdenarOpcionesAleatorias(nombresOpciones);
            
        }
        setTotalOpciones(array);
        setOpcionesRespuesta(array[parseInt(objetoIdAleato[0])])
            
        auxSubmit(objetoIdAleato[0]);
        setEtapa(terceraEtapa);
    }

    const ConstruirCuartaPantalla = () => {

        setRostrosHombresIndice(-1);
        setRostrosMujeresIndice(-1);
        ChecarRespuestas();
        setTimeout(() => {
            setEtapa(4)
        }, 10);
    }
    
    
    async function HandleBackButtonClick(): Promise<void> {
		if(esEntrenamiento){
            console.log("first")
			dispatch(newActivity({id:idEntrenamiento,name:"entrenamiento"}))
			dispatch(changePrograma({id:idEntrenamiento}))
			dispatch(changeCondicion({condicion:10}))
			navigate(-2);
		}else{
            if(testId === 'juego_linea'){
                console.log("second")
                dispatch(changePuntaje({verPuntaje:true}))
            }else{
                console.log("third")
                navigate(-1);
            }
		}
	  }
    
    const HandleReiniciar = () => {
        setEtapa(primeraEtapa)
    }

    /* Funciones utilizadas para poder completar el diseño html */
    const getDescripcionJuego = () => {     
        return (
            <div className={estiloJuegos.descripcionJuego}>
                Tienes 30 segundos para memorizar los nombres de estas 10 personas.   
            </div>
        );
    }

    const getDescripcionTest = () => {        
        return (
            <div className={estiloJuegos.descripcionJuego}>
                Durante los próximos 30 segundos, te desafiamos a observar cada rostro y memorizar su nombre. <br></br> ¡Cada segundo cuenta, así que concéntrate y haz brillar tu memoria!
            </div>
           
            
        );
    }
    
    const getObjetoAleatorio = () => {
        const isHombre = objetoIds[objetoIdAleator[objetoIdIndice]][1] === "hombre";
        const imagen = isHombre
            ? "assets/images/personas/Hombres/" + rostrosHombresIds[rostrosHombresIndice] + ".jpg"
            : "assets/images/personas/Mujeres/" + rostrosMujeresIds[rostrosMujeresIndice] + ".jpg";
       
        return (
            <>
            <img src={imagen} style={{height:'100%', aspectRatio:'1/1'}} alt="imagen de una persona aleatoria"></img>
            </>
        );
        
    }
    const TraerImagenRostro = () => {
        const isHombre = objetoIds[objetoIdIndice][1] === "hombre";
        const imagen = isHombre
            ? "assets/images/personas/Hombres/" + rostrosHombresIds[rostrosHombresIndice] + ".jpg"
            : "assets/images/personas/Mujeres/" + rostrosMujeresIds[rostrosMujeresIndice] + ".jpg";
        return (
            <>
            <img  src={imagen} style={{height:'100%', aspectRatio:'1/1'}} alt="imagen de una persona aleatoria"></img>
            </>
        );
        
    }
    const getObjetoMiniatura = (isHombre:boolean,rostrosHombresIndi:number, rostrosMujeresIndi:number) => {
        const imagen = isHombre
        ? "assets/images/personas/Hombres/" + rostrosHombresIds[rostrosHombresIndi] + ".jpg"
        : "assets/images/personas/Mujeres/" + rostrosMujeresIds[rostrosMujeresIndi] + ".jpg";
        return (
            <img src={imagen}></img>
        );
    }

    const GenerarArrayAMostrar = () => {
        let arrayRespuestas = userInputs.slice(0, contadorObjetos).map((element) => {return element !== '' ? element : 'No respondió'})
        console.log("🚀 ~ GenerarArrayAMostrar ~ userInputs:", userInputs)
        console.log("🚀 ~ GenerarArrayAMostrar ~ arrayRespuestas:", arrayRespuestas)
        let arrayPalabras = arrayRespuestas.map((_,index) => getPalabraWrapper(index))
        arrayRespuestas = ['Respuestas',...arrayRespuestas]
        arrayPalabras = ['Nombre',...arrayPalabras]
        return [arrayPalabras,arrayRespuestas]
    }

    const GenerarArrayImagenes = ():ReactNode[] => {
        let countMujeres =-1;
        let countHombres =-1;
        let arrayImg = []
        for(let index=0;index<objetoIds.length;index++){
            if(objetoIds[index][1] === "hombre"){
                countHombres++
            }else{
                countMujeres++
            }
            arrayImg.push(getObjetoMiniatura(objetoIds[index][1] === "hombre",countHombres,countMujeres))
        }
        return arrayImg
    }

    const getMensajes = () =>{
        
        let porcentaje = cantidadCorrecta/contadorObjetos;
        let mensajePersonalizado =obtenerMensaje(porcentaje);

        if (porcentaje > 0) {
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p  className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                    {esJuego && <h3 style={{margin:"0",color:'var(--color-Medium-Grey)',textTransform:'uppercase',fontWeight:'700'}} className = {estiloNeuronas.totalNeuronas}> Ganaste
                        <span style={{color:'var(--color-Pink-Light)',margin: '0 0.3em', fontWeight:'700'}}>{` ${nuevasNeuronas} `}</span>
                    neuronas</h3>}
                </div>

            );
        }else{
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p style={{marginBottom:"2vh"}} className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                </div>

            );
        }
            
        
    }
      
    const getPalabraWrapper = (indice : number) => {
        const indicePalabra = objetoIds[indice][0];        
	    return objetoIds[indice][1] === "hombre"
        ? nombresHombres[indicePalabra]
        : nombresMujeres[indicePalabra]; 
    }

    return (
        <div id='page' style={(testId === 'juego_linea'? {height: "100%"}: {})}>
            {nivel === 0 && <Loading isOpen={true}/>}

            {(testId !== 'juego_linea' || etapa === 1) &&
            <div className={etapa === 1 ? estiloJuegos.toolbarMemoriaNombres : estiloJuegos.toolbar_default}>
                {esJuego &&
                    <Link  className={estiloJuegos.backJuegos} to=".." relative='path'></Link>
                }
                <h1 className={`title ${estiloJuegos.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'2.37svh'} :{}}> {titulo} </h1>
                
            </div>
            }
            {etapa === 1 && 
                <div className={estiloJuegos.contenedorIconoNombres}>
                    <img src='/assets/componentes/Juegos/Rostro.svg' className={estiloJuegos.icono}></img>
                </div>
            }
            
            <div id='content_default' style={{height:'90%',padding:'2%'}}>

                {etapa !== 1  &&

                    <div style={{width:'100%'}}>
                        <Toolbar
                        esJuego={true}
                        nivel={nivel}
                        etapa={etapa}
                        handleTiempo={HandleTiempo}
                        maxTiempo={maxTiempo}
                        setTiempoMemoria={TiempoMemorizando}
                        />
                        {etapa == 2 && <p className={estiloJuegos.subtitulo_texto}>Concéntrate en cada detalle.</p>}
                        {etapa == 3 && <p className={estiloJuegos.subtitulo_texto}>¿Cómo se llama?</p>}
                    </div>

                }
                
                {etapa === 1 &&
                    
                    <div className={estiloJuegos.primeraPantalla}>
                           
                        {esJuego ? getDescripcionJuego()  : getDescripcionTest()}   
                        {nivel !== 0 &&
                            <button className={estiloJuegos.buttonEmpezar} onClick={HandlePlay}>
                                EMPEZAR
                            </button>}
                        {/* {nivel !== 0 &&
                            <Link className={estiloJuegos.buttonEmpezar} to={"/my/juegos/Memoria/versus"} >
                                VERSUS
                            </Link>} */}
                        {/* {esJuego && <button id='button_bordes_default' onClick={RedirectRanking}>
                            RANKING
                      
                        </button>}*/}
    
                    </div>
                }
                {etapa === 2 &&

                    <SegundaPantalla
                        datoPrincipal={objetoIds[objetoIdIndice][1] === "hombre" 
                        ? nombresHombres[objetoIds[objetoIdIndice][0]]
                        : nombresMujeres[objetoIds[objetoIdIndice][0]]}
                        
                        tituloPalabras='Palabras'
                        digitos={0}
                        resetContenido={ResetearIndices}
                        contenidoIdIndice={objetoIdIndice}
                        cantidadDeValores={cantidadDeObjetos}
                        leftSubmit={DisminuirIndiceDeNavegacion}
                        rightSubmit={AumentarIndiceDeNavegacion}
                        aSiguientePantalla={ConstruirTerceraPantalla}
                        getObjetos={TraerImagenRostro}
                    />
                }
                {etapa === 3 && testId===test_inicial ? 
                    <TerceraPantallaConImagenes 
                        resetContenido={ResetearIndices} 
                        contenidoIdIndice={objetoIdIndice} 
                        cantidadDeValores={contadorObjetos}
                        objetoIdAleatorio={objetoIdAleator}
                        leftSubmit={DisminuirIndiceDeNavegacion}
                        rightSubmit={AumentarIndiceDeNavegacion}
                        aSiguientePantalla={ConstruirCuartaPantalla}
                        getObjetos={getObjetoAleatorio}
                        guardaInput={guardaOpcionSeleccionada} 
                        userInputsTemporal={userInputs}></TerceraPantallaConImagenes>
                    : etapa === 3 &&
                    <TerceraPantallaConBotones
                        resetContenido={ResetearIndices}
                        contenidoIdIndice={objetoIdIndice}
                        cantidadDeValores={contadorObjetos}
                        objetoIdAleatorio={objetoIdAleator}
                        leftSubmit={DisminuirIndiceDeNavegacion}
                        rightSubmit={AumentarIndiceDeNavegacion}
                        aSiguientePantalla={ConstruirCuartaPantalla}
                        getObjetos={getObjetoAleatorio}
                        guardarOpcion={guardaOpcionSeleccionada }
                        getOpciones={opcionesRespuesta}
                    />
                }
                

                {etapa === 4 &&
                    <>
                        
                        <div className={style.contenido} style={{ padding: `0 0 ${cantidadCorrecta<=0? "5%": ""}`, height: "85%", flexGrow: 1, display: "flex", flexDirection: "column"}}>
                            {getMensajes()}
                            <MostrarResultados 
                                arrayItemImg={GenerarArrayImagenes()}
                                arrayAMostrar={GenerarArrayAMostrar()} 
                                calificaciones={[false,...calificaciones]}
                            />
                            <div style={{margin: "auto"}}>
                                <p className={style.textoTuviste}>TUVISTE</p>
                                <p className={style.resultado}>{`${cantidadCorrecta}/${contadorObjetos}`}</p>
                            </div>
                            
                        </div>

                        <BotonesJuegos funcionReiniciar={HandleReiniciar} funcionVolver={HandleBackButtonClick} esJuego={esJuego}/>
                    </>   
                }
            </div>
        </div>
    );
};

export default Game4;

