import React, { CSSProperties } from 'react'

interface Props{
  color:string;
  style:CSSProperties;
}

const TallerServicios = ({color,style}:Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 248 250" fill="none" style={style}>
    <g clip-path="url(#clip0_77_15210)">
      <path d="M27.5336 76.466C42.74 76.466 55.0672 64.1125 55.0672 48.8737C55.0672 33.6349 42.74 21.2814 27.5336 21.2814C12.3272 21.2814 0 33.6349 0 48.8737C0 64.1125 12.3272 76.466 27.5336 76.466Z" fill={color}/>
      <path d="M91.9763 250C85.0294 250 78.6896 245.288 76.9246 238.227C74.369 228.039 72.0957 218.644 69.8789 209.56C68.5657 204.14 67.2243 198.622 65.8124 192.891H28.9456C20.3608 192.891 13.4138 185.929 13.4138 177.326V101.016C13.4138 92.4129 20.3608 85.4511 28.9456 85.4511C37.5304 85.4511 44.4774 92.4129 44.4774 101.016V161.761H77.9554C85.0718 161.761 91.2845 166.615 93.0212 173.534C95.5769 183.722 97.8502 193.118 100.067 202.202C102.256 211.229 104.529 220.568 107.042 230.643C109.132 238.977 104.077 247.439 95.7604 249.533C94.4897 249.859 93.2189 250 91.9622 250H91.9763Z" fill={color}/>
      <path d="M220.466 76.466C235.673 76.466 248 64.1125 248 48.8737C248 33.6349 235.673 21.2814 220.466 21.2814C205.26 21.2814 192.933 33.6349 192.933 48.8737C192.933 64.1125 205.26 76.466 220.466 76.466Z" fill={color}/>
      <path d="M148.683 36.1194C154.192 22.7915 147.877 7.51171 134.577 1.9911C121.277 -3.52951 106.03 2.79958 100.521 16.1275C95.0125 29.4554 101.328 44.7352 114.628 50.2558C127.927 55.7764 143.175 49.4473 148.683 36.1194Z" fill={color}/>
      <path d="M156.038 250C154.781 250 153.51 249.844 152.24 249.533C143.923 247.439 138.868 238.977 140.958 230.643C143.485 220.554 145.759 211.201 147.947 202.145C150.15 193.075 152.423 183.694 154.965 173.534C156.701 166.615 162.914 161.761 170.031 161.761H203.523V101.016C203.523 92.4129 210.47 85.4511 219.054 85.4511C227.639 85.4511 234.586 92.4129 234.586 101.016V177.326C234.586 185.929 227.639 192.891 219.054 192.891H182.174C180.776 198.594 179.434 204.098 178.121 209.503C175.919 218.616 173.631 228.025 171.061 238.227C169.296 245.288 162.957 250 156.01 250H156.038Z" fill={color}/>
      <path d="M106.096 59.2738C107.861 60.8728 113.566 65.6271 122.277 66.2497C133.206 67.0138 140.676 60.6888 142.102 59.4295C142.652 59.2314 148.95 57.0523 154.654 61.0992C160.471 65.2309 160.415 72.0795 160.401 72.6172V116.34H88.9971V71.7257C88.9971 71.2305 89.3077 64.198 95.351 60.5473C100.251 57.59 105.277 59.0333 106.096 59.288V59.2738Z" fill={color}/>
      <path d="M192.933 127.25H55.0671V151.107H192.933V127.25Z" fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_77_15210">
      <rect width="248" height="250" fill={color}/>
      </clipPath>
    </defs>
    </svg>
  )
}

export default TallerServicios