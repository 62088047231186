
// import { IonContent, IonIcon, IonPage, IonToolbar,IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import React, { useEffect, useState } from 'react';
// import { arrowBackOutline } from 'ionicons/icons';

import style from "../../../theme/Juego2.module.css"; 
import estiloJuegos from "../../../theme/Juegos.module.css";
import estiloNeuronas from "../../../theme/PopoverNeuronas.module.css";

import Toolbar from '../componentes/Toolbar';
import TerceraPantalla from './componentes/TerceraPantalla';
import { useDato } from "../../../auth";
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { useAppDispatch, useAppSelector } from '../../../store';

import { obtenerNeuronas } from '../../../utils/neuronasUtil';
import { initialNeuronas } from '../../../hooks/reducer/reducerAuth';
import ResultadosNumPal from '../resultados/componentes/ResultadosNumPal';

import BotonesJuegos from '../componentes/BotonesJuegos';
import { obtenerMensaje } from '../../../utils/resultadoUtil';
import { changeCondicion, changePrograma, newActivity } from '../../../hooks/reducer/reducerActividad';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SegundaPantalla from './componentes/SegundaPantalla';

interface propTest{
    idTest: string;
    nombreTests: string;
    datos: string[];
    getDescripcion :() => JSX.Element;
    datosIds?: string[];
    cantidadDatos: number;
    inputType:"number" | "text";
    // titulo que se va a colocar sobre los datos que el usuario memorizó en los resultados
    tituloDatos: string;
}
const TestMemoria:React.FC<propTest> = (props) => {
    const navigate = useNavigate();
    const inputType = props.inputType;
    const idePrograma = props.idTest;
    const titulo = props.nombreTests;
    const datosIds = props.datosIds;
    const datos = props.datos;
    const tituloPalabras= props.tituloDatos;
    const cantidadDatos = props.cantidadDatos;
    const [etapa, setEtapa] = useState<number>(1);
    const [tiempo, setTiempo] = useState<number>(0);
    const [maxTiempo, setMaxTiempo] = useState<number>(0);
    const [userInputs, setUserInputs] = useState<string[]>();
    const [calificaciones, setCalificaciones] = useState<boolean[]>();
    const [inputRefs, setInputRefs] = useState<any[]>();
    const [cantidadCorrecta, setCantidadCorrecta] = useState<number>(0);
    
    const [nuevasNeuronas, setNuevasNeuronas] = useState<number>(0); 
    
    const userId = useAppSelector((state) => state.auth.userId);   
    const testId = useAppSelector((state) => state.activity.id);
    const neuronas = useAppSelector((state) => state.auth.neuronas);
    const idEntrenamiento = useAppSelector((state) => state.entrenamiento?.current_entrenamiento?.id);
    const esEntrenamiento = useAppSelector((state) => state.activity?.name == "entrenamiento");

	const {				
		state,
	} = useDato();
    const dispatch = useAppDispatch();
    const {
        guardarDatosCalificacion,        
    } = useJuegos();

    const handleTiempo = () => {
        console.log("🚀 ~ handleTiempo ~ etapa:", etapa)
        if (etapa === 2) {
            construirPantallaRespuestas();
        } else if (etapa === 3) {
            construirPantallaResultados();
        } else {
            throw new Error("tiempo iniciado fuera de lugar");
        }
    }
    const checarRespuestas = () => {
        console.log("test memoria inicial")
        const calificacionesTemporal = calificaciones.slice();
                
        for (let indice = 0; indice < userInputs.length; indice++) {
            checarRespuesta(indice, calificacionesTemporal);
        }
        setCalificaciones(calificacionesTemporal);
        ordenarRespuestas(calificacionesTemporal);
        const cantidadCorrectaTemporal = calificacionesTemporal.reduce((x, y) => x + Number(y), 0);
        setCantidadCorrecta(cantidadCorrectaTemporal);
        const neuronasNuevas = obtenerNeuronas(props.idTest, 1, cantidadCorrectaTemporal)
        console.log("neuronas nuevas"+neuronasNuevas)
		setNuevasNeuronas(neuronasNuevas);
        const neuronasTotales = neuronas + neuronasNuevas;
        dispatch(initialNeuronas({cantidad:neuronasTotales}));
        // Guarda la calificacion del usuario
        guardarDatosCalificacion(userId, testId, idePrograma, neuronasNuevas, null, cantidadCorrectaTemporal, tiempo, true, state.nombres, state.pais)    
    }
    const ordenarRespuestas =(calificaciones: boolean[]) => {
        console.log("🚀 ~ file: TestMemoriaInicial.tsx:96 ~ ordenarRespuestas ~ calificaciones:", calificaciones)
        
        let newUserInputs = userInputs.slice();
        for (let i=0;i<cantidadDatos;i++){
            let indice = props.datosIds ? datosIds[i] : i;
            
            if(calificaciones[i]){
                console.log("🚀 ~ file: TestMemoriaInicial.tsx:102 ~ ordenarRespuestas ~ i:", i)
                
                const elementoEncontrado = newUserInputs.find(item => compararPalabras(item,datos[indice]));
                const indiceEncontrado = newUserInputs.indexOf(elementoEncontrado);
                const primerElemento = newUserInputs[i];
                newUserInputs[i]=elementoEncontrado;
                newUserInputs[ indiceEncontrado ]= primerElemento;
                console.log(newUserInputs);
                setUserInputs(newUserInputs);
                
            }
            
        }

    }
    const limpiarString = (str: string) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
    }
    const compararPalabras = (palabra1: string, palabra2: string) => {
        
        if (limpiarString(palabra1) === limpiarString(palabra2)) { 
            return true;
        }else{
            return false;
        }
    }
    const checarRespuesta = (numeroIndice: number, calificacionesTemporal: boolean[]) => {
        let calificacionIndice = numeroIndice;
        const palabraMemorizada = limpiarString(userInputs[numeroIndice]);
        console.log("🚀 ~ file: TestMemoriaInicial.tsx:132 ~ checarRespuesta ~ palabraMemorizada:", palabraMemorizada)
        const palabrasAnteriores = userInputs.slice(0, numeroIndice);
        console.log("🚀 ~ file: TestMemoriaInicial.tsx:134 ~ checarRespuesta ~ palabrasAnteriores:", palabrasAnteriores)
        const palabraYaExistia = palabrasAnteriores.includes(palabraMemorizada);
        console.log("🚀 ~ file: TestMemoriaInicial.tsx:136 ~ checarRespuesta ~ palabraYaExistia:", palabraYaExistia)
        
        for (let i=0;i<cantidadDatos;i++){
            let indice = props.datosIds ? datosIds[i] : i;

            if (compararPalabras(datos[indice], palabraMemorizada)) {            
                calificacionesTemporal[i] = true;
                console.log("🚀 ~ file: TestMemoriaInicial.tsx:143 ~ checarRespuesta ~ i:", i)
                console.log("🚀 ~ file: TestMemoriaInicial.tsx:143 ~ checarRespuesta ~ calificacionesTemporal:", calificacionesTemporal)
                break;            
            }
        }
        
    }

    const getPalabraWrapper = (indice : number) => {
        let indicePalabra= props.datosIds ? datosIds[indice] : indice;
	    return datos[indicePalabra];
    }

    
    const TiempoMemorizando = (tiempoMem:number) =>{
        if(etapa==2){
            setTiempo(tiempoMem);
        }
    }
    const initializarConfig = () => {
        setUserInputs(Array(cantidadDatos).fill(""));
        setCalificaciones(Array(cantidadDatos).fill(false));
        setInputRefs(Array(cantidadDatos).fill(null));
    }
    
    const construirPantallaRespuestas = () => {
        setMaxTiempo(120);
        setEtapa(-1);
        setTimeout(() => setEtapa(3), 10);
    }
    const construirPantallaResultados = () => {
        checarRespuestas();
        setEtapa(-1);
        setTimeout(() => setEtapa(4), 10);
    }
    const constructor = () => {        
        setEtapa(1);
        initializarConfig();
        setMaxTiempo(30);
    }

    const DatosMemorizar = () => {
        const valores = [];

        for (let i = 0; i < cantidadDatos; i++) {
            const valor = props.datosIds ? datos[props.datosIds[i]] : datos[i];

            valores.push(
                valor
            );
        }
        return (
            <div style={{width:'100%',display:'flex',flexWrap:'wrap',padding:'10px 10%', justifyContent:'center',rowGap:'1svh',maxHeight:'60svh',overflowY:'auto'}}>
                {valores.map((valor) => (
                    <>
                        <p key={valor} style={{width:'100%',textAlign:'center',fontWeight:'600',margin:'0',fontSize:'3svh',
                            border:'1px solid var(--color-purple-dark)',borderRadius:'25px'
                        }}>{valor}</p>
                    </>
                ))}
                {/* <button className={`${estiloJuegos.buttonEmpezar} ${estiloJuegos.buttonFinalizar}`}
                onClick={construirPantallaRespuestas} style={{margin:'0',height:'fit-content',padding:'1% 4%'}}>LISTO</button> */}
            </div>
          );
        
    }
    const getMensajes = () =>{
        
        let porcentaje = cantidadCorrecta/cantidadDatos;
        console.log(porcentaje);
        let mensajePersonalizado =obtenerMensaje(porcentaje);
        if (porcentaje > 0) {
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p  className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                    <h3 style={{margin:"1vh 0 0 0"}}className = {estiloNeuronas.neuronas}>GANASTE +{nuevasNeuronas} NEURONAS</h3>
                    <h3 className={estiloNeuronas.totalNeuronas}> TOTAL: {neuronas}</h3>
                </div>

            );
        }else{
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p style={{marginBottom:"2vh"}} className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                    
                </div>

            );
        }
            
        
    }
    const guardaInputAyudante = (target: any) => {
        const indice = Number(target.id.split('-')[1]);

        const userInputsTemporal = userInputs.slice();
        userInputsTemporal[indice] = limpiarString( target.value);
        setUserInputs(userInputsTemporal);
        
        const inputRefsTemporal = inputRefs.slice();
        inputRefsTemporal[indice] = target;
        setInputRefs(inputRefsTemporal);
    }

    const guardaInput = (event: any) => {
        
        if (event.target.name === null || event.target.name === undefined) {
            console.log("hubo un error, no se reconoce el input");
        } else {
            guardaInputAyudante(event.target);
        }
    };

    function aSegundaPantalla(): void {
       setEtapa(2); 
    }
    
    useEffect(() => {
      constructor()
    }, [])
    

    const handleBackButtonClick = async () => {
        console.log("🚀 ~ handleBackButtonClick ~ esEntrenamiento:", esEntrenamiento)
        if(esEntrenamiento){
            console.log("click")
            await dispatch(newActivity({id:idEntrenamiento,name:"entrenamiento"}))
            await dispatch(changePrograma({id:idEntrenamiento}))
            dispatch(changeCondicion({condicion:10}))
			navigate(-2);
		}else{
			navigate(-1);
		}
    };

    return (
        <div id='page'>
            <div className={etapa === 1 ? (tituloPalabras==='Palabra' ? estiloJuegos.toolbarNeuralTrainer : estiloJuegos.toolbarCalculoMatematico) : estiloJuegos.toolbar_default} >
                <h1 className={`title ${estiloJuegos.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'2.37svh'} :{}}> {titulo} </h1>
            </div>
            
            {etapa === 1 && 
                <div className={tituloPalabras==='Palabra' ? estiloJuegos.contenedorIconoNeural : estiloJuegos.contenedorIconoMatematico}>
                    {tituloPalabras==='Palabra' ?
                        <img src='/assets/componentes/Juegos/ABC.svg' className={estiloJuegos.icono}></img>
                        :<img src='/assets/componentes/Juegos/CÁLCULO MATEMÁTICO.svg' className={estiloJuegos.icono}></img>
                    }
                    
                </div>
            }
            <div id='content_default' className={etapa === 3 ? 'teclado' : ''}>
            {etapa !== 1 &&
                <Toolbar
                etapa={etapa}
                handleTiempo={handleTiempo}
                maxTiempo={etapa === 2 ? 60 : 120}
                setTiempoMemoria={TiempoMemorizando}
                ascendente={false}
                />
            } 
                {etapa === 1 &&
                    <div className={estiloJuegos.primeraPantalla}>

                        {props.getDescripcion()}   
                        <button     className={estiloJuegos.buttonEmpezar} onClick={aSegundaPantalla}>
                        EMPEZAR
                        </button>

                    </div>
                
                }
                {etapa === 2 &&
                    DatosMemorizar()
                }
                {etapa === 3 &&
                    <TerceraPantalla
                    esJuego={false}
                    aCuartaPantalla={construirPantallaResultados}
                    userRespuestas={userInputs}
                    guardaInputs={guardaInput}
                    inputType={inputType}
                    />
                }
                {etapa === 4 &&
                    <>
                        <div className={style.contenido} style={{height: "70svh"}}>
                            {getMensajes()}
                            

                            <ResultadosNumPal
                            userInputs={userInputs}
                            calificaciones={calificaciones}
                            getPalabraWrapper={getPalabraWrapper}
                            game={titulo}
                            tituloPalabras={tituloPalabras}
                            height={cantidadCorrecta <= 0 ? '75%' : '50%'}
                            />
                            {cantidadCorrecta>0 ?
                                <div>
                                    <p className={style.textoTuviste}>tuviste</p>
                                    <p className={style.resultado}>{`${cantidadCorrecta}/${cantidadDatos}`}</p>
                                </div>
                                :<></>
                            }
                            
                        </div>
                        {/* 
                        <div style={{justifyContent: 'center', textAlign: 'center', width: '100%'}}>
                            <IonButton onClick={handleBackButtonClick} className='text-center'>Finalizar</IonButton>
                        </div>

                        */}

                        <BotonesJuegos funcionReiniciar={() => {}} funcionVolver={handleBackButtonClick} esJuego={false}/>


                    </>
                 } 

            </div>
        </div>
    );
};

export default TestMemoria;
