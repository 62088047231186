import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { aPrograma } from "../../../modelos/Programa";
import { useTests } from "../../../hooks/controlador/useTests";
import { useToast } from "../../../hooks/useToast";
// import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
// import { closeCircle } from "ionicons/icons";

interface Props {
    openVentanaPrograma:boolean;
    setOpenVentanaPrograma: Dispatch<SetStateAction<boolean>>;
    nombreTest:string;

}

const ModalCrearUsuariosTest: React.FC<Props> = (props) => {
    const [arrayOpciones, setArrayOpciones] = useState([]);
    const [nombreProgram, setNombreProgram] = useState('');

    const { 
        consultarTests, 
        consultarProgramasTest, 
        consultarProgramasParaTest,
        crearProgramaTest,
        consultarParticipantesTest,
     } = useTests();
    
    const {
        presentNoIngresoUsuario
    } = useToast();

    const CrearPrograma = async () => {
        arrayOpciones.map( async (element) => {
            if(element.crear){
                const programa = aPrograma(element.nombre);
                console.log("🚀 ~ file: ModalCrearProgramasTest.tsx:34 ~ arrayOpciones.map ~ element:", element)
                console.log("🚀 ~ file: ModalCrearProgramasTest.tsx:35 ~ arrayOpciones.map ~ programa:", programa)
                console.log("🚀 ~ file: ModalCrearProgramasTest.tsx:39 ~ arrayOpciones.map ~ props.nombreTes:", props.nombreTest)
                await crearProgramaTest(props.nombreTest, element.id, programa);
            }
        })
        props.setOpenVentanaPrograma(false)

    }

    const consultaOpciones = async () => {
        const resultados = await consultarProgramasParaTest();
        resultados.map((element) => {
            setArrayOpciones((prevState) => [
                ...prevState,
                {id: element.id, nombre:element.nombre, crear: false},
            ]);
        })
    }

    useEffect(() => {
        setArrayOpciones([])
        consultaOpciones()
    },[])

    // useEffect(() => {        
    //     console.log("🚀 ~ file: ModalCrearProgramasTest.tsx:52 ~ arrayOpciones:", arrayOpciones)
    // },[arrayOpciones])

    return (
        <></>
        // <IonModal isOpen={props.openVentanaPrograma}>
        //     <IonHeader>
        //         <IonToolbar>
        //         <IonButtons slot="end">
        //             <IonButton onClick={() => props.setOpenVentanaPrograma(false)}>
        //                 <IonIcon icon={closeCircle} />
        //             </IonButton>
        //         </IonButtons>
        //         <h1 className="ion-text-center title">Crear Programa</h1>
        //         </IonToolbar>
        //     </IonHeader>
        //     <IonContent className="ion-padding">
        //         <IonList>
        //                 {arrayOpciones.map((element,index) => 
        //                 <IonItem key={index}>
        //                     <IonCheckbox slot="start" value={element.crear} onIonChange={(event) => {
        //                         const arrayOpcionesCopy = [...arrayOpciones];
        //                         arrayOpcionesCopy[index].crear = event.detail.checked;
        //                         setArrayOpciones(arrayOpcionesCopy);
        //                     }}/>
        //                     <IonLabel>{element.nombre}</IonLabel>
        //                 </IonItem>
        //                 )}
        //         </IonList>
        //         <IonButton expand="block" onClick={CrearPrograma}>Crear</IonButton>
        //     </IonContent>
        // </IonModal>
    );
}

export default ModalCrearUsuariosTest