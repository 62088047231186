
import { DocumentSnapshot } from 'firebase/firestore';

import { nomLecturas, nomProgLecturaComprension, nomProgLecturaVelocidad, nomTest, nomUsuarioTests, nomUsuarios } from '../../datos/nombresBD';

// Hooks
import { useBaseDeDatos } from '../baseDeDatos/useBaseDeDatos';

import { nomTestProgramas } from '../../datos/nombresBD';
import { Lectura, toLectura } from '../../modelos/Lectura';
import { Calificacion, toCalificacion } from '../../modelos/Calificacion';
import { useJuegos } from './useJuegos';
import { test_general } from '../../datos/DatosTests';

export const useLectura = () => {

    const {
        guardarDatosCalificacion,
        guardarDatosCalificacionTestGeneral
      } = useJuegos();

    /**
     * Funciones para acceder el base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,  
        borrarDoc,
        recogerDoc,      
        recogerDocs,
        agregarCollection,    
        recogerDocsOrdenados,
        recogerDocsFiltrados,
    } = useBaseDeDatos();

    const consultarLecturaTest = async (idTest:string):Promise<Lectura> => {

        var docTest : DocumentSnapshot;        
        docTest = await recogerDoc(nomTest + "/" + idTest + "/" + nomTestProgramas + "/" + nomProgLecturaVelocidad);
        const idLectura = docTest.get("idLectura");
        const doc = await recogerDoc(nomLecturas + "/" + idLectura);
        
        if(doc.exists()){
            return toLectura(doc);
        }else{
            return null
        }
    }

    /*
    const correcciónLecturaRegister = async ():Promise<void> => {
        var docTest : DocumentSnapshot[] = [];
        docTest = await recogerDocs(nomUsuarios);
        for(const doc of docTest){
            recorrerTest(doc.id)
        }
        
    }
    
    const recorrerTest = async(idUser:string):Promise<void> => {
        var docTest : DocumentSnapshot[] = [];
        docTest = await recogerDocs(nomUsuarios +"/" + idUser+"/"+ nomUsuarioTests);
        for(const doc of docTest){
            confirmarCorreccion(idUser,doc.id)
        }
    }

    const confirmarCorreccion = async (idUser: string, idTest:string) => {
        var docLectura: DocumentSnapshot;
        var docComprension: DocumentSnapshot;
        console.log("ideUser ", idUser, " idTest ", idTest)

        docLectura = await recogerDoc(nomUsuarios + "/" + idUser + "/" + nomUsuarioTests + "/" + idTest + "/" + nomTestProgramas + "/" + nomProgLecturaVelocidad);
        docComprension = await recogerDoc(nomUsuarios + "/" + idUser + "/" + nomUsuarioTests + "/" + idTest + "/" + nomTestProgramas + "/" + nomProgLecturaComprension);
        const lectura = toCalificacion(docLectura);

        if(docLectura.exists()){
            console.log("existe lectura velocidad")
            if(docComprension.exists()){
                console.log("existe lectura velocidad")
                arregloCompletoRegistros(idUser,idTest,lectura,toCalificacion(docComprension));
            }
            if(!lectura.resultado2){
                arregloEstadoRegistros(idUser,idTest,lectura);
            }
        }
        return null
    }

    const arregloEstadoRegistros = async (idUser:string, idTest: string, lectura: Calificacion):Promise<void> => {
        await borrarDoc(nomUsuarios + "/" + idUser + "/" + nomUsuarioTests + "/" + idTest + "/" + nomTestProgramas + "/" + nomProgLecturaComprension);
        await borrarDoc(nomTest + "/" + idTest + "/" + nomTestProgramas + "/" + nomProgLecturaComprension + "/" + idUser);
        if(idTest == test_general){
            await guardarDatosCalificacionTestGeneral(idUser, nomProgLecturaVelocidad, lectura.nivel, lectura.resultado,lectura.tiempo, false, lectura.nombre, lectura.dependencia);
        }else{
            await guardarDatosCalificacion(idUser, idTest, nomProgLecturaVelocidad, null, lectura.resultado,lectura.tiempo, false, lectura.nombre, lectura.dependencia,null);
        }
    }

    const arregloCompletoRegistros = async (idUser:string, idTest: string, lectura: Calificacion, comprension: Calificacion):Promise<void> => {
        await borrarDoc(nomUsuarios + "/" + idUser + "/" + nomUsuarioTests + "/" + idTest + "/" + nomTestProgramas + nomProgLecturaComprension);
        await borrarDoc(nomTest + "/" + idTest + "/" + nomTestProgramas + "/" + nomProgLecturaComprension + "/" + idUser);
        if(idTest == test_general){
            await guardarDatosCalificacionTestGeneral(idUser, nomProgLecturaVelocidad, lectura.nivel, lectura.resultado,lectura.tiempo, true, lectura.nombre, lectura.dependencia,comprension.resultado2);
        }else{
            await guardarDatosCalificacion(idUser, idTest, nomProgLecturaVelocidad, null, lectura.resultado,lectura.tiempo, true, lectura.nombre, lectura.dependencia,comprension.resultado2);
        }
    }
    */


    return {        
        consultarLecturaTest,        
    }
}
