
/** Interna */
import { useDato } from '../auth';
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from '../store';
import { useTests } from '../hooks/controlador/useTests';
import { newTorneo } from '../hooks/reducer/reducerActividad';
import { modTestInicial } from '../hooks/reducer/reducerAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { TestInicial } from '../modelos/Test';
import  "../theme/pages.css"; 

import { useStorgeImagenes } from '../hooks/baseDeDatos/useStorageImagenes';
import { useEntrenamiento } from '../hooks/controlador/useEntrenamiento';

import useRegistroEntrenamiento from '../hooks/controlador/useRegistroEntrenamiento';
import { RegistroEntrenamiento } from '../modelos/entrenamiento/RegistroEntrenamiento';
import Loading, { Spinner } from './entrenamiento/componentes/Loading';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../firebase';


/**
 * Pagina principal del usuario
 *
 * @author [Aiden Cullo](https://github.com/cullo7)
 */
const HomePage: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [flag] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [testsAdmin, setTestsAdmin] = useState([]);
  const [entrenamientoActual, setEntrenamientoActual] = useState(null);
  const [sesionActual, setSesionActual] = useState(null);
  
  // const {
  //   functionArreglo,
  //   functionArregloRegistro
  // } = useRegistroEntrenamiento();

  const {
    state,
  } = useDato();
  
  
  const [photoPerfil, setPhotoPerfil] = useState("assets/images/avatar.svg");
  
  const userId = useAppSelector((state) => state.auth.userId);
  const neuronas = useAppSelector((state) => state.auth.neuronas);
  const nombre = state?.nombres;  
  
  
  useEffect(() =>{
    
    if (location.pathname === "/my/home"){
      
      console.log("🚀 ~ file: HomePage.tsx:84 ~ state:", state);
      setTestsAdmin([]);
      setLoading(true);
      Promise.allSettled(
        [
          traerEntrenamiento(),
          consulta()
        ]
      ).then(() => setLoading(false));
      var divParaRutaEspecifica = document.getElementById("supercerebro-fondo");
      divParaRutaEspecifica.style.display = "block";
    }else{
      var divParaRutaEspecifica = document.getElementById("supercerebro-fondo");
      divParaRutaEspecifica.style.display = "none";
      
    }
    
  },[location])

 	
  const {
    consultarTestInicialUsuario,
    crearTestInicialUsuario,
    consultarTestsAdminUsuario,
  } = useTests();

  const {
    agregarImg,
    obtenerURL
  } = useStorgeImagenes();

  const {
    consultarEntrenamientos
  } = useEntrenamiento();

  const {
    consultarRegistroEntrenamiento
  } = useRegistroEntrenamiento();



  const traerEntrenamiento = async () => {
    const array = await consultarEntrenamientos(userId)
    let registro:RegistroEntrenamiento = null;
    switch(array.length){
      case (0):
        registro = null;
        setEntrenamientoActual(null);
        setSesionActual(null);
        break;
        
      case (1):
        registro = await consultarRegistroEntrenamiento(userId,array[0].id);
        setEntrenamientoActual(array[0].id);
        if(registro?.completadoSesion == true){
          if(registro?.completadoEntrenamiento !== true){
            setSesionActual(registro?.ordenSesion+1);
          }else{
            setSesionActual(null);
          }
        }else{
          setSesionActual(registro?.ordenSesion);
        }
        break;
        
      default:
        for(let i in array){
          registro = await consultarRegistroEntrenamiento(userId,array[i].id);
          if(registro?.completadoSesion == true){
            if(registro?.completadoEntrenamiento == false){
              setEntrenamientoActual(array[i].id);
              setSesionActual(registro?.ordenSesion+1);
              break;
            }else{
              setEntrenamientoActual(null)
              setSesionActual(null)
            }
          }else{
            setEntrenamientoActual(array[i].id);
            setSesionActual(registro?.ordenSesion);
            break;
          }
        }
        break;
    }
  }

  const consulta = async () => {
    const testInicial:TestInicial = await consultarTestInicialUsuario(userId);    
    if (testInicial === undefined) {      
      crearTestInicialUsuario(userId);      
    }else if(testInicial.completado){
      dispatch(modTestInicial({condicion: true}))
    }else if(!testInicial.completado){
      dispatch(modTestInicial({condicion: false}))
    }
    
    // CONSULTA LOS TEST ADMINISTRADOS
    const testAdministrados = await consultarTestsAdminUsuario(userId);				
		setTestsAdmin(await testAdministrados)
  }

  const buscarPhoto = async() => {
    const respuesta = await obtenerURL("usuarios" + "/" + userId + "/" + "imagenes" + "/" + "fotoPerfil");

    if(respuesta) setPhotoPerfil(respuesta);
  }

  useEffect(() => {
    if(!openModal){
      buscarPhoto()
    }
  }, [flag,openModal])
  
  const inicializar = (id: string, name: string, torneo: boolean) => {
    dispatch(newTorneo({ id: id, name: name, torneo:torneo}))
  }
  
 return (
    <div id="page"style={{WebkitTapHighlightColor:'black !important'}}>
      <div id='toolbar_default' className='home-toolbar'>
        <div className='contenedor-title-home' onClick={() => {navigate("/my/home/datosPersonales")}}>
          <div className='contenedor-title-foto'>
            <h1 className='title-home'>BIENVENIDO <span className='nombre'>{nombre}</span></h1>
            <div style={{left:'4.1%', width:'fit-content', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <div className='marco-foto'>
                <div className='contenedor-foto'>
                  <img src={photoPerfil} alt="Foto de usuario" className='foto-usuario'></img>
                </div>
              </div>
              <div className='div-neurona-home'>
                <div className='div-neurona'>
                  <img style={{maxWidth:'100%'}} src='/assets/icon/icon_neuronas_tab.svg' />
                </div>
                <p className='degradee'>{neuronas}</p>
              </div>
            </div>
          
            
          </div>
          <div className='contenedor-empezar-button'>
            <div className='text-empezar'>
              Ver Perfil
            </div>
            {/* <IonButton slot="end" fill="clear" className='button-editar' onClick={() => setOpenModal(true)}>editar</IonButton> */}
          </div>
        </div>
        <div style={{width:'100%'}}>
          <div style={{backgroundColor:'#FFFFFF',height:'2px',width:'90%',margin:'1vh 5%'}}></div>
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',position:'relative',background:'transparent',height:'35vh',width:'100%',zIndex:'3'}}>
            <img src='/assets/images/supercerebro/sc_SenalandoCerebro.png' style={{position:'relative',zIndex:'3',height:'110%',top:'-5%'}}></img>
            <div style={{background:'#45225F',borderRadius:'100%',top: '10%', width: '27vh', height: '80%',position: 'absolute',zIndex:'2'}}></div>
          </div>
        </div>
      </div>

      <div id='content_default' className={''} style={{height:'fit-content'}}>
        <div className={`centerFlex containerHome`} style={{height: "100%"}}>
          {loading?
            <Spinner/>:
            <>
              <p className='subtitleHome' >{sesionActual == null ? '¿Listo para desafiar y mejorar tus habilidades cerebrales?': '¿Listo para continuar fortaleciendo tu mente?'}</p>
              {sesionActual == null ? 
                <p className='subtitleHome subtitleHome_black subtitleHome_line'  style={{fontSize:'medium'}}>{'Descubre nuestros entrenamientos'}</p>:
                <p className='subtitleHome subtitleHome_black'  style={{fontSize:'1.5vh'}}>{'Estás en la'}</p>
                }
              {sesionActual !== null &&
                <>
                  <p className='containerHome-entrenamiento'>
                    Sesión {sesionActual}
                  </p>
                  <p  className='subtitleHome_black subtitleHome-noMargin'>
                    de {entrenamientoActual}
                  </p>
                </>}
              <button id='button_rosado_default' className='home-button_rosado' onClick={() => navigate('/my/entrenamiento')}>
                {sesionActual == null ? 'Empieza hoy' : '¡ A entrenar !'}
              </button>
            </>
          }
            
            {/*false && <IonList style={{minHeight:"10vh"}}>
                {tests.map((element, index) => (
                  <IonCard key={index} style={{ width: '95%' }}>
                    <IonItem routerLink={'/my/testInicialPage'} onClick={() => {
                      inicializar(element.id, element.name ,element?.torneo);
                    }}>
                      <IonIcon icon={saveOutline} slot="start" />
                      <IonLabel>{element.nombre}</IonLabel>
                    </IonItem>
                  </IonCard>
                )
                )}
                {tests.map((element, index) => (
                  <IonButton key={index} routerLink={'/my/testInicialPage'} onClick={() => {
                    inicializar(element.id, element.name ,element?.torneo);
                  }} className='button-test-inicial'>
                      <IonLabel>{element.nombre}</IonLabel>
                  </IonButton>
                )
                )}           
                                
              </IonList>*/}
          { false && testsAdmin.length !== 0 && <label>Administración de Tests</label>}
          {false && testsAdmin.map((element, index) => (
            <div className='card-home' key={index} style={{ width: '95%' }}>
              <div onClick={() => {
                inicializar(element.id, element.name, element?.torneo);
                navigate(`/my/TestAdminPage/${element.nombre}`)
              }}>
                <label>{element.nombre}</label>
              </div>
            </div>
          )
          )}

    {/*     
              {/* {state.administrador && <IonRow>
                <IonCol>
                  <IonCard>
                    <IonItem routerLink="/my/entidades">
                      <IonIcon icon={peopleCircle} slot="start" />
                      <IonLabel>Entidades y Areas</IonLabel>
    
                    </IonItem>
                  </IonCard>
                </IonCol>
              </IonRow>} */}
    
              {/* {state.administrador && <IonRow>
                <IonCol>
                  <IonCard>
                    <IonItem routerLink="/my/AdminTests">
                      <IonIcon icon={albums} slot="start" />
                      <IonLabel>Administración Tests</IonLabel>
    
                    </IonItem>
                  </IonCard>
                </IonCol>
              </IonRow>} */}

        </div>
      </div>
      {/* {openMessage && <div id="modal_cortina_default" onClick={() => setOpenMessage((prev) => !prev)}>
        <div id="modal_default" className="scroll-up">
          <label className='title-mensaje'>{mensaje == 'Ingrese su codigo promocional' ? 'CÓDIGO' :'¡ATENCION!'}</label>
          <div className='linea'></div>
          <label className='mensaje '>
            {mensaje} HOLA
          </label>
          <br></br>
          {mensaje == 'Ingrese su codigo promocional' ?
          <div>
            <input type='text' placeholder='XXX-XXX-XXX' style={{width:'70%',textTransform:'uppercase'}}></input>
            <div style={{width:'40%',height:'5vh'}}>
              <button id='button_rosado_default' style={{height:'100%'}} onClick={registrarCodigo}>
              Ok
              </button>
            </div>
          </div>
          :
          <div style={{width:'40%',height:'5vh'}}>
            <button id='button_rosado_default' style={{height:'100%'}} onClick={registrarCodigo}>
            Ok
            </button>
          </div>}
          <br></br>
        </div>
      </div>} */}
    </div>
  );
}

export default HomePage;
