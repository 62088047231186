import { useEffect, useRef, useState } from "react"
import { useDato } from "../../../auth";

import Loading from "../componentes/Loading"
import Checkout from "../../../Checkout";

import estiloJuegos from "../../../theme/Juegos.module.css";
import stylePopover from "../../../theme/PopoverNeuronas.module.css";
import estiloLanding from "../../../theme/entrenamiento/Landing/landing.module.css";
import VideoConContador from "../componentes/VideoConContador";
import { Factura, ProductoReferencia, TiposProductos } from "../../../modelos/Facturas";
import PuntosMorados from "../componentes/PuntosMorados";
import Selector from "../componentes/Selector";
import { paises } from "../../../datos/paises";
import { useParams } from "react-router-dom";
import { useEntrenamiento } from "../../../hooks/controlador/useEntrenamiento";
import { agregarAlCarroFacebook, pageViewFacebook } from "../../../ConfigPixel";
import { useToast } from "../../../hooks/useToast";
import { useAppSelector } from "../../../store";

interface Formulario{
  nombre:string;
  codigoTel:string;
  telefono:string;
  especificaciones?:string;
  direccion?:string;
  ciudad?:string;
  correo?:string;
  documentoIdentidad?:number;
}

interface InformacionCompra {
  costo: number;
  descuento: number;
  idsProductos: ProductoReferencia[];
}

type Params = {
  codigoParams:string;
}

const LandingLibro = () => {
  const userId = useAppSelector((state) => state.auth?.userId)
  const dato = useDato();
  const {codigoParams} = useParams<Params>();

  const ENTORNO = (process.env.REACT_APP_ENVIRONMENT === 'production')? "produccion": "desarrollo";
  const VIDEOURL1 = `https://firebasestorage.googleapis.com/v0/b/supercerebros-${ENTORNO}.appspot.com/o/publico%2Fneurobics%2Fventa_neurobics.mp4?alt=media`;

  const [usuarioFormulario, setUsuarioFormulario] = useState<Formulario>({nombre:'',codigoTel:'+57',telefono:''})
  const [infoCompra, setInfoCompra] = useState<InformacionCompra>({costo:45000,descuento:0,idsProductos:[]})
  const [loading, setLoading] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)
  const [montar,setMontar] = useState(false);
  const [facturaPagoDirecto, setFacturaPagoDirecto] = useState<{id:string, factura:Factura} | null>(null);
  const {
    consultarCampaña,
    incrementarVisitas,
  } = useEntrenamiento();
  const { presentMensajePersonalizado } = useToast()

  
  const ValidarEmail = (valor:string):Boolean => {
    var reg:RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var regOficial:RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (reg.test(valor) && regOficial.test(valor)) {
      console.log("válido oficial y extraoficialmente");
      return true
    } else if (reg.test(valor)) {
      console.log("válido extraoficialmente");
      return true
      
    } else {
      console.log("incorrecto");
      return false
    }
  }

  const ValidarDatos = (esDigital:boolean) => {
    if(usuarioFormulario.codigoTel && usuarioFormulario.telefono && usuarioFormulario.nombre){
      return true
    }
    if(esDigital){
      if(ValidarEmail(usuarioFormulario.correo)){
        return true
      }
      return false
    }else{
      if(usuarioFormulario.ciudad && usuarioFormulario.direccion){
        return true
      }
      return false
    }
  }

  const BundleHandleEnviarInfo = (esDigital:boolean) => {
    if(ValidarDatos(esDigital)){
      if(esDigital){
        setInfoCompra((state) => ({
          ...state,
          ['costo']:24900,
          ['idsProductos']: [{id_referencia:'entrena_tu_mente_pdf',tipo:TiposProductos.tipoLibroDigital}as ProductoReferencia]
        }))
      }else{
        setInfoCompra((state) => ({
          ...state,
          ['costo']:45000,
          ['idsProductos']: [{id_referencia:'entrena_tu_mente',tipo:TiposProductos.tipoLibroFisico}as ProductoReferencia]
        }))
      }
      HandleSubmit()
    }else{
      presentMensajePersonalizado("Datos incompletos")
    }
  }

  function HandleSubmit(): void {
    agregarAlCarroFacebook();
    setOpenPopover(true);
    setMontar(true)
  }

  const CodigoInfo = async (codig: string) => {
    const codificado = await consultarCampaña(codig);

    if(codificado.descuento){
      setInfoCompra((state) => ({
        ...state,
        ['descuento']:codificado.descuento
      }))
    }

    incrementarVisitas(codig).catch(console.error);
  }

  useEffect(() => {
    pageViewFacebook();   
    console.log("🚀 ~ useEffect ~ codigoParams:", codigoParams)
    if(codigoParams){
      CodigoInfo(codigoParams)
    }
  }, [])


  // Funcionamiento interfaz
  const setCodigoTel = (e:any) => {
    setUsuarioFormulario((state) => ({
      ...state,
      ['codigoTel']: e.toString()
    }))
  }

  const DesmontarPopover = () =>{
      setMontar(false)
      setTimeout(() => {
        setOpenPopover(false);
      }, 1000);
    }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuarioFormulario((state) => ({
      ...state,
      [name]: value
    }));
  };

  const ComponentInputs = (esDigital:boolean) => {
    // Flag true si es producto digital
    return(
      <div style={{display:'grid',padding:'5%',gap:'5px'}}>
        <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>¿A DONDE ENVIAMOS TU LIBRO?</p>
        <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>NOMBRE COMPLETO*</p>
        <input type='text' name="nombre" style={{width:'100%'}} placeholder='Ingrese su nombre' value={usuarioFormulario.nombre} onChange={handleChange} className='inputPersonalizado2'></input>
        <div style={{display:'flex',flexDirection:'row',gap:'5%'}}>
            <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{width:'45.5%',color:'var(--color-purple-dark)'}}>PAÍS*</p>
            <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>NÚMERO DE TELÉFONO*</p>
        </div>
        <div style={{display:'flex',flexDirection:'row',height:'4.97svh',gap:'5%'}}>
            <div style={{height:'100%'}}>
            <Selector setSelection={setCodigoTel} selection={usuarioFormulario.codigoTel} opciones={paises} showValues={true}
            style={{backgroundColor:'#D9D9D9'}}/>
            </div>
            <input type='number' name="telefono" style={{width:'100%'}} placeholder='Ingrese su telefono' value={usuarioFormulario.telefono} onChange={handleChange} className='inputPersonalizado2'></input>
        </div>
        {esDigital ?<>
          <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>CORREO ELECTRÓNICO*</p>
          <input type='text' name="correo" style={{width:'100%'}} placeholder='Ingrese su correo' value={usuarioFormulario.correo} onChange={handleChange} className='inputPersonalizado2'></input>
        </>:
        <>
          <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>Documento de identidad*</p>
          <input type='number' name="documentoIdentidad" style={{width:'100%'}} placeholder='Ingrese su documento de identidad' value={usuarioFormulario.documentoIdentidad} onChange={handleChange} className='inputPersonalizado2'></input>
          <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>CIUDAD*</p>
          <input type='text' name="ciudad" style={{width:'100%'}} placeholder='Ingrese su ciudad' value={usuarioFormulario.ciudad} onChange={handleChange} className='inputPersonalizado2'></input>
          <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>DIRECCION*</p>
          <input type='text' name="direccion" style={{width:'100%'}} placeholder='Calle 00 #00 - 00' value={usuarioFormulario.direccion} onChange={handleChange} className='inputPersonalizado2'></input>
          <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>ESPECIFICACIONES*</p>
          <input type='text' name="especificaciones" style={{width:'100%'}} placeholder='casa, torre 00 apto 000' value={usuarioFormulario.especificaciones} onChange={handleChange} className='inputPersonalizado2'></input>
        </>}
        <label style={{color:'var(--color-purple-dark)',alignSelf:'flex-end',textAlign:'end',width:'100%'}}>*Campos obligatorios</label>
        <button className={'buttonMorado'} style={{margin:'0 auto',width:'fit-contain',padding:'2% 15%'}} type="submit" onClick={() => BundleHandleEnviarInfo(esDigital)}>ENVIAR</button>
      </div>
    )
  }

  return (
    <div id='page' className={estiloLanding.page} style={{height:'100%',maxWidth:'70svh',width:'100%',zIndex:'10',position:'relative'}}>
      <Loading isOpen={loading}></Loading>
      <div id='content_default' style={openPopover ? {overflowX:'hidden',height:'fit-content',overflowY:'scroll',opacity:"0.4",borderRadius: '0'}:{overflowX:'hidden',height:'fit-content',overflowY:'scroll',borderRadius: '0', marginBottom: (dato?"8.9svh":"0")}}  >
        <div  style={{ width:'100%',height:'fit-content',background:'linear-gradient(0deg, #D74286 0%, #45225F 100%)'}} >
            <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center',alignItems:'center'}}>
            <p className={estiloJuegos.tituloJuegos} style={{marginBottom:'0.81rem',marginTop:'2.18rem',fontWeight:'900'}}>NUESTRO LIBRO</p>
            </div>  
            <div className='linea' style={{backgroundColor:'#FFFFFF',margin:'0 10.25%'}}></div>
            <p className={estiloLanding.text}>HAZ UNA ELECCIÓN INTELIGENTE<br /> ENTRENA TU MENTE</p>
            <div className={estiloJuegos.contenedorVideo} style={{display:'flex',justifyContent:'center'}}>
            {VIDEOURL1 !== null && 
            <>
            <VideoConContador videoUrl={undefined}
            // poster={'/assets/images/landingVideoPoster.jpg'} preload='auto' autoplay={true} 
            style_video={{maxHeight:'60svh',width:'auto',height: "100%",maxWidth:'100%', aspectRatio:"9/16"}}
            style_contain={{maxHeight:'fit-content',width:'fit-content',height:'60svh',margin:'5% 0',borderRadius:'25px',overflow:'hidden',boxShadow:'5px 0px 10px black'}}>
                {
                <>
                <source src={VIDEOURL1}/>
                <source src='https://firebasestorage.googleapis.com/v0/b/supercerebros-desarrollo.appspot.com/o/publico%2Fneurobics%2FCORTESIA_NEUROBICS.mp4?alt=media&token=3e0c349f-9721-457d-b21d-f6df5ebec62e'/>
                </>
                }
            </VideoConContador>
            </>}
            </div>
        </div>
        <div style={{width:'100%',position:'relative',marginBottom:'3em'}}>
          <img src="/assets/images/landing_libro_mancha.png" width='100%' style={{position:'relative'}}></img>
          <img className={estiloLanding.fotoLibro1} src="/assets/images/landing_libro1.png"></img>
          <img className={estiloLanding.fotoLibro2} src="/assets/images/landing_libro2.png"></img>
          <div className={`${estiloLanding.fondoDegradado_sombra}`}>
            <label className={estiloLanding.label} style={{width:'100%'}}>Adquiérelo en físico ó en digital</label>
            <div className='linea' style={{backgroundColor:'#FFFFFF',margin:'0 10.25%'}}></div>
          </div>
        </div>
        <div className={`${estiloLanding.libroGrid} ${estiloLanding.IncludeLibro}`} style={{padding:'0 15%',width:'100%'}}>
          <h2 className={estiloLanding.tituloMorado} style={{gridColumn:'1/2 span'}}>¿Qué encontrarás en el libro?</h2>
          <PuntosMorados width={2} rotacion={'320deg'}></PuntosMorados>
          <p>Cómo entrenar tu cerebro</p>
          <PuntosMorados width={2} rotacion={'320deg'}></PuntosMorados>
          <p>Rutina para mejorar tu productividad</p>
          <PuntosMorados width={2} rotacion={'320deg'}></PuntosMorados>
          <p>Historia de supercerebros</p>
        </div>
        <figure className={estiloLanding.imglibro2}>
          <img src="/assets/images/landing_libro_img1.png"></img>
          <p>Libro <span>físico</span></p>
        </figure>
        <div className={`${estiloLanding.precioMorado}`} style={{marginTop:'-5px'}}>$ {(45000).toLocaleString('es-CO')} COP</div>
        {ComponentInputs(false)}
        <figure className={estiloLanding.imglibro2}>
          <img src="/assets/images/landing_libro_img2.png"></img>
          <p>Libro <span>digital</span></p>
        </figure>
        <div className={`${estiloLanding.precioMorado}`} style={{marginTop:'-5px'}}>$ {(24900).toLocaleString('es-CO')} COP</div>
        {ComponentInputs(true)}
        <div style={{margin:'2svh 0'}}>
          <div className={estiloLanding.contenedorFoto}>
            <h1 className={estiloLanding.tituloSobreNosotros}>SOBRE NOSOTROS</h1>
            <img className={estiloLanding.juanDavid} src='/assets/images/sobre_nosotros.png'></img>
          </div>
          <div className={estiloLanding.textoSobreNosotros} style={{margin:'-5.9svh 5% 2svh',width:'90%'}}>
            Somos los representantes Colombianos y semifinalistas en el programa “SUPERCEREBROS” del canal internacional NATGEO, con más de <span> 12 años de experiencia</span>,
            dedicados al aprendizaje y la enseñanza de la metodología MILAG, tiempo en el cual hemos sido reconocidos en procesos de formación y Neuro-entrenamiento en niños, 
            jóvenes, adultos y adultos sabios.
          </div>
        </div>
        <div style={{marginTop:'0%',paddingTop:'10%',background:'linear-gradient(167deg, #45225F 7.98%, #D74286 79.34%)',height:'fit-content',width:'111%',margin:'0 -5.5%',position:'relative',bottom:'0',padding:'10% 5%',display:'flex',flexDirection:'column',alignItems:'center'}}>
          <img src="/assets/images/Logo-blanco.png" style={{width:'20%'}}></img>
          <p style={{fontSize:'3svh',color:'white',letterSpacing:'0.5svh',margin:'0'}}>SUPERCEREBROS</p>
          <div style={{background:'#FFFFFF',width:'100%',height:'1px',margin:'10% 0'}}></div>
          <p className={estiloLanding.text_info} style={{fontSize:`2svh`,margin:'0'}}>SuperCerebros SAS<br></br>Calle 19 #6-68 Oficina 603, Bogotá<br></br>310 311 3156</p>
        </div>
      </div>
      {openPopover &&
      <>
        <div className={[stylePopover.contenedor,montar ? '' : stylePopover.desmontar].join(" ")}>
          <div className={stylePopover.contenido}>
            <div className="contenido_popover" style={(dato)?{paddingBottom: "8.9svh"}:{}}>

              <h3 className='confirmacion_pedido'>CONFIRMACIÓN DE PEDIDO</h3>
              <div className='producto'>
                <div> {`Libro entrena tu mente"`}</div>
                <div style={{textAlign:"end"}}>${(infoCompra.costo).toLocaleString('es-CO')}</div>
              </div>
              {infoCompra.descuento > 0 && <div  className='producto'>
                <div>Descuento</div>
                <div style={{textAlign:"end"}}>${(-infoCompra.descuento).toLocaleString('es-CO')} </div>
              </div>}
              <div className='linea_punteada'></div>
              <div className='valor_total'>
                <div>Valor total:</div>
                {/* <div style={{textAlign:"end"}}>${(costo - costo * descuento).toLocaleString('es-CO')} </div> */}
                <div style={{textAlign:"end"}}>${(infoCompra.costo - infoCompra.descuento).toLocaleString('es-CO')} </div>
              </div>
              
              <Checkout 
                titulo={`Libro entrena tu mente`} 
                description={`Libro entrena tu mente`} 
                costo={(infoCompra.costo - infoCompra.descuento)}
                setLoading={() => setLoading((prev)=>!prev)}
                userId={userId ?? null}
                id_productos={infoCompra.idsProductos}
                rutaRedireccion='/responseFactura'
                email={usuarioFormulario?.correo ?? ''}
                telefono={usuarioFormulario?.telefono}
                codigoTelefono={usuarioFormulario?.codigoTel}
                nombre={usuarioFormulario?.nombre}
                campaña={codigoParams?? null}
                pagoDirecto={facturaPagoDirecto}
                direccion={usuarioFormulario?.direccion ?? ''}
                documentoIdentidad={usuarioFormulario?.documentoIdentidad ?? null}
                />
              <button style={{height:"4.5vh", width:"100%",marginBottom:"2vh"}} className="volver" onClick={DesmontarPopover} >
                VOLVER
              </button>
            </div>
          </div>
        </div>
      </>}
    </div>
  )
}

export default LandingLibro