import style from "../../../theme/programas/calculo/Game1.module.css";

import { useEffect, useRef, useState } from "react";

import { buttons } from "../../../utils/Buttons";
import Button from "../../componentes/Button";

interface Props{
    etapa: number;
    cantidadNumeros: number;
	cantidadDigitos: number;
	velocidad: number;
    onEnviar: (suma: number, enviado: number) => void;

}

export const EtapaJuegoSumas1 = ({etapa, cantidadNumeros, cantidadDigitos, velocidad, onEnviar}: Props) => {

	const [adivina, setAdivina] = useState("0");
	const [subEtapa, setSubEtapa] = useState(0);
	const [numero, setNumero] = useState(-1);
	const [suma, setSuma] = useState(-1);

	const limiteDigitos = useRef(0);
	const max = useRef(0);
	const min = useRef(0);

	

    const enviar = () => {
		setSubEtapa(0);
		if(isNaN(Number(adivina))){
			onEnviar(suma, 0);
		}else{
			onEnviar(suma, Number(adivina));
		}
	}
    const reset = () => {
		setAdivina("#");
	}
    const backspace = () => {
		setAdivina(adivina.slice(0, -1));
	}

    const handleCalculator = (e: React.SyntheticEvent, operator: string) => {
		if (operator === "Enviar") {
			enviar();
		} else if (operator === "C") {
			reset();
		} else if (operator === "Borrar") {
			backspace();
		} else {
			if (adivina.length < limiteDigitos.current) {
				if (adivina === "#") {
					setAdivina(operator)
				} else {
					setAdivina(adivina + operator);
				}
			}
		}
	}

    /*-------------------------------------------------*/

	const mostrarNumeros = (array: number[]) => {
		if (array.length) {
			setNumero(array[0]);
			setTimeout(() => mostrarNumeros(array.slice(1)), velocidad)
		} else {
			setSubEtapa(2);
		}

	}

	const randomNumBetween = (min: number, max: number) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

    const crearArrayDeNumeros = (cuenta: number): [number[], number] => {

		let arr: number[] = [];
		let i = 0;

		let num = null;
		let numAnterior = 0;
		let total = 0;
		while (i !== cuenta) {
			num = randomNumBetween(min.current, max.current);

			if (num !== numAnterior) {
				arr.push(num);
				total = total + num;
				numAnterior = num;
				i++;
			}
		}
		return [arr, total];
	}

    const iniciarJuego = () => {
		const [numerosTemporal, total] = crearArrayDeNumeros(cantidadNumeros);
		setSuma(total);
		mostrarNumeros(numerosTemporal);
		setSubEtapa(1);
	}

    useEffect(() => {
        switch(etapa){
        case(1):
            reset();
            iniciarJuego();
            break;
        }
    }, [etapa]);

	useEffect(() => {
		max.current = Math.pow(10, cantidadDigitos) - 1;
		min.current = Math.pow(10, cantidadDigitos - 1);
		limiteDigitos.current = String(max.current*cantidadNumeros).length;
	},
	[cantidadNumeros, cantidadDigitos]);

	// useEffect(() => {
	// 	reset();
	// 	iniciarJuego();
	// }, [])

    const getCalculator = () => {
		return (

			<div className={style.calculadoraSumas1}>
				{buttons.map((buttonRow, index) => {

					return (
						<>
							{buttonRow.map(button => {
								// return <div>H</div>
								return <Button key={button.value} value={button.value} special={button.special} send={button?.send} clickEvent={handleCalculator} />;
							})}
						</>
					);
				})}
			</div>

		);

	}

    return (
        <>
            {subEtapa === 1 &&
				<div className={`${style.calculatorLetra} ${style.numero_principal}`} >
					{numero}
				</div>

			}
{/*
  PANTALLA 3
*/}
		 	{subEtapa === 2 &&

				<div className={[style.numero_principal_respuesta, style.calculator].join(" ")} style={{marginBottom:'8svh'}}>
					<div className={`${style.calculatorLetra} ${adivina === "#" ? style.sin_input : ""}`} style={{height:'30%'}}>
						{adivina==="#" ? '': adivina}
					</div>
					{getCalculator()}
				</div>
			}
        </>
    );
}