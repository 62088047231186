const NotFoundPage: React.FC = () => {

	return (
		<div id='page' style={{height:'100%'}}>
			<div id='content_default' style={{padding:'5%',justifyContent:'center'}}>
				<h1 style={{
					color: 'var(--color-Pink-Light)',
					fontSize: '15svw',
					fontWeight: '900',
				}}>404</h1>
				<h1 style={{
					color: 'var(--color-Pink-Light)',
					fontSize: '3svw',
					fontWeight: '900',
				}}>Page not found.</h1>
			</div>
		</div >
	);

}

export default NotFoundPage;
