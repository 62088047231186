
import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";

/**
 * Interfaz para los datos de jugadores
 */
export interface Actividad {
    id?: string;

    orden: number;

    tipo: string;

    titulo: string;

    subtitulo: string;
    
    descripcion: string;   

    ruta: string|string[];

    rating?:number;

    cantidadPersonas?:number;

    fechaInicio?: Timestamp;

    fechaFin?: Timestamp;

    completado?: number;
    
    tiene_bloqueo?:Number;

    tipoPregunta?:string;

    tituloRecurso?:string;
}


export const aActividad = (orden:number, tipo:string, titulo:string, subtitulo:string, descripcion:string, ruta:string, completado?: number,tiene_bloqueo?:Number,tipoPregunta?:string, id?:string) : Actividad => {
    
    const actividad: Actividad = ActividadVacia;

    if (id !== null && id !== undefined) {
        actividad.id = id;
    }

    if (orden !== null && orden !== undefined) {
        actividad.orden = orden;
    }

    if (tipo !== null && tipo !== undefined) {
        actividad.tipo = tipo;
    }

    if (titulo !== null && titulo !== undefined) {
        actividad.titulo = titulo;
    } 
    if (subtitulo !== null && subtitulo !== undefined) {
        actividad.subtitulo = subtitulo;
    } 

    if (descripcion !== null && descripcion !== undefined) {
        actividad.descripcion = descripcion;
    } 
       
    if (ruta !== null && ruta !== undefined) {
        actividad.ruta = ruta;
    }      

    if (completado!== null &&completado !== undefined) {
        actividad.completado =completado;
    }

    if (tiene_bloqueo!== null &&tiene_bloqueo !== undefined) {
        actividad.tiene_bloqueo =tiene_bloqueo;
    }

    if (tipoPregunta!== null &&tipoPregunta !== undefined) {
        actividad.tipoPregunta =tipoPregunta;
    }
         
    return actividad;       
}

export const cleanData = (data: Partial<Actividad>): Actividad => {
    return {
        id: defaultValue(data.id, ''),
        orden: defaultValue(data.orden, 0),
        tipo: defaultValue(data.tipo, ''),
        titulo: defaultValue(data.titulo, ''),
        tituloRecurso: defaultValue(data.tituloRecurso, ''),
        subtitulo: defaultValue(data.subtitulo, ''),
        descripcion: defaultValue(data.descripcion, ''),
        ruta: defaultValue(data.ruta, ''),
        rating: defaultValue(data.rating, 0),
        cantidadPersonas: defaultValue(data.cantidadPersonas, 0),
        fechaInicio: defaultValue(data.fechaInicio, null),
        fechaFin: defaultValue(data.fechaFin, null),
        completado: defaultValue(data.completado, 0),
        tiene_bloqueo: defaultValue(data.tiene_bloqueo, 0),
        tipoPregunta: defaultValue(data.tipoPregunta, ''),
    } as Actividad;
};


export const toActividadParaVideo = (actividad:Actividad) => {
    const formattedRegActividad: Partial<any> = {
        id: actividad.id,
        orden: actividad.orden,
        tipo: actividad.tipo,
        ruta: actividad.ruta,
    };
    
    if (typeof(actividad.subtitulo) === typeof('subtitulo') && actividad.subtitulo) {
        formattedRegActividad.subtitulo = actividad.subtitulo;
    }
    console.log("🚀 ~ toActividadParaVideo ~ typeof(actividad.subtitulo) === typeof('subtitulo'):", typeof(actividad.subtitulo) === typeof('subtitulo'))
    if (typeof(actividad.titulo) === typeof('titulo') && actividad.titulo) {
        formattedRegActividad.titulo = actividad.titulo;
    }
    console.log("🚀 ~ toActividadParaVideo ~ typeof(actividad.titulo) === typeof('titulo'):", typeof(actividad.titulo) === typeof('titulo'))
    if (typeof(actividad.rating) === typeof(0) && !isNaN(actividad.rating)) {
        formattedRegActividad.rating = actividad.rating;
    }
    if (typeof(actividad.cantidadPersonas) === typeof(0) && actividad.cantidadPersonas) {
        formattedRegActividad.cantidadPersonas = actividad.cantidadPersonas;
    }
    return formattedRegActividad
}
// Sesion prueba

export const ActividadVacia = {
    id: null,
    orden: null,
    tipo: null,
    titulo: null,
    subtitulo: null,
    descripcion: null,
    ruta: null,
    completado: 0,   
} as Actividad

/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>

const defaultValue = <T>(value: T | undefined | null, defaultVal: T): T => {
    return value !== undefined && value !== null ? value : defaultVal;
};

export const toActividad = (doc: FireBaseDocument): Actividad | undefined => {
    if (doc.exists()) {
        const data = doc.data() as Partial<Actividad> || {};
        const actividad: Partial<Actividad> = {
            ...data,
            id: doc.id,
            tiene_bloqueo: data.tiene_bloqueo !== undefined ? data.tiene_bloqueo : 0,
        };
        return cleanData(actividad);
    }
    return undefined;
};

