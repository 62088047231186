const titulo = "Memoria de nombres";
const cantRostrosHombres = 138;
const cantRostrosMujeres = 142;

//| Nivel | 
// Cantidad de nombres | 
// Tiempo para memorizar (segundos) | 
// tiempo para responder (segundos) |
const niveles = [[0, 20, 30, 240],
[1, 10, 30, 120],
[2, 30, 30, 120]];

export const calificacionNombres = (nroNivel, respuesta) => {
    const respuestaTotal = niveles[nroNivel][1];
    return Math.floor((respuesta * 10 ) / respuestaTotal);
}

const nombresHombres = [
    "Hugo",
    "Martín",
    "Lucas",
    "Mateo",
    "Leo",
    "Daniel",
    "Alejandro",
    "Pablo",
    "Manuel",
    "Álvaro",
    "Adrián",
    "David",
    "Mario",
    "Enzo",
    "Diego",
    "Marcos",
    "Izan",
    "Javier",
    "Marco",
    "Álex",
    "Bruno",
    "Oliver",
    "Miguel",
    "Thiago",
    "Antonio",
    "Carlos",
    "Ángel",
    "Juan",
    "Gonzalo",
    "Sergio",
    "Nicolás",
    "Gabriel",
    "Jorge",
    "Samuel",
    "Adam",
    "Rodrigo",
    "Jaime",
    "Víctor",
    "Guillermo",
    "Rubén",
    "Rafael",
    "Ismael",
    "Francisco",
    "Alberto",
    "Santiago",
    "Alonso",
    "Ignacio",
    "Matías",
    "Leonardo",
    "Luis",
    "Benjamín",
    "Sebastián",
    "Andrés",
    "Pedro",
    "Emilio",
    "Ricardo",
    "Felipe",
    "Eduardo",
    "Roberto",
    "Raúl",
    "José",
    "Esteban",
    "Marcelo",
    "Alexis",
    "Arturo",
    "Héctor",
    "Óscar",
    "Fernando",
    "Tomás",
    "Orlando",
    "Edgar",
    "Ernesto",
    "Wilmer",
    "Omar",
    "Mauricio",
    "Patricio",
    "Cristian",
    "Agustín",
    "Julio",
    "Guido",
    "Kevin",
    "Cristóbal",
    "César",
    "Renato",
    "Nelson",
    "Iván",
    "Gustavo",
    "Edgardo",
    "Damián",
    "Juan Carlos",
    "Mariano",
    "Óliver",
    "Adriano",
    "Elías",
    "Israel",
    "Joaquín",
    "Andrés Felipe",
    "Iván Darío",
    "Darío",
    "Gabriel ",
    "Alejandro ",
    "Germán",
    "Juan Pablo",
    "Ramiro",
    "René",
    "Isaac",
    "Maximiliano",
    "Ezequiel",
    "Valentín",
    "Fabián",
    "Luciano",
    "Alan",
    "Emiliano",
    "Facundo",
    "Juan José",
    "Ulises",
    "Roberto Carlos",
    "Jonathan",
    "Ariel",
    "Joel",
    "Adolfo",
    "José Carlos",
    "Julián"
];

const nombresMujeres = [
    "Sofía",
    "Valentina",
    "Isabella",
    "Camila",
    "Victoria",
    "Luciana",
    "Natalia",
    "Gabriela",
    "Mia",
    "Daniela",
    "Emily",
    "Martina",
    "Antonella",
    "Amelia",
    "Emilia",
    "Olivia",
    "Emma",
    "Aurora",
    "Valeria",
    "Mariana",
    "Clara",
    "Laura",
    "Julia",
    "Carolina",
    "Alejandra",
    "Andrea",
    "Renata",
    "Lila",
    "Amanda",
    "Catalina",
    "Ana",
    "Fernanda",
    "Elena",
    "Paulina",
    "Sara",
    "Estrella",
    "Abril",
    "Alma",
    "Elisa",
    "Carmen",
    "Jimena",
    "Raquel",
    "Ana Sofía",
    "Lucía",
    "Julieta",
    "María",
    "Brenda",
    "Alicia",
    "Adriana",
    "Regina",
    "Ximena",
    "Eloísa",
    "Clarissa",
    "Fabiana",
    "Graciela",
    "Beatriz",
    "Rosalía",
    "Celeste",
    "Maribel",
    "Giselle",
    "Violeta",
    "Isadora",
    "Paula",
    "Alba",
    "Carla",
    "Claudia",
    "Marta",
    "Lola",
    "Ariadna",
    "Candela",
    "Nora",
    "Inés",
    "Irene",
    "Laia",
    "Lara",
    "Sonia",
    "Diana",
    "Vanessa",
    "Jazmín",
    "Paloma",
    "Xiomara",
    "Samantha",
    "Marisol"
];


export { titulo, nombresHombres, nombresMujeres, niveles, cantRostrosHombres,cantRostrosMujeres};