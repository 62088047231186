import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store';
import { useParams } from 'react-router-dom';
import CodImagenes from '../../../datos/DatosCodigoPalabras';
// estilos
import style from "../../../theme/codigoPalabras.module.css"; 
import styleMemoriaNombres from "../../../theme/MemoriaNombres.module.css";
import estiloJuegos from "../../../theme/Juegos.module.css";
import style2 from "../../../theme/Juego2.module.css"; 
import styleRecorrerValores from "../../../theme/programas/cuestionarios/recorrerValores.module.css"; 

import { changeCondicion } from '../../../hooks/reducer/reducerActividad';
import BotonesJuegos from '../componentes/BotonesJuegos';
import { useNavigate } from 'react-router-dom';
import Loading from '../../entrenamiento/componentes/Loading';
import MostrarResultados from '../resultados/componentes/MostrarResultados';
import useRegistroEntrenamiento from '../../../hooks/controlador/useRegistroEntrenamiento';
import BotonBordeDegradado from '../../componentes/BotonBordeDegradado';

interface Preguntas {
  codigo: number;
  opcion1: number;
  opcion2: number;
  opcion3: number;
  opcionSeleccionada: number;
}

type Params = {
  limit:string;
}

const JuegoCodigodePalabras = () => {
  const [preguntas, setPreguntas] = useState<Preguntas[]>([]);
  const [tipoPregunta, setTipoPregunta] = useState<string>('');
  const [tipoRespuesta, setTipoRespuesta] = useState<string>('');
  const [etapa, setEtapa] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [openDescripcion, setOpenDescripcion] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [objetoDescripcion,setObjetoDescripcion] = useState<number>(0);
  const [correctas, setCorrectas] = useState<number>(0);
  const [seleccionada,setSeleccionada] = useState<number []>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {limit} = useParams<Params>();
  let opciones = [0,1,2,3,4,5,6,7,8,9];
  
  const { actualizarRegistroEntrenamiento } = useRegistroEntrenamiento();
  const userId = useAppSelector((state) => state.auth.userId);
  const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento?.id ?? null);
  const indiceActividad = useAppSelector((state) => state.entrenamiento.current_actividad);
  const indiceSesion = useAppSelector((state) => state.entrenamiento.current_sesion);
  const esEntrenamiento = useAppSelector((state) => state.activity.id === "entrenamiento");
  const tipoPreguntaRedux = useAppSelector((state) => state.activity.tipoPregunta);
  const parametro = useAppSelector((state) => state.activity.tiene_bloqueo);
  const lenghtActividades = useAppSelector((state) => state.entrenamiento.current_actividades?.length);

  const ScrollLeft = () => {
    if(index>0){
      let referenciaFicha = document.getElementById(`ficha${index-1}`)
      if (referenciaFicha) {        
        setIndex((prevIndex) => prevIndex -1);
        referenciaFicha.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    };
  }

  const ScrollRight = () => {
    if(index < preguntas.length-1){
      let referenciaFicha = document.getElementById(`ficha${index+1}`)
      if (referenciaFicha) {        
        setIndex((prevIndex) => prevIndex +1);
        referenciaFicha.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
  }

  const VerificarRespuesta = () => {
    let count = 0;
    for (let i=0; i<preguntas.length; i++){
      if (preguntas[i].codigo === preguntas[i].opcionSeleccionada ){
        count++;
      }
    }
    setCorrectas(count);
    
  };

  const HandleFinalizar = () => {
    setEtapa(2);
    VerificarRespuesta()

  }

  const HandleClick = (opcion: number, botonSeleccionada: number) =>{
    preguntas[index].opcionSeleccionada =opcion;
    
    const nuevaSeleccionada = [...seleccionada];
    nuevaSeleccionada[index] = botonSeleccionada;
    setSeleccionada(nuevaSeleccionada);
    
    setTimeout(() => {
      ScrollRight();
    }, 500); 
    

  }

  const GetCodigo = () => {
    if (tipoPregunta === "string"){
      return( 
        <>
          {preguntas.map((pregunta,index) => (
            <div className={style.scroll} key={index}>{pregunta.codigo}</div>
          ))}
        </>
      );
    }else {
      return(
        <>
        {preguntas.map((pregunta,_index) => (
          <img className={`${style.scroll} ${style.imagen}`} key={pregunta.codigo} id={`ficha${_index}`} src={`assets/images/fichas/_${pregunta.codigo}.png`}/>
        ))}
        </>
      );
    }
  }

  const GenerarArrayImages = () => {
    let imgs = []
    preguntas.forEach((element) => {
      imgs.push(<img src={`assets/images/fichas/_${element.codigo}.png`}></img>)
    })
    return(
      imgs
    )
  }

  const GenerarArrayCalificaciones = () => {
    let array = preguntas.map((e)=> e.codigo === e.opcionSeleccionada)
    array.unshift(null)
    return array
  }

  const GenerarArrayResultadosAMostrar = () => {
    const opcionesSeleccionadas = preguntas.map((e) => CodImagenes.getName(e.codigo));
    const arrayWord = ['Nombre', ...opcionesSeleccionadas];
    const arrayNumeros = preguntas.map((e) => e.codigo > 99 ? `0${(e.codigo-100).toString()}` : e.codigo.toString());
    const arrayCodeWord = ['Numero', ...arrayNumeros];
    const arrayInputs = preguntas.map((e) => {return e.opcionSeleccionada === -1 ? '#': e.opcionSeleccionada > 99 ? `0${(e.opcionSeleccionada-100).toString()}` : e.opcionSeleccionada.toString()});
    const arrayInputUser = ['Respuesta', ...arrayInputs];
    let array = [arrayWord,arrayCodeWord,arrayInputUser]
    return array
  }
  

  const GetOpciones = () => {
    if (tipoRespuesta === "string"){
      return( 
        <>
          { seleccionada[index] !==1  ? 
          <BotonBordeDegradado onClick={() => HandleClick(preguntas[index].opcion1,1)}>
            {preguntas[index].opcion1 > 99 ? `0${preguntas[index].opcion1 - 100}` : preguntas[index].opcion1}
          </BotonBordeDegradado>: 
          <button className={`${styleMemoriaNombres.inputsOptions} ${styleMemoriaNombres.selected}`}>
            <label style={{color:'white'}}>{preguntas[index].opcion1 > 99 ? `0${preguntas[index].opcion1 - 100}` : preguntas[index].opcion1}</label>
          </button>
          }
          {seleccionada[index] !== 2 ?
          <BotonBordeDegradado onClick={() => HandleClick(preguntas[index].opcion2,2)}>
            {preguntas[index].opcion2 > 99 ? `0${preguntas[index].opcion2 - 100}` : preguntas[index].opcion2}
          </BotonBordeDegradado>: 
          <button className={`${styleMemoriaNombres.inputsOptions} ${styleMemoriaNombres.selected}`}>
            <label style={{color:'white'}}>{preguntas[index].opcion2 > 99 ? `0${preguntas[index].opcion2 - 100}` : preguntas[index].opcion2}</label>
          </button>}
          {seleccionada[index] !== 3 ?
          <BotonBordeDegradado onClick={() => HandleClick(preguntas[index].opcion3,3)}>
            {preguntas[index].opcion3 > 99 ? `0${preguntas[index].opcion3 - 100}` : preguntas[index].opcion3}
          </BotonBordeDegradado>: 
          <button className={`${styleMemoriaNombres.inputsOptions} ${styleMemoriaNombres.selected}`}>
            <label style={{color:'white'}}>{preguntas[index].opcion3 > 99 ? `0${preguntas[index].opcion3 - 100}` : preguntas[index].opcion3}</label>
          </button>}
        </>
      );
    }else {
      return(
        <>
          <button className={`${seleccionada[index]===1 ? style.seleccionada :""}`} onClick={() => HandleClick(preguntas[index].opcion1,1)}>
            <div className={`${seleccionada[index]===1 ? style.transparente  :""}`}>
              <img className={style.imagenOpciones} src={`assets/images/fichas/${preguntas[index].opcion1}.png`}/>
            </div>
          </button>
          <button className={`${seleccionada[index]===2 ? style.seleccionada :""}`} onClick={() => HandleClick(preguntas[index].opcion2,2)}>
            <div className={`${seleccionada[index]===2 ? style.transparente  :""}`}> 
            <img className={style.imagenOpciones} src={`assets/images/fichas/${preguntas[index].opcion2}.png`}/>
            </div>
          </button>
          <button className={`${seleccionada[index]===3 ? style.seleccionada :""}`} onClick={() => HandleClick(preguntas[index].opcion3,3)}>
            <div className={`${seleccionada[index]===3 ? style.transparente :""}`}>
              <img className={style.imagenOpciones} src={`assets/images/fichas/${preguntas[index].opcion3}.png`}/>
            </div>
          </button>
        </>
      );
    }
  }

  const Descripcion = (objeto: number) =>{
    setOpenDescripcion(true);
    setObjetoDescripcion(objeto)
  }


// Función de comparación para organizar aleatoriamente
  function CompararAleatoriamente() {
    return Math.random() - 0.5;
  }

  const RandomNumero = () => {
    if (Number(limit) < 110){
      if(Number(limit) < 10){
        return  Math.floor(Math.random() * 10);
      }
      if(Number(limit) > 50){
        console.log("🚀 ~ RandomNumero ~ Math.floor(Math.random() * (Number(limit)-50 === 0 ? 10 : Number(limit))) + 50:", Math.floor(Math.random() * (Number(limit)-50 === 0 ? 10 : Number(limit))) + 50)
        console.log("🚀 ~ RandomNumero ~ (Number(limit)-50 === 0 ? 10 : Number(limit)):", (Number(limit)-50 === 0 ? 10 : Number(limit)))
        return  Math.floor(Math.random() * (Number(limit)-50 === 0 ? 10 : Number(limit)-50)) + 50;
      }
      return  Math.floor(Math.random() * Number(limit));
    }
    else {
      return  Math.floor(Math.random() * 110);
    }
  }

  const SetOpcionesRespuesta100 = (pregunta: Preguntas, anterioresPreguntas: Preguntas[]) => {
    const randomNumber = RandomNumero();
    const existeCodigoIgual = anterioresPreguntas.some((pregunta) => pregunta.codigo === randomNumber);
    if (!existeCodigoIgual) {
      pregunta.codigo = randomNumber;
      return pregunta;
    }else {
      return SetOpcionesRespuesta100 (pregunta, anterioresPreguntas);
    }
  };

  // Función recursiva para obtener tres opciones aleatorias diferentes
  const SetTresOpcionesAleatorias = (seleccionada:number, tresOpciones = []) => {
    if (tresOpciones.length === 3) {
      return tresOpciones;
    }

    if (tresOpciones.length === 0) {
      tresOpciones.push(seleccionada);
    }else{
      const opcionAleatoria = RandomNumero();
      if(!tresOpciones.includes(opcionAleatoria)){
        tresOpciones.push(opcionAleatoria);
      }
    }
    return SetTresOpcionesAleatorias(seleccionada, tresOpciones);
  };
  
  const SetPreguntasRandom = () => {
    let count = 0;
    let anterioresPreguntas = [];
    opciones.sort(CompararAleatoriamente);
    while (count< 10) {
      let nuevaPregunta: Preguntas = {
        codigo: -1, 
        opcion1: 0,
        opcion2: 0,
        opcion3: 0,
        opcionSeleccionada: -1,
      };
  
      nuevaPregunta = SetOpcionesRespuesta100(nuevaPregunta, anterioresPreguntas);
      let tresOpciones = [];
      
      tresOpciones = SetTresOpcionesAleatorias(nuevaPregunta.codigo);
      tresOpciones.sort(CompararAleatoriamente)
      
      nuevaPregunta.opcion1 = tresOpciones[0];
      nuevaPregunta.opcion2 = tresOpciones[1];
      nuevaPregunta.opcion3 = tresOpciones[2];
      anterioresPreguntas.push(nuevaPregunta);
      setPreguntas((prevstate)=> [...prevstate, nuevaPregunta]);
      count++;
    }
  }

  const APrimeraPantalla = () => {
    Constructor()
  }

  const Constructor = () => {
    setEtapa(0)
    ConsultarElCuestionario();
    setPreguntas([]);
    setSeleccionada([]);
    setIndex(0);
    SetPreguntasRandom();
  }
  

  const ConsultarElCuestionario = async () => {
    setLoading(true);
    setTipoPregunta(tipoPreguntaRedux);
    console.log("🚀 ~ consultarElCuestionario ~ tipoPreguntaRedux:", tipoPreguntaRedux)
    if(tipoPreguntaRedux == "string"){
      setTipoRespuesta("imagen");     
    }else{
      setTipoRespuesta("string");     
    }
    setLoading(false);    
  }

  useEffect(() => {
    Constructor();
  }, []);
  
  const HandleBackButtonClick = async() => {
    if(!userId){
      return Constructor()
    }
    if(correctas>=parametro){
      actualizarRegistroEntrenamiento(userId,idEntrenamiento,indiceSesion+1,indiceActividad+1);
      dispatch(changeCondicion({condicion:correctas}))
      navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
    }else{
      return navigate(-1);
    }
  }
  return (
    <div id='page'>
      <Loading isOpen={loading}/>
      <div id={etapa !== 0 && 'toolbar_default'} className={etapa === 0 ? estiloJuegos.toolbarMemoriaPalabras : style.toolbar_default}>
        {userId && <div className={estiloJuegos.backJuegos} onClick={HandleBackButtonClick}/>}
        <h1 className="title" style={{marginTop:'2svh'}}>Código de Palabras </h1> 
      </div>
        {etapa === 0 && 
          <div className={estiloJuegos.contenedorIconoPalabras}>
            <img src='/assets/componentes/Juegos/AC5.svg' className={estiloJuegos.icono}></img>
          </div>
        }
      <div id='content_default' style={{padding:'2%'}}>
        {etapa === 0 && <>
          <div className={estiloJuegos.primeraPantalla} style={{justifyContent:'space-evenly'}}>
            <div className={estiloJuegos.descripcionJuego}>
              ¡Desafía tu memoria! <br></br> Tienes encontrar el valor de las siguientes 10 palabras.
            </div>
            <button className={estiloJuegos.buttonEmpezar} onClick={()=>setEtapa(1)}>
              EMPEZAR
            </button>
          </div>
        </>}
        {etapa === 1 &&
          <div className={style.contenedor}>
            <div className={style.navegacionImagen}>
              <div className={style.contenedorCodigo} >
                {tipoPregunta === "imagen" && 
                  <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index].codigo)} className={style.buttonDescription}/>
                }
                <div className={style.contenedorScroll} >
                  {GetCodigo()}
                </div>
              </div>
            </div>
            <div className='centrar' style={{gap:'10px',margin:'2svh 0'}}>
              <p className='subtitleHome' style={{textTransform:'uppercase',fontSize:'5svh'}}>{CodImagenes.getName(preguntas[index].codigo)}</p>
              <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index].codigo)}/>
            </div>
            <div className={style.contenedorOpciones}>
              {GetOpciones()}
            </div>
            {tipoRespuesta === "imagen" && <div className={style.contenedorDescripciones}>
                <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index].opcion1)}/>
                <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index].opcion2)}/>
                <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index].opcion3)}/>
            </div>}
            <div style={{display:'flex',justifyContent:'center',gap: '0.55svh'}}>
                <button className={`${style2.nv_button} ${!index ? 'element_block':''}`} onClick={ScrollLeft}>
                  <img src='/assets/icon/flechaBlancoSend.svg' alt='Go' style={{transform:'rotate(180deg)'}}></img>
                </button>
                {index < 9 ? 
                <button className={style2.nv_button} onClick={ScrollRight}>
                    <img src='/assets/icon/flechaBlancoSend.svg' alt='Go'></img>
                </button> 
                : <button className={style2.nv_button} style={{width:'24.2svh',marginLeft:'14.24px'}} onClick={HandleFinalizar}>TERMINAR</button>}
            </div>
          </div>
        }
        {etapa === 2 &&
          <>
            <div className={style2.contenido} style={{ padding: `0 0 ${correctas<=0? "2%": ""}`, display: "flex", flexDirection: "column", fontFamily: 'Montserrat'}}>
                <p className={`${correctas >=7 ?
                  styleRecorrerValores.resultado2 : correctas >=5 ?
                  styleRecorrerValores.resultado1 : styleRecorrerValores.resultado0
                } ${styleRecorrerValores.mensajePersonalizado}`} style={{fontSize:'3.64svh'}}>
                  { correctas>=parametro ? '¡FELICITACIONES!' : '¡BUEN INTENTO!'} 
                </p>
                <p className={styleRecorrerValores.mensajePersonalizado} 
                style={{ color: 'var(--color-Medium-Grey)',fontSize:'2.5svh' }}>
                  {correctas >= parametro ? (
                    'PUEDES AVANZAR A LA SIGUIENTE ACTIVIDAD'
                  ) : (
                    <>
                      OBTÉN MINIMO <span style={{ color: 'var(--desired-color)' }}>{parametro}</span> ACIERTOS PARA AVANZAR
                    </>
                  )}
                </p>
                <MostrarResultados 
                  arrayItemImg={GenerarArrayImages()}
                  arrayAMostrar={GenerarArrayResultadosAMostrar()} 
                  calificaciones={GenerarArrayCalificaciones()}
                  styleContenedorTabla={{maxHeight:'50svh',margin:'4% 2%'}}
                />
                <div style={{margin: "0svh auto"}}>
                  <p className={style2.textoTuviste}>TUVISTE</p>
                  <p className={style2.resultado} style={{margin:'0'}}>{`${correctas}/${10}`}</p>
                </div>
            </div>
            {!userId ? <button id='button_rosado_default' style={{width:'fit-content'}} onClick={HandleBackButtonClick}>Jugar de nuevo</button> :
            <>
              {(parametro!==0 && correctas>=parametro) ? <button id='button_rosado_default' className={style.finalizar} onClick={HandleBackButtonClick}>Volver</button>:
              <BotonesJuegos funcionReiniciar={APrimeraPantalla} funcionVolver={HandleBackButtonClick} esJuego={false} esEntrenamiento={esEntrenamiento}/>}
              <div className={style.contenido} style={{ padding: `0 0 ${correctas<=0? "5%": ""}`, height: "85%", flexGrow: 1, display: "flex", flexDirection: "column"}}></div>
            </>}
              
          </>
        }
        {openDescripcion &&
          <div id="modal_cortina_default"  onClick={() => setOpenDescripcion(false)}>
            <div id="modal_default" className={`${style.modal} scroll-up`} onClick={(e) => e.stopPropagation()} >
              <img className={style.back} src="assets/icon/flechaBlancaMorado.svg" alt="" onClick={() => setOpenDescripcion(false)} />
              <img src={`assets/images/fichas/${objetoDescripcion}.png`} alt="" style={{maxHeight:'54%'}}/>
              <p className={style.tituloImagen}> {CodImagenes.getName(objetoDescripcion)}</p>
              <p style={{textTransform:'none'}}>{CodImagenes.getDefinition(objetoDescripcion)}</p>
            </div>
          </div>
        }  
      </div>
    </div>
  )
}

export default JuegoCodigodePalabras
