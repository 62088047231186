export const palabrasInvalidas = [
    "anzan",
    "anzann",
    'anzaan',
    "aanzan",
    "anzzan",
    "annzan",
    "annzzan",
    "aannzan",
    "aanzzan",
    "anzzzzan",
    "anzaaan",
    "anzzaan",
    "anzzaaaan",
    "aaannzaan",
    "annzaaan",
    "anzana",
    "anzannn",
    "anzaaan",
    "aaanzan"
    
    ]