const CodImagenes = {
    data: {},
  
    addEntry: function (key, name, definition) {
      this.data[key] = {
        name: name,
        definition: definition,
      };
    },
  
    removeEntry: function (key) {
      delete this.data[key];
    },
  
    getName: function (key) {
      return this.data[key] ? this.data[key].name : '';
    },
  
    getDefinition: function (key) {
      return this.data[key] ? this.data[key].definition : '';
    },
  
    getAllEntries: function () {
      return this.data;
    },
  };

  CodImagenes.addEntry(0, 'oso', 'Mamífero grande y peludo que habita en diversas regiones.');
  CodImagenes.addEntry(1, 'té', 'Bebida caliente y aromática obtenida de las hojas de té.');
  CodImagenes.addEntry(2, 'uña', 'Placa córnea que recubre y protege la extremidad de los dedos.');
  CodImagenes.addEntry(3, 'humo', 'Mezcla visible de partículas sólidas y líquidas suspendidas en el aire.');
  CodImagenes.addEntry(4, 'oro', 'Metal precioso de color amarillo brillante.');
  CodImagenes.addEntry(5, 'ola', 'Movimiento ondulatorio del mar o de una masa de agua.');
  CodImagenes.addEntry(6, 'ojo', 'Órgano de la vista en los seres humanos y animales.');
  CodImagenes.addEntry(7, 'cuy', 'Roedor pequeño originario de los Andes de Sudamérica.');
  CodImagenes.addEntry(8, 'uva', 'Fruto redondo y jugoso utilizado para hacer vino.');
  CodImagenes.addEntry(9, 'pie', 'Extremidad inferior del cuerpo humano y de algunos animales.');
  CodImagenes.addEntry(10, 'tusa', 'Resto de la mazorca del maíz después de quitar los granos.');
  CodImagenes.addEntry(11, 'tutú', 'Prenda de vestir que hace parte de la indumentaria de bailarinas de danza clásica.');
  CodImagenes.addEntry(12, 'tina', 'Recipiente grande y profundo utilizado para bañarse.');
  CodImagenes.addEntry(13, 'toma', 'Elemento con capacidad de transmitir energía eléctrica a dispositivos que sean conectados.');
  CodImagenes.addEntry(14, 'toro', 'Animal mamífero, herbívoro y cuadrúpedo, de gran tamaño.');
  CodImagenes.addEntry(15, 'tela', 'Material flexible y textil hecho de fibras naturales o sintéticas.');
  CodImagenes.addEntry(16, 'toga', 'Prenda de vestir amplia y larga, generalmente utilizada por académicos.');
  CodImagenes.addEntry(17, 'taco', 'Comida típica mexicana que consiste en una tortilla enrollada con relleno.');
  CodImagenes.addEntry(18, 'tofu', 'Alimento hecho a partir de la soja y utilizado como sustituto de la carne.');
  CodImagenes.addEntry(19, 'tapa', 'Cubierta o pieza que se utiliza para cerrar un recipiente.');
  CodImagenes.addEntry(20, 'nasa', 'Trampa de pesca en forma de jaula, generalmente hecha de materiales como malla o alambre.');
  CodImagenes.addEntry(21, 'nata', 'Capa de grasa que se forma en la superficie de la leche cuando se deja reposar o se calienta.');
  CodImagenes.addEntry(22, 'nene', 'Forma cariñosa de referirse a un niño o bebé.');
  CodImagenes.addEntry(23, 'neme', 'Material negro, espeso y pegajoso derivado del petróleo, utilizado para pavimentar carreteras y como adhesivo en la construcción.');
  CodImagenes.addEntry(24, 'noria', 'Máquina compuesta de dos grandes ruedas engranadas, se usa para subir agua o como atracción de feria.');
  CodImagenes.addEntry(25, 'anillo', 'Aro de metal u otro material que se utiliza como adorno en los dedos.');
  CodImagenes.addEntry(26, 'nigua', 'Insecto pequeño que se introduce bajo la piel causando picazón.');
  CodImagenes.addEntry(27, 'nuca', 'Parte posterior del cuello, en la base del cráneo.');
  CodImagenes.addEntry(28, 'nieve', 'Agua helada que se desprende de las nubes en cristales pequeños que caen en forma de copos blancos.');
  CodImagenes.addEntry(29, 'naipe', 'Cada una de las cartas de una baraja.');
  CodImagenes.addEntry(30, 'mesa', 'Mueble con una superficie plana y patas para apoyarlo.');
  CodImagenes.addEntry(31, 'moto', 'Vehículo de dos ruedas.');
  CodImagenes.addEntry(32, 'mano', 'Extremidad del cuerpo humano situada al final del brazo.');
  CodImagenes.addEntry(33, 'momia', 'Cadáver humano o animal preservado mediante técnicas de embalsamamiento.');
  CodImagenes.addEntry(34, 'muro', 'Estructura vertical sólida y resistente que se utiliza para delimitar espacios, brindar soporte y protección.');
  CodImagenes.addEntry(35, 'muela', 'Diente posterior utilizado para moler los alimentos.');
  CodImagenes.addEntry(36, 'mago', 'Persona que realiza trucos de ilusionismo o magia.');
  CodImagenes.addEntry(37, 'moco', 'Secreción viscosa producida por las mucosas nasales.');
  CodImagenes.addEntry(38, 'mofa', 'Expresión facial exagerada que se realiza con el propósito de comunicar emociones.');
  CodImagenes.addEntry(39, 'mapa', 'Representación gráfica de un territorio o lugar.');
  CodImagenes.addEntry(40, 'rosa', 'Flor de pétalos suaves y fragantes, generalmente de color rosado.');
  CodImagenes.addEntry(41, 'reata', 'Cuerda gruesa utilizada para amarrar, sujetar o arrear.');
  CodImagenes.addEntry(42, 'ruana', 'Prenda de vestir tradicional de Colombia, similar a un poncho, hecha de lana.');
  CodImagenes.addEntry(43, 'remo', 'Instrumento largo y plano utilizado para propulsar una embarcación en el agua.');
  CodImagenes.addEntry(44, 'herrero', 'Persona que   trabaja el hierro y fabrica objetos de metal.');
  CodImagenes.addEntry(45, 'rulo', 'Rizo o bucle en el cabello.');
  CodImagenes.addEntry(46, 'riego', 'Proceso de suministrar agua a las plantas de manera artificial para facilitar su crecimiento y desarrollo');
  CodImagenes.addEntry(47, 'roca', 'Masa sólida compuesta por minerales y presente en la superficie terrestre.');
  CodImagenes.addEntry(48, 'rufa', 'Tipo de pescado pequeño, también conocido como boga o boquilla, que se encuentra en ríos y lagos.');
  CodImagenes.addEntry(49, 'ropa', 'Conjunto de prendas de vestir.');
  CodImagenes.addEntry(50, 'loza', 'Material cerámico utilizado en la fabricación de vajillas y objetos de adorno.');
  CodImagenes.addEntry(51, 'lata', 'Envase metálico utilizado para almacenar alimentos u otros productos.');
  CodImagenes.addEntry(52, 'luna', 'Satélite natural que orbita alrededor de la Tierra.');
  CodImagenes.addEntry(53, 'lima', 'Fruto cítrico de forma ovalada y sabor ácido.');
  CodImagenes.addEntry(54, 'loro', 'Ave tropical conocida por su capacidad de imitar sonidos y palabras.');
  CodImagenes.addEntry(55, 'lulo', 'Fruto de sabor ácido y refrescante, originario de América del Sur.');
  CodImagenes.addEntry(56, 'liga', 'Cinta o banda de tejido normalmente elástico para sujetar las medias o los calcetines.');
  CodImagenes.addEntry(57, 'laca', 'Sustancia líquida utilizada como barniz o recubrimiento.');
  CodImagenes.addEntry(58, 'lava', 'Material fundido expulsado por un volcán durante una erupción.');
  CodImagenes.addEntry(59, 'lupa', 'Lente de aumento utilizada para observar objetos pequeños.');
  CodImagenes.addEntry(60, 'gasa', 'Tejido fino y transparente utilizado en el ámbito médico.');
  CodImagenes.addEntry(61, 'gato', 'Animal doméstico de compañía, conocido por su agilidad y destreza.');
  CodImagenes.addEntry(62, 'guineo', 'Variedad del plátano, de tamaño pequeño y sabor dulce.');
  CodImagenes.addEntry(63, 'goma', 'Chicle o goma de borrar.');
  CodImagenes.addEntry(64, 'gorra', 'Prenda de vestir que cubre la cabeza.');
  CodImagenes.addEntry(65, 'gala', 'Vestido sobresaliente y lucido.');
  CodImagenes.addEntry(66, 'gajo', 'Cada una de las partes en que está naturalmente dividido el interior de algunos frutos, como la naranja.');
  CodImagenes.addEntry(67, 'guaca', 'Recipiente o lugar donde se guardaba dinero u objetos de valor en la antigüedad.');
  CodImagenes.addEntry(68, 'gafa', 'Gafas o anteojos utilizados para corregir la visión.');
  CodImagenes.addEntry(69, 'jiba', 'Protuberancia o joroba en la espalda de algunos animales.');
  CodImagenes.addEntry(70, 'casa', 'Construcción donde las personas viven o habitan.');
  CodImagenes.addEntry(71, 'cota', 'Arma defensiva del cuerpo usada antiguamente, que en un principio era de cuero.');
  CodImagenes.addEntry(72, 'cono', 'Figura geométrica tridimensional con una base circular y un vértice.');
  CodImagenes.addEntry(73, 'cama', 'Mueble utilizado para dormir o descansar.');
  CodImagenes.addEntry(74, 'carro', 'Vehículo con ruedas utilizado para el transporte de personas o mercancías.');
  CodImagenes.addEntry(75, 'cola', 'Extremo posterior de ciertos animales.');
  CodImagenes.addEntry(76, 'caja', 'Recipiente generalmente rectangular utilizado para guardar o transportar objetos.');
  CodImagenes.addEntry(77, 'coco', 'Fruto del cocotero con cáscara dura y pulpa blanca.');
  CodImagenes.addEntry(78, 'café', 'Bebida caliente y estimulante obtenida a partir de los granos de café.');
  CodImagenes.addEntry(79, 'copa', 'Recipiente utilizado para servir bebidas, es un vaso con pie de apoyo.');
  CodImagenes.addEntry(80, 'fosa', 'Hoyo o cavidad en el suelo, generalmente excavada, que puede usarse para diversos fines, como enterramiento, almacenamiento o drenaje.');
  CodImagenes.addEntry(81, 'foto', 'Fotografía, imagen obtenida mediante un proceso de captura de luz.');
  CodImagenes.addEntry(82, 'fauno', 'Criatura mitológica que combina características humanas y de cabra.');
  CodImagenes.addEntry(83, 'foami', 'Producto industrial de látex o sintético, esponjoso y blando.');
  CodImagenes.addEntry(84, 'faro', 'Torre alta con una luz potente en la parte superior, utilizada como guía para la navegación.');
  CodImagenes.addEntry(85, 'folio', 'Hoja de un libro o de un cuaderno.');
  CodImagenes.addEntry(86, 'fuego', 'Proceso de combustión que produce calor y luz.');
  CodImagenes.addEntry(87, 'foca', 'Mamífero marino de cuerpo robusto y aletas.');
  CodImagenes.addEntry(88, 'FIFA', 'Federación Internacional de Fútbol Asociación.');
  CodImagenes.addEntry(89, 'fab', 'Marca de jabón para ropa, popular en Colombia.');
  CodImagenes.addEntry(90, 'pesa', 'Objeto utilizado para ejercitar y fortalecer los músculos.');
  CodImagenes.addEntry(91, 'pito', 'Instrumento de viento que produce sonidos agudos al soplar.');
  CodImagenes.addEntry(92, 'piano', 'Instrumento musical de cuerda y teclado.');
  CodImagenes.addEntry(93, 'puma', 'Felino de gran tamaño y poderoso, también conocido como león de montaña.');
  CodImagenes.addEntry(94, 'perro', 'Animal doméstico de compañía conocido por su lealtad hacia los humanos.');
  CodImagenes.addEntry(95, 'pala', 'Herramienta de mano utilizada para excavar o mover tierra.');
  CodImagenes.addEntry(96, 'paja', 'Tallo seco de los cereales, utilizado como material para techos o como lecho para animales.');
  CodImagenes.addEntry(97, 'pico', 'Herramienta de cantero y con dos puntas opuestas aguzadas, que sirve principalmente para desbastar la piedra.');
  CodImagenes.addEntry(98, 'puf', 'Asiento acolchado sin respaldo.');
  CodImagenes.addEntry(99, 'pipa', 'Utensilio utilizado para fumar tabaco o sustancias.');

CodImagenes.addEntry(100, 'seso', 'Masa de tejido nervioso contenida en el cráneo.');
CodImagenes.addEntry(101, 'seto', 'Cercado hecho de matas o arbustos, o de palos o varas entretejidos.');
CodImagenes.addEntry(102, 'seno', 'glándula mamaria presente en mamíferos, especialmente en mujeres, que produce leche para alimentar a las crías.');
CodImagenes.addEntry(103, 'sumo', 'Arte marcial de origen japonés, en que los adversarios, cuyo peso excede siempre de los 100 kg, luchan cuerpo a cuerpo.');
CodImagenes.addEntry(104, 'sierra', 'Herramienta para cortar madera, piedra u otros objetos duros, que generalmente consiste en una hoja de acero dentada sujeta a una empuñadura, bastidor o armazón.');
CodImagenes.addEntry(105, 'sol', 'Estrella luminosa, centro de nuestro sistema planetario.');
CodImagenes.addEntry(106, 'soga', 'Cuerda larga y algo gruesa, hecha de tiras de cuero crudo y retorcido.');
CodImagenes.addEntry(107, 'saco', 'Prenda de lana o algodón para el torso y los brazos que se utiliza para calentarse de clima fríos.');
CodImagenes.addEntry(108, 'sofá', 'Asiento cómodo para dos o más personas, que tiene respaldo y brazos.');
CodImagenes.addEntry(109, 'sopa', 'Plato compuesto de un caldo y uno o más ingredientes sólidos cocidos en él.');     

export default CodImagenes;