import React, { useRef } from 'react'
import estiloLanding from "../../../../theme/entrenamiento/Landing/landing.module.css";

interface Props{
    pregunta:string;
    respuesta: string;
    indice:number;
}

const FAQ:React.FC<Props> = ({pregunta,respuesta,indice}) => {
  const refRespuesta = useRef(null)
  const refFlecha = useRef(null)

  const Desplegar = () => {
    if(refRespuesta.current.clientHeight !== 0){
        refRespuesta.current.style.height = 0
        refRespuesta.current.style.overflow = 'hidden'
        refFlecha.current.style.transform = 'rotate(180deg)'
    }else{
        refRespuesta.current.style.height = 'fit-content'
        refFlecha.current.style.transform = 'rotate(0deg)'
    }

  }

  return (
    <>
    { indice%2 === 0 ?
        <>
            <p className={estiloLanding.preguntaTexto} onClick={Desplegar}>
            {indice+1}-{pregunta}</p>
            <img src='/assets/icon/flechaAbrirContenido.svg' onClick={Desplegar}  ref={refFlecha}></img>
            <p className={estiloLanding.respuestaTexto} ref={refRespuesta}>
            {respuesta}
            </p>
        </>:
        <></>
    }
    </>
  )
}

export default FAQ