import React, { CSSProperties } from 'react'

interface Props{
  color:string;
  style:CSSProperties;
}

const ConferenciasServicios = ({color,style}:Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 150 228" fill="none" style={style}>
      <g clip-path="url(#clip0_77_15203)">
        <path d="M43.7419 219.222L37.0442 120.468C37.083 119.56 37.3157 116.666 39.5397 114.071C42.1774 110.983 45.6943 110.542 46.4701 110.451H104.655C105.469 110.672 108.573 111.632 110.615 114.759C112.154 117.133 112.283 119.482 112.283 120.455C110.357 153.282 108.43 186.109 106.517 218.923C106.478 219.598 106.232 222.764 103.607 225.177C101.616 227.007 99.3793 227.37 98.5518 227.474H52.211C51.3059 227.318 48.3967 226.683 46.121 224.075C44.4789 222.193 43.9488 220.182 43.7677 219.209L43.7419 219.222Z" fill={color}/>
        <path d="M30.8637 118.755C31.2645 116.121 32.3765 111.775 35.8547 108.375C39.475 104.846 43.9488 103.562 47.1166 103.562H105.482C106.943 103.873 111.831 105.08 115.451 109.673C118.05 112.968 118.748 116.394 118.955 118.016H136.708C137.393 118.055 141.001 118.159 143.884 115.421C145.862 113.539 146.47 111.321 146.651 110.412C147.763 105.404 148.862 100.409 149.974 95.4002C149.909 94.6606 149.522 90.8978 146.276 88.173C143.729 86.0321 140.923 85.7986 139.992 85.7596H10.3569C6.54254 85.2925 2.8963 86.9793 1.1249 90.0285C-0.698219 93.1555 0.193946 96.4252 0.387895 97.074C1.37057 102.394 2.35324 107.701 3.34885 113.02C4.03413 114.032 5.58572 116.044 8.3398 117.471C11.262 118.989 13.9643 119.015 15.1797 118.95C20.4163 118.885 25.653 118.82 30.8766 118.768L30.8637 118.755Z" fill={color}/>
        <path d="M26.8037 49.2476C24.6444 50.7008 14.5978 57.3441 11.6627 70.5657C9.20604 81.6076 12.7618 88.9515 14.0677 91.7022" stroke="white" stroke-width="5" stroke-miterlimit="10"/>
        <path d="M26.8037 60.1856C33.3305 60.1856 38.6216 54.876 38.6216 48.3263C38.6216 41.7767 33.3305 36.4671 26.8037 36.4671C20.2768 36.4671 14.9857 41.7767 14.9857 48.3263C14.9857 54.876 20.2768 60.1856 26.8037 60.1856Z" fill={color}/>
        <path d="M96.629 33.6246C101.674 21.4032 95.8902 7.39197 83.7114 2.32969C71.5325 -2.73259 57.5701 3.07105 52.5255 15.2925C47.4808 27.5139 53.2643 41.5251 65.4431 46.5874C77.6219 51.6497 91.5843 45.846 96.629 33.6246Z" fill={color}/>
        <path d="M57.6286 54.8528C59.2448 56.319 64.4685 60.6787 72.4463 61.2496C82.454 61.9502 89.294 56.1503 90.5999 54.9956C91.1042 54.8139 96.8709 52.8157 102.095 56.5266C107.422 60.3154 107.37 66.5953 107.357 67.0884C107.357 80.4528 107.357 66.0763 107.357 79.4407H41.9705C41.9705 65.8039 41.9705 79.9078 41.9705 66.271C41.9705 65.8168 42.2549 59.3682 47.7889 56.0206C52.2756 53.3088 56.8787 54.6323 57.6286 54.8658V54.8528Z" fill={color}/>
      </g>
      <defs>
      <clipPath id="clip0_77_15203">
        <rect width="150" height="227" fill={color} transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default ConferenciasServicios