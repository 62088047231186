import { useEffect, useRef, useState } from "react";
import style from "../../../theme/entrenamiento/Landing/LandingCompensar.module.css";
import estiloLanding from "../../../theme/entrenamiento/Landing/landing.module.css";
import estiloJuegos from "../../../theme/Juegos.module.css";
import stylePopover from "../../../theme/PopoverNeuronas.module.css";
import estiloPago from '../../../theme/pagoPage.module.css'

import VideoPlayerIOs from '../componentes/VideoPlayerIOs';
import { useDato } from "../../../auth";
import { useLocation, useNavigate, useParams} from "react-router-dom";
import { useBaseDeDatos } from "../../../hooks/baseDeDatos/useBaseDeDatos";
import { nomCampañas } from "../../../datos/nombresBD";
import { Timestamp } from "firebase/firestore";
import VideoConContador from "../componentes/VideoConContador";
import Checkout from "../../../Checkout";
import Selector from "../componentes/Selector";
import { paises } from "../../../datos/paises";
import { Entrenamiento } from "../../../modelos/entrenamiento/Entrenamiento";
import { ProductoReferencia } from "../../../modelos/Facturas";
import { Campaña } from "../../../modelos/entrenamiento/Campaña";
import { useEntrenamiento } from "../../../hooks/controlador/useEntrenamiento";
import { Producto } from "../../../modelos/Producto";
import { Spinner } from "../componentes/Loading";
import { useToast } from "../../../hooks/useToast";

interface Formulario{
  nombre:string;
  codigoTel:string;
  telefono:string;
  especificaciones?:string;
  direccion?:string;
  ciudad?:string;
  correo:string;
}

type Params = {
  idProducto:string;
  codigoParams:string;
}

const LandingCompra = () => {

  const {idProducto,codigoParams} = useParams<Params>();
  const navigate = useNavigate()
  const location = useLocation();
  const dato = useDato();

  const [codigoTel,setCodigoTel] = useState('+57');
  const [producto,setProducto] = useState<Producto>(null);
  const [campana,setCampana] = useState<Campaña>(null);
  const [usuarioFormulario, setUsuarioFormulario] = useState<Formulario>({nombre:'',codigoTel:'+57',telefono:'',correo:''})

  const [openPopover,setOpenPopover] = useState(false);
  const [montar,setMontar] = useState(false);
  const [progreso,setProgreso] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const inputInscripcion = useRef<HTMLDivElement>(null);
  const referenceReproductor = useRef(null);
  const video = useRef(null);
  const {ActualizarUnCampo,IncrementarCampo} = useBaseDeDatos();
  const {consultarProductoVentas,consultarCampaña, incrementarVisitas } = useEntrenamiento();
  const { presentMensajePersonalizado } = useToast()
  const ENTORNO = (process.env.REACT_APP_ENVIRONMENT === 'production')? "produccion": "desarrollo";

  const [videoUrl1,setVideoUrl1] = useState<string>(
    `https://firebasestorage.googleapis.com/v0/b/supercerebros-${ENTORNO}.appspot.com/o/publico%2Fcompensar%2Fventa_compensar.mp4?alt=media`
  );

  const consulta = async () => {
    await consultarProductoVentas(idProducto).then(
      res => {
        console.log("🚀 ~ consulta ~ res:", res)
        if(!res){
          console.error("error cargando informacion del entrenamiento, entrenamiento no existente");
        }else{
          setProducto(res)
        }
      }
    ).catch(e => {
      console.error(e, "error cargando informacion del entrenamiento");
    })
    await consultarCampaña(codigoParams).then(
      res => {
        console.log("🚀 ~ consulta ~ res:", res)
        if(!res){
          console.error("error cargando informacion de campaña, campaña no existente");
        }else{
          incrementarVisitas(codigoParams).catch(console.error);
          setCampana(res)
        }
      }
    ).catch(e => {
      console.error(e, "error cargando informacion de la campaña");
    })
  }

  const DesmontarPopover = () =>{
    setMontar(false)
    setTimeout(() => {
      setOpenPopover(false);
    }, 1000);
  }

  const ValidarEmail = (valor:string):Boolean => {
    var reg:RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var regOficial:RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (reg.test(valor) && regOficial.test(valor)) {
      console.log("válido oficial y extraoficialmente");
      return true
    } else if (reg.test(valor)) {
      console.log("válido extraoficialmente");
      return true
      
    } else {
      console.log("incorrecto");
      return false
    }
  }

  const ValidarDatos = (esDigital:boolean) => {
    if(usuarioFormulario.codigoTel && usuarioFormulario.telefono && usuarioFormulario.nombre){
      return true
    }
    if(esDigital){
      if(ValidarEmail(usuarioFormulario.correo)){
        return true
      }
      return false
    }else{
      if(usuarioFormulario.ciudad && usuarioFormulario.direccion && ValidarEmail(usuarioFormulario.correo)){
        return true
      }
      return false
    }
  }

  function HandleEnviarInfo (){
    if(ValidarDatos(producto.tipo === 'e-book')){
      setOpenPopover(true);
      setMontar(true)
    }else{
      presentMensajePersonalizado("Datos incompletos")
    }
  }

  function ScrollToComprar(){
    const contenido = document.getElementById('inputInscripcion');
    contenido.scrollIntoView({block:'center',behavior:'smooth'});
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuarioFormulario((state) => ({
      ...state,
      [name]: value
    }));
  };
  
  const ComponentInputs = (esDigital:boolean) => {
    // Flag true si es producto digital
    return(
      <div style={{display:'grid',padding:'5%',gap:'5px'}}>
        <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>NOMBRE COMPLETO*</p>
        <input type='text' name="nombre" style={{width:'100%'}} placeholder='Ingrese su nombre' value={usuarioFormulario.nombre} onChange={handleChange} className='inputPersonalizado2'></input>
        <div style={{display:'flex',flexDirection:'row',gap:'5%'}}>
            <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{width:'45.5%',color:'var(--color-purple-dark)'}}>PAÍS*</p>
            <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>NÚMERO DE TELÉFONO*</p>
        </div>
        <div style={{display:'flex',flexDirection:'row',height:'4.97svh',gap:'5%'}}>
            <div style={{height:'100%'}}>
            <Selector setSelection={setCodigoTel} selection={usuarioFormulario.codigoTel} opciones={paises} showValues={true}
            style={{backgroundColor:'#D9D9D9'}}/>
            </div>
            <input type='number' name="telefono" style={{width:'100%'}} placeholder='Ingrese su telefono' value={usuarioFormulario.telefono} onChange={handleChange} className='inputPersonalizado2'></input>
        </div>
        <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>CORREO ELECTRÓNICO*</p>
        <input type='text' name="correo" style={{width:'100%'}} placeholder='Ingrese su correo' value={usuarioFormulario.correo} onChange={handleChange} className='inputPersonalizado2'></input>
        {!esDigital && <>
          <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>CIUDAD*</p>
          <input type='text' name="ciudad" style={{width:'100%'}} placeholder='Ingrese su ciudad' value={usuarioFormulario.ciudad} onChange={handleChange} className='inputPersonalizado2'></input>
          <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>DIRECCION*</p>
          <input type='text' name="direccion" style={{width:'100%'}} placeholder='Calle 00 #00 - 00' value={usuarioFormulario.direccion} onChange={handleChange} className='inputPersonalizado2'></input>
          <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{color:'var(--color-purple-dark)'}}>ESPECIFICACIONES*</p>
          <input type='text' name="especificaciones" style={{width:'100%'}} placeholder='casa, torre 00 apto 000' value={usuarioFormulario.especificaciones} onChange={handleChange} className='inputPersonalizado2'></input>
        </>}
        <label style={{color:'var(--color-purple-dark)',alignSelf:'flex-end',textAlign:'end',width:'100%'}}>*Campos obligatorios</label>
        <button className={'buttonMorado'} style={{margin:'0 auto',width:'fit-contain',padding:'2% 15%'}} type="submit" onClick={HandleEnviarInfo}>ENVIAR</button>
      </div>
    )
  }

  useEffect(() => {
    setVideoUrl1(producto?.videoURL)
  }, [producto])

  useEffect(() => {
    consulta()
  }, [])
  
  return (
    <div id='page' className={estiloLanding.page} style={{height:'100%',maxWidth:'70svh',zIndex:'10',position:'relative'}}>
        { producto ?
        <div id='content_default' style={openPopover ? {overflowX:'hidden',height:'100%',overflowY:'scroll',opacity:"0.4",borderRadius: '0'}:{overflowX:'hidden',height:'100%',overflowY:'scroll',borderRadius: '0', marginBottom: (dato?"8.9svh":"0")}}  >
          <div  style={{ width:'100%',height:'fit-content',background:'linear-gradient(0deg, #D74286 0%, #45225F 100%)'}} >
            
            <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center',alignItems:'center',padding:'5% 0'}}>
              <p className={estiloJuegos.tituloJuegos} style={{marginBottom:'0.81rem',marginTop:'2.18rem',fontWeight:'900'}}>"{producto?.nombre}" </p>
            </div> 

            <div className={estiloJuegos.contenedorVideo} style={{display:'flex',justifyContent:'center'}} ref={video}>
            {videoUrl1 !== null && 
            <>
              <VideoConContador setNeuronasGanadas={setProgreso} videoUrl={codigoParams !== 'cod_3' ? videoUrl1: undefined} ref={referenceReproductor} 
              poster={codigoParams !== 'cod_3' && '/assets/images/landingVideoPoster.jpg'} preload='auto' autoplay={true} 
              style_video={{maxHeight:'60svh',width:'auto',height: "100%",maxWidth:'100%'}} 
              style_contain={{maxHeight:'fit-content',width:'fit-content',height:'60svh',margin:'5% 0',borderRadius:'25px',overflow:'hidden',boxShadow:'5px 0px 10px black'}}>
              </VideoConContador>
            </>}
            </div>
            
          </div>
              
          {(!loading) && <div className={estiloLanding.showInfo} style={{width:'100%'}}>
            <div id='inputInscripcion'>
                <div className={estiloPago.factura} style={{paddingTop:'2%'}}>
                  <p>Factura de Compra</p>
                  <div className={estiloPago.linea}>
                    {Array.from({ length: 16 }, (_, index) => (
                      <div key={index}></div>
                    ))}
                  </div>
                  <div className={estiloPago.grid}>
                    <p>valor</p>
                    <p className={estiloPago.valor}>{(producto.precio).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
                    <p>descuento</p>
                    <p className={estiloPago.valor}>{campana?.descuento ? (campana.descuento).toLocaleString('es-CO', { style: 'currency', currency: 'COP' }):"No hay descuento"}</p>
                  </div>
                  <div className={`${estiloPago.grid} ${estiloPago.backMorado}`}>
                    <p>total</p>
                    <p className={estiloPago.valor}>{((producto.precio - (campana?.descuento ?? 0))>0 ? (producto.precio - (campana?.descuento ?? 0)) : 0).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
                  </div>
                </div>
                <>
                  {ComponentInputs(producto?.tipo !== 'libro')}
                </>
            </div>
            <br></br>
            <div className='linea' style={{backgroundColor:'#45225F',margin:'3px 10%'}}></div>
            <br></br>
            <div>
              <div className={estiloLanding.contenedorFoto}>
                <h1 className={estiloLanding.tituloSobreNosotros}>SOBRE EL PRODUCTO</h1>
                <img className={estiloLanding.juanDavid} src='/assets/images/sobre_nosotros.png'></img>
              </div>
              <div className={estiloLanding.textoSobreNosotros}>
                {producto.descripcion}
              </div>
            </div>
          </div>}
          <button
           style={dato?{marginBottom: "8.9svh"}:{}}
           className={estiloLanding.buttonEstatico} onClick={ScrollToComprar}>
            INGRESA AL CURSO
          </button>
        </div> : <Spinner></Spinner>}
        {openPopover && producto &&
        <>
          <div className={[stylePopover.contenedor,montar ? '' : stylePopover.desmontar].join(" ")}>
            <div className={stylePopover.contenido}>
              <div className="contenido_popover" style={(dato)?{paddingBottom: "8.9svh"}:{}}>

                <h3 className='confirmacion_pedido'>CONFIRMACIÓN DE PEDIDO</h3>
                <div className='producto'>
                  <div> {`Entrenamiento "${producto?.nombre}"`}</div>
                  <div style={{textAlign:"end"}}>${(producto.precio).toLocaleString('es-CO')}</div>
                </div>
                {campana?.descuento > 0 && <div  className='producto'>
                  <div>Descuento</div>
                  <div style={{textAlign:"end"}}>${(-campana?.descuento).toLocaleString('es-CO')} </div>
                </div>}
                <div className='linea_punteada'></div>
                <div className='valor_total'>
                  <div>Valor total:</div>
                  <div style={{textAlign:"end"}}>${(( producto.precio - (campana?.descuento ?? 0))>0 ? (producto.precio - (campana?.descuento ?? 0)) : 0).toLocaleString('es-CO')} </div>
                </div>
                
                <Checkout titulo={`${producto?.nombre}`} 
                  description={`${producto?.nombre}`} 
                  costo={(producto.precio - (campana?.descuento ?? 0))>0 ? (producto.precio - (campana?.descuento ?? 0)) : 0}
                  setLoading={() => setLoading((prev) => !prev)}
                  userId={null}
                  id_productos={[{id_referencia:producto.id,tipo:producto.tipo}as ProductoReferencia]}
                  rutaRedireccion='/responseFactura'
                  email={usuarioFormulario.correo}
                  telefono={usuarioFormulario.telefono}
                  codigoTelefono={usuarioFormulario.codigoTel}
                  nombre={usuarioFormulario.nombre}
                  campaña={campana? campana?.id: null}
                  direccion={producto?.tipo !== 'libro' ? null : `${usuarioFormulario.direccion} ${usuarioFormulario.especificaciones ?? ''}`}
                  sesionesPrueba={campana?.sesionesPrueba}
                  urlCompra={location.pathname}
                  />
                <button style={{height:"4.5vh", width:"100%",marginBottom:"2vh"}} className="volver" onClick={DesmontarPopover} >
                  VOLVER
                </button>
              </div>
            </div>
          </div>
        </>}     
    </div>
  )
}

export default LandingCompra