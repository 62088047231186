import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import NotFoundPage from '../NotFoundPage'
import HomePaginaWeb from './HomePaginaWeb'
import QuienesSomos from './QuienesSomos'
import Servicios from './Servicios'
import MobileHome from './MobileHome'
import Tienda from './Tienda'
import ServiciosMobile from './ServiciosMobile'
import QuienesSomosMobile from './QuienesSomosMobile'
import TiendaMobile from './TiendaMobile'

const RoutesWeb = () => {
  return (
    <BrowserRouter>
        <Routes>
            {IsMobile() ? 
            <>
              <Route path='/web/quienesSomos' element={<QuienesSomosMobile/>}/>
              <Route path='/web/servicios/:Section?' element={<ServiciosMobile/>}/>
              <Route path="/web/home/:Section?" element={<MobileHome />}/>	
              <Route path="/web/tienda" element={<TiendaMobile />}/>	
              <Route path="/web/" element={<Navigate to="/web/home" replace/>} />
              <Route path="/web/*" element={<Navigate to="/web/home" replace/>} />
            </>
            :
            <>
              <Route path="/web/home" element={<HomePaginaWeb />}/>	
              <Route path="/web/quienesSomos" element={<QuienesSomos />}/>	
              <Route path="/web/servicios/:Section?" element={<Servicios />}/>	
              <Route path="/web/tienda" element={<Tienda />}/>	
              <Route path="/web/" element={<Navigate to="/web/home" replace/>} />
            </>}

            {/* Anexos a la app */}

            <Route path='*' element={<NotFoundPage />}/>		
        </Routes>
    </BrowserRouter>
  )
}

export default RoutesWeb

export function GetViewportWidth(): number{
  return document.documentElement.clientWidth;
}
export function GetViewportHeight(): number{
  return document.documentElement.clientHeight;
}
export function GetAspectRatio(): number{
  return document.documentElement.clientWidth/document.documentElement.clientHeight;
}
export function IsMobile(mobileWidth: number = 760): boolean{
  return (GetViewportWidth() <= mobileWidth || GetAspectRatio() <= (5/8));
}