import React, { CSSProperties, useState } from 'react'
import styleFirstTimeEntrenamiento from "../../../theme/entrenamiento/FirstTimeEntrenamiento.module.css";


export interface opcion{
    value: any;
    name: any;
}

interface Props{
    setSelection:React.Dispatch<React.SetStateAction<any>>;
    selection: any;
    opciones: opcion[];
    showValues?: boolean;
    nullable?: boolean;
    style?:CSSProperties;
    style_contain?:CSSProperties;
    style_cortina?:CSSProperties;
}

export const Selector:React.FC<Props> = (props) => {
    const [currentSelection, setCurrentSelection] = useState(() => {
        if(props.opciones.length <= 0) return null;
        else if(props.selection){
            for(const opc of props.opciones){
                if(props.selection === opc.value)
                    return opc;
            }
        }
        else return props.opciones[0];
    });

    const [previousSelection, setPreviousSelection] = useState(currentSelection);

    const [selector, setSelector] = useState(true)

    const cancelar = () => {
        setCurrentSelection(previousSelection);
        setSelector(true)
    }

    const salir = () => {
        props.setSelection(currentSelection.value);
        setPreviousSelection(currentSelection);
        setSelector(true)
    }

  return (
    <div className={styleFirstTimeEntrenamiento.selector} style={{...props.style_contain,border:'1px solid var(--color-Pink-Light)',zIndex :(selector === false ? '30':'')}}>
        <input type='text' value={`${props.showValues? currentSelection?.value ?? '': currentSelection?.name ?? ''}`} className={styleFirstTimeEntrenamiento.selectorInput} onClick={() => setSelector(false)} readOnly
        style={props.style}></input>
        {selector === false &&<>
            <div className={styleFirstTimeEntrenamiento.cortina} style={props.style_cortina} hidden={selector} onClick={cancelar}></div>
            <div className={styleFirstTimeEntrenamiento.box} hidden={selector}>
            <div className={styleFirstTimeEntrenamiento.line} style={{margin: "initial"}}></div>
            <div className={styleFirstTimeEntrenamiento.top}>
                {props.nullable || props.opciones.length <= 0 &&
                    <button onClick={() => setCurrentSelection(null)}>
                        <div><div className={props.selection == null ? styleFirstTimeEntrenamiento.seleccionado : ''}></div></div>
                        <p style={{color:'white'}}>selection</p>
                    </button>
                }
                {props.opciones.map((element,k) => (
                    <button onClick={() => setCurrentSelection(element)} key={k}>
                        <div><div className={currentSelection?.value === element?.value ? styleFirstTimeEntrenamiento.seleccionado : ''}></div></div>
                        <p>{element.name}</p>
                    </button>
                ))}
            </div>
            <div className={styleFirstTimeEntrenamiento.line2}></div>
            <div className={styleFirstTimeEntrenamiento.bot}>
                <button onClick={cancelar}>cancel</button>
                <button onClick={salir}>ok</button>
            </div>
            </div>
        </>}
    </div>
  );
}

export default Selector;
