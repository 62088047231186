import React, { CSSProperties } from "react";
import ReactPlayer from "react-player/lazy";
// import YouTube from "react-youtube";

interface Props{
  urlVideo:string;
  style_video?:CSSProperties;
  style_contain?:CSSProperties;
  height?:string;
  width?:string;
}

const VideoPlayerYoutube: React.FC<Props> = ({urlVideo,style_video}:Props) => {

  return (
    <div>
      <ReactPlayer 
        url={urlVideo} 
        light={true}
        controls 
        height={'100%'} 
        style={style_video}
        width={'auto'}></ReactPlayer>
    </div>
  );
};

export default VideoPlayerYoutube;
