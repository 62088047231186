import estiloJuegos from "../../../theme/Juegos.module.css";
import { useNavigate } from 'react-router-dom'
import BotonBordeDegradado from "../../componentes/BotonBordeDegradado";

interface Props {
    funcionReiniciar: () => void;
    funcionVolver: () => void;
    esJuego: boolean;
    esEntrenamiento?: boolean;
}

export const BotonesJuegos:React.FC<Props> = (props) => {

    console.log(props.esJuego)

    const navigate = useNavigate();

    const handleRedirect = ( ruta:string) => {
        navigate(ruta)
    }
  return (
    <div className={estiloJuegos.div_botones_grid}>
        {/* <div style={!props.esJuego && !props.esEntrenamiento ? {gridTemplateColumns:'repeat(1,1fr)'} : {gap:'6%'}}> */}

        {props.esJuego || props.esEntrenamiento ? 
            <BotonBordeDegradado onClick={props.funcionVolver} paddingInterno="5.55% 5%" style={{padding: "2px 0"}}>volver</BotonBordeDegradado>
            :
            <button onClick={() => props.funcionVolver()} className={estiloJuegos.buttonPlayAgain}>
                Finalizar
            </button>
        }
        {(props.esJuego || props.esEntrenamiento) && <button onClick={() => props.funcionReiniciar()} className={estiloJuegos.buttonPlayAgain}>Jugar
            de nuevo</button>}

        {/* </div> */}
        {/* {props.esJuego && !props.esEntrenamiento && 
            <button  onClick={() => handleRedirect("/my/juegos/ranking")} className={estiloJuegos.botonRanking}>
                <span >RANKING</span>
            </button>  
        } */}

    </div>
  )
}

export default BotonesJuegos;