
// este recibe un numeroNoPermitido en el caso donde no se debe aceptar un valor que ya se encuetra en el array
export const ArrayDeNumerosParaBotones = (cuenta: number, datos:string[] | number, numeroNoPermitido:string) => {

    const tamMaximo = typeof datos === 'number' ? datos : datos.length;


    let arr: string[] = [];
    let i = 0;
    const max = typeof datos === 'number' ? tamMaximo: tamMaximo - 1;
    const min =  typeof datos === 'number' ? 1:0;
    let num = null;
    while (i !== cuenta) {
        num = Math.floor(Math.random() * (max - min + 1)) + min;
        
        if (!arr.includes(num) && num!= numeroNoPermitido) {
            arr.push(num);
            i++;
            
        }
    }        
    return arr;
}





