import { useEffect, useRef, useState } from 'react';

/**
 * Interfaz para las propiedades del tiempo
 */
interface prop{

    /**  Funcion que gestiona los tiempos en las etapas*/
    handleTiempo: () => void;

    /** Tiempo maximo para memorizar o responder*/
    maxTiempo: number;
    setTiempoMemoria: (newTiempoMemoria: number) => void;
    bandera: boolean;
    contar?: boolean;
    ascendente?: boolean;
}

/**
 * Pone la palabra Tiempo en pantalla y 
 * llama al componente Tiempo para temporizar el juego.
 */
const TiempoWrapper: React.FC<prop> = (props) => {
    const [time, setTime] = useState(props.ascendente ? 0 : props.maxTiempo*100);
    const[contar, setContar] = useState(props.contar ?? true);
    const[ascendente, setAscendente] = useState(props.ascendente ?? false);
    let intervalId = null ;

    useEffect(() => {
         intervalId = setInterval(() => {
            if(contar){
                setTime((prevTime) => (prevTime + (ascendente? 1 : (-1))));
            }
        }, 10);
        
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if(time<=0 && !ascendente){
            props.setTiempoMemoria(Number(props.maxTiempo) - Number(time));
            props.handleTiempo();
            clearInterval(intervalId);
        }
        if(time >= props.maxTiempo*100 && ascendente){
            props.setTiempoMemoria(Number(time));
            props.handleTiempo();
            clearInterval(intervalId);
        }
        props.setTiempoMemoria(ascendente? Number(time): (Number(props.maxTiempo) - Number(time)));
    }, [time])

    useEffect(() => {
        setContar(props.contar ?? true);
    }, [props.contar])

    useEffect(() => {
        setTime(props.ascendente ? 0 : props.maxTiempo*100)
    }, [props.bandera])
    
    const FormatTime = (milliseconds: number): string => {
        const totalSeconds = Math.floor(milliseconds / 100);
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    return (
        <div className="topInfoContainer">
            <img src="/assets/icon/relojBlanco2.svg" alt="⏱"/>
            <p style={{color:'white',margin:'0'}}>{FormatTime(time)}</p>
        </div>
    );
    
}

export default TiempoWrapper;