import { useEffect, useMemo, useRef, useState } from 'react'
import { useEntrenamiento } from '../../../hooks/controlador/useEntrenamiento';

import { Entrenamiento } from '../../../modelos/entrenamiento/Entrenamiento';

import "../../../theme/entrenamiento/entrenamiento.css"
import stylePopover from "../../../theme/PopoverNeuronas.module.css";
import estiloJuegos from "../../../theme/Juegos.module.css";
import estiloLanding from "../../../theme/entrenamiento/Landing/landing.module.css";

import { Campaña } from '../../../modelos/entrenamiento/Campaña';
import Checkout from '../../../Checkout';
import { paises } from '../../../datos/paises';
import { useToast } from '../../../hooks/useToast';
import { Timestamp} from 'firebase/firestore';
import { agregarAlCarroFacebook, pageViewFacebook } from '../../../ConfigPixel';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loading from '../componentes/Loading';
import Selector from '../componentes/Selector';
import { useDato } from '../../../auth';
import { useAppSelector } from '../../../store';
import { useFacturas } from '../../../hooks/controlador/useFacturas';
import { Factura, ProductoReferencia } from '../../../modelos/Facturas';
import VideoConContador from '../componentes/VideoConContador';
import { MensajeUsuario } from '../../../modelos/MensajeUsuario';
import ComponenteCortesia from './ComponenteCortesia';
import { Modal } from 'react-bootstrap';
import FAQ from '../componentes/components/FAQ';

type Params = {
  titleEntrenamiento:string;
  codigoParams:string;
}

interface TiempoRestante {
  dias: number;
  horas: number;
  minutos: number;
  segundos: number;
}
interface Props{
  ruta?: string;
}

const LandingProducto = ({ruta}: Props) => {
  const dato = useDato();
  const { getFactura } = useFacturas();

  const idTrain = useAppSelector((state) => state.activity.id_program)
  const email = useAppSelector((state) => state.auth.email);

  // INFORMACIÓN WEB
  const {titleEntrenamiento,codigoParams} = useParams<Params>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [entrenamiento,setEntrenamiento] = useState<Entrenamiento>();
  const [campaña,setCampaña] = useState<Campaña>();
  const [loading, setLoading] = useState(false);
  const [mensaje, setMensaje] = useState(false);
  const [openPopover,setOpenPopover] = useState(false);
  const [aplicaDescuento,setAplicaDescuento] = useState(false);
  const [showInfo,setShowInfo] = useState(Boolean(dato));
  const [montar,setMontar] = useState(false);
  const [descuento,setDescuento] = useState<number>(0);
  const [tiempoRestante,setTiempoRestante] = useState<TiempoRestante>(null);
  const [fechalimite,setFechalimite] = useState<Date>();
  const [textSize,setTextSize] = useState<number>(16);
  const [telefono,setTelefono] = useState<string>((dato?.state)?dato.state.telefono:"");
  const [codigoTel,setCodigoTel] = useState<string>((dato?.state)?dato.state.codigo:'+57');
  const [correo_usuario,setCorreo_usuario] = useState<string>(email??"");
  const [nombre_usuairo,setNombre_usuairo] = useState<string>((dato?.state)?dato.state.nombres:"");
  const [inputCodigoCampaña, setInputCodigoCampaña] = useState<string>("");
  const [facturaPagoDirecto, setFacturaPagoDirecto] = useState<{id:string, factura:Factura} | null>(null);

  const inputInscripcion = useRef<HTMLDivElement>(null);
  const ionPage = useRef(null);
  const video = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    consultarCampaña,
    incrementarVisitas,
    consultarEntrenamientoVentas,
  } = useEntrenamiento();
  const {
    presentMensajePersonalizado
  } = useToast();
  
  
  function restarUnSegundoAlTiempo(fecha: Date):void {
    const ahora = new Date();
    
    // Obtener la diferencia en milisegundos
    let diferencia = Math.max(fecha.getTime() - ahora.getTime(), 0);
    if( diferencia <= 0){
      setAplicaDescuento(false)
      setDescuento(0)
      return setTiempoRestante({dias:0,horas:0,minutos:0,segundos:0} as TiempoRestante)
    }
  
    // Calcular días, horas, minutos y segundos
    const segundos = Math.floor(diferencia / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);
  
    return setTiempoRestante({
      dias: dias,
      horas: horas % 24,
      minutos: minutos % 60,
      segundos: segundos % 60
    } as TiempoRestante);
  }

  const calcularTiempoRestante = async (timestamp: Timestamp) => {
    // Obtener la fecha actual
    const ahora = new Date();
  
    // Obtener la fecha objetivo a partir del Timestamp de Firebase
    const fechaObjetivo = timestamp?.toDate();
  
    // Calcular la diferencia en milisegundos
    const diferencia = fechaObjetivo?.getTime() - ahora.getTime();
    
    if (diferencia < 0) {
      setAplicaDescuento(false)
      setDescuento(0)
      return setTiempoRestante({dias:0,horas:0,minutos:0,segundos:0} as TiempoRestante)
    }
    
  
    // Calcular días, horas, minutos y segundos
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
    const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

    return setTiempoRestante({dias:dias,horas:horas,minutos:minutos,segundos:segundos} as TiempoRestante)
  }
  
  useEffect(() => {
    let intervalId;
    
    if (aplicaDescuento) {
      intervalId = setInterval(() => {
        ajustarTexto()
        if(campaña?.fechaVigente !== undefined){
          calcularTiempoRestante(campaña?.fechaVigente)
        }else{
          restarUnSegundoAlTiempo(fechalimite)
        }}, 1000);
    }
    
    return () => clearInterval(intervalId);
  }, [aplicaDescuento]);


  const ajustarTexto = () => {
    setTextSize(ionPage.current.offsetWidth * 0.032)
  }

  const CodigoInfo = async (codig: string) => {
    const codificado = await consultarCampaña(codig);

    console.log("codificado", codificado);
    

    if(!codificado || !(codificado.descuento)){
      setDescuento(0);
      setAplicaDescuento(false);
      return;
    }

    incrementarVisitas(codig).catch(console.error);

    let descuento = Number(codificado?.descuento);
    setDescuento(descuento);
    setCampaña(codificado);
    if(codificado?.fechaVigente !== undefined){
      calcularTiempoRestante(codificado?.fechaVigente)
    }else{
      if(codificado?.tiempoVigencia !== 0){
        let fechalimite = new Date();
        fechalimite.setMinutes(fechalimite.getMinutes() + codificado?.tiempoVigencia);
        setFechalimite(fechalimite)
        restarUnSegundoAlTiempo(fechalimite)
      }
    }
    setAplicaDescuento(true)
  }
    
  const Consulta = async (codig?:string) => {
    var divParaRutaEspecifica = document.getElementById("supercerebro-fondo");
    divParaRutaEspecifica.style.display = "block";
    console.log(codig);
    if(codig){
      await CodigoInfo(codig);
    }else{
      setAplicaDescuento(false)
    }
  }

  const ValidarEmail = (valor:string):Boolean => {
    var reg:RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var regOficial:RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (reg.test(valor) && regOficial.test(valor)) {
      console.log("válido oficial y extraoficialmente");
      return true
    } else if (reg.test(valor)) {
      console.log("válido extraoficialmente");
      return true
      
    } else {
      console.log("incorrecto");
      return false
    }
  }

  const DesmontarPopover = () =>{
    setMontar(false)
    setTimeout(() => {
      setOpenPopover(false);
    }, 1000);
  }

  useEffect(() => {
    pageViewFacebook();   

    setNombre_usuairo(prev => searchParams.get("nombre") ?? prev);
    setTelefono(prev => searchParams.get("tel") ?? prev);
    setCorreo_usuario(prev => searchParams.get("correo") ?? prev);
    const info = Boolean(searchParams.get("info") ?? showInfo);

    const idFactura = searchParams.get("id_fact");

    console.log("info:",info);

    let load = 0;
    let toLoad = idFactura? 1: 0;
    if(info === true){
      setLoading(true);
      if(idFactura) getFactura(idFactura).then(res => {

        if(res){
          setFacturaPagoDirecto({id:idFactura, factura:res});
          setOpenPopover(true);
        }

        if (load > toLoad) {setLoading(false)}
        load++;
        console.log("load:", load);
      })
    }
    consultarEntrenamientoVentas(titleEntrenamiento).then(
      res => {
        if(!res){
          console.error("error cargando informacion del entrenamiento, entrenamiento no existente");
          if(ruta) navigate(`../${ruta}/neurobics${codigoParams? `/${codigoParams}`: ""}?${searchParams.toString()}`);
          else navigate("../");
        }else{
          console.log(res);
          if (load > toLoad) {setLoading(false)}
          load++;
          console.log("load:", load);
          setEntrenamiento(res);
        }
      }
    ).catch(e => {
      console.error(e, "error cargando informacion del entrenamiento");
      if (ruta) navigate(`../${ruta}/neurobics${codigoParams? `/${codigoParams}`: ""}?${searchParams.toString()}`);
      else navigate("../");
    })

    Consulta(codigoParams).then(() => {if (load > toLoad) {setLoading(false)}; console.log("toLoad:", toLoad); load++; console.log("load:", load);})
  },[])

  const ValidarDatos = (): Boolean => {
    if(ValidarEmail(correo_usuario) && codigoTel && telefono && nombre_usuairo ){
      return true
    }else{
      presentMensajePersonalizado("Datos incompletos")
      return false
    }
  }
    
  function HandleSubmit(): void {
    agregarAlCarroFacebook();
    if(ValidarDatos()){
      setOpenPopover(true);
      setMontar(true)
    }else{
      presentMensajePersonalizado("Datos incompletos")
    }
  }

  function ScrollToComprar(){
    const contenido = document.getElementById('inputInscripcion');
    contenido.scrollIntoView({block:'center',behavior:'smooth'});
  }

  const RegistrarCodigo = (codigo: string) => {
    setLoading(true);
    Consulta(codigo).finally(
      () => {
        setLoading(false);
        ScrollToComprar();
      }
    );
  }


  return (
  <div id='page' className={estiloLanding.page} style={{height:'100%',maxWidth:'70svh',zIndex:'10',position:'relative'}} ref={ionPage}>
    <Loading isOpen={loading}></Loading>
    <div id='content_default' style={openPopover ? {overflowX:'hidden',height:'100%',overflowY:'scroll',opacity:"0.4",borderRadius: '0'}:{overflowX:'hidden',height:'100%',overflowY:'scroll',borderRadius: '0', marginBottom: (dato?"8.9svh":"0")}}  >
      <div  style={{ width:'100%',height:'fit-content',background:'linear-gradient(0deg, #D74286 0%, #45225F 100%)'}} >
        
        <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center',alignItems:'center'}}>
          <p className={estiloJuegos.tituloJuegos} style={{marginBottom:'0.81rem',marginTop:'2.18rem',fontWeight:'900'}}>"{titleEntrenamiento}" </p>
        </div>  
        <div className='linea' style={{backgroundColor:'#FFFFFF',margin:'0 10.25%'}}></div>
        
        <p className={estiloLanding.text}>Biohacking cerebral <br /> atención. concentración. memoria</p>
        <div className={estiloJuegos.contenedorVideo} style={{display:'flex',justifyContent:'center'}} ref={video}>
        {entrenamiento?.ruta !== null && 
        <>
          <VideoConContador 
          autoplay={true}
          videoUrl={entrenamiento?.ruta}
          poster={entrenamiento?.posterVideo ?? '/assets/images/landingVideoPoster.jpg'}
          style_contain={{width: "100%", height: "auto"}}
          style_video={{width: "100%", height: "auto"}}
          ></VideoConContador>
        </>}
        </div>
        
      </div>
          
      {(!loading) && <div className={estiloLanding.showInfo} style={{width:'100%'}}>
        <h2 className={estiloLanding.tituloMorado} style={{marginTop:'5.68vh',marginBottom:'4.26vh'}}>Libera el Poder de Tu Cerebro con {entrenamiento?.titulo}</h2>
        {entrenamiento?.descripcion && <> 
          <p className={estiloLanding.parrafoGris}>
            {entrenamiento?.descripcion}
          </p>
          <div className='linea' style={{backgroundColor:'#45225F',margin:'3px 10%'}}></div>
        </>}
      
        <div className={estiloLanding.container_grid}>
          <div className={estiloLanding.columna1} ><img src='/assets/icon/Mano_SuperCerebro.svg'></img></div>
          <p className={estiloLanding.parrafo2}>Entrenadores:<br></br>
            <span style={{ fontWeight:'700', color:'#45225F'}}>David Cantor - Juan Duque</span>
          </p>
          <div className={estiloLanding.columna1}><img src='/assets/icon/Mano_SuperCerebro.svg'></img></div>
          <p className={estiloLanding.parrafo2}>Participantes:<br></br>
            <span style={{ fontWeight:'700', color:'#45225F'}}>1214</span>
          </p>
          <div className={estiloLanding.columna1}><img src='/assets/icon/Mano_SuperCerebro.svg'></img></div>
          <p className={estiloLanding.parrafo2}>Última actualización:<br></br>
            <span style={{ fontWeight:'700', color:'#45225F'}}>Mayo 2024</span>
          </p>
          <div className={estiloLanding.columna1}><img src='/assets/icon/Mano_SuperCerebro.svg'></img></div>
          <p className={estiloLanding.parrafo2}>Precio<br></br>
            <span style={{fontSize:'2.3em', fontWeight:'700', color:'#45225F', marginTop:'10px',textTransform:'uppercase'}}>${Number(entrenamiento?.precio).toLocaleString('es-CO')}<span style={{fontSize:'1.5rem'}}>{entrenamiento?.moneda ?? 'COP'}</span></span>
          </p>
        </div>
      
        <div className={estiloLanding.contenedorPrecioDescuento}>
          <img style={{height:'25.47vh'}} src='/assets/images/supercerebro/sc_nice.png'></img>
          
          {aplicaDescuento && <div className={estiloLanding.descuento}>
            <span> {Math.floor(descuento/Number(entrenamiento.precio)*100)} %</span><br></br>descuento
          </div>}
          <div className={estiloLanding.precioCurso} style={(aplicaDescuento)?{}:{alignSelf:'center',justifyContent:'center',height:'fit-content',padding:'2% 5%'}}>
            <p style={{lineHeight:'110%',textAlign:'end'}}>
              ${Number((entrenamiento?.precio ?? 0) - descuento).toLocaleString('es-CO')}<span style={{color:'inherit'}}>{entrenamiento?.moneda ?? 'COP'}</span>
            </p>
          </div>  
          
        </div>
        <br></br>
        {aplicaDescuento && <div>
          <div style={{display:'grid',gridTemplateColumns:'repeat(4,1fr)',gap:'2%',height:'9vh'}}>
            <div className={estiloLanding.cajonTime}>
              {tiempoRestante?.dias}
            </div>
            <div className={estiloLanding.cajonTime}>
              {tiempoRestante?.horas}
            </div>
            <div className={estiloLanding.cajonTime}>
              {tiempoRestante?.minutos}
            </div>
            <div className={estiloLanding.cajonTime}>
              {tiempoRestante?.segundos}
            </div>

          </div>
          <div style={{display:'grid',gridTemplateColumns:'repeat(4,1fr)',gap:'2%',height:'fit-content',marginTop:'1vh'}}>
            <p className={estiloLanding.subtitleTime}>días</p>
            <p className={estiloLanding.subtitleTime}>Horas</p>
            <p className={estiloLanding.subtitleTime}>minutos</p>
            <p className={estiloLanding.subtitleTime}>segundos</p>
          </div>
        </div>}
      
        <div className={estiloLanding.boxInput} id='inputInscripcion'ref={inputInscripcion}>
          <h2 className={estiloLanding.tituloInputBox}>¿A dónde deberíamos enviar el acceso al curso?</h2>
          <p className={estiloLanding.campoObligatorio}>Nombre Completo*</p>
          <input type='text' style={{width:'100%'}} placeholder='Ingrese su nombre' value={nombre_usuairo} onChange={(e) => setNombre_usuairo(e.target.value)} className='inputPersonalizado2'></input>
          <p className={estiloLanding.campoObligatorio}>número de telefono*</p>
          <div style={{display:'flex',flexDirection:'row',height:'4.97svh'}}>
            <div style={{height:'100%'}}>
              <Selector setSelection={setCodigoTel} selection={codigoTel} opciones={paises} showValues={true}/>
            </div>
            <input type='number' style={{width:'100%'}} placeholder='Ejemplo: ' value={telefono} onChange={(e) => setTelefono(e.target.value)} className='inputPersonalizado2'></input>
          </div>
          <p className={estiloLanding.campoObligatorio}>Correo electronico*</p>
          <input type='text' style={{width:'100%'}} placeholder='Ejemplo: example@xmpl.com' value={correo_usuario} onChange={(e) => setCorreo_usuario(e.target.value)} className='inputPersonalizado2'></input>
          <div style={{display: 'flex',flexDirection:'column',alignItems:'center',width:'100%',justifyContent:'center',marginTop:'2.5vh'}}>
            <button className='pagar' onClick={()=> HandleSubmit()}>{'ingresa al curso'}</button>
          </div>
          { codigoParams === 'campana' && 
          <>
            <p className={estiloLanding.campoObligatorio}>Codigo de campaña</p>
            <input type='text' style={{width:'100%'}} placeholder='Ejemplo: codigo' value={inputCodigoCampaña} onChange={(e) => setInputCodigoCampaña(e.target.value)} className='inputPersonalizado2'></input>
            <div style={{display: 'flex',flexDirection:'column',alignItems:'center',width:'100%',justifyContent:'center',marginTop:'2.5vh'}}>
              <button className='pagar' onClick={() => RegistrarCodigo(inputCodigoCampaña)}>registra el codigo</button>
            </div>
          </>}
        </div>
        {entrenamiento?.descripcion2 && <>
          <br></br>
          <p className={estiloLanding.parrafoGris}>
            {entrenamiento.descripcion2}
          </p>
        </>}
        <h2 className={estiloLanding.tituloMorado} style={{fontSize:'4.26vh',textTransform:'none'}}>¿Qué incluye el curso?</h2>
        <div className={estiloLanding.container_grid} style={{gap:'2svh'}}>
          <div className={estiloLanding.columna1} ><img src='/assets/icon/Mano_SuperCerebro.svg'></img></div>
          <p className={estiloLanding.parrafo2Resaltado}>{entrenamiento?.cantidadSesiones ?? 0} CLASES
          </p>
          <div className={estiloLanding.columna1}><img src='/assets/icon/Mano_SuperCerebro.svg'></img></div>
          <p className={estiloLanding.parrafo2Resaltado}>ACCESO POR 365 DÍAS A TODOS LOS MATERIALES DEL CURSO
          </p>
          <div className={estiloLanding.columna1} style={{alignSelf:'flex-start'}}><img src='/assets/icon/Mano_SuperCerebro.svg'></img></div>
          <p >
            <span className={estiloLanding.parrafo2Resaltado}>  ASISTENTE PERSONALIZADO POR WHATSAPP:</span>
            <br />
            <span className={estiloLanding.parrafo2}>
              Tendrás un compañero de entrenamiento cerebral en tu bolsillo, listo para recordarte, motivarte y mantener tu progreso.
            </span>
          </p>
        </div>
        <br></br>
        <img src='/assets/images/distintosDispositivos.png' style={{width:'100%'}}></img>
        {entrenamiento?.rutaTestimonio && <>
        <div className='linea' style={{backgroundColor:'#45225F',margin:'3px 10%'}}></div>
        <br></br>
        <p style={{marginTop:'5%',marginBottom:'2%',fontSize:`${textSize * 2}px`,color:'#45225F',textAlign:'center',textTransform:'uppercase',fontWeight:'900'}}>testimonios</p>
        <div className={estiloJuegos.contenedorVideo} ref={video} style={{margin:'5.1vh 0',padding:'0 15.34%'}}>
          {entrenamiento?.rutaTestimonio !== null && <VideoConContador videoUrl={entrenamiento?.rutaTestimonio} autoplay={false} style_video={{width:'100%'}}/>}
        </div>
    </>}
      {entrenamiento?.sobreNosotros && <div>
        <div className={estiloLanding.contenedorFoto}>
          <h1 className={estiloLanding.tituloSobreNosotros}>SOBRE NOSOTROS</h1>
          <img className={estiloLanding.juanDavid} src='/assets/images/sobre_nosotros.png'></img>
        </div>
        <div className={estiloLanding.textoSobreNosotros} dangerouslySetInnerHTML={{ __html: entrenamiento.sobreNosotros }}>
        </div>
      </div>}
      {entrenamiento?.faq && <div className={estiloLanding.preguntasFrecuentes}>
        {/* Preguntas frecuentes */}
        <p className={estiloLanding.superOferta}>FAQ</p>
        <p className={estiloLanding.tituloPreguntasFrecuentes}>Preguntas Frecuentes</p>
        <div style={{width:'60%',margin:'0 20%',marginBottom:'2%',height:'2px',backgroundColor:'#45225F'}}></div>
        <div className={estiloLanding.preguntas}>
          {entrenamiento?.faq.map((element,_i) =>
            <FAQ pregunta={element} respuesta={element[_i]} indice={_i}></FAQ>
          )}
        </div>
      </div>}
          
      <div style={{backgroundColor:'#FFFFFF',borderRadius:'23px',height:'5vh',margin:'0 -5.5%',marginBottom:'-5%',position:'relative',zIndex:'2'}}></div>
      <div style={{marginTop:'0%',paddingTop:'10%',background:'linear-gradient(167deg, #45225F 7.98%, #D74286 79.34%)',height:'fit-content',width:'111%',margin:'0 -5.5%',position:'relative',bottom:'0',padding:'10% 5%',display:'flex',flexDirection:'column',alignItems:'center'}}>
        <p style={{marginTop:'0%',marginBottom:'0%',fontSize:`${textSize * 3}px`,color:'#FFFFFF',textAlign:'center',textTransform:'uppercase',fontWeight:'900',fontFamily:'Montserrat'}}>clientes</p>
        <p style={{marginTop:'2%',marginBottom:'2%',fontSize:`${textSize * 1}px`,color:'#FFFFFF',textAlign:'center',textTransform:'uppercase',fontWeight:'200',fontFamily:'Montserrat'}}>Algunos de nuestros clientes</p>
        <div style={{height:'20vh',width:'100%',backgroundColor:'#FFFFFF',marginTop:'5%',borderRadius:'20px',display:'grid',gridTemplateColumns:'1fr 1fr 1fr',alignItems:'center',justifyItems:'center',alignContent:'center'}}>
          <img src='/assets/images/clientesAliados/uan.png'></img>
          <img src='/assets/images/clientesAliados/compensar.png'></img>
          <img src='/assets/images/clientesAliados/planetario.png'></img>
          <img src='/assets/images/clientesAliados/bancoOccidente.png'></img>
          <img src='/assets/images/clientesAliados/gimnasio.png'></img>
        </div>
        <div style={{background:'#FFFFFF',width:'100%',height:'1px',margin:'10% 0'}}></div>
        <p className={estiloLanding.text_info} style={{fontSize:`${textSize}px`}}>SuperCerebros SAS<br></br>Calle 19 #6-68 Oficina 603, Bogotá<br></br>310 311 3156</p>
      </div>
    </div>}
      <button
        style={dato?{marginBottom: "8.9svh"}:{}}
        className={estiloLanding.buttonEstatico} onClick={ScrollToComprar}>
        INGRESA AL CURSO
      </button>
    </div>
        
    {openPopover &&
    <>
      <div className={[stylePopover.contenedor,montar ? '' : stylePopover.desmontar].join(" ")}>
        <div className={stylePopover.contenido}>
          <div className="contenido_popover" style={(dato)?{paddingBottom: "8.9svh"}:{}}>

            <h3 className='confirmacion_pedido'>CONFIRMACIÓN DE PEDIDO</h3>
            <div className='producto'>
              <div> {`Entrenamiento "${entrenamiento?.titulo}"`}</div>
              <div style={{textAlign:"end"}}>${(entrenamiento?.precio).toLocaleString('es-CO')}</div>
            </div>
            {aplicaDescuento &&<div  className='producto'>
              <div>Descuento</div>
              <div style={{textAlign:"end"}}>${(-descuento).toLocaleString('es-CO')} </div>
            </div>}
            <div className='linea_punteada'></div>
            <div className='valor_total'>
              <div>Valor total:</div>
              {/* <div style={{textAlign:"end"}}>${(costo - costo * descuento).toLocaleString('es-CO')} </div> */}
              <div style={{textAlign:"end"}}>${(entrenamiento?.precio - descuento).toLocaleString('es-CO')} </div>
            </div>
            
            <Checkout titulo={`Entrenamiento ${entrenamiento?.titulo}`} 
              description={`Entrenamiento ${entrenamiento?.titulo}`} 
              costo={(entrenamiento?.precio - descuento)}
              tipoMoneda={entrenamiento?.moneda}
              setLoading={() => setLoading(prev => !prev)}
              userId={null}
              id_productos={[{id_referencia:idTrain??titleEntrenamiento,tipo:'entrenamiento'}as ProductoReferencia,(entrenamiento?.asignacion ?? null)]}
              rutaRedireccion='/responseFactura'
              email={correo_usuario}
              telefono={telefono}
              codigoTelefono={codigoTel}
              nombre={nombre_usuairo}
              campaña={campaña? campaña.id: null}
              pagoDirecto={facturaPagoDirecto}
              sesionesPrueba={campaña?.sesionesPrueba}
              urlCompra={location.pathname}
              />
            <button style={{height:"4.5vh", width:"100%",marginBottom:"2vh"}} className="volver" onClick={DesmontarPopover} >
              VOLVER
            </button>
          </div>
        </div>
      </div>
    </>}     
        
    {mensaje && <Modal open={mensaje} onClickOutside={() => setMensaje(false)}>
      <div className={estiloLanding.modalMensaje} >
        Revisa tu whatsapp
      </div>
    </Modal>}
  </div>
  )
}

export default LandingProducto