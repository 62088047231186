import React from "react";
import estiloJuegos from "../../theme/Juegos.module.css"
import BotonBordeDegradado from "./BotonBordeDegradado";

interface Props{
  textoSuperior?: string;
  onClickSuperior?: () => void;
  textoInferior?: string;
  onClickInferior?: () => void;
  style?: React.CSSProperties;
  esJuego?:boolean;
}

const DosBotonesColumna = ({textoSuperior, textoInferior, onClickSuperior, onClickInferior, style, esJuego}: Props) => {
  return (
    <div style={style}>
      <button className={estiloJuegos.buttonEmpezar} style={esJuego === false ? {display:'none'}:{}} onClick={onClickSuperior}>{textoSuperior ?? ""}</button>
      <BotonBordeDegradado onClick={onClickInferior} style={{width: '100%', fontSize: "1.2rem", lineHeight:"40px", padding: "2px 0"}} paddingInterno="2%">{textoInferior ?? ""}</BotonBordeDegradado>
    </div>
  );
}

export default DosBotonesColumna;
