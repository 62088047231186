

import React, { useEffect, useState } from 'react';
import { useIA } from "../../hooks/controlador/useIA";
import "../../theme/chatIA.css"
import estiloChat from "../../theme/chatIA.module.css"
import { useToast } from "../../hooks/useToast";
import { usePalabras } from "../../hooks/controlador/usePalabras";
import { Asociacion, aAsociacion } from "../../modelos/AsocionTraduccion";

import Loading from '../entrenamiento/componentes/Loading';

const ConsultaIngles: React.FC = () => {

  const SYSTEM_PARECIDO_FONETICO_IA = "system_parecido_fonetico";
  const SYSTEM_ASOCIACION_IA = "system_asociacion";
  const SYSTEM_TRADUCCION_IA = "system_traduccion_ingles";
  
  const [systemParecidoFonetico, setSystemParecidoFonetico] = useState();
  const [systemAsociacion, setSystemAsociacion] = useState();
  const [systemTraduccion, setSystemTraduccion] = useState();

  const [asociaciones, setAsociaciones] = useState([]);
  const [indexParecido, setIndexParecido] = useState(0);
  const [loading, setLoading] = useState(false);
  const [espanol, setEspanol] = useState('');
  const [traduccion, setTraduccion] = useState("Traduccion");
  const [parecidos, setParecidos] = useState([]);
  const [asosiacionesRespuesta, setAsosiacionesRespuesta] = useState([]);
  const [respuestasUserParecido, setRespuestasUserParecido] = useState([]);
  const [respuestasUserAsociacion, setRespuestasUserAsociacion] = useState([]);

  const {
    presentMensajePersonalizado
  } = useToast()

  const {
    obtenerConfiguracionMensaje
  } = useIA();

  const {
    VerificarExistencia,
    TraerAsociaciones,
    CrearPalabra,
  } = usePalabras()
  
  const {
    consultarChat
  } = useIA();

  const GuardarRegistro = async() => {
    setLoading(true)
    await CrearPalabra(
      espanol.toLowerCase(),
      traduccion.toLowerCase(),
      aAsociacion(asociaciones[indexParecido].parecido,asociaciones[indexParecido].frase)
    )    
    setLoading(false)
  }

  const FuncionBarril = async () => {
    setIndexParecido(0)
    setTraduccion("Traduccion")
    setAsociaciones([])
    setParecidos([])
    setAsosiacionesRespuesta([])
    if (espanol === null || espanol === '') {
      presentMensajePersonalizado("No hay palabra")
    } else {
      setLoading(true)
      await HandleClick(espanol)
      setLoading(false)
    }
  }
  
  const HandleClick = async(espanol:string) => {
    setLoading(true)
    espanol = espanol.toLowerCase()
    const respuestaIngles = await VerificarExistencia(espanol)
    if(respuestaIngles !== null){
      let backupAsociaciones = await TraerAsociaciones(espanol) 
      setAsociaciones(backupAsociaciones)
      setTraduccion(respuestaIngles)
    }else{
      ConsultaIA(systemTraduccion,espanol);
    }
    setLoading(false)
  }

  const MensajeFonetico = (array:string[],respuestasUser:string[],mensaje:any[],input:string,inputIAFrase?:string) => {
    array.forEach((respuestaChat,index) => {
      mensaje.push({
        "role": "assistant",
        "content": respuestaChat,
      },
      {
        "role": "user",
        "content": `${respuestasUser[index]} ${input} ${inputIAFrase ? inputIAFrase : ''}`,
      })
    })
    return mensaje
  }

  const CrearMensajeIA = (content:string,inputIA:string,inputIAFrase?:string) => {
    let mensaje = [
      {
        "role": "system",
        "content": content,
      },
      {
        "role": "user",
        "content": `${inputIA} ${inputIAFrase ? inputIAFrase : ''}`,
      }
    ]

    if(content === systemParecidoFonetico){
      if(parecidos.length <= 3){
        mensaje = MensajeFonetico(parecidos,respuestasUserParecido,mensaje,inputIA)
      }else{
        setParecidos([])
      }
    }
    return mensaje;
  }

  const ConsultaIA = async (content:string,inputIA: any) => {
    let mensaje = null;
    let backupIndexParecido = indexParecido;
    let backupAsociaciones = asociaciones;
    let backupTraduccion: string = traduccion;
    let backupParecido: string = asociaciones[indexParecido]?.parecido;
    let backupFrase: string = asociaciones[indexParecido]?.frase;
    setLoading(true)
    switch (content) {
      // @ts-expect-error
      case (systemTraduccion):
        mensaje = CrearMensajeIA(systemTraduccion,espanol);
        backupTraduccion = await consultarChat(mensaje);
        backupTraduccion = backupTraduccion.toLowerCase()
        if(content === systemTraduccion){
          backupAsociaciones = []
          backupIndexParecido = 0
        }
        if(backupTraduccion === null || backupTraduccion === undefined){
          console.log("Error no se puede traducir esa palabra")
          presentMensajePersonalizado("Error no se puede traducir esa palabra")
          break
        }
        setTraduccion(backupTraduccion)
        
      // @ts-expect-error
      case (systemParecidoFonetico):
        mensaje = CrearMensajeIA(systemParecidoFonetico,backupTraduccion);
        console.log("🚀 ~ ConsultaIA ~ mensaje:", mensaje)
        backupParecido = await consultarChat(mensaje);
        backupParecido = backupParecido.toLowerCase()
        setParecidos((prev) => [...prev,backupParecido])
        backupAsociaciones.push(aAsociacion(backupParecido,''))
        if(content === systemParecidoFonetico){
          backupIndexParecido +=1;
          setAsosiacionesRespuesta([])
        }
        
      case (systemAsociacion):
        mensaje = CrearMensajeIA(systemAsociacion,backupParecido,espanol);
        if(content === systemAsociacion){
          if(asosiacionesRespuesta.length <= 3){
            mensaje = MensajeFonetico(asosiacionesRespuesta,respuestasUserAsociacion,mensaje,backupParecido,espanol)
          }else{
            setAsosiacionesRespuesta([])
          }
        }
        console.log("🚀 ~ ConsultaIA ~ mensaje:", mensaje)
        backupFrase = await consultarChat(mensaje);
        setAsosiacionesRespuesta((prev) => [...prev,backupFrase])
        backupAsociaciones[backupIndexParecido].frase = backupFrase
        setIndexParecido(backupIndexParecido)
        setAsociaciones(backupAsociaciones)
        break
        
      default:
        console.log("Error no se envio un tipo de peticion valido")
        break
    }
    setLoading(false)
  }

  // Realiza la validacion de no existir más posibles opciones entre frases o palabras llama a consultar
  const ValidaAumentarIndice = (content:string,inputIA:string) =>{
    let indiceTemporal = (indexParecido + 1) % asociaciones.length; 
    if(indiceTemporal){
      return setIndexParecido(indiceTemporal)
    }
    return ConsultaIA(content,inputIA)
  }

  //Trae los mensajes iniciales de la confiuguracion para consultas a chatGPT
  async function ObtenerConfiguracion() {
    const systemParecidoFoneticoMensaje = await obtenerConfiguracionMensaje(SYSTEM_PARECIDO_FONETICO_IA);
    const systemAsociacionMensaje = await obtenerConfiguracionMensaje(SYSTEM_ASOCIACION_IA);
    const systemTraduccionMensaje = await obtenerConfiguracionMensaje(SYSTEM_TRADUCCION_IA);
    
    setRespuestasUserParecido(await obtenerConfiguracionMensaje(SYSTEM_PARECIDO_FONETICO_IA,true))
    setRespuestasUserAsociacion(await obtenerConfiguracionMensaje(SYSTEM_ASOCIACION_IA,true))
    setSystemParecidoFonetico(systemParecidoFoneticoMensaje)
    setSystemAsociacion(systemAsociacionMensaje)
    setSystemTraduccion(systemTraduccionMensaje)
  }

  
  useEffect(() => {
    ObtenerConfiguracion();
  }, []);

  const ResaltarParrafo = (paragraph:string, word1:string, word2:string) => {

    if (typeof paragraph !== 'string') {
      // Si paragraph no es una cadena de texto, mostrar un mensaje de error o manejarlo según sea necesario
      return <p>Error: El párrafo proporcionado no es una cadena de texto.</p>;
    }

    // Dividir el párrafo en palabras
    const parts  = paragraph?.split(/(\s+)/);
  
    return (
      <label style={{ whiteSpace: 'pre-wrap', display: 'inline-block',textTransform:'none',width:'calc(100% - 1.9svh - 2.79svh)',alignSelf:'flex-end',marginRight:'2.79svh'}}>
        {parts && parts.map((part, index) => (
          <span key={index}>
            {part === word1 || part === word2 ? <strong style={{ color: 'var(--color-Pink-Light)', fontWeight: '700', whiteSpace: 'nowrap' }}>{part}</strong> : part}
          </span>
        ))}
      </label>
    );
  };

  return (
    <div id='page'>
      <Loading isOpen={loading} />
      <div id='' className={`${estiloChat.toolbar_tamanoChat} centrar`} style={{height:'32.1svh',marginBottom:'-9svh',padding:'6% 5%'}}>
        <p className={estiloChat.title}>Inglés</p>
        <div className={estiloChat.lineaTool}></div>
        <div className={`${estiloChat.containImagen} ${traduccion !== 'Traduccion' && estiloChat.containImagen_active}`}>
          <p>¿Qué palabra quieres aprender hoy?</p>
          <div className={estiloChat.imagen}/>
        </div>
      </div>
      <div style={traduccion === 'Traduccion' ? {justifyContent:'center'}:{height:'fit-content'}} id="content_default">
        {traduccion === 'Traduccion' ? 
        <div className={estiloChat.defaultPage}>
          <input type="text" placeholder="Ingrese una palabra" value={espanol} onChange={(event) => setEspanol(event.target.value)}></input>
          <img src="/assets/icon/flechaRosadaSend.svg" style={{height:'2.76svh',width:'2.91svh'}} onClick={() => FuncionBarril()}></img>
        </div>
        : <>
        <div className={estiloChat.card}>
          <p>Palabra</p>
          <div>
            <input type="text" placeholder="Ingrese una palabra" value={espanol} onChange={(event) => setEspanol(event.target.value)}></input>
            <img src="/assets/icon/flechaRosadaSend.svg" style={{height:'2.245svh',width:'2.35svh'}} onClick={() => FuncionBarril()}></img>
          </div>
          <div style={{marginTop:'4.27svh',marginRight:'2.79svh'}}>
            <p>Traducción</p>
            <label>{traduccion}</label>
          </div>
          <div style={{marginRight:'2.79svh'}}>
            <img src='/assets/icon/icon_refresh.png' onClick={async() => {ValidaAumentarIndice(systemParecidoFonetico,traduccion)}}></img>
            <p>Palabra similar:</p>
            <label>{asociaciones[indexParecido]?.parecido}</label>
          </div>
          <div style={{marginRight:'2.79svh'}}>
            <img src='/assets/icon/icon_refresh.png' onClick={() => ConsultaIA(systemAsociacion,asociaciones[indexParecido]?.parecido)}></img>
            <p>asociación:</p>
          </div>
          {asociaciones.length >= 1
          && ResaltarParrafo(asociaciones[indexParecido].frase,espanol,asociaciones[indexParecido].parecido)}
        </div>
        <div className={estiloChat.boxButton}>
          <button id='button_rosado_default' onClick={() => GuardarRegistro()} style={{margin:'0'}}>Guardar</button>
        </div>
        </>}
      </div>
    </div>
  );
};

export default ConsultaIngles;
