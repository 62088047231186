
// Coleccion Users
const nomUsuarios = "usuarios";
const nomUsuarioTests = "usuario_tests";
const nomUsuarioAdminTests = "usuario_adminTests";
const nomUsuarioTestProgramas = "usuario_test_programas";
const nomUsuarioTestProgramaUsos = "usuario_test_programa_usos";
const nomConfiguracionJuegos = "configuracion_juegos";
const nomUsuarioEntrenamiento = "usuario_entrenamiento";
const nomUsuarioPublicidad = "usuario_publicidad";

// Neuronas
const nomNeuronas = "neuronas"; 
const nomNeuronasSemanales = "neuronas_semanales"; 
const nomRegistroEntrenamiento = "registro_entrenamiento"
const nomRegistroActividad = "registro_actividades"

// Coleccion TEST
const nomTest = "tests";
const nomTestProgramas = "test_programas";
const nomTestAdministradores = "test_administradores";
const nomTestParticipantes = "test_participantes";
const nomTestProgramaUsuarios = "test_programa_usuarios";

// Coleccion Programas
const nomProgramas = "programas";

// Coleccion Lecturas
const nomLecturas = "lecturas";

// Entrenamientos
const nomEntrenamiento = "entrenamientos";
const nomEntrenamientoVentas = "entrenamientos_ventas";
const nomEntrenamientoSesiones = "entrenamiento_sesiones";
const nomEntrenamientoActividades = "entrenamiento_actividades";
const nomUsuarioEntrenamientos = "usuario_entrenamientos";
const nomUsuarioEntrenamientoSesiones = "usuario_entrenamiento_sesiones";
const nomUsuarioEntrenamientoActividades = "usuario_entrenamiento_actividades";

// nombre de los programas generales juegos
const nomProgSumas1 = "sumas_1";
const nomProgMemNumeros = "memoria_numeros";
const nomProgMemPalabras = "memoria_palabras";
const nomProgMemNombres = "memoria_nombres";
const nomProgLecturaVelocidad = "lectura_velocidad";
const nomProgLecturaComprension = "lectura_comprension";
const nomProgCuestionario = "cuestionario";
const nomProgCodigoPalabras = "codigo_palabras";
const nomProgSuperCerebrosDice = "supercerebros_dice";
const nomProgParejas = "memoria_parejas";


// nombre de los programas de test inicial
const nomTestMemNumerosInicial = "test_memoria_numeros_inicial";
const nomTestMemPalabrasInicial = "test_memoria_palabras_inicial";
const nomTestMemNombresInicial = "test_memoria_nombres_inicial";
// nombre de los programas de test
const nomTestSumas1 = "test_sumas_1";

// Coleccion Entidades
const nomEntidades = "entidad";
const nomEntidadIntegrante = "entidad_integrantes";
const nomEntidadEntrenamiento = "entidad_entrenamientos";

const nomCampañas = "campanas";

// coleccion facturas
const factura = "facturas";

// coleccion mensajes
const nomMensajes = "mensajes"
const nomMensajesCurso = "mensajes_cursos"
const nomMensajesVenta = "mensajes_ventas"
const nomMensajesConfiguracion = "mensajes_configuracion"
const nomMensajesConversaciones = "mensajes_conversaciones"


// coleccion palabras
const nomPalabras = "palabras";
const nomPalabraAsociacionIngles = "asociaciones_ingles";

// modulo simultaneo
const nomJuegoSimultaneo = "juegos_linea";
const nomJuegoIntegrantes = "juegoIntegrantes";


export {
    nomUsuarios,
    nomUsuarioTests,
    nomUsuarioAdminTests,
    nomUsuarioTestProgramas,
    nomUsuarioTestProgramaUsos,
    nomUsuarioPublicidad,
    nomNeuronas,
    nomNeuronasSemanales,
    nomRegistroEntrenamiento,
    nomTest,
    nomTestProgramas,   
    nomTestParticipantes,
    nomTestProgramaUsuarios,
    nomProgramas,
    nomLecturas,
    nomProgSumas1,
    nomProgLecturaVelocidad,
    nomProgLecturaComprension,
    nomProgMemNumeros,
    nomProgMemPalabras,
    nomProgCodigoPalabras,    
    nomProgMemNombres,
    nomProgCuestionario,
    nomTestSumas1,
    nomTestMemNumerosInicial,
    nomTestMemPalabrasInicial,
    nomEntidades,
    nomEntidadIntegrante,
    nomEntidadEntrenamiento,
    nomConfiguracionJuegos,
    nomUsuarioEntrenamiento,
    nomEntrenamiento,
    nomEntrenamientoVentas,
    nomEntrenamientoSesiones, 
    nomEntrenamientoActividades,
    nomRegistroActividad,
    nomUsuarioEntrenamientos, 
    nomUsuarioEntrenamientoSesiones, 
    nomUsuarioEntrenamientoActividades,
    nomTestAdministradores,
    nomProgSuperCerebrosDice,
    nomTestMemNombresInicial,
    nomCampañas,
    factura,
    nomProgParejas,
    nomPalabras,
    nomPalabraAsociacionIngles,
    nomJuegoSimultaneo,
    nomJuegoIntegrantes,
    nomMensajes,
    nomMensajesCurso,
    nomMensajesVenta,
    nomMensajesConfiguracion,
    nomMensajesConversaciones,
};
