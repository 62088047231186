// import { IonButton, IonContent, IonHeader, IonLabel, IonLoading, IonPage, IonToolbar } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { Lectura } from '../../../modelos/Lectura';
import { useLectura } from '../../../hooks/controlador/useLectura';
import ToolbarLectura from './componentes/ToolbarLectura';
import SegundaPantallaLectura from './componentes/SegundaPantallaLectura';
import TerceraPantallaLectura from './componentes/TerceraPantallaLectura';
import { useAppSelector } from '../../../store';
import { useDato } from '../../../auth';
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { nomProgLecturaVelocidad, nomProgMemPalabras } from '../../../datos/nombresBD';
import { useLocation } from 'react-router-dom';



export const VelocidadLectura = () => {

  const userId = useAppSelector((state) => state.auth.userId);
  const testId = useAppSelector((state) => state.activity.id);

  const {
    guardarDatosCalificacion,
  } = useJuegos();


  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState<Boolean>(false);
  const primeraEtapa = 1;
  const segundaEtapa = 2;
  const terceraEtapa = 3;
  const inicialValorNumero = 1;
  const location = useLocation();
  const [tiempo, setTiempo] = useState<number>(0);
  const [tiempoMemorizado, setTiempoMemorizado] = useState<number>(0);
  const [etapa, setEtapa] = useState<number>(inicialValorNumero);
  const [lectura, setLectura] = useState<Lectura>();
  const { consultarLecturaTest } = useLectura();


  const aSiguientePantalla = (id: number) => {
    console.log("etapa " + id)
    setEtapa(id)
  }


  const constructor = async () => {
    setLoading(true)
    setEtapa(inicialValorNumero)
    setLectura(await consultarLecturaTest(testId));
    console.log("🚀 ~ file: VelocidadLectura.tsx:51 ~ constructor ~ testId:", testId)
    console.log("🚀 ~ file: VelocidadLectura.tsx:58 ~ constructor ~ setLectura:", lectura)
    setEtapa(primeraEtapa)
    setTiempoMemorizado(0)
    setFlag(false)
    setLoading(false)
  }

  useEffect(() => {
      constructor();

  }, [])
  
  useEffect(() => {
    console.log("🚀 ~ file: VelocidadLectura.tsx:23 ~ VelocidadLectura ~ tiempoMemorizado:", tiempoMemorizado)

  }, [tiempoMemorizado])

  return (
    <></>
    // <IonPage>
    //   <IonLoading isOpen={loading} />
    //   <IonHeader>
    //     <IonToolbar>
    //       <h1 className="ion-text-center title"> Lectura </h1>
          
    //     </IonToolbar>
    //   </IonHeader>
    //   <IonContent className='ion-padding'>
    //     <ToolbarLectura
    //       flag={flag}
    //       aSiguientePantalla={aSiguientePantalla}
    //       // running={running}
    //       // setRunning={setRunning}
    //       nivel={lectura?.nivel}
    //       etapa={etapa}
    //       setTiempoMemoria={setTiempoMemorizado}
    //     />
    //     {etapa === 1 &&
    //       <div className="ion-text-center" >
    //         <IonLabel>En esta prueba, tendrás que leer el texto: {lectura?.titulo}.  </IonLabel> 
    //         <br></br> 
    //         <br></br>   
    //         <IonLabel> Una vez que hayas terminado de leer, se te presentarán cinco preguntas relacionadas con el contenido del texto. </IonLabel> 
    //         <br></br> 
    //         <br></br> 
    //         <IonButton expand="block" onClick={() => aSiguientePantalla(2)}>Comenzar el Test</IonButton>
    //     </div>
    //     }
    //     {etapa === 2 &&
    //       <SegundaPantallaLectura
    //         aSiguientePantalla={aSiguientePantalla}
    //         setLoading={setLoading}
    //         setFlag={setFlag}
    //         lectura={lectura}
    //       />
    //     }
    //     {etapa === 3 &&
    //       <TerceraPantallaLectura
    //         setLoading={setLoading}
    //         aSiguientePantalla={aSiguientePantalla}
    //         tiempoMemorizado={tiempoMemorizado}
    //         lectura={lectura}
    //       />
    //     }
    //   </IonContent>
    // </IonPage>
  )
}

export default VelocidadLectura;