/** Externas */
import { useReducer } from 'react';

/** Locales */
import { Usuario, Aplicacion, USUARIO_VACIO, USUARIO_DATO_DUMMY } from '../modelos/usuario';
import { logObj } from '../utils/ayudantes';

export function useStorage() {

	function reducer(state, action) {

		if (typeof action === 'string') {
			const str = action;
			action = {
				type: str
			}
		}
		
		if (action.type === "crear-datos") {
			const aplicacion = new Aplicacion();
			aplicacion.setUsuario(action.dato);
			return aplicacion;
		}

		if (action.type === "borrar") { 
			return new Aplicacion();
		}

		if (action.type === "sign-out") {
			const aplicacion = new Aplicacion();
			return aplicacion;
		}

		if (action.type === "test") {
			return new Aplicacion();
		}

		if (action.type === "guardar-datos") {
			const aplicacion = new Aplicacion();
			aplicacion.setUsuario({
				administrador: false,
				datosGuardado: true,
				initializado: true,
				usuarioDato: action.dato.usuarioDato,
			})
			return aplicacion;
		}

		if (action.type === "set-datos") {			
			const aplicacion = new Aplicacion();
			aplicacion.setUsuario(action.dato)
			return aplicacion;
		}

		throw Error('Unknown action.');
	}

	const [state, dispatch] = useReducer(reducer, new Aplicacion());

	return {
		state,
		dispatch,
	}
}
