import React from 'react'

const PuntosMorados = ({width,rotacion}) => {
  return (
    <div style={{width:'fit-content',height:'fit-content',aspectRatio:'2/1',display:'flex',flexDirection:'row',transform:`rotate(${rotacion})`}}>
        <div style={{width:`${width}svh`,height:`${width}svh`,borderRadius:'100%',background:'var(--color-purple-dark)'}}></div>
        <div style={{width:`${2*width}svh`,height:`${2*width}svh`,borderRadius:'100%',background:'var(--color-purple-dark)'}}></div>
    </div>
  )
}

export default PuntosMorados