import { DocumentData, DocumentSnapshot } from "firebase/firestore";

export interface Pregunta {
    id? : string;   
    pregunta : string;
    imagen? : string;
    video? : string;
    respuestas?: Respuesta[];
}

export interface Respuesta {
    id? : string;   
    respuesta : string;
    imagen? : string;
    correcta: Boolean;
}

export const aPregunta = (pregunta: string, id?:string, imagen?:string, video?:string,respuestas?: Respuesta[]) : Pregunta => {
    return {         
        id: id,
        pregunta: pregunta,
        imagen:imagen,
        video:video,
        respuestas: respuestas,
    } as Pregunta;
}
export const aRespuesta = (correcta:Boolean, id?:string, respuesta?:string, imagen?:string) : Respuesta => {
    return {         
        id: id,
        respuesta: respuesta,
        imagen:imagen,
        correcta: correcta,
    } as Respuesta;
}


type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toRespuesta = (doc : FireBaseDocument) : Respuesta => {
    const data = doc.data();
    return {
        id : doc.id,
        respuesta: data.respuesta,
        imagen: data.imagen,
        correcta: data.correcta,
    } as Respuesta;
}

export const toPregunta = (doc : FireBaseDocument, respuestas: Respuesta[]) : Pregunta => {
    const data = doc.data();
    return {
        id : doc.id,
        pregunta: data.pregunta,
        imagen:data.imagen,
        video:data.video,
        respuestas: respuestas,
    } as Pregunta;
}