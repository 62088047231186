
import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";



export interface Test {
    id : string; 
    nombre : string;
    descripcion : string;
    fechaCreacion : Timestamp;
    fechaInicio : Timestamp;
    fechaFin : Timestamp;    
    obligatorio : boolean;   
    torneo : boolean;       
}

export interface TestInicial {
    id : string;     
    completado : boolean;       
}

export interface ParticipanteTest {  
    id : string;    
    correo : string;
}

export const aParticipanteTest = (correo : string) : ParticipanteTest => {
    return {         
        correo : correo,
    } as ParticipanteTest;    
}

export const aTest = (nombre : string, descripcion : string, fechaInicio : string, fechaFin : string, torneo: boolean, obligatorio : boolean) : Test => {
    return {
        fechaCreacion : Timestamp.now(),
        nombre : nombre,
        descripcion : descripcion,
        fechaInicio : Timestamp.fromDate(new Date(Date.parse(fechaInicio))),        
        fechaFin : Timestamp.fromDate(new Date(Date.parse(fechaFin))),
        obligatorio : obligatorio,
        torneo : torneo,        
    } as Test;        
}

export const aTestUsuario = (nombre : string, fechaInicio : string, fechaFin : string, obligatorio : boolean, torneo: boolean) : Test => {
    return {
        fechaCreacion : Timestamp.now(),
        nombre : nombre,
        fechaInicio : Timestamp.fromDate(new Date(Date.parse(fechaInicio))),        
        fechaFin : Timestamp.fromDate(new Date(Date.parse(fechaFin))),
        obligatorio : obligatorio,
        torneo : torneo                 
    } as Test;        
}

export const aTestInicialUsuario = (completado : boolean) : TestInicial => {
    return {
        completado : completado,                  
    } as TestInicial;        
}


/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>


export const toTest = (doc : FireBaseDocument) : Test => {
    const data = doc.data() as Test;
    return {   
        id : doc.id,     
        nombre : data.nombre,
        fechaInicio : data.fechaInicio,
        fechaFin : data.fechaFin,
        obligatorio: data.obligatorio,        
        torneo: data.torneo,
    } as Test;
}

export const toTestInicial = (doc : FireBaseDocument) : TestInicial => {
    const data = doc.data() as TestInicial;
    return {   
        id : doc.id,     
        completado : data.completado,
    } as TestInicial;
}

export const toParticipanteTest = (doc : FireBaseDocument) : ParticipanteTest => {
    const data = doc.data();
    return {   
        id : doc.id,     
        correo : data.correo,
    } as ParticipanteTest;
}