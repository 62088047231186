import { useEffect, useMemo, useState } from 'react'
import { useEntrenamiento } from '../../hooks/controlador/useEntrenamiento';
import { useAppDispatch, useAppSelector } from '../../store';
import { changeCondicion, changePrograma, guardarSubtitulo, newActivity } from '../../hooks/reducer/reducerActividad';
import { RegistroEntrenamiento } from '../../modelos/entrenamiento/RegistroEntrenamiento';
import useRegistroEntrenamiento from '../../hooks/controlador/useRegistroEntrenamiento';
import { useNavigate } from 'react-router-dom';
import Loading, { Spinner } from './componentes/Loading';
import estiloEntrenamiento from '../../theme/entrenamiento/EntrenamientoPage.module.css'
import estiloSelector from '../../theme/programas/componentes/selectorNivel.module.css'
import { Entrenamiento } from '../../modelos/entrenamiento/Entrenamiento';
import { ChangeRedux_actividades_entrenamiento, ChangeRedux_current_actividades, ChangeRedux_current_entrenamiento, ChangeRedux_current_indice_actividad, ChangeRedux_current_registro_usuario, ChangeRedux_current_sesion } from '../../hooks/reducer/reducerEntrenamiento';

const EntrenamientoPage = ({cargando}:{cargando:boolean}) => {

    const userId = useAppSelector((state) => state.auth.userId);
    const entrenamientos_app = useAppSelector((state) => state.entrenamiento.entrenamientos_app);
    const entrenamientos_user = useAppSelector((state) => state.entrenamiento.entrenamientos_user);
    const registros_user = useAppSelector((state) => state.entrenamiento.registros_usuario);

    const [loading, setLoading] = useState(cargando);
    const [openpopover, setOpenpopover] = useState(false);
    const [selectEntrenamiento, setSelectEntrenamiento] = useState(null);
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { consultarEntrenamiento, consultarEntrenamientos, consultarActividadesSinSesion} = useEntrenamiento();
    const { consultarRegistroEntrenamiento} = useRegistroEntrenamiento();

    //Constates 
    var nomNeurobics = 'neurobics';
    var nomSuperNutricion = 'super - nutricion';
    var nomSuperIngles = 'superIngles';
    var nomMemoria = 'memoria';
    var nomSuperCuboRubik = 'super -  cubo rubik';

    const BuscarEntrenamientoSiExiste = (nomEntrenamiento:string): string => {
        let bandera = entrenamientos_user?.find((element) => element === nomEntrenamiento)
        return !bandera ? estiloEntrenamiento.entrenamientoDiv_block : ''
    }

    const Consulta = async () => {
        dispatch(changeCondicion({condicion:10}))
        dispatch(newActivity({id:null,name:"entrenamiento"}))
    }
    

    useEffect(() => {
        setLoading(cargando);
        if(cargando) Consulta();
    }, [cargando]);

    useEffect(() => {
      if(selectEntrenamiento === null){
        setOpenpopover(false)
      }else{
        setOpenpopover(true)
      }    
    }, [selectEntrenamiento])
    
    
    const HandleRedirectEntrenamiento = async(idEntrenamientoInput:string) => {
        if(!cargando){
            let entrenamientoUsuario = entrenamientos_app.find(elemento => elemento.id === idEntrenamientoInput);
            let asignado = entrenamientos_user.includes(idEntrenamientoInput);
            dispatch(newActivity({id:idEntrenamientoInput,name:"entrenamiento"}))
            if(!asignado){
                setLoading(false)
                if(idEntrenamientoInput === nomNeurobics){
                    return navigate(`/my/entrenamiento/${idEntrenamientoInput}/promocion/${idEntrenamientoInput}`)
                }else{
                    setSelectEntrenamiento(idEntrenamientoInput)
                }
            }else{
                let entrenamineto:Entrenamiento = entrenamientoUsuario
                dispatch(ChangeRedux_current_entrenamiento({entrenamiento:entrenamineto}))
                let registroActual = registros_user.find(elemento => elemento.idEntrenamiento === idEntrenamientoInput)
                dispatch(ChangeRedux_current_registro_usuario({registro:registroActual}));
                dispatch(ChangeRedux_actividades_entrenamiento({actividades_entrenamiento:null}))
                dispatch(ChangeRedux_current_actividades({current_actividades: null}))
                dispatch(ChangeRedux_current_indice_actividad(null))
                dispatch(ChangeRedux_current_sesion({sesion: null}))

                if(entrenamineto?.tipo === "actividades"){
                    // const actividades = await consultarActividadesSinSesion(idEntrenamientoInput)
                    // dispatch(changeSesion({sesion:null,actividades:actividades,cantidadSesiones:null}))
                    // dispatch(newActivity({id:"entrenamiento",name:"entrenamiento"}));
                    // dispatch(changeCondicion({condicion:0}));
                    // const idActividad = registro?.actividadActual ? registro.actividadActual : "new";
                    // const ruta = `/my/entrenamiento/${idEntrenamientoInput}/${null} ${idActividad}`;
                    // //console.log("🚀 ~ file: EntrenarPage.tsx:120 ~ handleRedirect ~ ruta:", ruta);
                    // return navigate(ruta);
                }else{
                    //Verificar el primer ingreso
                    console.log("redirigir Entrenamiento")
                    return navigate(`/my/entrenamiento/${idEntrenamientoInput}`)
                }
            }

        }
    }

    const HandleRedirectRutaEstatica = (idEntrenamiento:string) => {
        switch (idEntrenamiento) {
            case (nomSuperIngles):
                navigate('consultaIngles')
                break
            default:
                console.error('Entrenamiento not found.')
                break
        }
    }

  return (
    <div id='page'>
        <div id='toolbar_default'>
            <h1 className="ion-text-center title">Entrenamientos</h1>
        </div>
        <div id='content_default' style={{padding:'7.6% 3.4%'}}>
            <div className={estiloEntrenamiento.entrenamientoGrid}>
                
                <div style={{ width: '100%' }} className={`${estiloEntrenamiento.cardEntrenamiento} ${BuscarEntrenamientoSiExiste('neurobics')}`}
                    onClick={() => {HandleRedirectEntrenamiento(nomNeurobics)}}
                    >
                    {cargando?
                        <Spinner></Spinner>:
                        <label className={estiloEntrenamiento.label_entrenamiento}>Neurobics</label>
                    }
                </div>
                <div style={{ width: '100%' }} className={`${estiloEntrenamiento.cardEntrenamiento} ${BuscarEntrenamientoSiExiste('superNutricion')}`}
                    onClick={() => {setSelectEntrenamiento(nomSuperNutricion)}}
                    >
                    {cargando?
                        <Spinner></Spinner>:<label className={estiloEntrenamiento.label_entrenamiento}>super nutrición</label>
                    }
                </div>
                <div style={{ width: '100%' }} className={`${estiloEntrenamiento.cardEntrenamiento} ${ BuscarEntrenamientoSiExiste('superIngles')}`}
                    onClick={() => {BuscarEntrenamientoSiExiste(nomSuperIngles) === '' ? HandleRedirectRutaEstatica(nomSuperIngles):setSelectEntrenamiento(nomSuperIngles)}}
                    >
                    {cargando?
                        <Spinner></Spinner>:<label className={estiloEntrenamiento.label_entrenamiento}>super inglés</label>
                    }
                </div>
                <div style={{ width: '100%' }} className={`${estiloEntrenamiento.cardEntrenamiento} ${ BuscarEntrenamientoSiExiste('memoria')}`}
                    onClick={() => {HandleRedirectEntrenamiento(nomMemoria)}}
                    >
                        {cargando?
                            <Spinner></Spinner>:<label className={estiloEntrenamiento.label_entrenamiento}>entrena tu super cerebro</label>
                        }
                </div>
                <div style={{ width: '100%' }} className={`${estiloEntrenamiento.cardEntrenamiento} ${estiloEntrenamiento.entrenamientoDiv_block}`}
                    onClick={() => {setSelectEntrenamiento(nomSuperCuboRubik)}}
                    >
                        {cargando?
                            <Spinner></Spinner>:<label className={estiloEntrenamiento.label_entrenamiento}>super cubo rubik</label>
                        }
                </div>
            </div>
        </div>   
        {openpopover && <div id="modal_cortina_default" onClick={() => setSelectEntrenamiento(null)}>
            <div className={estiloEntrenamiento.modal}>
                <div onClick={() => setOpenpopover(false)}>
                    <label className={`title-mensaje 
                    ${selectEntrenamiento === nomSuperCuboRubik ? estiloEntrenamiento.cubo: 
                    selectEntrenamiento === nomMemoria ? estiloEntrenamiento.cerebro : 
                    selectEntrenamiento === nomSuperNutricion ? estiloSelector.palabras : '' }`}>PRÓXIMAMENTE</label>  
                    <img src={`/assets/images/cardEntrenamiento/${
                        selectEntrenamiento === nomSuperCuboRubik ? 'cubo.png': 
                        selectEntrenamiento === nomMemoria ? 'cerebro.png' : 
                        selectEntrenamiento === nomSuperNutricion ? 'nutricion.png' : 'ingles.png'
                    }`}></img>          
                    <p className='mensaje '>
                    <strong>Curso</strong> <br></br> {selectEntrenamiento}
                    </p>
                </div>
            </div>
        </div>}
    </div>
  )
}

export default EntrenamientoPage
