import { DocumentSnapshot, DocumentData } from "firebase/firestore";

export interface Entidad {
    id? : string;   
    nombre : string;
    descripcion : string;
}

export const aEntidad = (nombre : string, descripcion : string) : Entidad => {
    return {
        nombre : nombre,
        descripcion : descripcion,
    } as Entidad;
    
}


type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toEntidad = (doc : FireBaseDocument) : Entidad => {
    const data = doc.data();
    return {
        id : doc.id,
        nombre : data.nombre,
        descripcion : data.descripcion,
    } as Entidad;
}