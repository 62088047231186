import React, { useState } from 'react';
import estiloJuegos from "../../../theme/Juegos.module.css";
import { Link } from 'react-router-dom';

const NeuralTrainer = () => {
    const [etapa, setEtapa] = useState<number>(1);
    const getDescripcionJuego = () => {        
        return (
            <div className={estiloJuegos.descripcionJuego}>
              Calcula las fechas
            </div>
        );
    }
    const handlePlay =() => {

    }

  return (
    <div id='page'>
      <div className={etapa === 1 ? estiloJuegos.toolbarNeuralTrainer : estiloJuegos.toolbar_default}>
            
            <Link  className={estiloJuegos.backJuegos}  to=".." relative="path">
            </Link>
            <h1 className={` title ${estiloJuegos.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'2.37svh'} :{}}>NEURAL TRAINER</h1>
            
      </div>
        {etapa === 1 && 
          <div className={estiloJuegos.contenedorIconoNeural}>
            <img src='/assets/componentes/Juegos/NEURAL TRAINER.svg' className={estiloJuegos.icono}></img>
          </div>
        }
        <div id='content_default'>
          {etapa === 1 &&
                
            <div className={estiloJuegos.primeraPantalla}>
                {getDescripcionJuego()} 
                <button className={estiloJuegos.buttonEmpezar} onClick={handlePlay}>
                    EMPEZAR
                </button>
                <Link className={estiloJuegos.BordesButtonRanking} to="/my/juegos/ranking">
                    <button  className={estiloJuegos.rankingJuegos} >RANKING</button>
                </Link>                   

            </div>
                
          }
        </div>
    </div >
  );
};

export default NeuralTrainer;