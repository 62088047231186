/** Externa */
import {
    collection,
    getDocs,
    getDoc,
    setDoc,
    doc,
    addDoc,
    deleteDoc,
    QuerySnapshot,
    CollectionReference,
    QueryDocumentSnapshot,
    DocumentReference,
    DocumentSnapshot,
    DocumentData,
    query,
    orderBy,
    where,
    updateDoc,
    OrderByDirection,
    or,
} from 'firebase/firestore';

/** Local */
import { firestore } from '../../firebase';
import { nomTest, nomTestProgramaUsuarios, nomTestProgramas } from '../../datos/nombresBD';
import { Calificacion, toCalificacion } from '../../modelos/Calificacion';

/**
 * Hook para manejar acceso al base de datos
 *
 * @author [Aiden Cullo](https://github.com/cullo7)
 */
export const useDBRanking = () => {

    type FilterValue = string | boolean | number;

    /**
     * Obtiene todos los documentos de una coleccion de manera ordenada por un campo
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @param {string} nomCampo - el nombre del campo por el cual ordenar
     * @param {boolean} ascendente - si es true, ordena de manera ascendente, si es false, ordena de manera descendente
     * @returns {} - Un array con todos los documentos de la coleccion
     */   
    const consultarOrdenadosDosCriterios = async (ruta:CollectionReference, nomCampo1: string, campo1Asc: boolean, nomCampo2: string, campo2Asc: boolean): Promise<any[]> => {
        
        const calificaciones : Calificacion[] = [];
        let orden1: OrderByDirection = 'desc';
        let orden2: OrderByDirection = 'desc';
        if(campo1Asc){
            orden1 = 'asc';
        }
        if(campo2Asc){
            orden2 = 'asc';
        }
        
        const querySnapshot: QuerySnapshot = await getDocs(
            query(
                ruta,
                orderBy(nomCampo1, orden1),
                orderBy(nomCampo2, orden2)
            )
        );
        const documentosOrdenados = querySnapshot.docs;

        for (const doc of  documentosOrdenados) {             
            calificaciones.push(toCalificacion(doc));  
        }
        return calificaciones;
    }


    /**
     * Obtiene todos los documentos de una coleccion filtrada por un campo
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @param {string} nomCampo - el nombre del campo por el cual ordenar
     * @returns {} - Un array con todos los documentos de la coleccion
     */   
    const recogerDocsFiltrados = async (ruta: string, nomCampo: string, valorDeFiltro: FilterValue): Promise<DocumentSnapshot[]> => {
        const arrDoc : DocumentSnapshot[] = [];
        const cRef: CollectionReference<DocumentData> = collection(firestore, ruta);        
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(query(cRef, where(nomCampo, "==", valorDeFiltro)));
       
        for (const docRef of querySnapshot.docs) {            
            const docSnapshot = await getDoc(docRef.ref);            
            arrDoc.push(docSnapshot);
        }
        return arrDoc;
    }
       
    return {
        consultarOrdenadosDosCriterios
    }
}
