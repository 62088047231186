import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { Auth, getAuth } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { getStorage } from 'firebase/storage';
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { connectFunctionsEmulator } from "firebase/functions";

const firebaseConfigProduccion = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseConfigDesarrollo = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_DEV,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_DEV
};
let firebaseConfig;
let urlStorage;
if (process.env.REACT_APP_ENVIRONMENT === 'development') {    
    firebaseConfig = firebaseConfigDesarrollo;       
    urlStorage = process.env.REACT_APP_FIREBASE_STORAGE_URL_DEV;
} else if (process.env.REACT_APP_ENVIRONMENT === 'production') {  
    firebaseConfig = firebaseConfigProduccion;
    urlStorage = process.env.REACT_APP_FIREBASE_STORAGE_URL;
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
//export const auth = getAuth(app);
let auth : Auth;
if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
        persistence: indexedDBLocalPersistence,
    });
} else {
    auth = getAuth(app);
}
export { auth };

// Initialize Firebase firestore
export const firestore = getFirestore(app);

// Initialize Firebase Storage
export const storage = getStorage(app);

// Initialize Firebase Functions
export const functions = getFunctions(app);

export const storageUrl = urlStorage;

if (window.location.hostname === "localhost") {
    connectFunctionsEmulator(functions, "localhost", 5001);
}


