
import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";
import { Sesion } from "./Sesion";
import { ProductoReferencia } from "../Facturas";

export interface SesionMapa {
    id: string;
    titulo:string;
    condicion?:string|boolean;
}

/**
 * Interfaz para los datos de jugadores
 */
export interface Entrenamiento {
    id?: string;
    titulo: string;
    subtitulo: string;
    descripcion: string;
    descripcion2?:string;
    ruta: string;
    rutaTestimonio?: string;
    tipo:string;
    fechaCreacion: Timestamp;
    fechaInicio: Timestamp;
    fechaFin?: Timestamp;    
    moneda?:string;
    sesiones?: SesionMapa[];
    cantidadSesiones?: number;
    iva?:number;
    estado?: string;
    precio?: number;
    tipoPago?:string | null;
    idFactura?: string | null;
    idCupon?: string | null;
    pruebaGratuita?: number;
    faq?: string[];
    sobreNosotros?: string;
    posterVideo?: string;    
    asignacion?: ProductoReferencia;
}

export const aEntrenamiento = (titulo:string,subtitulo:string, descripcion:string, ruta:string,tipo:string) : Entrenamiento => {
    
    const entrenamiento: Entrenamiento = {
        fechaCreacion : Timestamp.now()
    } as Entrenamiento;

    if (titulo !== null && titulo !== undefined) {
        entrenamiento.titulo = titulo;
    }
    if (subtitulo !== null && subtitulo !== undefined) {
        entrenamiento.subtitulo = subtitulo;
    }
    if (tipo !== null && tipo !== undefined) {
        entrenamiento.tipo = tipo;
    }    
    if (descripcion !== null && descripcion !== undefined) {
        entrenamiento.descripcion = descripcion;
    }    
    if (ruta !== null && ruta !== undefined) {
        entrenamiento.ruta = ruta;
    }      
    return entrenamiento;       
}

/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toEntrenamiento = (doc : FireBaseDocument) : Entrenamiento => {    
    if(doc.exists()){        
        const calify = doc.data() as Entrenamiento;
        calify.id = doc.id;
        return calify
    }
    return undefined;
    
}

export const toEntrenamientoExists = (doc : FireBaseDocument) : Entrenamiento => {   
    const calify = doc.data() as Entrenamiento;
    calify.id = doc.id;
    return calify
    
}

