

import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";


export interface RegistroActividad {
    id : string; 
    fechaRealizado : Timestamp;
    like : number;
}

export const registroActividadVacio = {
    id: '',
    fechaRealizado:Timestamp.now(),
    like: 5
}as RegistroActividad

export const aRegistroActividad = (id : string,fechaRealizado : Timestamp,like : number) : RegistroActividad => {
    id = id == null || id == undefined ? null : id;
    like = like == null || like == undefined ? null : like;
    fechaRealizado = fechaRealizado == null || fechaRealizado == undefined ? null : fechaRealizado;
    return {
        id: id,
        fechaRealizado: fechaRealizado,
        like: like,
    } as unknown as RegistroActividad;        
}

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toRegistroActividad = (doc : FireBaseDocument) : RegistroActividad => {
    const data = doc.data() as RegistroActividad;
    return {   
        id : data.id,
        fechaRealizado : data.fechaRealizado,
        like : data.like
    } as RegistroActividad;
}