/** Externas */
import {
    DocumentSnapshot,
} from 'firebase/firestore';
/** Internas */

// Configuracion
import { auth } from '../../firebase';
// Modelos
import { Usuario, USUARIO_VACIO, toUsuario, UsuarioDato, toUsuarioCorreo } from '../../modelos/usuario';

// Hooks
import { useBaseDeDatos } from '../baseDeDatos/useBaseDeDatos';
import { useToast } from '../useToast';
import { useStorage } from '../useStorage';
import { nomProgMemNombres, nomProgMemPalabras, nomProgMemNumeros, nomProgSumas1, nomUsuarios, nomUsuarioTestProgramas, nomUsuarioTestProgramaUsos, nomTest, nomTestProgramas, nomTestProgramaUsuarios, nomUsuarioTests } from '../../datos/nombresBD';
import { test_general } from '../../datos/DatosTests';
// import { logout } from './reducer/reducerAuth';
// import { useAppDispatch } from '../store';

/**
 * Control para manejar el base de datos y el estado internal
 * del la aplicacion. Todo el control pasa por aqui.
 *
 * @author [Aiden Cullo](https://github.com/cullo7)
 */
export const useControl = () => {

    /**
     * Funciones para acceder el base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,
        borrarDoc,
        reemplazarDoc,
        recogerDoc,
        recogerDocsFiltrados,
        borrarCollection,
    } = useBaseDeDatos();

    /**
     * Funciones presentar mensajes de toast
     * @type {Objecto}
     * @const
     */
    const {
        presentBorrarUsuario,
        presentSignOut
    } = useToast();

    const {
        dispatch,
        state,
    } = useStorage();

    /** Cuenta */
    //---------------------------------------------------------------------//

    /**
     * Borrar cuenta y datos del usuario
     */
    const borrarCuenta = async (): Promise<void> => {
        let user = auth.currentUser;
        await borrarDatos(user.uid);
        if (user) {
            user.delete().then(() => {
                presentBorrarUsuario();
            }).catch((error) => {
                if (error.code === 'auth/requires-recent-login') {
                    auth.signOut();
                    presentSignOut();
                } else {
                    console.error("error no identificado, error: ", error);
                }
            });
        } else {
            console.log("no encontramos el usuario");
        }
        dispatch({
            type: "borrar",
        })

    }

    const logOutCuenta = async (): Promise<void> => {
        await auth.signOut();
        dispatch({ type: "sign-out" });
    }


    /** Datos */
    //---------------------------------------------------------------------//


    /**
     * Initializar datos del ususario
     * @param {string} userId - Id del usuario para firestore 
     */
    const initializarDatos = async (userId: string): Promise<void> => {
        await agregarDoc(nomUsuarios + "/" + userId, USUARIO_VACIO)        
        dispatch({
            type: "crear-datos"
        })
    }

    /**
     * Initializar datos del ususario
     * @param {string} userId - Id del usuario para firestore 
     */
    const initializarDatosMasivo = async (userId: string): Promise<void> => {
        await agregarDoc(nomUsuarios + "/" + userId, USUARIO_VACIO) 
    }

    /**
     * Sube datos personales
     * @param {string} userId - Id del usuario para firestore 
     * @param {Usuario} usuario - Datos personales del usuario
     */
    const guardarDatos = async (userId: string, usuario: Usuario): Promise<void> => {
        reemplazarDoc(nomUsuarios + "/" + userId, usuario)        
        dispatch({
            type: "set-datos",
            dato: usuario,
        })
    }

    /**
     * Borrar datos del usuario
     * @param {string} userId - Id del usuario para firestore 
     */
    const borrarDatos = async (userId: string): Promise<void> => {
        await borrarJuegos(userId);
        await borrarCollection(nomUsuarios + "/" + userId + "/" + nomUsuarioTests);
        // Borrar documento del usuario
        await borrarDoc(nomUsuarios + "/" + userId)
        // Borrar del ranking general de los juegos
        await borrarRankingJuegos(userId);
    }

    /**
     * Recoge datos del ususario
     * @param {string} userId - Id del usuario para firestore 
     * @returns {Promise<DocumentSnapshot>} - Promise que resuelve a un DocumentSnapshot
     */
    const recogerDatos = async (userId: string): Promise<void> => {
        const respuesta = await recogerDoc(nomUsuarios + "/" + userId)
        const datosUsuario = toUsuario(respuesta)
        //guardarDatos(userId, datosUsuario)
        dispatch({
            type: "set-datos",
            dato: datosUsuario,
        })        
    }

    /**
     * Recoge datos del ususario
     * @param {string} userId - Id del usuario para firestore 
     * @returns {Promise<Usuario>}
     */
    const recogerDatosConstante = async (userId: string): Promise<Usuario> => {
        const datosUsuario = toUsuario(await recogerDoc(nomUsuarios + "/" + userId))
        //guardarDatos(userId, datosUsuario)
        return datosUsuario
    }

    const consultarUsuario = async (correo: string): Promise<UsuarioDato> => {        
        
        var arrDoc : DocumentSnapshot[] = [];
        
        arrDoc = await recogerDocsFiltrados(nomUsuarios, "usuarioDato.correo", correo);
                
        for (const doc of  arrDoc) {             
            const datos = toUsuarioCorreo(doc);
            datos.id = doc.id
            return (datos);  
        }
        return null;
    }

    /** Juegos */
    //---------------------------------------------------------------------//

    

    /**
     * Borrar datos de los juegos
     * @param {string} userId - Id del usuario para firestore 
     */
    const borrarJuegos = async (userId: string): Promise<void> => {
        await borrarJuego(nomProgSumas1, userId)
        await borrarJuego(nomProgMemNumeros, userId)
        await borrarJuego(nomProgMemPalabras, userId)
        await borrarJuego(nomProgMemNombres, userId)
    }

    /**
     * Borrar datos del los juegos
     * @param {string} userId - Id del usuario para firestore 
     */
    const borrarRankingJuegos = async (userId: string): Promise<void> => {
        await borrarDoc(nomTest + "/" + test_general + "/" + nomTestProgramas + "/" + nomProgSumas1 + "/" + nomTestProgramaUsuarios +"/" + userId)
        await borrarDoc(nomTest + "/" + test_general + "/" + nomTestProgramas + "/" + nomProgMemNombres + "/" + nomTestProgramaUsuarios +"/" + userId)
        await borrarDoc(nomTest + "/" + test_general + "/" + nomTestProgramas + "/" + nomProgMemNumeros + "/" + nomTestProgramaUsuarios +"/" + userId)
        await borrarDoc(nomTest + "/" + test_general + "/" + nomTestProgramas + "/" + nomProgMemPalabras + "/" + nomTestProgramaUsuarios +"/" + userId)
    }

    /**
     * Borrar datos de un juego
     * @param {string} userId - Id del usuario para firestore 
     * @param {string} nombre - Nombre del juego
     */
    const borrarJuego = async (nombre: string, userId: string): Promise<void> => {
        await borrarCollection(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + test_general + "/" + nomUsuarioTestProgramas +"/" + nombre + "/" + nomUsuarioTestProgramaUsos)
        await borrarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + test_general + "/" + nomUsuarioTestProgramas +"/" + nombre)
    }

    
    return {
        borrarCuenta,
        logOutCuenta,
        initializarDatos,
        initializarDatosMasivo,                
        guardarDatos,
        recogerDatosConstante,
        recogerDatos,      
        consultarUsuario,  
        state,
    }
}
