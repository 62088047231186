import style from "../../../../theme/memoriaPalabras.module.css"

interface Props{
    datoPrincipal: string;
    digitos: number;
    tituloPalabras: string;
}

const NavegacionPalabra:React.FC<Props> = (props) => {
  return (
    <div className={style.palabra} style={props.tituloPalabras == "Numeros" ? props.digitos < 3 ? {fontSize:'20vh',margin:'10svh 0'} : props.digitos < 5 ? {fontSize:'11vh'} : {fontSize:'7.5vh'} : {fontSize:'4.7vh'}}> {props.datoPrincipal}</div>
  )
}

export default NavegacionPalabra;