// import {
//   IonHeader,
//   IonPage,
//   IonTitle,
//   IonToolbar,
//   IonButton,
// } from "@ionic/react";

import React, { useState } from 'react';
// import { IonContent } from '@ionic/react';
import { useAlmacenamiento } from "../hooks/baseDeDatos/useAlmacenamiento";

const SubirArchivo: React.FC = () => {

  const {
    guardarArchivo,
    obtenerURLArchivo,
  } = useAlmacenamiento();


  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      guardarArchivo(selectedFile, "imagenes/" + selectedFile.name)

    }
  };


  return (
    <></>
    // <IonPage>
    //   <IonHeader>
    //     <IonToolbar>
    //       <h1 className="ion-text-center title">File Uploader</h1>
    //     </IonToolbar>
    //   </IonHeader>
    //   <IonContent>
    //     <input type="file" onChange={handleFileChange} />
    //     <IonButton onClick={handleUpload}>Subir Archivo</IonButton>
    //   </IonContent>
    // </IonPage>

  );
};

export default SubirArchivo;
