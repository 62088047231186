import { DocumentData, DocumentSnapshot } from "firebase/firestore";

export interface UsuarioCliente{
    nombre: string;
    correo: string;
    direccion: string;
    telefono: string;
}

export interface Producto{
    id: string;
    nombre: string;
    valor: number;
    descripcion: string;
    tipo: string;
    imagen: string;
    descuento: string;
    detalles?: any;
    videoURL?: string;
    precio?: number;
}

export const ProductoPrueba:Producto ={

} as Producto

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toProducto = (doc : FireBaseDocument) : Producto => {    
    if(doc.exists()){        
        const product = doc.data() as Producto;
        product.id = doc.id;
        return product
    }
    return undefined;
}
