import React, { useMemo, useState } from 'react'
import Loading from '../componentes/Loading'
import Selector from '../componentes/Selector'
import { paises } from '../../../datos/paises'
import styleHomePage from '../../../theme/web/HomePaginaWeb.module.css'
import styleForm from '../../../theme/web/Servicios.module.css'
import style from '../../../theme/Formulario.module.css'
import { useBaseDeDatos } from '../../../hooks/baseDeDatos/useBaseDeDatos'
import { useToast } from '../../../hooks/useToast'
import { useAppDispatch } from '../../../store'
import { useNavigate } from 'react-router-dom'
import compraEfectivo from '../../../utils/compraEfectivo'
import Modal from '../../componentes/Modal'
import { aspectRatio } from '../../../utils/boundarys'
import { ProductoReferencia } from '../../../modelos/Facturas'

const AsistenciaPage = () => {
  const [loading, setLoading] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [pais, setPais] = useState('+57');
  const [nombre, setNombre] = useState('');
  const [cedula, setCedula] = useState('');
  const [correo, setCorreo] = useState('');
  const [telefono, setTelefono] = useState('');

  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const payUrl = useMemo(()=> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development')  
      return process.env.REACT_APP_EPAYCO_URL_CONFIRMATION_DEV;       
    else if (process.env.REACT_APP_ENVIRONMENT === 'production')
      return process.env.REACT_APP_EPAYCO_URL_CONFIRMATION;

  }, [process.env.REACT_APP_ENVIRONMENT]);

  const {
    presentMensajePersonalizado
  } = useToast();

  const {
    agregarDoc
  } = useBaseDeDatos();

  function ValidarInformacionUsuario() {
  
    // Validar nombre: no vacío
    if (!nombre || nombre.trim() === '') {
      return presentMensajePersonalizado('El nombre es obligatorio.');
    }
  
    const telefonoRegex = /^\d{10}$/; // 10 dígitos
    if (!telefono || !telefonoRegex.test(telefono)) {
      return presentMensajePersonalizado('El teléfono debe ser de 10 dígitos y solo contener números.');
    }
  
    // Validar cédula: no vacío y formato adecuado (ejemplo: números entre 6-10 dígitos)
    const cedulaRegex = /^\d{6,10}$/;
    if (!cedula || !cedulaRegex.test(cedula)) {
      return presentMensajePersonalizado('La cédula debe contener entre 6 y 10 dígitos.');
    }
  
    // Validar correo: formato de correo electrónico válido
    const correoRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!correo || !correoRegex.test(correo)) {
      return presentMensajePersonalizado('El correo electrónico no es válido.');
    }

    // Validar correo: formato de correo electrónico válido
    if (terminos === false) {
      return presentMensajePersonalizado('Debes aceptar tratamiento de datos');
    }
    
    return EnviarInformacion()
  }

  const EnviarInformacion = async () => {
    presentMensajePersonalizado('Enviando...');
    let informacionUsuario = {
      nombre: nombre,
      telefono: pais + telefono,
      cedula:cedula,
      correo:correo,
    }
    setLoading(true)
    const resp = await agregarDoc('infoConferenciaCompensar23_05'+ '/' + cedula,informacionUsuario)
    console.log("🚀 ~ EnviarInformacion ~ resp:", resp)
    presentMensajePersonalizado('Información Actualizada')
    await handleEfectivo(informacionUsuario)
    setLoading(false)
  }

  
  async function handleEfectivo(infoUser:any):Promise<void> {
    try {
      await compraEfectivo(payUrl, 
        {
          id_productos:[{id_referencia:'neurobics',tipo:'entrenamiento'}as ProductoReferencia],
          nombre: nombre,
          telefono: telefono,
          email: infoUser.correo,
          codigoTelefono: pais,
          userId: null,
          campaña: 'Compensar60+Vital',
          valorPagado: 0,
          tipoModena: "USD",
          urlCompra: window.location.pathname
        }
      )
      setOpenDialog(true);
    }catch(error){
      presentMensajePersonalizado("Ha ocurrido un error");
      console.log("🚀 ~ file: PagoPage.tsx:105 ~ handleEfectivo ~ error:", error)
    }
  }

  
	function verPoliticas(){
    navigate('/COMPENSAR60+VITAL/PoliticasPrivacidad');
  }

  return (
    <div id='page' style={{height:'100%',overflow:'hidden',background:'var(--color-degradado-vertical-2)',margin:'auto'}}>
      <Loading isOpen={loading}/>
      <Modal open={openDialog} fillPercentage={(aspectRatio() <= 3/5)?100:80} className={style.cartelRegistro}>
        
        <h1 style={{color: 'var(--color-purple-dark)', textAlign: 'center'}}>Gracias por registrarte!!</h1>
        <hr className={styleHomePage.separator} style={{backgroundColor: 'var(--color-purple-dark)'}}/>

        <p style={{marginBottom:"0.5rem"}}>Te registraste con los siguentes datos:</p>
        <div className={style.cartelDatos}>
          <p><strong>NOMBRE COMPLETO:</strong></p> <p className={style.cartelDatosUsuario}>{nombre}</p>
          <p><strong>CÉDULA:</strong></p> <p className={style.cartelDatosUsuario}>{cedula}</p>
          <p><strong>PAÍS:</strong></p> <p className={style.cartelDatosUsuario}>{paises.find(v => v.value === pais).name}</p>
          <p><strong>TELÉFONO:</strong></p> <p className={style.cartelDatosUsuario}>{telefono}</p>
          <p><strong>CORREO ELECTRÓNICO:</strong></p> <p className={style.cartelDatosUsuario}>{correo}</p>
        </div>

        <p style={{textAlign: "justify"}}>
          <strong style={{color: "var(--color-Pink-Light)"}}>
            SE HA ENVIADO UN CORREO DE CONFIRMACIÓN CON SU NUEVO PERFIL Y CONTRASEÑA, ASI COMO UN MENSAJE DE WHATSAPP CON ESTA MISMA INFORMACIÓN 
          </strong>
        </p>

        <div className={style.botonesCartel}>
          <button id='button_rosado_default' style={{margin:"1rem", width: "min-content", whiteSpace: "nowrap", borderRadius: "0.5rem"}}
          onClick={() => window.location.href = '/'}>
            IR A LA APLICACIÓN
          </button>
          <button id='button_rosado_default' style={{margin:"1rem", width: "min-content", whiteSpace: "nowrap", borderRadius: "0.5rem"}}
          onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=13867033109'}>
            IR A WHATSAPP
          </button>
        </div>
      </Modal>
      <h1 style={{color:'white',margin:'auto'}}>Fomulario 60+ Vital</h1>
      <div className={styleForm.formCorreo} style={{margin:'auto'}}>
        <div className={styleForm.boxInputs}>
            <h1>¿TE INTERESA? ¡CONTÁCTANOS YA!</h1>
            <div className={styleForm.styleInput} style={{width:'100%'}}>
                <label className={styleForm.itemBoxInput}>NOMBRE COMPLETO*</label>
                <input className={styleForm.itemBoxInput} type='text' value={nombre} onChange={(e) => setNombre(e.target.value)}></input>
            </div>
            <div className={styleForm.styleInput} style={{width:'100%'}}>
                <label className={styleForm.itemBoxInput}>CÉDULA*</label>
                <input className={styleForm.itemBoxInput} type='number' value={cedula} onChange={(e) => setCedula(e.target.value)}></input>
            </div>
            <div className={styleForm.styleInput} style={{flexGrow: 1}}>   
                <label className={styleForm.itemBoxInput}>PAÍS*</label>
                <Selector setSelection={setPais} selection={pais} opciones={paises} style={{color:'var(--color-Dark-Grey)',fontSize:'2svh',height:'5svh'}}/>
            </div>
            <div className={styleForm.styleInput} style={{flexGrow: 3}}>
                <label className={styleForm.itemBoxInput}>NÚMERO DE TELÉFONO*</label>
                <input className={styleForm.itemBoxInput} type='telefono' value={telefono} onChange={(e) => setTelefono(e.target.value)}></input>
            </div>
            <div className={styleForm.styleInput} style={{width:'100%'}}>
                <label className={styleForm.itemBoxInput}>CORREO ELECTRÓNICO*</label>
                <input className={styleForm.itemBoxInput} type='text' value={correo} onChange={(e) => setCorreo(e.target.value)}></input>
            </div>
            <p style={{width:'100%',textAlign:'end',fontSize:'70%',color:'white',margin:'0'}}>*Campos obligatorios</p>
            
            <div className='item-terminos' style={{zIndex:'1'}}>
              <div className='checkmarkPersonalizada' style={{width: '4vh'}} onClick={() => setTerminos((state) => !state)}>
                {terminos && <img src='/assets/icon/chuloMorado.svg' style={{height:'150%',width:'150%',position:'relative',right:'-50%',maxWidth:'none'}}></img>}
              </div>
              <label className='tratamiento-datos' style={{color:'white'}}>
                *Acepto los términos, condiciones y el tratamiento de mis datos personales conforme a la <wbr/>
                <a className="ver-politicas" style={{color:'white'}} onClick={verPoliticas} >política de privacidad</a>
              </label>
            </div>
            <button className={styleForm.buttonCorreo} onClick={ValidarInformacionUsuario}>ENVIAR INFORMACIÓN</button>
        </div>
      </div>
    </div>
  )
}

export default AsistenciaPage