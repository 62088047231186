import { Outlet, useNavigate, useParams } from "react-router-dom";
import { RegistroEntrenamiento } from "../../modelos/entrenamiento/RegistroEntrenamiento";
import { Entrenamiento } from "../../modelos/entrenamiento/Entrenamiento";
import { useAppDispatch, useAppSelector } from "../../store";
import Loading from "./componentes/Loading";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ChangeRedux_cargando } from "../../hooks/reducer/reducerEntrenamiento";

type Param = {
    idEntrenamiento: string
}

interface Props{
    loading: boolean;
}
const LayoutEntrenamiento = () => {
    const {idEntrenamiento} = useParams<Param>();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const loading_redux = useAppSelector((state) => state.entrenamiento.cargando);
    const asignados = useAppSelector((state) => state.entrenamiento.entrenamientos_user);
    const entrenamientos = useAppSelector(state => state.entrenamiento.entrenamientos_app);
    const registros = useAppSelector(state => state.entrenamiento.registros_usuario);

    useEffect(() => {
        if(!loading_redux){
            if(!(asignados?.includes(idEntrenamiento))){
                navigate(`promocion/${idEntrenamiento}`);
            }else if(!(registros?.find(registro => registro.idEntrenamiento === idEntrenamiento))){
                navigate(`firstTime/${idEntrenamiento}`);
            }
        }
    }, [loading_redux])

    return (
        <>
        <Loading isOpen={loading_redux}/>
        {!loading_redux && <Outlet/>}
        </>
    )
}

export default LayoutEntrenamiento;