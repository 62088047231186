import React, { useEffect } from 'react'

interface Props{
    position:number;
}

export const MedallaRanking = ({position} : Props) => {
    
    const GetColor = () => {
        switch (position) {
            case 1:
                return 'var(--color-yellow)';
    
            case 2:
                return '#C2B8BD';
    
            case 3:
                return '#E5915A';
    
            default:
                return  '#FDB72E';
          }
    }
    
  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',margin:'-1svh'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="4svh" height="4svh" viewBox="0 0 42 41" fill="none">
            <path d="M20.853 40.6446C32.236 40.6446 41.4639 31.7589 41.4639 20.7974C41.4639 9.83609 32.236 0.950195 20.853 0.950195C9.46998 0.950195 0.242188 9.83609 0.242188 20.7974C0.242188 31.7589 9.46998 40.6446 20.853 40.6446Z" fill={GetColor()}/>
            <text x="50%" y="50%" fill="white" font-size="2svh" text-anchor="middle" alignment-baseline="middle">#{position}</text>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="2.5svh" height="2.5svh" viewBox="0 0 28 21" fill="none">
            <path d="M25.4264 0.504029C26.4341 -0.0150517 27.5944 0.748299 27.5944 1.87806V16.6871C27.5944 19.4352 25.6707 20.7787 23.289 19.649L15.1058 15.7711C14.4035 15.4658 13.3042 15.4658 12.6019 15.7711L4.41866 19.649C2.03696 20.7482 0.113281 19.4047 0.113281 16.6566L0.17435 1.87806C0.17435 0.748299 1.3652 0.0154825 2.3423 0.504029C5.79271 2.24448 9.70114 3.22157 13.8538 3.22157C18.0065 3.22157 21.9455 2.24448 25.4264 0.504029Z" fill={GetColor()}/>
        </svg>
    </div>
  )
}
