import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import '../../../theme/actividades.css'
import { Actividad } from '../../../modelos/entrenamiento/Actividad';
import styleVistaVideo from "../../../theme/entrenamiento/componentes/VistaVideo.module.css";
import stylePrograma from "../../../theme/admin/programa.module.css";
import { ref } from 'firebase/storage';
import { storage } from '../../../firebase';


interface Props {
    handleBack:() => Promise<void>;
    setLoading:React.Dispatch<React.SetStateAction<boolean>>;
    recursoUrl: string;
    actividad:Actividad;
    GuardarRegistro: () => Promise<void>;
}

const VistaDeRecurso:React.FC<Props> = (props) => {
    const [nameRecurso, setNameRecurso] = useState()

    useLayoutEffect(() => {
      
    }, [])

    useEffect(() => {
        componentDidMount()
      return () => {
        componentWillUnmount()
      }
    }, [])    

    const downloadFile = async () => {
        props.setLoading(true)
        await fetch(props.recursoUrl)
        .then(async (response) => {
          // `url` is the download URL for 'images/stars.jpg'
          console.log("🚀 ~ .then ~ response:", response)
          const blob = await response.blob();
          console.log("🚀 ~ .then ~ blob:", blob)
    
          // Crea un enlace de descarga temporal para el archivo
          const downloadUrl = window.URL.createObjectURL(blob);
          console.log("🚀 ~ .then ~ downloadUrl:", downloadUrl)
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = props.actividad.tituloRecurso ?? props.actividad.titulo;
          a.click();
    
          // Limpia la URL de descarga temporal
          window.URL.revokeObjectURL(downloadUrl);
        })
        .catch((error) => {
          // Handle any errors
          console.log("🚀 ~ downloadFile ~ error:", error.code)
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
      
            // ...
      
            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
        });
        props.setLoading(false)
      };
    
    const componentWillUnmount = async() => {
        const largo = document.getElementById('el-body').clientHeight ;
        const ancho = document.getElementById('el-body').clientWidth ;
        if((largo*3/4) >= ancho){
            document.getElementById('el-body').style.background = '#ffffff' ;
        }else{
            document.getElementById('el-body').style.background = 'linear-gradient(180deg, #45245F 0%, #D74285 100%)' ;
        }
    }
    
    const componentDidMount = async() => {
        props.GuardarRegistro();
    }
    

    const FunctionSalir = async() => {
        console.log('Funcionb salir')
        return props.handleBack();
    }
    
    return (
        <>
          <div className={`${styleVistaVideo.toolbar_Vista_video}`} style={{backgroundSize:'100% 130%'}}>
              <div style={{display:'inherit',width:'100%',padding:'4em 2.5em'}}>
                  <button className={`${stylePrograma.backButton} ${styleVistaVideo.backButton}`} style={{height:'100%'}} onClick={FunctionSalir}/>
                  <h1 className="sesionTitulo" style={{width:'60%'}} >{props.actividad?.titulo ?? `Sesión {indexSesionActual+1}`}</h1>
              </div>
          </div>
          <div className={stylePrograma.content} style={{position:'relative',borderRadius:'25px 25px 0 0 ',padding:'0',overflow:'hidden',display:'flex',flexDirection:'column',alignItems:'center',gap:'3em',marginTop:'-2em'}}>
            <p className={styleVistaVideo.descripcion} style={{fontSize:'0.97em',fontWeight:'500',padding:'3em 11%'}}>{props.actividad?.descripcion}</p>
            <a onClick={downloadFile} style={{zIndex:'2'}}>
              <img src='/assets/icon/documentopdf.svg' style={{left: '50%',position: 'relative', transform:'translateX(-50%)'}}></img>
              <br></br>
              <text style={{color:'blue',textDecoration:'underline'}}>{props.actividad.tituloRecurso ?? props.actividad.titulo}.pdf</text>
            </a>
            <button className={styleVistaVideo.ButonFinalizar} style={{left:'auto',transform:'none'}} onClick={downloadFile}>DESCARGAR</button>
            <img className='imagenDificultad' src={`/assets/componentes/ActividadVideo/fondo_neuronas_gris.svg`}></img>
          </div>
        </>
    )
}

export default VistaDeRecurso
