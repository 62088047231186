/** Internas */
import { useDato } from '../../auth';
import  "../../theme/pages.css"; 
import { useAppDispatch, useAppSelector } from '../../store';
import { logout } from '../../hooks/reducer/reducerAuth';
import { useEffect, useState } from 'react';
import PhotoModal from './componentes/PhotoModal';
import { useStorgeImagenes } from '../../hooks/baseDeDatos/useStorageImagenes';
import { newTorneo } from '../../hooks/reducer/reducerActividad';
import { nombre_test_inicial, test_inicial } from '../../datos/DatosTests';
import { Link, useNavigate } from 'react-router-dom';
import estiloJuegos from "../../theme/Juegos.module.css";
import { CleanEntrenamiento } from '../../hooks/reducer/reducerEntrenamiento';

const DatosPersonalesPage: React.FC = () => {
    const neuronas = useAppSelector((state) => state.auth.neuronas);
    const userId = useAppSelector((state) => state.auth.userId);
    const email = useAppSelector((state) => state.auth.email);
    const navigate = useNavigate();
	const {
        state,
		logOutCuenta
	} = useDato();
    const [openModal, setOpenModal] = useState(false);
    const [photoPerfil, setPhotoPerfil] = useState("/assets/images/avatar.svg");
	const dispatch = useAppDispatch();
    
    const {
        obtenerURL
      } = useStorgeImagenes();
    const buscarPhoto = async() => {
        const respuesta = await obtenerURL("usuarios" + "/" + userId + "/" + "imagenes" + "/" + "fotoPerfil");
        if(respuesta) setPhotoPerfil(respuesta);
    }
    useEffect(() => {
        buscarPhoto();
        
    }, []);
    
	const cerrarSesion = async () => {
		dispatch(logout());
		dispatch(CleanEntrenamiento())
		logOutCuenta();
	}
    const inicializarTestInicial = () => {
        dispatch(newTorneo({ id: test_inicial, name: nombre_test_inicial, torneo:false}))
        navigate('/my/testInicialPage');

    }
      

	return (
		<div id='page'>
            <div id='toolbar_default' className='toolbarDatosPersonales'>
                <Link  className={estiloJuegos.backJuegos} style={{top:'25%',transform: 'translateY(-50%)'}} to=".." relative='path'></Link>
                <h1 className="title_default">Datos personales</h1>
            </div>
			<div id='content_default' className='datos-personales-page' style={{fontFamily:'Montserrat',zIndex:'1'}}>
	
                <div className="marco-foto-morado marco-foto">
                    <div className="contenedor-foto-datos contenedor-foto">
                        <img  src={photoPerfil} alt="Foto de usuario" className='foto-usuario' onClick={() => {console.log("fotoclick"); setOpenModal(true);}}></img>
                    </div>
                </div >
                <div className='contenedor-neuronas-datosPersonales'>
                    <div className='neuronas-datosPersonales'>
                        <span className='neuronas'>{neuronas}</span>
                        <div className='circulo-neuronas'>
                            <img src="assets/icon/icon_neuronas_tab.svg"  className='neurona-icon' />
                        </div>
                    </div>
                    
                </div>
                
                
                <div className='nombre-datosPersonales'>
                    {state?.nombres}
                </div>
                <div className='linea-datosPersonales'></div>
                <div className='email-datosPersonales'>
                    {email}
                </div>
                {/* <button id='button_bordes_default' style={{marginBottom:'0px'}} className='buttons-datosPersonales' onClick={()=>inicializarTestInicial()}>
                    RESULTADOS TEST
                </button> */}
                
                <button id='button_rosado_default' onClick={() =>navigate("/my/home/editarUsuario")} className={`editar-datosPersonales buttons-datosPersonales`}>
                    EDITAR
                </button>
                <button id='button_bordes_default' className="buttons-datosPersonales"  onClick={cerrarSesion}>
                    CERRAR SESIÓN
                </button>
                {state._administrador && <button className="buttons-datosPersonales" onClick={() =>navigate("/my/home/PagoCurso/neurobics")} >
                    COMPRAS EFECTIVO
                </button>}
	
				
				
			</div>
            {openModal &&
                <PhotoModal openModal={openModal} setOpenModal={setOpenModal} photoPerfil={photoPerfil} setPhotoPerfil={setPhotoPerfil}/>

            }
		</div>
	);
}

export default DatosPersonalesPage;
