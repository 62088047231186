/** Importaciones externales  */

import './theme/variables.css';

/** Externas */
// import {
// 	setupIonicReact,
// } from '@ionic/react';
import { useRef, useState, useEffect } from 'react';
import { Route, Navigate, Routes, useNavigate } from 'react-router-dom';

/** Locales */
//firebase
import { DatoContext } from './auth';
//paginas
import LoginPage from './vista/LoginPage';
import RegisterPage from './vista/usuario/RegisterPage';
import AppTabs from './AppTabs';
import DatosPage from './vista/usuario/DatosPage';
import NotFoundPage from './vista/NotFoundPage';
//hooks
import { useControl } from './hooks/controlador/useControl';
//mas
import { useAppSelector } from './store';
import Loading from './vista/Loading';
import PruebaPage from './vista/anexos/PruebaPage';
import MemorizacionPalabras from './vista/anexos/MemorizacionPalabras';
import MemorizacionNumeros from './vista/anexos/MemorizacionNumeros';
import CuentaRegresivaPresentacion from './vista/anexos/CuentaRegresivaPresentacion';
import ResponseePayco from './RespuestaEpayco';
import LandingCurso from './vista/entrenamiento/LandingPage/LandingCurso';
import PagoPage from './vista/admin/PagoPage';
import RespuestaPixel from './RespuestaPixel';
import JuegoSimultaneo from './vista/programas/simultaneo/JuegoSimultaneo';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { initialNeuronas, loadAuth, login } from './hooks/reducer/reducerAuth';
import { useDispatch } from 'react-redux';
import useNeurona from './hooks/controlador/useNeurona';

// setupIonicReact();


const App: React.FC = () => {

	/**
	 * Funciones para acceder el estado de autenticacion
	 * @type {Objecto}
	 * @const
	 */
	
	const dispatch = useDispatch();

	const loadingAuth = useAppSelector((state) => state.auth.loadingAuth);
	const loggedIn = useAppSelector((state) => state.auth.loggedIn);
	const userId = useAppSelector((state) => state.auth.userId);

	/**
	 * Funciones para cambiar estado de aplicacion
	 * @type {Objecto}
	 * @const
	 */
	const {
		borrarCuenta,
		logOutCuenta,
		initializarDatos,
		guardarDatos,
		recogerDatos,
		state,
	} = useControl();

	const { consultarNeuronas } = useNeurona();

	const navigate = useNavigate();
	
	const [cargando, setCargando] = useState(true);

	const originUrl = useRef(window.location.pathname);

	const Guardar = async (userId: string) => {
		dispatch(loadAuth());
		await recogerDatos(userId);
		const neuronas = await consultarNeuronas(userId)
		dispatch(initialNeuronas({cantidad:neuronas.total}))
		dispatch(loadAuth());
	}

	useEffect(() => {
		const unsuscribe = onAuthStateChanged(auth, user => {
			console.log('user Login', user);
			if(user){
				dispatch(login({userId:user.uid, email:user.email}));
				Guardar(user.uid).then(() => {
					console.log("originUrl", originUrl.current);

					if(originUrl.current){
						if (originUrl.current.includes("my/juegos")){
							console.log('Incluye my/juegos')
							const params = originUrl.current.split("/");
							const subDomain = params[params.findIndex(v => 'juegos' === v.toLowerCase()) + 1]
							if(subDomain) navigate(`my/juegos/${subDomain}`);
							else navigate('/my/juegos');
						}else{
							console.log('Incluye nada ruta')
							navigate(originUrl.current);
						}
					}else if(window.location.pathname.includes('/my') || window.location.pathname.includes('/login') || window.location.pathname === "/"){
						navigate('/my/home');
					}
					setCargando(false);
				});

			}else{
			  if(!window.location.pathname.includes('/torneo')){
				navigate('/login');
			  }
			  setCargando(false);
			}
			unsuscribe();
		})

		if(!window.location.pathname.includes('/my')){
			setCargando(false);
		}
		

    }, []);

	if (cargando) {
		return <Loading/>;
	}


	
	return (
		<div id='App'>
			{/*
	      * Guardar functiones para acceder al estado de aplicacion para acceder despues
	      */}
			
			<DatoContext.Provider value={{
				borrarCuenta,
				logOutCuenta,
				initializarDatos,				
				guardarDatos,
				recogerDatos,				
				state,
			}}>
				<Routes>
					<Route path="/torneo/:idSala?" element={<div className={!userId ? 'divAllView' : ''} style={!userId ? {} : {height:'109%'}}><JuegoSimultaneo/></div>}/>
					<Route path="/login" element={<LoginPage />}/>															
					<Route path="/datos" element={<DatosPage />}/>															
					<Route path="/register" element={<RegisterPage />}/>																						
					<Route path="my/*" element={<AppTabs />}/>	
					<Route path="/" element={<Navigate to="/my/home" replace/>} />

					{/* Anexos a la app */}

					<Route path="/prueba" element={<PruebaPage/>}/>													
					<Route path="/presentacionMemorizacionPalabras" element={<MemorizacionPalabras/>}/>															
					<Route path="/presentacionMemorizacionNumeros" element={<MemorizacionNumeros/>}/>															
					<Route path="/cuentaRegresiva" element={<CuentaRegresivaPresentacion/>}/>
					<Route path="/PagoCurso/:titleEntrenamiento/:codigoParams" element={<PagoPage/>}/>
					<Route path="/PagoCurso/:titleEntrenamiento" element={<PagoPage/>}/>
					<Route path="/responseFactura" element={<div className={'divAllView'} style={{height:'109%'}}><ResponseePayco/></div>}/>
					<Route path="/RealizadaCompra" element={<RespuestaPixel/>}/>

					<Route path='*' element={<NotFoundPage />}/>		
				</Routes>
			</DatoContext.Provider>
			<div id="toast"></div>
		</div>
	);
}

export default App;
