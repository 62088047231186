import { useState } from "react";
import Modal from "../../../componentes/Modal";
import UserInputs from "../../../usuario/componentes/UserInputs";

interface prop{
    userRespuestas: string[];
    esJuego:boolean;
    guardaInputs: any;
    inputType:"number" | "text";
    aCuartaPantalla: () => void;
    longitudInput?: string[];
}

/**
 * llama a la funcion que va a gestionar las respuestas de usuario. 
 */

const TerceraPantalla:React.FC<prop> = (props) => {
    const [openModal, setOpenModal] = useState(false)
    return (
        <div style={{textAlign:'center',height:'-webkit-fill-available',width:'80%'}}>
            <label style={{color: 'var(--Medium-Grey, #868189)'}}>¿Cuáles eran {props.inputType === "number" ? 'los numeros' : 'las palabras'}?</label>
            <UserInputs
                userInputs={props.userRespuestas}
                guardaInput={props.guardaInputs}
                inputType={props.inputType}
                longitudInput={props.longitudInput}
            />
            <br />
            <div className={"ion-text-center"}>
                <button id='button_rosado_default' style={{maxWidth:'50%'}} onClick={() => {if(props.esJuego){props.aCuartaPantalla()}else{setOpenModal(true)}}}>Finalizar {props.esJuego ? '': 'test'}</button>
            </div>
            <Modal open={openModal} onClickOutside={() => setOpenModal(false)}>
                <div style={{background:'white',border: '1px solid var(--color-purple-dark)',borderRadius:'25px',padding:'5% 10%'}}>
                    <p>¿Desea terminar completamente el test?</p>
                    <button onClick={() => props.aCuartaPantalla()} id="button_rosado_default" style={{width:'45%',margin:'2.5%'}}>Sí</button>
                    <button onClick={() => setOpenModal(false)} id="button_rosado_default" style={{width:'45%',margin:'2.5%'}}>No</button>
                </div>
            </Modal>
        </div>
    );
}

export default TerceraPantalla;