import style from "../../theme/web/Tienda.module.css";
import styleHome from "../../theme/web/HomePaginaWeb.module.css";
import NavbarWeb from "./componentes/NavbarWeb";
import FooterWebPage from "./componentes/FooterWebPage";
import { useRef } from "react";

const Tienda = () => {
    const footPage = useRef(null)
    return(
        <div className={style.page}>
            <NavbarWeb footPage={footPage}/>
            <Presentacion/>
            <hr className={styleHome.separator} style={{backgroundColor: "var(--color-purple-dark)"}}/>
            <Productos/>
            <hr className={styleHome.separator} style={{backgroundColor: "var(--color-purple-dark)"}}/>
            <Eventos/>
			<img src='/assets/componentes/web/CurvaMorada.png' ref={footPage}></img>
            <FooterWebPage/>
        </div>
    );
}

const Presentacion = () => {
    return(
        <div className={style.presentacion}>
            <div>
                <h1>CURSOS</h1>
                <h2>APP<br/>SUPERCEREBROS</h2>
                <p>
					Es una plataforma digital diseñada para mejorar la salud cerebral
					y la agilidad mental a través de juegos entretenidos y cursos educativos.
					La aplicación ofrece una variedad de herramientas diseñadas para todos, 
					desde estudiantes y profesionales hasta personas mayores interesados en mantener 
					su agudeza mental.
                </p>
                <a className={styleHome.enlace} href='/my/home'>ABRIR APLICACION</a>

            </div>
            <img src='/assets/images/web/appMovil.png' alt='app movil'/>
        </div>
    );
}

interface ProductoProp{
    img:string;
    alt:string;
    backgroundImg:string;
    titulo:string;
    descripcion:string;
}
const MostrarProductos = ({productos, textColor = "color: var(--color-Medium-Grey)"}:{productos: ProductoProp[], textColor?: string}) => {
    return(
        <>
        {
            productos.map((v, i) => 
                <div className={style.producto} key={i}>
                    <img src={v.img} alt={v.alt}/>
                    <div className={styleHome.productoDescripcion} style={{backgroundImage: v.backgroundImg, color: textColor}}>
                        <h2>{v.titulo}</h2>
                        <p>{v.descripcion}</p>
                    </div>
                    <a className={styleHome.enlace}>ADQUIRIR</a>
                </div>
            )
        }
        </>
    );
}

const Productos = () => {
    const cursosInfo: ProductoProp[] = [
        {
            img:'/assets/images/web/cursoNutricion.svg',
            alt:"curso super nutricion",
            backgroundImg: "url('/assets/images/web/cursoNutricionBackground.png')",
            titulo: "SUPER NUTRICIÓN",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoNeurobics.svg',
            alt:"curso neurobics",
            backgroundImg: "url('/assets/images/web/cursoNeurobicsBackground.png')",
            titulo: "NEUROBICS",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoEntrenamiento.svg',
            alt:"curso entrena tu super cerebro",
            backgroundImg: "url('/assets/images/web/cursoEntrenamientoBackground.png')",
            titulo: "ENTRENA TU SUPER CEREBRO",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoIngles.svg',
            alt:"curso super ingles",
            backgroundImg: "url('/assets/images/web/cursoInglesBackground.png')",
            titulo: "SUPER INGLÉS",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoRubik.svg',
            alt:"curso super cubo rubik",
            backgroundImg: "url('/assets/images/web/cursoRubikBackground.png')",
            titulo: "SUPER CUBO RUBIK",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },
    ];

    const productosInfo: ProductoProp[] = [
        {
            img:'/assets/images/web/libroFisico.png',
            alt:'libro fisico supercerebros',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "LIBRO FÍSICO SUPERCEREBROS",
            descripcion: "El libro SuperCerebros brinda una sólida base investigativa respaldada por más de una década de experiencia. Cuenta con estrategias para afinar la memoria y el rendimiento cognitivo."
        },{
            img:'/assets/images/web/rompecabezasCubo.png',
            alt:'rompecabezas cubo',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "CUBO RUBIK",
            descripcion: "Este famoso rompecabezas tridimensional es una herramienta efectiva para el desarrollo y la mejora de diversas habilidades cognitivas y motoras. Logra un procesamiento de información más lógico y rápido, fortaleciendo la inteligencia espacial al mismo tiempo."
        },{
            img:'/assets/images/web/simonDice.png',
            alt:'simon dice',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "SIMON SAYS GAME",
            descripcion: "Inspirado en el tradicional juego Simón Dice este juego de memoria y secuencias se ha adaptado a una versión electrónica con luces y sonidos. Desarrolla la concentración, mejora la memoria y las habilidades auditivas y visuales."
        },{
            img:'/assets/images/web/eBook.png',
            alt:'eBook supercerebros',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "E-BOOK SUPERCEREBROS",
            descripcion: "El libro SuperCerebros ahora cuenta con una versión digital para tener siempre a mano en tu dispositivo favorito. Lleva contigo la versión digital donde quiera que vayas y repasa estrategias y técnicas para enriquecer tu aprendizaje."
        },{
            img:'/assets/images/web/kitRegalo.png',
            alt:'kit regalo supercerebros',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "KIT REGALO SUPERCEREBROS",
            descripcion: "El regalo ideal para aquellos que estén interesados en el entrenamiento mental. Encontrarán juegos beneficiosos para la salud cerebral, que buscan incentivar el ejercicio constante de las habilidades mentales."
        }
    ];

    return(
        <>
        <h1>CURSOS</h1>
        <div className={style.tiendaProductos}>
            <MostrarProductos productos={cursosInfo} textColor="white"/>
        </div>
        <hr className={styleHome.separator} style={{backgroundColor: "var(--color-purple-dark)"}}/>
        <h1>PRODUCTOS</h1>
        <div className={style.tiendaProductos}>
            <MostrarProductos productos={productosInfo}/>
        </div>
        </>
    );
}

const Eventos = () => {
    return (
        <>
        <h1>EVENTOS</h1> 
        <div className={style.evento}>
            <img src="/assets/images/web/eventoEntrenaTuMente.png" alt="entrena tu mente dorada"></img>
            <div className={style.eventoInfo}>
                <h3>ENTRENA TU MENTE DORADA<br/>COMPENSAR +60 VITAL</h3>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Donec felis quam, placerat sed suscipit eget, luctus
                faucibus arcu. Nulla vulputate neque at nunc luctus, ac
                rutrum metus finibus. Quisque condimentum auctor lorem nec
                porta. Pellentesque finibus facilisis urna, eget bibendum
                diam cursus ac. Aliquam a mattis velit. Nam elit nibh,
                porttitor vel est non, feugiat scelerisque nisi. Maecenas
                urna odio, euismod quis arcu in, hendrerit luctus nisl.
                Fusce porttitor, mi semper vestibulum bibendum, sapien
                nibh lobortis mi, et aliquam risus dui eget eros.
                Curabitur sit amet nunc nibh. Nulla id lectus ac magna
                vestibulum viverra vitae quis libero.
                </p>
                <a className={styleHome.enlace}>INSCRIBIRSE</a>
            </div>
        </div>
        <div className={style.evento}>
            <img src="/assets/images/web/eventoVacacionesNinos.png" alt="entrena tu mente dorada"></img>
            <div className={style.eventoInfo}>
                <h3>VACACIONES SUPERINTELIGENTES<br/>COMPENSAR NIÑOS</h3>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Donec felis quam, placerat sed suscipit eget, luctus
                faucibus arcu. Nulla vulputate neque at nunc luctus, ac
                rutrum metus finibus. Quisque condimentum auctor lorem nec
                porta. Pellentesque finibus facilisis urna, eget bibendum
                diam cursus ac. Aliquam a mattis velit. Nam elit nibh,
                porttitor vel est non, feugiat scelerisque nisi. Maecenas
                urna odio, euismod quis arcu in, hendrerit luctus nisl.
                Fusce porttitor, mi semper vestibulum bibendum, sapien
                nibh lobortis mi, et aliquam risus dui eget eros.
                Curabitur sit amet nunc nibh. Nulla id lectus ac magna
                vestibulum viverra vitae quis libero.
                </p>
                <a className={styleHome.enlace}>INSCRIBIRSE</a>
            </div>
        </div>
        <div className={style.evento}>
            <img src="/assets/images/web/eventoConferencia.png" alt="entrena tu mente dorada"></img>
            <div className={style.eventoInfo}>
                <h3>CONFERENCIA SUPERCEREBROS<br/>ALCALDIA DE TOCANCIPÁ</h3>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Donec felis quam, placerat sed suscipit eget, luctus
                faucibus arcu. Nulla vulputate neque at nunc luctus, ac
                rutrum metus finibus. Quisque condimentum auctor lorem nec
                porta. Pellentesque finibus facilisis urna, eget bibendum
                diam cursus ac. Aliquam a mattis velit. Nam elit nibh,
                porttitor vel est non, feugiat scelerisque nisi. Maecenas
                urna odio, euismod quis arcu in, hendrerit luctus nisl.
                Fusce porttitor, mi semper vestibulum bibendum, sapien
                nibh lobortis mi, et aliquam risus dui eget eros.
                Curabitur sit amet nunc nibh. Nulla id lectus ac magna
                vestibulum viverra vitae quis libero.
                </p>
                <a className={styleHome.enlace}>INSCRIBIRSE</a>
            </div>
        </div>
        </>
    )
}

export default Tienda;
