import { Link, useParams } from 'react-router-dom';
import styleDesktop from '../../theme/web/HomePaginaWeb.module.css'
import styleTienda from '../../theme/web/Tienda.module.css'
import style from '../../theme/web/mobileHome.module.css'
import FooterWebPage from './componentes/FooterWebPage';
import NavbarWeb from './componentes/NavbarWeb';
import { forwardRef, useEffect, useRef } from 'react';

const MobileHome = () => {
	const {Section} = useParams<{Section:string}>();
	const servicios = useRef<HTMLDivElement>(null)

	useEffect(() => {
	  if(Section === 'servicios' && servicios.current){
		servicios.current.scrollIntoView({ block: "start", behavior: "smooth" })
	  }
	}, [])
	

	return (
		<div className={`${styleDesktop.page} ${style.page}`}>
			<Banner/>
			<NavbarWeb/>
			<Presentacion/>
			<QuienesSomos/>
			<img src="/assets/images/web/ondaMorada.png"/>
			<Servicios ref={servicios}/>
			<img src="/assets/images/web/transicionMB.png"/>
			<Tienda/>
			<img src="/assets/images/web/transicionBM.png"/>
			<FooterWebPage/>
		</div>
	);
}

const Banner = () => {
	return(
		<div className={styleDesktop.banner}>
			<p>SUPERCEREBROS</p>
			<img src='/assets/images/web/bannerWeb.png' alt='banner'/>
			<div className={styleDesktop.logo}>
				<img src='/assets/images/Logo-blanco-fondo.png' alt='logo'/>
			</div>
		</div>
	);
}

const Presentacion = () => {
	return(
		<>
		<div className={style.presentacion}>
			<h1>APP</h1>
			<h3>SUPERCEREBROS</h3>
			<img src='/assets/images/web/presentacion-default.png' alt='presentacion'/>
			<p>
				Es una plataforma digital diseñada para mejorar la salud cerebral
				y la agilidad mental a través de juegos entretenidos y cursos educativos.
				La aplicación ofrece una variedad de herramientas diseñadas para todos, 
				desde estudiantes y profesionales hasta personas mayores interesados en mantener 
				su agudeza mental.
			</p>
			<a className={`${styleDesktop.enlace} ${style.enlace}`} href='/my/home'>ABRIR APLICACION</a>

		</div>
		</>
	);
}

const QuienesSomos = () => {

	return (
		<>
		<div className={style.quienesSomos}>
			<h1>¿Quienes somos?</h1>
			<p>
				Somos Supercerebros, una empresa líder en entrenamiento mental dedicada a potenciar las capacidades
				cognitivas de atención, concentración y memoria. Nuestro enfoque se centra en fortalecer la salud mental
				y la agilidad intelectual, abriendo caminos para la creatividad y la imaginación. Con una vasta experiencia
				en el campo, nos comprometemos con la excelencia y transformamos vidas al enseñar técnicas efectivas que
				permiten a estudiantes, trabajadores, retirados y cualquier persona interesada mejorar sus capacidades mentales.
			</p>
			<Link to="/web/quienesSomos" className={`${styleDesktop.enlace} ${style.enlace}`}>CONOCE MÁS</Link>
			<img src='/assets/images/web/quienesMovil.png'/>
		</div>
		</>
	);
}

const Servicios = forwardRef<HTMLDivElement>((_,ref) => {
	return (
		<>
		<div className={styleDesktop.servicios} ref={ref}>
			<div className={styleDesktop.serviciosTitulo}> 
				<svg width="160" height="120" viewBox="0 0 119 79" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio='xMinYMin'>
					<circle cx="83.4302" cy="44.0002" r="35" transform="rotate(-69.6067 83.4302 44.0002)" fill="#D74286"/>
					<circle cx="21.5669" cy="21.0016" r="21" transform="rotate(-69.6067 21.5669 21.0016)" fill="#D74286"/>
					<circle cx="29.0546" cy="61.1256" r="10" transform="rotate(-69.6067 29.0546 61.1256)" fill="#D74286"/>
				</svg>
				<p>SERVICIOS</p>
				<svg style={{transform: "rotate(270deg)", alignSelf: "end"}} preserveAspectRatio='xMinYMin'
					width="160" height="120" viewBox="0 0 119 79" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="83.4302" cy="44.0002" r="35" transform="rotate(-69.6067 83.4302 44.0002)" fill="#D74286"/>
					<circle cx="21.5669" cy="21.0016" r="21" transform="rotate(-69.6067 21.5669 21.0016)" fill="#D74286"/>
					<circle cx="29.0546" cy="61.1256" r="10" transform="rotate(-69.6067 29.0546 61.1256)" fill="#D74286"/>
				</svg>
			</div>
			<div className={style.serviciosInfo}>

				<img src='/assets/images/web/servicioShow.svg' alt="logo Show" className={styleDesktop.serviciosLogo}/>
				<p className={styleDesktop.serviciosNombre}>Show</p>
				<p className={styleDesktop.serviciosDescripcion}>
					El "Show de Juegos y Desafíos Mentales" fusiona entretenimiento y aprendizaje para potenciar las
					habilidades cognitivas del equipo. Comienza en el "Gimnasio Mental", con estaciones interactivas que
					estimulan diferentes regiones de nuestro cerebro, seguido de un espectáculo de 90 minutos diseñado 
					para desafiar y enriquecer la capacidad mental de los participantes.
				</p>
				<Link className={`${styleDesktop.enlace} ${style.enlace}`}  to='/web/servicios/show'>MÁS INFORMACIÓN</Link>

				<img src='/assets/images/web/servicioConferencia.svg' alt="logo Conferencia" className={styleDesktop.serviciosLogo}/>
				<p className={styleDesktop.serviciosNombre}>Conferencia</p>
				<p className={styleDesktop.serviciosDescripcion}>
					La conferencia "Descubre tu Potencial" potencia las capacidades mentales individuales mediante el 
					entrenamiento mental. Motiva a los asistentes a descubrir su supercerebro y supermemoria, ofreciendo 
					ejercicios prácticos para mejorar atención, concentración, memoria, creatividad e imaginación. 
					Proporciona estrategias aplicables tanto en la parte personal, académica y profesional.
				</p>
				<Link className={`${styleDesktop.enlace} ${style.enlace}`}  to='/web/servicios/conferencias'>MÁS INFORMACIÓN</Link>

				<img src='/assets/images/web/servicioTalleres.svg' alt="logo Neuro-talleres" className={styleDesktop.serviciosLogo}/>
				<p className={styleDesktop.serviciosNombre}>Neuro-talleres</p>
				<p className={styleDesktop.serviciosDescripcion}>
					Hemos diseñado una serie de Neurotalleres exclusivos que buscan fomentar el desarrollo profesional y
					personal de sus equipos. A través de técnicas avanzadas de neuro-entrenamiento, nuestros talleres están
					diseñados para maximizar el rendimiento cognitivo, mejorar habilidades específicas y promover una cultura 
					de mejora continua en el ambiente laboral.
				</p>
				<Link className={`${styleDesktop.enlace} ${style.enlace}`}  to='/web/servicios/taller'>MÁS INFORMACIÓN</Link>

			</div>
		</div>
		</>
	);
})

interface ProductoProp{
    img:string;
    alt:string;
    backgroundImg:string;
    titulo:string;
    descripcion:string;
}
const MostrarProductos = ({productos, textColor = "color: var(--color-Medium-Grey)"}:{productos: ProductoProp[], textColor?: string}) => {
    return(
        <>
        {
            productos.map((v, i) => 
                <div className={`${styleTienda.producto} ${style.producto}`} key={i}>
                    <img src={v.img} alt={v.alt}/>
                    <div className={`${style.productoDescripcion} ${styleDesktop.productoDescripcion}`} style={{backgroundImage: v.backgroundImg, color: textColor}}>
                        <h2>{v.titulo}</h2>
                        <p>{v.descripcion}</p>
                    </div>
                    <Link to="/web/tienda" className={`${styleDesktop.enlace} ${style.enlace}`}>VER MÁS</Link>
                </div>
            )
        }
        </>
    );
}

const Tienda = () => {
	const cursosInfo: ProductoProp[] = [
        {
            img:'/assets/images/web/cursoNutricion.svg',
            alt:"curso super nutricion",
            backgroundImg: "url('/assets/images/web/cursoNutricionBackground.png')",
            titulo: "SUPER NUTRICIÓN",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoNeurobics.svg',
            alt:"curso neurobics",
            backgroundImg: "url('/assets/images/web/cursoNeurobicsBackground.png')",
            titulo: "NEUROBICS",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoEntrenamiento.svg',
            alt:"curso entrena tu super cerebro",
            backgroundImg: "url('/assets/images/web/cursoEntrenamientoBackground.png')",
            titulo: "ENTRENA TU SUPER CEREBRO",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        }
    ];

	return (
	<div className={style.tienda}>
		<p className={styleDesktop.tiendaTitulo}>CURSOS</p>
		<MostrarProductos productos={cursosInfo} textColor="white"/>
	</div>
	);
}



export default MobileHome
