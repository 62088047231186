
import React, { useEffect, useState } from 'react'
import '../../theme/anexos.css'
// import { IonButton } from '@ionic/react';
import { useLocation } from 'react-router-dom';

export const CuentaRegresivaPresentacion = () => {
    const location = useLocation();
    const [array, setArray] = useState(Array.from(Array(30).keys(), i => 30 - i));
    const [posicion, setPosicion] = useState(0)
    const [begin, setBegin] = useState(false)
    const [pausa, setPausa] = useState(false)
    useEffect(()=> {
        if(location.pathname == "/cuentaRegresiva"){
            const interval = setInterval(() => {
                if(posicion<29 && begin && !pausa){
                    console.log(pausa);
                    setPosicion(prevPosicion => prevPosicion + 1);
                }
              }, 1000);
          
              return () => clearInterval(interval);
        }
    }
    ,[begin])
    
    const cuenta = () =>{
        setBegin(true)
    }

  return (
    <div className='fondo-presentacion'>
        <div className='contenedor-icono-fondo'>
            <img src='/assets/images/logo-supercerebros.png' style={{width:'100%',height:'100%',background: 'transparent'}}></img>
        </div>
        {/* <h1 className='title-presentacion'>memorización de números</h1> */}
        {begin?
        <div className='cuenta_regresiva'>
            {posicion > 29?<>
                <p style={{marginBottom:'0%',marginTop:'0%'}}>{"Fin"}</p>
                <button onClick={()=>{setPosicion(0);setBegin(false);setPausa(false)}}>Reiniciar</button></>
            : 
            <>
            <p style={{marginBottom:'0%',marginTop:'0%'}}>{array[posicion]}</p></>}
        </div>
        :<button className='button_presentacion' onClick={() => {cuenta()}}>INICIAR</button>}
    </div>
  )
}

export default CuentaRegresivaPresentacion;
