// import { IonCol } from "@ionic/react";
import React from 'react';
import styles from "../../theme/programas/Button.module.css";
// import { checkmarkSharp } from "ionicons/icons";
// import { closeSharp } from "ionicons/icons";


const Button = props => {

    const { value, special,send, clickEvent , test} = props;

    return (
        <div className={ `${ special ? send ? styles.specialButtonOk : styles.specialButtonErase  : test ? styles.button_test : styles.button } animate__animated animate__faster` } onClick={ e => clickEvent(e, value) }>
            { value === "Borrar" ? 
                // <ion-icon icon={closeSharp}></ion-icon> 
                <div style={{height:'3.5svh'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 38 37" fill="none">
                        <path d="M37.5752 3.72643L33.8488 0L19.0752 14.7736L4.30162 0L0.575195 3.72643L15.3488 18.5L0.575195 33.2736L4.30162 37L19.0752 22.2264L33.8488 37L37.5752 33.2736L22.8016 18.5L37.5752 3.72643Z" fill="white"/>
                    </svg>
                </div>
                : value == 'Enviar' ? 
                // <ion-icon icon={checkmarkSharp}></ion-icon> 
                <div style={{height:'3.5svh'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 47 35" fill="none">
                        <path d="M15.2116 27.6866L4.23429 16.7164L0.575195 20.3731L15.2116 35L46.5752 3.65672L42.9161 0L15.2116 27.6866Z" fill="white"/>
                    </svg>
                </div>
                : value }
        </div>
    );
}

export default Button;
