import React, { CSSProperties } from 'react'

interface Props{
    color:string;
    style:CSSProperties;
}

const ShowServicios = ({color,style}:Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 216 244" fill="none" style={style}>
        <g clip-path="url(#clip0_77_15198)">
        <path d="M99.3354 37.1738C103.744 26.5499 98.6897 14.3702 88.0455 9.96962C77.4014 5.56907 65.1984 10.6141 60.7894 21.238C56.3804 31.8618 61.4351 44.0415 72.0793 48.4421C82.7234 52.8426 94.9264 47.7977 99.3354 37.1738Z" fill={color}/>
        <path d="M65.6226 51.9288C66.2216 53.0567 69.4762 58.8767 76.5955 60.9521C85.5569 63.5575 92.7328 57.9406 93.3883 57.4105C99.3098 59.8806 105.231 62.3507 111.142 64.8208C117.594 77.3857 124.058 89.9618 130.511 102.527C120.826 116.389 111.142 130.239 101.457 144.101C100.169 136.15 98.8691 128.198 97.5808 120.257C101.988 114.561 106.407 108.866 110.814 103.181C105.649 96.7405 100.485 90.2889 95.3207 83.8486C100.056 127.95 104.791 172.04 109.526 216.141C104.361 213.129 99.1968 210.129 94.0324 207.117C88.1109 185.101 82.1894 163.073 76.2791 141.056C64.5491 140.729 52.819 140.413 41.089 140.086C39.9024 150.936 38.7159 161.787 37.5406 172.637C30.8733 169.84 24.1946 167.054 17.5272 164.257C18.8155 151.579 20.1151 138.902 21.4033 126.235C21.6519 124.916 23.3922 116.592 31.088 112.373C34.8398 110.321 38.4447 110.084 40.4562 110.118C49.3837 110.332 58.3224 110.546 67.2499 110.76C65.4192 99.2671 63.5885 87.7737 61.7578 76.2803L27.856 62.2604C25.1099 44.135 22.3639 26.0095 19.6178 7.88408C19.663 7.51187 20.3411 2.88745 24.4658 0.879778C27.9012 -0.800803 32.2519 0.0451273 35.1223 3.05664C37.5406 18.2834 39.9702 33.5101 42.3886 48.7368C50.1408 49.8084 57.8817 50.8799 65.6339 51.9627L65.6226 51.9288Z" fill={color}/>
        <path d="M105.333 55.2336C110.791 56.5081 114.645 58.1774 115.741 59.1023C116.283 59.5535 116.769 60.0497 116.769 60.0497C117.549 60.8393 118.08 61.5498 118.397 62.001C124.442 70.3362 130.025 82.5402 130.025 82.5402C131.935 86.7247 134.873 92.5898 139.224 99.4588C139.495 100.339 140.026 102.504 139.224 105.019C138.772 106.429 138.06 107.456 137.528 108.076C128.488 124.194 119.447 140.3 110.407 156.418V174.622C113.526 175.976 120.826 178.627 129.776 176.957C140.919 174.893 147.439 167.483 149.88 164.629C156.943 156.362 156.604 149.842 161.022 136.589C161.022 136.589 167 118.644 177.973 103.237C183.691 95.2066 194.834 82.5627 215.989 72.5469C207.739 73.2011 195.32 73.3816 180.799 70.2911C165.091 66.9412 158.649 62.2491 142.704 58.5271C134.093 56.5194 121.47 54.5004 105.333 55.2223V55.2336Z" fill={color}/>
        <path d="M16.465 168.588C45.6319 184.706 74.7988 200.812 103.966 216.93L139.8 244H0C5.4921 218.859 10.9729 193.729 16.465 168.588Z" fill={color}/>
        </g>
        <defs>
        <clipPath id="clip0_77_15198">
            <rect width="216" height="244" fill={color}/>
        </clipPath>
        </defs>
    </svg>
  )
}

export default ShowServicios