
/** Importaciones de React */
import { useState, useEffect } from "react";

/** Importaciones de Firebase */
 
/** Importaciones de modulos personalizados */

/** Importaciones Hooks personalizados */

/** Importaciones Interfaces */

/** Interfaces Locales*/

interface PropsNombre {
    setDatoLocal : (a : string , b : string) => void;
    datoAnterior? : string;
}

const NombresComponent: React.FC<PropsNombre> = ({ setDatoLocal,datoAnterior}) => {
    
    const [nombre, setNombre] = useState(datoAnterior);

    useEffect(() => {
	    if(datoAnterior) {
	        setNombre(datoAnterior);
	    }
    }, [datoAnterior]);
    

    const handleEleccion = (respuesta : string) => {
      console.log("🚀 ~ handleEleccion ~ respuesta:", respuesta)
      setNombre(respuesta)
      setDatoLocal("nombres", respuesta)
    }
  return (
    <>
      <div className='item-nombres'>
        <label className='label-datos'>Nombres</label>
        <input
          value={nombre}
          style={{background:'#F6F4F7'}}
          onChange={(event) => handleEleccion(event.target.value)}
        />
      </div>
    </>
  );
};

export default NombresComponent;
