import React, { ReactNode, useEffect, useState} from "react";

// estilos
import style from "../../../../theme/MemoriaNombres.module.css";
import estiloJuegos from "../../../../theme/Juegos.module.css"
import estiloJuegos2 from "../../../../theme/Juego2.module.css"
import NavegacionImagen from "./NavegacionImagen";
import CirculosDeSeleccion from "./CirculosObjetoSeleccionado";
import BotonBordeDegradado from "../../../componentes/BotonBordeDegradado";

interface TerceraPantallaConBotonesProp{
    resetContenido: () => void;
    contenidoIdIndice: number;
    cantidadDeValores: number;
    objetoIdAleatorio?: string[];
    leftSubmit: () => void;
    rightSubmit: () => void;
    aSiguientePantalla: () => void;
    getObjetos: () => ReactNode; 
    guardarOpcion: (value: string, index: number) => void;
    getOpciones: string[];
}

const TerceraPantallaConBotones:React.FC<TerceraPantallaConBotonesProp> = (props) => {
    
    const [index,setIndex] = useState<number>(0);
    const [selectedButtons, setSelectedButtons] = useState<number[]>([]);
    const [questionActual, setQuestionActual] = useState<number>(0);
    
    useEffect(() => {
      setQuestionActual(props.contenidoIdIndice)
    }, [props.contenidoIdIndice])
    
    
    const left= () =>{
        if(props.contenidoIdIndice > 0){
            setQuestionActual(questionActual - 1);
            props.leftSubmit();
            opciones();
        }
    }
    const right = () =>{
        if(props.contenidoIdIndice < props.cantidadDeValores-1){
            setQuestionActual(questionActual + 1);
            props.rightSubmit();
            opciones(); 
        }
    }
    const reset = () =>{
        setQuestionActual(0);
        props.resetContenido();
    }
    const handleButtonClick = (buttonId: number) => {
        const seleccionados = [...selectedButtons]
        seleccionados[questionActual]=buttonId
        setSelectedButtons(seleccionados)
        
    };
    
    // guarda las opciones seleccionadas
    const guardarValores = (numero:number, id:number,idButton:number) => {
        handleButtonClick(idButton);
        props.guardarOpcion(props.getOpciones[numero],id)
        if (props.contenidoIdIndice < props.cantidadDeValores - 1 ){
            setTimeout(()=>{
                right()
            }    
            ,250)
        }
    }
    // actualiza el valor de index
    useEffect(() => {
        if (props.objetoIdAleatorio) {
            setIndex(parseInt(props.objetoIdAleatorio[props.contenidoIdIndice]));
        } else {
            setIndex(index =>index+1);
        }
      }, [props.contenidoIdIndice, props.objetoIdAleatorio]);
    

    const opciones = () =>{
        return(
            
            <div key={index} id="inputs" className={style.inputBox}>
                {selectedButtons[questionActual] !== 1 ?
                <BotonBordeDegradado onClick={() =>guardarValores(0,index,1)}>
                    {selectedButtons[questionActual] === 1 ? <label style={{color:'white'}}>{props.getOpciones[0]}</label>: <span className={estiloJuegos.degradadoTexto}>{props.getOpciones[0]}</span>}
                </BotonBordeDegradado>:
                <button className={`${style.inputsOptions} ${style.selected}`}>
                    {selectedButtons[questionActual] === 1 ? <label style={{color:'white'}}>{props.getOpciones[0]}</label>: <span className={estiloJuegos.degradadoTexto}>{props.getOpciones[0]}</span>}
                </button>}
                {selectedButtons[questionActual] !== 2 ?
                <BotonBordeDegradado onClick={() =>guardarValores(1,index,2)}>
                    {selectedButtons[questionActual] === 2 ? <label style={{color:'white'}}>{props.getOpciones[1]}</label>: <span className={estiloJuegos.degradadoTexto}>{props.getOpciones[1]}</span>}
                </BotonBordeDegradado>:
                <button className={`${style.inputsOptions} ${style.selected}`}>
                    {selectedButtons[questionActual] === 2 ? <label style={{color:'white'}}>{props.getOpciones[1]}</label>: <span className={estiloJuegos.degradadoTexto}>{props.getOpciones[1]}</span>}
                </button>}
                {selectedButtons[questionActual] !== 3 ?
                <BotonBordeDegradado onClick={() =>guardarValores(2,index,3)}>
                    {selectedButtons[questionActual] === 3 ? <label style={{color:'white'}}>{props.getOpciones[2]}</label>: <span className={estiloJuegos.degradadoTexto}>{props.getOpciones[2]}</span>}
                </BotonBordeDegradado>:
                <button className={`${style.inputsOptions} ${style.selected}`}>
                    {selectedButtons[questionActual] === 3 ? <label style={{color:'white'}}>{props.getOpciones[2]}</label>: <span className={estiloJuegos.degradadoTexto}>{props.getOpciones[1]}</span>}
                </button>}
                {selectedButtons[questionActual] !== 4 ?
                <BotonBordeDegradado onClick={() =>guardarValores(3,index,4)}>
                    {selectedButtons[questionActual] === 4 ? <label style={{color:'white'}}>{props.getOpciones[3]}</label>: <span className={estiloJuegos.degradadoTexto}>{props.getOpciones[3]}</span>}
                </BotonBordeDegradado>:              
                <button className={`${style.inputsOptions} ${style.selected}`}>
                    {selectedButtons[questionActual] === 4 ? <label style={{color:'white'}}>{props.getOpciones[3]}</label>: <span className={estiloJuegos.degradadoTexto}>{props.getOpciones[3]}</span>}
                </button>}
            </div>
            
        );
    }
    return (
        <>
            <div  style={{display:'flex',flexDirection:'column',padding:'0% 0% 0% 0% !important', width:'100%', height: '-webkit-fill-available',overflowY: 'scroll'}}>
                <div>
                    <NavegacionImagen obtenerImagen={props.getObjetos} 
                    />
                </div>
                <div style={{display: "flex",flexDirection: "column",alignItems:'center'}}>
                    {opciones()}                
                </div>
                <div style={{display:'flex',justifyContent:'center',gap: '0.55svh'}}>
                    <button className={estiloJuegos2.nv_button} onClick={left}>
                        <img src='/assets/icon/flechaBlancoSend.svg' alt='Go' style={{transform:'rotate(180deg)'}}></img>
                    </button>
                    {props.contenidoIdIndice < props.cantidadDeValores-1 ? 
                    <button className={estiloJuegos2.nv_button} onClick={right}>
                        <img src='/assets/icon/flechaBlancoSend.svg' alt='Go'></img>
                    </button> 
                    : <button className={estiloJuegos2.nv_button} style={{width:'24.2svh',marginLeft:'14.24px'}} onClick={props.aSiguientePantalla}>TERMINAR</button>}
                </div>
            </div>
        </>
    );
}

export default TerceraPantallaConBotones;