
// Hooks
import { useBaseDeDatos, agregarCollection } from '../baseDeDatos/useBaseDeDatos';
import {factura } from '../../datos/nombresBD';
import { Factura,ProductoReferencia,aFactura, toFactura } from '../../modelos/Facturas';
import { DocumentSnapshot, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { ref } from 'firebase/storage';

export const crearFactura = async (myFactura:Factura): Promise<string> => {
    const id =await agregarCollection(factura ,myFactura);
    return id;
}

export const useFacturas = () => {

    const {
        ActualizarDoc,
        agregarCollection,
        recogerDoc
    } = useBaseDeDatos();

    const crearFactura = async (myFactura:Factura): Promise<string> => {
        const id =await agregarCollection(factura ,myFactura);
        return id;
    }
    const actualizarFactura = async (myFactura:Factura,idFactura:string) :Promise<void>=> {
        await ActualizarDoc(factura + "/" + idFactura,myFactura);
    }
    const getFactura =async (id:string) :Promise<Factura> => {
        const datos:DocumentSnapshot = await recogerDoc(factura+ "/"+id );
        const myfactura:Factura =toFactura(datos);
        return myfactura
    }

    const consultarFacturaPendiente = async (nombre: string, telefono: string, correo: string, id_productos: ProductoReferencia[]) :Promise<[string, Factura] | null> => {
        const ref = collection(firestore, factura);
        const snapshot = await getDocs(query(ref,
            where("estado_transaction", "==", "inicializada"),
            where("correo_usuario", "==", correo)
        ));

        let pendientes: [string, Factura][] = [];

        snapshot.forEach(doc => {
            const facturaDoc = doc.data() as Factura;

            if(facturaDoc.recordatorios && facturaDoc.recordatorios < 3) pendientes.push([doc.id, facturaDoc]);
        });

        if(pendientes.length <= 0) return null;

        pendientes.sort((a, b) => {
            return (
                    Number(a[1].nombre_usuario === nombre) +
                    Number(a[1].telefono_usuario === telefono) +
                    Number(a[1].id_productos === id_productos)
                ) - (
                    Number(b[1].nombre_usuario === nombre) +
                    Number(b[1].telefono_usuario === telefono) +
                    Number(b[1].id_productos === id_productos)
                )
        })

        return pendientes[0];
    }

    const setusuarioTrain =async (idtrain:string) => {
    }
    
    return {
        crearFactura,
        actualizarFactura,
        getFactura,
        consultarFacturaPendiente,
        setusuarioTrain,
        
    }
}
