import { useNavigate, useParams } from 'react-router-dom';
import styleServicios from '../../theme/web/Servicios.module.css'
import styleHome from '../../theme/web/HomePaginaWeb.module.css'
import NavbarWeb from './componentes/NavbarWeb'
import ShowServicios from './componentes/ShowServicios';
import Selector from '../entrenamiento/componentes/Selector';
import { paises } from '../../datos/paises';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useToast } from '../../hooks/useToast';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';
import FooterWebPage from './componentes/FooterWebPage';
import VideoPlayerIOs from '../entrenamiento/componentes/VideoPlayerIOs';

// modulos para swiper
import ConferenciasServicios from './componentes/ConferenciasServicios';
import TallerServicios from './componentes/TallerServicios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Contactanos from './componentes/Contactanos';

type Params = {
	Section:string;
}

const ServiciosMobile = () => {
  const {Section} = useParams<Params>();
  const navigate = useNavigate();
  const footPage = useRef(null);

  const IMAGENES = [
    "/img/poster.png",
    "/img/poster.png",
    "/img/poster.png",
    "/img/poster.png"
  ]

  const GetDirection = () => {
	if(Section){
		switch(Section){
			case 'taller':
				return <Servicio 
                titulo='Neuro-taller' IMAGENES={IMAGENES}
                descripcion='
                    En Supercerebros, creemos en el poder de la mente para superar límites y alcanzar la excelencia. Es por esto
                    que hemos diseñado una serie de Neurotalleres exclusivos, orientados a empresas que buscan fomentar el desarrollo
                    profesional y personal de sus equipos. A través de técnicas avanzadas de neuro-entrenamiento, nuestros talleres 
                    están diseñados para maximizar el rendimiento cognitivo, mejorar habilidades específicas y promover una cultura 
                    de mejora continua en el ambiente laboral.'
                svg={<TallerServicios color='white' style={{height:'100%',width:'auto',maxWidth:'30%'}}/>}/>
				
			case 'conferencia':
				return <Servicio 
                titulo='Conferenciaa' IMAGENES={IMAGENES}
                descripcion='
                    La conferencia "Descubre tu Potencial" es una experiencia transformadora diseñada para revelar y potenciar las capacidades
                    mentales innatas de cada individuo. esta sesión se centra en la motivación hacia el entrenamiento mental y en demostrar que
                    todos poseemos un supercerebro y una supermemoria. A través de una combinación de ejercicios prácticos, los asistentes 
                    descubrirán herramientas sencillas pero efectivas para entrenar su mente. Aprenderán a mejorar su atención, concentración,
                    memoria, creatividad e imaginación, y se llevarán estrategias concretas para aplicar en su vida diaria y laboral.'
                svg={<ConferenciasServicios color='white' style={{height:'100%',width:'auto',maxWidth:'30%'}}/>}/>
					
			default:
				return <Servicio 
                titulo='Show' IMAGENES={IMAGENES}
                descripcion='
                    El "Show de Juegos y Desafíos Mentales" es una experiencia única que combina entretenimiento y aprendizaje para fortalecer
                    las habilidades cognitivas de tu equipo. Comienza con el "Gimnasio Mental", una zona interactiva con cuatro estaciones que
                    estimulan distintos lóbulos cerebrales. Tras esta estimulante preparación, los participantes disfrutarán de un espectáculo de
                    90 minutos en el auditorio, diseñado para desafiar y enriquecer su capacidad mental.'
                svg={<ShowServicios color='white' style={{height:'100%',width:'auto',maxWidth:'30%'}}/>}/>
		}
	}
  }

  useLayoutEffect(() => {
    if(!Section){
        console.log("aquiiiii")
        navigate('/web/home/servicios')
    }
  }, [])
  

  return (
    <div className={styleServicios.page}>
        <NavbarWeb mobile={true} footPage={footPage}/>
		{GetDirection()}
        <img src='/assets/componentes/web/CurvaMorada.png' style={{width:'100%',marginBottom:'-2px'}} ref={footPage}></img>
        <FooterWebPage/>
    </div>
  )
}

interface InfoServicio{
    titulo:string;
    svg:ReactNode;
    descripcion:string;
    IMAGENES:string[];
}

const Servicio = ({titulo,svg,descripcion,IMAGENES}:InfoServicio) => {
	
    const [loading, setLoading] = useState(false)


	return(
		<div>
			<div className={styleServicios.fondoMorado}>
                {svg}
				<h1>{titulo}</h1>
				<p>supercerebro</p>
			</div>
            <img src='/assets/componentes/web/CurvaMorada.png' style={{transform:'rotate(180deg)',width:'153%',marginTop:'-2px'}}></img>
			<p className={styleServicios.descripcion}>{descripcion}</p>
            <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            navigation={true}
            modules={[ Autoplay, Navigation]}
            className={styleServicios.mySwiper}
            >
            {IMAGENES.map((img) => 
                <SwiperSlide style={{width:'100%',aspectRatio:'1 / 1',height:'auto',maxHeight:'20svh'}}>
                    <img
                        src={img}
                        loading="lazy"
                    />
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
            )}
            </Swiper>
            <Contactanos setLoading={setLoading}/>
            <div style={{width:'100%'}} className={styleServicios.lineaBack}>
                <div className={styleServicios.linea}></div>
            </div>
			<h1 className={styleServicios.titleAlone}>TESTIMONIOS</h1>
            <VideoPlayerIOs videoUrl={''} />
            <div style={{width:'100%'}} className={styleServicios.lineaBack}>
                <div className={styleServicios.linea}></div>
            </div>
			<h1 className={styleServicios.titleAlone} style={{fontSize:'150%'}}>CLIENTES SATISFECHOS</h1>
			<div className={styleServicios.clientes}>
				<img src='/assets/componentes/web/Alcaldía_tocancipa.png' alt='cliente 1'></img>
				<img src='/assets/componentes/web/akila.png' alt='cliente 2'></img>
				<img src='/assets/componentes/web/alcaldia_cajica.png' alt='cliente 3' style={{height:'7svh'}}></img>
				<img src='/assets/componentes/web/Alcaldia_Sopo.png' alt='cliente 4'></img>
			</div>
		</div>
	)
}

export default ServiciosMobile