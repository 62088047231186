

import { DocumentSnapshot, DocumentData, Timestamp } from "firebase/firestore";


export interface Neurona {
    id : string; 
    nombre: string;
    total : number;
    ultimoRegistro : Timestamp;         
}

export const aNeurona = (neuronas : number, nombre:string,fecha?:Timestamp) : Neurona => {
    return {
        total : neuronas,   
        nombre: nombre,
        ultimoRegistro : fecha ? fecha : Timestamp.now(),               
    } as Neurona;        
}

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toNeurona = (doc : FireBaseDocument) : Neurona => {
    const data = doc.data() as Neurona;
    return {   
        id : doc.id,     
        nombre : data.nombre,
        total : data.total,
        ultimoRegistro : data.ultimoRegistro,
    } as Neurona;
}