import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import HomePaginaWeb from './vista/PaginaWeb/HomePaginaWeb';
import LandingCurso from './vista/entrenamiento/LandingPage/LandingCurso';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFoundPage from './vista/NotFoundPage';
import RoutesWeb from './vista/PaginaWeb/RoutesWeb';
import LandingCompensar from './vista/entrenamiento/LandingPage/LandingCompensar';
import AsistenciaPage from './vista/entrenamiento/LandingPage/AsistenciaPage';
import PoliticasPrivacidad from './vista/usuario/componentes/PoliticasPrivacidad';
import LandingLibro from './vista/entrenamiento/LandingPage/LandingLibro';
import JuegoCodigodePalabras from './vista/programas/cuestionarios/JuegoCodigodePalabras';
import LandingCompra from './vista/entrenamiento/LandingPage/LandingCompra';
import JuegoFechas from './vista/programas/cuestionarios/JuegoFechas';
import LandingProducto from './vista/entrenamiento/LandingPage/LandingProducto';

function Redireccionar() {

  if (window.location.pathname.startsWith('/programas') || window.location.pathname.startsWith('/COMPENSAR60+VITAL')
    || window.location.pathname.startsWith('/landing') || window.location.pathname.startsWith('/comprar')) {
    // Si es el subdominio de la aplicación, muestra la PWA
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/programas/fechas" element={<div className='divAllView'>
              <JuegoFechas />
            </div>}/>
          <Route path="/programas/CodigoPalabras/:limit" element={<div className='divAllView'>
              <JuegoCodigodePalabras />
            </div>}/>
          <Route path='/COMPENSAR60+VITAL/PoliticasPrivacidad' element={<PoliticasPrivacidad/>}/>
          <Route path='/COMPENSAR60+VITAL' element={<AsistenciaPage/>}/>
          <Route path="/landing/:titleEntrenamiento/:codigoParams?" element={<LandingCurso/>}/>
          <Route path="/landingLibro/:codigoParams?" element={<LandingLibro/>}/>
          <Route path="/landingPage/:titleEntrenamiento/:codigoParams?" element={<LandingProducto/>}/>
          <Route path="/comprar/:idProducto/:codigoParams?" element={<LandingCompra/>}/>
          <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
      </BrowserRouter>
    );
  }else if (window.location.pathname.startsWith('/web')) {
    // Si es el subdominio de la aplicación, muestra la PWA
    return <RoutesWeb />;
  } else {
    // Para cualquier otro caso, muestra la página estática
    return <BrowserRouter><App /></BrowserRouter>;
  }
}


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Redireccionar />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
