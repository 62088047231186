import React, { useEffect, useState } from 'react'
import QRCode, {} from 'react-qr-code'

// Importacion de css
import estiloJuegos from "../../../theme/Juegos.module.css";
import style from '../../../theme/programas/simultaneo/simultaneo.module.css'
import { nomProgMemNombres, nomProgMemNumeros, nomProgMemPalabras, nomProgParejas, nomProgSumas1, nomProgSuperCerebrosDice } from '../../../datos/nombresBD';
import { useSala } from '../../../hooks/controlador/useSala';
import { useDato } from '../../../auth';
import { useAppDispatch, useAppSelector } from '../../../store';
import { changePrograma, newActivity } from '../../../hooks/reducer/reducerActividad';
import { addCantidadIntegrantes, cleanJuego, clear } from '../../../hooks/reducer/reducerJuegoSimultaneo';
import Loading from '../../entrenamiento/componentes/Loading';
import { Ranking, toSala } from '../../../modelos/Sala';
import { useBaseDeDatos } from '../../../hooks/baseDeDatos/useBaseDeDatos';
import BotonBordeDegradado from '../../componentes/BotonBordeDegradado';
import DosBotonesColumna from '../../componentes/DosBotonesColumna';

interface Props {
  setIdSala: (string) => void;
  reingresar: string;
  reboot: boolean;
  setReboot:React.Dispatch<React.SetStateAction<boolean>>;
}

const HostComponent = ({setIdSala, reingresar,reboot,setReboot} : Props) => {
  const {
    crearSala,
    subscribirDoc,
    subscribirColeccion,
    desubscribir,
    iniciarJuego,
    iniciarSiguienteJuego,
    cerrarSalaDoc,
    cerrarSalaColeccion,
    RecogerSala,
    GuardarTorneo,
    BorrarResultados,
  } = useSala();

  const { recogerDoc } = useBaseDeDatos();

  const {
    state
  } = useDato();

  // let dominio = 'https://app.supercerebros.com';
  // let dominio = 'https://supercerebros-desarrollo.web.app';
  let dominio = '';


  const dispatch = useAppDispatch();
  const cantInteg = useAppSelector((state) => state.juegoSimultaneo.cantidadIntegrates)

  const [etapa, setEtapa] = useState(1);
  const [sala, setSala] = useState('');
  const [nombreSala, setNombreSala] = useState('');
  const [data, setData] = useState(null);
  const [rankingList, setRankingList] = useState<Ranking[]>([]);
  const [loading, setLoading] = useState(false);
  const [listening, setListening] = useState<any>(null);
  const [ranking, setRanking] = useState<any>(null);
  const [cantidadIntegrantes, setCantidadIntegrantes] = useState(0);
  const [juegos, setJuegos] = useState<string[]>([]);
  const [iniciar, setIniciar] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isClickeable, setIsClickeable] = useState(true);
  
  const HandleCrearSala = async() => {
    setLoading(true)
    setNombreSala('')
    if(juegos.length !== 0){
      setLoading(true)
      const resp = await crearSala(juegos)
      console.log("🚀 ~ HandleCrearSala ~ resp:", resp)
      setSala(resp)
      setIdSala(resp)
      subscribirDoc('juegos_linea/' + resp + '/juegoIntegrantes/' + `${resp}Integrantes`,setData,setListening)
      setEtapa(2)
      setLoading(false)
      subscribirEnResultados(resp)
    }else{
      console.log("Agregue un juego minimo.")
    }
    setLoading(false)
  }

  const HandleReingresarSala = async () => {
    setLoading(true)
    setEtapa(1)
    console.log("🚀 ~ HandleReingresarSala ~ HandleReingresarSala:", HandleReingresarSala)
    if(sala && sala.length < 1){
      alert("Ingrese el codigo de la sala");
      setLoading(false);
      return;
    }
    console.log("🚀 ~ HandleReingresarSala ~ sala:", reingresar)
    let salaRemoto = await RecogerSala(reingresar);

    if(!salaRemoto){
      alert("La Sala no existe");
      setLoading(false);
      return;
    }

    setJuegos(salaRemoto.juego);

    let newData = toSala(await recogerDoc('juegos_linea/' + reingresar + '/juegoIntegrantes/' + `${reingresar}Integrantes`))

    setData(newData);
    setIniciar(salaRemoto.iniciar);
    
    if(salaRemoto.iniciar > 0){

      console.log('newData',newData)
      dispatch(addCantidadIntegrantes({cantidadIntegrates: newData?.integrantes.length,sala: reingresar}))

      setEtapa(3);
    }
    else{
      subscribirDoc('juegos_linea/' + reingresar + '/juegoIntegrantes/' + `${reingresar}Integrantes`,setData,setListening);
      setEtapa(2);
    }
    subscribirEnResultados(reingresar);
    setLoading(false);
  }

  const HandleAgregarJuego = (nomJuego:string) => {
    // Creamos una copia del array actual
    const copy = [...juegos];
    // Verificamos si el nuevo juego ya existe en el array
    const index = copy.indexOf(nomJuego);
    if (index === -1) {
        // Si no existe, lo agregamos al array
        copy.push(nomJuego);
    } else {
        // Si existe, lo eliminamos del array
        copy.splice(index, 1);
    }
    // Actualizamos el estado del array
    console.log(copy);
    setJuegos(copy);
  }

  const HandleIniciarJuego = async(sala:string) => {
    setLoading(true)
    if(data?.integrantes.length >= 1){
      if(juegos.length >= iniciar+1){
        dispatch(addCantidadIntegrantes({cantidadIntegrates: data?.integrantes.length,sala: sala}))
        console.log("🚀 ~ HandleIniciarJuego  setCantidadIntegrantes ~ 0:", 0)
        setCantidadIntegrantes(0)
        console.log("🚀 ~ desubscribir HandleIniciarJuego ~ listening: handle inciiar juego", listening)
        desubscribir(listening,setListening,setData)
        await iniciarJuego(sala,data)
        setIniciar((prev) => prev+1);
        setEtapa(3)
      }else{
        alert('No hay mas juegos')
      }
    }else{
      alert('Necesitas al menos un integrantes')
    }
    setLoading(false)
  }

  const HandleSiguienteJuegoIndex = async () => { 
    setIniciar((prev) => prev + 1 );
    setIsClickeable(true)
    setEtapa(3)
  }

  const subscribirEnResultados = async(resp:string) =>{
    subscribirColeccion('juegos_linea/' + `${resp}Resultados/`+`Ranking`,setRankingList,setRanking,true)
  }

  const HandleGuardarSala = (sala:string) => {
    if(nombreSala !== ''){
      console.log("🚀 ~ HandleGuardarSala ~ nombreSala:", nombreSala)
      GuardarTorneo(sala,nombreSala)
      HandleCerrarSala(sala)
      setIsClickeable(true)
    }
  }

  const HandleBorrarRanking = (sala:string) => {
    BorrarResultados(sala)
    HandleCerrarSala(sala)
  }

  const HandleMostrarPosiciones = (sala:string) => {
    cerrarSalaDoc(sala)
    desubscribir(listening,setListening,setData)
    setData(null)
    setLoading(false)
  }
  
  const HandleCerrarSala = (sala:string) => {
    dispatch(cleanJuego())
    setJuegos([])
    console.log("🚀 ~ desubscribir HandleCerrarSala ~ listening:", listening)
    desubscribir(ranking,setRanking,setRankingList)
    cerrarSalaColeccion(sala)
    setIsClickeable(true)
    console.log("🚀 ~ desubscribir HandleCerrarSala ~ listening:", ranking)
    setLoading(false)
    setEtapa(1)
  }

  const HandlerFinEscucha = () => {
    if(juegos.length - iniciar === 0){
      console.log('Finaliza la escucha del ranking.')
      ranking()
    }
    console.log("🚀 ~ HandlerFinEscucha setCantidadIntegrantes ~ 0:", 0)
    setCantidadIntegrantes(0)
    setEtapa(4)
  }

  const HandleSiguienteJuego = () =>{
    const server = iniciarSiguienteJuego(sala)
    setIsClickeable(false)
  }

  const GetClaseToolbar = () => {
    switch (juegos[iniciar - 1]){
      case nomProgMemNombres:
        return estiloJuegos.toolbarMemoriaNombres
        
      case nomProgMemPalabras:
        return estiloJuegos.toolbarMemoriaPalabras
        
      case nomProgParejas:
        return estiloJuegos.toolbarParejas

      case nomProgSuperCerebrosDice:
        return estiloJuegos.toolbarSCDice
          
      default:
        return estiloJuegos.toolbarCalculoMatematico
    }
  }

  const GetClaseContenedor = () => {
    switch (juegos[iniciar - 1]){
      case nomProgMemNombres:
        return estiloJuegos.contenedorIconoNombres
        
      case nomProgMemPalabras:
        return estiloJuegos.contenedorIconoPalabras
        
      case nomProgParejas:
        return estiloJuegos.contenedorIconoParejas

      case nomProgSuperCerebrosDice:
        return estiloJuegos.contenedorIconoSC
          
      default:
        return estiloJuegos.contenedorIconoMatematico
    }
  }

  const GetIcono = () => {
    console.log("🚀 ~ GetIcono ~ juegos[iniciar - 1]:", juegos[iniciar - 1])
    console.log("🚀 ~ GetIcono ~ iniciar:", iniciar)
    console.log("🚀 ~ GetIcono ~ juegos:", juegos)

    switch (juegos[iniciar - 1]){
      case nomProgMemNombres:
        return 'Rostro'
        
      case nomProgMemPalabras:
        return 'ABC'
        
      case nomProgParejas:
        return 'Parejas'

      case nomProgSuperCerebrosDice:
        return 'SC'
          
      default:
        return 'CÁLCULO MATEMÁTICO'
    }
  }

  const ContadorRankingActualJuego = (ranking:Ranking[],indice:number) => {
    return ranking?.filter((item) => item.indiceJuego === indice).length;
  }

  useEffect(() => {
    console.log("🚀 ~ useEffectRank ~ rankingList:", rankingList)
    console.log("🚀 ~ useEffectRank ~ etapa:", etapa)
    if(etapa === 3 && rankingList?.length !== 0){
      let cantidadReportados = ContadorRankingActualJuego(rankingList,iniciar)
      console.log("🚀 ~ useEffectRank ~ cantidadReportados:", cantidadReportados)
      if(cantidadReportados >= 0){
        console.log("🚀 ~ useEffectRank rankingList ~ setCantidadIntegrantes:", cantidadReportados)
        setCantidadIntegrantes(cantidadReportados)
      }
    }else{
      console.log("🚀 ~ useEffectRank rankingList ~ setCantidadIntegrantes 2:", 0)
      setCantidadIntegrantes(0)
    }
  }, [rankingList])

  useEffect(() => {
    console.log("🚀 ~ HostComponent ~ cantidadIntegrantes:", cantidadIntegrantes)
  }, [cantidadIntegrantes])
  

  useEffect(() => {
    if(etapa === 1 && reingresar !== '' ){
      setSala(reingresar)
      HandleReingresarSala()
    }

    return () => {
      console.log("🚀 ~ desubscribir return ~ listening: en useEffect", listening)
      console.log("🚀 ~ desubscribir return ~ listening: en useEffect", iniciar)
      console.log("🚀 ~ desubscribir return ~ listening: en useEffect", juegos[iniciar-1])
      desubscribir(listening,setListening,setData)
      console.log("🚀 ~ desubscribir return ~ ranking: en useEffect", ranking)
      desubscribir(ranking,setRanking,setRankingList)
    }
  }, [])
  
  const HandleReboot = () => {
    desubscribir(listening,setListening,setData)
    desubscribir(ranking,setRanking,setRankingList)
    HandleReingresarSala()
  }

  useEffect(() => {
    if(reboot){
      HandleReboot()
      setReboot(false)
    }
  }, [reboot])
  
  return (
    <> 
      <Loading isOpen={loading}/>
      {etapa === 1 && <>
        <div className={style.juegoGrid}>
          <button className={`${style.cardSumas} ${!juegos.includes(nomProgSumas1) && style.desactive}`} 
          onClick={() => HandleAgregarJuego(nomProgSumas1)}>
            <div className={style.juegoGrid_circle}
            style={juegos.includes(nomProgSumas1)?{background:'var(--color-Pink-Light)'}:{background:'var(--color-Medium-Grey)'}}></div>
            Sumas
          </button>
          <button className={`${style.cardNombres} ${!juegos.includes(nomProgMemNombres) && style.desactive}`} 
          onClick={() => HandleAgregarJuego(nomProgMemNombres)}> 
            <div className={style.juegoGrid_circle}
            style={juegos.includes(nomProgMemNombres)?{background:'var(--color-green)'}:{background:'var(--color-Medium-Grey)'}}></div>
            Memoria Nombres
          </button>
          <button className={`${style.cardPalabras} ${!juegos.includes(nomProgMemPalabras) && style.desactive}`} 
          onClick={() => HandleAgregarJuego(nomProgMemPalabras)}> 
            <div className={style.juegoGrid_circle}
            style={juegos.includes(nomProgMemPalabras)?{background:'var(--color-light-blue)'}:{background:'var(--color-Medium-Grey)'}}></div>
            Memoria Palabras
          </button>
          <button className={`${style.cardParejas} ${!juegos.includes(nomProgParejas) && style.desactive}`} 
          onClick={() => HandleAgregarJuego(nomProgParejas)}> 
            <div className={style.juegoGrid_circle}
            style={juegos.includes(nomProgParejas)?{background:'var(--color-light-blue)'}:{background:'var(--color-Medium-Grey)'}}></div>
            Parejas
          </button>
          <button className={`${style.cardSimon} ${!juegos.includes(nomProgSuperCerebrosDice) && style.desactive}`} 
          onClick={() => HandleAgregarJuego(nomProgSuperCerebrosDice)}> 
            <div className={style.juegoGrid_circle}
            style={juegos.includes(nomProgSuperCerebrosDice)?{background:'var(--color-blue-purple)'}:{background:'var(--color-Medium-Grey)'}}></div>
            SuperCerebros Dice
          </button>
        </div>
        <button id='button_rosado_default' onClick={HandleCrearSala} style={{width:'90%'}}>EMPEZAR</button>
      </>}
      
      {etapa === 2  && <>
        <div className={style.qrBox}>
          <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={`${dominio}/torneo/${sala}`}
          viewBox={`0 0 256 256`}
          />
        </div>
        <h2 className={style.sala}>SALA: <a href={`${dominio}/torneo/${sala}`}>{sala}</a></h2>
        <h2 className={style.sala} style={{fontSize:'16px'}}>
          <a href={`${dominio}/torneo/${sala}`} style={{color:'inherit',fontWeight:'700'}}>{`${dominio}/torneo/${sala}`}</a>
          </h2>
        <div className={style.jugadores}>
          <h1>Jugadores
            <p>{data !== null ? data?.integrantes.length : 0}</p>

          </h1>
        </div>
        <button id='button_rosado_default' style={{width:'90%',margin:'2svh'}} onClick={() => HandleIniciarJuego(sala)}>EMPEZAR</button>
      </>}
      
      {data?.iniciar && <h3>INICIA EL JUEGO {data?.iniciar}</h3>}

      {etapa === 3 &&
        <>
          <div className={GetClaseToolbar()} style={{width:'100%'}}>
            <h1 className={`title ${estiloJuegos.tituloJuegos}`}>{juegos[iniciar-1]}</h1>
          </div>
          <div className={GetClaseContenedor()}>
            <img src={`/assets/componentes/Juegos/${GetIcono()}.svg`} className={estiloJuegos.icono}></img>
          </div>
          <div id='content_default' style={{justifyContent:'center'}}>
            <div className={style.jugadores}>
              <h1>jugadores</h1>
              <p>{cantidadIntegrantes}/{cantInteg}</p>
            </div>
            <button id='button_rosado_default' style={{width:'70%'}} className={isClickeable === true ? '': style.button_disable} 
            onClick={() => {isClickeable === true && HandleSiguienteJuego()}}>
              Empezar
            </button>
            <button id='button_rosado_default' style={{width:'70%'}}  
            className={style.button_able}
            onClick={() => {((juegos.length - iniciar !== 0) ? HandlerFinEscucha(): setOpenConfirmation(true))}}>
              {juegos.length - iniciar !== 0 ? 'Ver Resultados' : 'Finalizar'}
            </button>
          </div>
        </>
      }

      {etapa === 4 &&
        <>
          {juegos.length - iniciar === 0 && 
          <>
            <h1 className={style.titleRanking}>Ranking</h1>
            <div className={style.lastRank}>
              <div className={style.second}>
                <p>{rankingList[1]?.nombre}</p>
                <p>{rankingList[1]?.total.toFixed(0)}</p>
                <div></div>
              </div>
              <div className={style.first}>
                <p>{rankingList[0]?.nombre}</p>
                <p>{rankingList[0]?.total.toFixed(0)}</p>
                <div></div>
              </div>
              <div className={style.third}>
                <p>{rankingList[2]?.nombre}</p>
                <p>{rankingList[2]?.total.toFixed(0)}</p>
                <div></div>
              </div>
            </div>
          </>}
          <div className={`${style.containerRank} ${juegos.length-iniciar === 0 ?style.final:''}`} style={juegos.length - iniciar === 0?{paddingTop:'0'}:{}}>
            {rankingList.map((element,index) => (
              <div>
                <p>{index+1}</p>
                <p>{element.nombre}</p>
                <p>{element.total}</p>
              </div>
            ))}
          </div>
          { iniciar < juegos.length ?
            <button id='button_rosado_default' style={{margin:'2svh'}} onClick={() => HandleSiguienteJuegoIndex()}>SIGUIENTE</button>:
            <>
              <button id='button_rosado_default' style={{margin:'2svh'}} onClick={() => HandleMostrarPosiciones(sala)}>Mostrar Posiciones</button>
              <button id='button_rosado_default' style={{margin:'2svh'}} onClick={() => setOpenConfirmation(true)}>Cerrar Sala</button>
            </>
          }
        </>
      }

      {etapa === 5 &&
        <div className={style.nombrar}>
          <label className={style.nombrar}>NOMBRA ESTE TORNEO</label>
          <input className={style.nombrar} value={nombreSala} onChange={(e) => setNombreSala(e.target.value)}></input>
          <button id="button_rosado_default" className={style.nombrar} onClick={() => HandleGuardarSala(sala)}>GUARDAR</button>
          <div className={style.nombrar}>O</div>
          <button className={style.blanco} onClick={() => HandleBorrarRanking(sala)}>ELIMINAR DATOS</button>
        </div>
      }

      {openConfirmation && <div id="modal_cortina_default">
          <div id="modal_default" style={{zIndex:'5', height:'fit-content', padding:'5%'}} onClick={() => setOpenConfirmation(false)}>
            <div className={style.divConfirmation}>
              <h1>¿ESTÁS SEGURO?</h1>
              <DosBotonesColumna textoSuperior={'continuar'} textoInferior='volver'
                onClickSuperior={etapa===3?HandlerFinEscucha:(()=>setEtapa(5))} onClickInferior={() => setOpenConfirmation(false)}/>
            </div>
          </div>
      </div>}
    </>
  )
}

export default HostComponent
