
import { Link } from 'react-router-dom';
import style from '../../theme/web/HomePaginaWeb.module.css'
import FooterWebPage from './componentes/FooterWebPage';
import NavbarWeb from './componentes/NavbarWeb';
import { useRef } from 'react';

const HomePaginaWeb: React.FC = () => {

	const footPage = useRef(null);

	return (
		<div className={style.page}>
			<Banner/>
			<NavbarWeb footPage={footPage}/>
			<Presentacion/>
			<TransicionMoradoBlanco/>
			<QuienesSomos/>
			<TransicionBlancoMorado/>
			<Servicios/>
			<TransicionMoradoBlanco/>
			<Tienda/>
			<TransicionBlancoMorado/>
			<ClientesSatisfechos/>
			<hr className={style.separator} style={{backgroundColor: 'white'}}/>
			<Testimonios/>
			<hr className={style.separator} style={{backgroundColor: 'white'}} ref={footPage}/>
			<FooterWebPage/>
		</div>
	);
}

const Banner = () => {
	return(
		<div className={style.banner}>
			<p>SUPERCEREBROS</p>
			<img src='/assets/images/web/bannerWeb.png' alt='banner'/>
			<div className={style.logo}>
				<img src='/assets/images/Logo-blanco-fondo.png' alt='logo'/>
			</div>
		</div>
	);
}

const TransicionMoradoBlanco = () => {
	return(
		<svg className={style.transicion} width="1920" height="155" viewBox="0 0 1920 155"
			fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio='xMinYMin'>
			<rect width="1920" height="155" fill="white"/>
			<path d="M1920 0H0V71C87.6667 99 322.5 155 564.5 155C867 155 1128.6 46.1639 1383 15C1583 -9.5 1786.5 18 1920 71V0Z" fill="#45225F"/>
		</svg>
	);
}
const TransicionBlancoMorado = () => {
	return(
		<svg  className={style.transicion} width="1920" height="155" viewBox="0 0 1920 155" fill="none"
			xmlns="http://www.w3.org/2000/svg" preserveAspectRatio='xMinYMin'>
			<rect width="1920" height="155" fill="#45225F"/>
			<path d="M1920 0H0V71C87.6667 99 322.5 155 564.5 155C867 155 1128.6 46.1639 1383 15C1583 -9.5 1786.5 18 1920 71V0Z" fill="white"/>
		</svg>
	);
}

const Presentacion = () => {
	return(
		<>
		<div className={style.presentacion}>
			<img src='/assets/images/web/presentacion-default.png' alt='presentacion'/>
			<div className={style.presentacionInfo}>
				<p>Aplicación Movil</p>
				<p>SUPERCEREBROS</p>
				<p>
					Es una plataforma digital diseñada para mejorar la salud cerebral
					y la agilidad mental a través de juegos entretenidos y cursos educativos.
					La aplicación ofrece una variedad de herramientas diseñadas para todos, 
					desde estudiantes y profesionales hasta personas mayores interesados en mantener 
					su agudeza mental.
				</p>
				<a className={style.enlace} href='/my/home'>ABRIR APLICACION</a>
			</div>

		</div>
		</>
	);
}

const QuienesSomos = () => {

	return (
		<>
		<div className={style.quienesSomos}>
			<div className={style.quienesColumna + " " + style.quienesIzquierda}>
				<p className={style.quienesNombre}>Juan Pablo Duque</p>
				<p className={style.quienesDescripcion}>
					Es un Mente-atleta colombiano, conferencista internacional y experto en
					neuro-entrenamiento. Fue semifinalista del programa SUPERCEREBROS de NatGeo,
					seleccionado entre más de 2000 participantes, e integrante de la selección 
					Colombia de Memoria y Cálculo Mental, logrando la posición 11 en el mundial 
					de Las Vegas 2016. Instructor latinoamericano de Mapas Mentales, En el año 2016
					rompio el récord sudamericano de memoria en la modalidad número PI con 1,111 aproximaciones.
					Con más de 7 años de experiencia, ha participado en eventos académicos y profesionales en Colombia 
					y Latinoamérica, destacándose en aprendizaje, memoria y comunicación.
				</p>
				<img src='/assets/images/web/quienesJuanPablo.png' alt='Juan Pablo Duque'></img>
			</div>
			<div className={style.quienesColumna}>
				<h1>?Quienes somos¿</h1>
				<img src='/assets/images/web/quienesDavidCantor.png'></img>
				<p className={style.quienesNombre}>David Cantor</p>
				<p className={style.quienesDescripcion}>
					Es un emprendedor colombiano especializado en Entrenamiento Mental que busca mejorar procesos
					de atención, concentración, memoria, lectura rápida y gimnasia cerebral. Cofundador de SuperCerebros,
					es el entrenador mental más joven de Sudamérica. Integra la selección Colombia de Memoria y Cálculo
					Mental, logrando la posición #12 en el ranking internacional en el mundial de Las Vegas, EE.UU.
					Además, es un conferencista reconocido mundialmente en Neuroentrenamiento, Psicoestimulación,
					Motivación juvenil y Esfuerzo sostenido. 
				</p>
			</div>
		</div>
		</>
	);
}

const Servicios = () => {
	return (
		<>
		<div className={style.servicios}>
			<div className={style.serviciosTitulo}> 
				<svg width="160" height="120" viewBox="0 0 119 79" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio='xMinYMin'>
					<circle cx="83.4302" cy="44.0002" r="35" transform="rotate(-69.6067 83.4302 44.0002)" fill="#D74286"/>
					<circle cx="21.5669" cy="21.0016" r="21" transform="rotate(-69.6067 21.5669 21.0016)" fill="#D74286"/>
					<circle cx="29.0546" cy="61.1256" r="10" transform="rotate(-69.6067 29.0546 61.1256)" fill="#D74286"/>
				</svg>
				<p>SERVICIOS</p>
				<svg style={{transform: "rotate(270deg)", alignSelf: "end"}} preserveAspectRatio='xMinYMin'
					width="160" height="120" viewBox="0 0 119 79" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="83.4302" cy="44.0002" r="35" transform="rotate(-69.6067 83.4302 44.0002)" fill="#D74286"/>
					<circle cx="21.5669" cy="21.0016" r="21" transform="rotate(-69.6067 21.5669 21.0016)" fill="#D74286"/>
					<circle cx="29.0546" cy="61.1256" r="10" transform="rotate(-69.6067 29.0546 61.1256)" fill="#D74286"/>
				</svg>
			</div>
			<div className={style.serviciosInfo}>

				<img src='/assets/images/web/servicioShow.svg' alt="logo Show" className={style.serviciosLogo}/>
				<p className={style.serviciosNombre}>Show</p>
				<p className={style.serviciosDescripcion}>
					El "Show de Juegos y Desafíos Mentales" fusiona entretenimiento y aprendizaje para potenciar las
					habilidades cognitivas del equipo. Comienza en el "Gimnasio Mental", con estaciones interactivas que
					estimulan diferentes regiones de nuestro cerebro, seguido de un espectáculo de 90 minutos diseñado 
					para desafiar y enriquecer la capacidad mental de los participantes.
				</p>
				<Link className={style.enlace + " " + style.serviciosEnlace} to='/web/servicios/show'>MÁS INFORMACIÓN</Link>

				<img src='/assets/images/web/servicioConferencia.svg' alt="logo Conferencia" className={style.serviciosLogo}/>
				<p className={style.serviciosNombre}>Conferencia</p>
				<p className={style.serviciosDescripcion}>
					La conferencia "Descubre tu Potencial" potencia las capacidades mentales individuales mediante el 
					entrenamiento mental. Motiva a los asistentes a descubrir su supercerebro y supermemoria, ofreciendo 
					ejercicios prácticos para mejorar atención, concentración, memoria, creatividad e imaginación. 
					Proporciona estrategias aplicables tanto en la parte personal, académica y profesional.
				</p>
				<Link className={style.enlace + " " + style.serviciosEnlace} to='/web/servicios/conferencias'>MÁS INFORMACIÓN</Link>

				<img src='/assets/images/web/servicioTalleres.svg' alt="logo Neuro-talleres" className={style.serviciosLogo}/>
				<p className={style.serviciosNombre}>Neuro-talleres</p>
				<p className={style.serviciosDescripcion}>
					Hemos diseñado una serie de Neurotalleres exclusivos que buscan fomentar el desarrollo profesional y
					personal de sus equipos. A través de técnicas avanzadas de neuro-entrenamiento, nuestros talleres están
					diseñados para maximizar el rendimiento cognitivo, mejorar habilidades específicas y promover una cultura 
					de mejora continua en el ambiente laboral.
				</p>
				<Link className={style.enlace + " " + style.serviciosEnlace} to='/web/servicios/taller'>MÁS INFORMACIÓN</Link>

			</div>
		</div>
		</>
	);
}

const Tienda = () => {
	return (
	<div className={style.tienda}>
		<p className={style.tiendaTitulo}>TIENDA ONLINE</p>
		<p className={style.tiendaDescripcion}>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit.
			Sed lorem ante, feugiat at placerat vel, vehicula at odio.
			Nullam non enim at nibh efficitur aliquet id malesuada orci.
			Aliquam augue ante, pulvinar eu justo ac, tempor laoreet augue. 
		</p>
		<Link to="/web/tienda" className={style.enlace}>VER MAS</Link>
		<div className={style.tiendaElementos}>

			<img src='/assets/images/web/cursoNutricion.svg' alt='curso super nutricion'/>
			<div className={style.cursoDescripcion} style={{backgroundImage: "url('/assets/images/web/cursoNutricionBackground.png')"}}>
				<h2>SUPER NUTRICIÓN</h2>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Sed lorem ante, feugiat at placerat vel, vehicula
				</p>
			</div>
			<a className={style.enlace}>ADQUIRIR</a>

			<img src='/assets/images/web/cursoNeurobics.svg' alt='curso neurobics'/>
			<div className={style.cursoDescripcion} style={{backgroundImage: "url('/assets/images/web/cursoNeurobicsBackground.png')"}}>
				<h2>NEUROBICS</h2>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Sed lorem ante, feugiat at placerat vel, vehicula
				</p>
			</div>
			<a className={style.enlace}>ADQUIRIR</a>

			<img src='/assets/images/web/cursoEntrenamiento.svg' alt='curso entrena tu super cerebro'/>
			<div className={style.cursoDescripcion} style={{backgroundImage: "url('/assets/images/web/cursoEntrenamientoBackground.png')"}}>
				<h2>ENTRENA TU SUPER CEREBRO</h2>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Sed lorem ante, feugiat at placerat vel, vehicula
				</p>
			</div>
			<a className={style.enlace}>ADQUIRIR</a>

		</div>
		<hr className={style.separator} style={{backgroundColor: "var(--color-purple-dark)"}}/>
		<div className={style.tiendaElementos}>

			<img src='/assets/images/web/libroFisico.png' alt='libro fisico supercerebros'/>
			<div className={style.productoDescripcion} style={{backgroundImage: "url('/assets/images/web/productosBackground.png')"}}>
				<h2>LIBRO FÍSICO SUPERCEREBROS</h2>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Sed lorem ante, feugiat at placerat vel, vehicula
				</p>
			</div>
			<a className={style.enlace}>ADQUIRIR</a>

			<img src='/assets/images/web/rompecabezasCubo.png' alt='rompecabezas cubo'/>
			<div className={style.productoDescripcion} style={{backgroundImage: "url('/assets/images/web/productosBackground.png')"}}>
				<h2>CUBO RUBIK</h2>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Sed lorem ante, feugiat at placerat vel, vehicula
				</p>
			</div>
			<a className={style.enlace}>ADQUIRIR</a>

			<img src='/assets/images/web/simonDice.png' alt='simon dice'/>
			<div className={style.productoDescripcion} style={{backgroundImage: "url('/assets/images/web/productosBackground.png')"}}>
				<h2>SIMON SAYS GAME</h2>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Sed lorem ante, feugiat at placerat vel, vehicula
				</p>
			</div>
			<a className={style.enlace}>ADQUIRIR</a>

		</div>
	</div>
	);
}

const ClientesSatisfechos = () => {
	return (
		<>
		<h1>CLIENTES SATISFECHOS</h1>
		<div className={style.clientes}>
			<img src='/assets/images/web/teleMedellin.png' alt='tele medellin'/>
			<img src='/assets/images/web/IDRD.png' alt='instituto distrital de recreacion y deporte'/>
			<img src='/assets/images/web/DIAN.png' alt='DIAN'/>
			<img src='/assets/images/web/TED.png' alt='TED'/>
			<img src='/assets/images/web/planetario.png' alt='planetario de bogota'/>
		</div>
		</>
	);
}

const Testimonios = () => {
	return (
		<>
		<h1>TESTIMONIOS</h1>
		<div className={style.testimonios}>
			<img src='/assets/images/web/Testimonios.png' alt='testimonios'/>
		</div>
		</>
	);
}

const Entrevistas = () => {
	return (
		<>
		<h1>ENTREVISTAS</h1>
		</>
	);
}

export default HomePaginaWeb;