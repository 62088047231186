import style from "../../theme/web/Tienda.module.css";
import styleHome from "../../theme/web/HomePaginaWeb.module.css";
import NavbarWeb from "./componentes/NavbarWeb";
import FooterWebPage from "./componentes/FooterWebPage";
import { useRef } from "react";

const TiendaMobile = () => {
    const footPage = useRef(null)
    return(
        <div className={style.page}>
            <NavbarWeb footPage={footPage}/>
            <img src='/assets/componentes/web/CurvaMorada.png' style={{transform:'rotate(180deg)',width:'153%',marginTop:'-2px'}}></img>
            <Presentacion/>
            <hr className={styleHome.separator} style={{backgroundColor: "var(--color-purple-dark)"}}/>
            <Productos/>
            <hr className={styleHome.separator} style={{backgroundColor: "var(--color-purple-dark)"}}/>
            <Eventos/>
            <img src='/assets/componentes/web/CurvaMorada.png' style={{width:'100%',marginBottom:'-2px'}} ref={footPage}></img>
            <FooterWebPage/>
        </div>
    );
}

const Presentacion = () => {
    return(
        <div className={style.presentacion}>
            <h1>CURSOS</h1>
            <h2>APP<br/>SUPERCEREBROS</h2>
            <img src='/assets/images/web/appMovil.png' alt='app movil'/>
            <a className={styleHome.enlace} href='/my/home'>ABRIR APLICACION</a>
        </div>
    );
}

interface ProductoProp{
    img:string;
    alt:string;
    backgroundImg:string;
    titulo:string;
    descripcion:string;
}
const MostrarProductos = ({productos, textColor = "color: var(--color-Medium-Grey)"}:{productos: ProductoProp[], textColor?: string}) => {
    return(
        <>
        {
            productos.map((v, i) => 
                <div className={style.producto} key={i}>
                    <img src={v.img} alt={v.alt}/>
                    <div className={styleHome.productoDescripcion} style={{backgroundImage: v.backgroundImg, color: textColor}}>
                        <h2>{v.titulo}</h2>
                    </div>
                    <a className={styleHome.enlace}>ADQUIRIR</a>
                </div>
            )
        }
        </>
    );
}

const Productos = () => {
    const cursosInfo: ProductoProp[] = [
        {
            img:'/assets/images/web/cursoNutricion.svg',
            alt:"curso super nutricion",
            backgroundImg: "url('/assets/images/web/cursoNutricionBackground.png')",
            titulo: "SUPER NUTRICIÓN",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoNeurobics.svg',
            alt:"curso neurobics",
            backgroundImg: "url('/assets/images/web/cursoNeurobicsBackground.png')",
            titulo: "NEUROBICS",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoEntrenamiento.svg',
            alt:"curso entrena tu super cerebro",
            backgroundImg: "url('/assets/images/web/cursoEntrenamientoBackground.png')",
            titulo: "ENTRENA TU SUPER CEREBRO",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoIngles.svg',
            alt:"curso super ingles",
            backgroundImg: "url('/assets/images/web/cursoInglesBackground.png')",
            titulo: "SUPER INGLÉS",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/cursoRubik.svg',
            alt:"curso super cubo rubik",
            backgroundImg: "url('/assets/images/web/cursoRubikBackground.png')",
            titulo: "SUPER CUBO RUBIK",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },
    ];

    const productosInfo: ProductoProp[] = [
        {
            img:'/assets/images/web/libroFisico.png',
            alt:'libro fisico supercerebros',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "LIBRO FÍSICO SUPERCEREBROS",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/rompecabezasCubo.png',
            alt:'rompecabezas cubo',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "CUBO RUBIK",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/simonDice.png',
            alt:'simon dice',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "SIMON SAYS GAME",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/eBook.png',
            alt:'eBook supercerebros',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "E-BOOK SUPERCEREBROS",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        },{
            img:'/assets/images/web/kitRegalo.png',
            alt:'kit regalo supercerebros',
            backgroundImg: "url('/assets/images/web/productosBackground.png')",
            titulo: "KIT REGALO SUPERCEREBROS",
            descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ante, feugiat at placerat vel, vehicula"
        }
    ];

    return(
        <>
        <h1>CURSOS</h1>
        <div className={style.tiendaProductos}>
            <MostrarProductos productos={cursosInfo} textColor="white"/>
        </div>
        <hr className={styleHome.separator} style={{backgroundColor: "var(--color-purple-dark)"}}/>
        <h1>PRODUCTOS</h1>
        <div className={style.tiendaProductos}>
            <MostrarProductos productos={productosInfo}/>
        </div>
        </>
    );
}

const Eventos = () => {
    return (
        <>
        <h1>EVENTOS</h1> 
        <div className={style.evento}>
            <img src="/assets/images/web/eventoEntrenaTuMente.png" alt="entrena tu mente dorada"></img>
            <div className={style.eventoInfo}>
                <h3>ENTRENA TU MENTE DORADA COMPENSAR +60 VITAL</h3>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Donec felis quam, placerat sed suscipit eget, luctus
                faucibus arcu. Nulla vulputate neque at nunc luctus, ac
                rutrum metus finibus. Quisque condimentum auctor lorem nec
                porta. Pellentesque finibus facilisis urna, eget bibendum
                diam cursus ac. Aliquam a mattis velit. Nam elit nibh,
                porttitor vel est non, feugiat scelerisque nisi. Maecenas
                urna odio, euismod quis arcu in, hendrerit luctus nisl.
                Fusce porttitor, mi semper vestibulum bibendum, sapien
                nibh lobortis mi, et aliquam risus dui eget eros.
                Curabitur sit amet nunc nibh. Nulla id lectus ac magna
                vestibulum viverra vitae quis libero.
                </p>
                <a className={styleHome.enlace}>INSCRIBIRSE</a>
            </div>
        </div>
        </>
    )
}

export default TiendaMobile;
