

//export const saltos = [10, 8, 6, 4, 3, 2, 1, 1]
export const saltos = [1, 1, 2, 3, 4, 6, 8, 10]


//Matriz con las diferentes variables del Game 1 =>
// 0 Nivel |
// 1 aciertos para cambio de nivel |
// 2 cantidad de números a sumar |
// 3 cantidad de digitos de cada número |
// 4 velocidad |
// 5 tiempo de respuesta en segundos |

// velocida = 1325*0.953^x+240
//cantidad numeros a sumar = (3/10)*nivel+2

export const niveles = [[],
	[1 , 1, 2 , 1, 1500, 10],
	[2 , 2, 2 , 1, 1400, 10],
	[3 , 2, 2 , 1, 1300, 10],
	[4 , 2, 3 , 1, 1200, 10],
	[5 , 3, 3 , 1, 1100, 10],
	[6 , 3, 3 , 1, 1000, 10],
	[7 , 3, 4 , 1, 1000, 10],
	[8 , 4, 4 , 1, 975 , 10],
	[9 , 4, 4 , 1, 950 , 10],
	[10, 4, 5 , 1, 925 , 10],
	[11, 5, 5 , 1, 900 , 10],
	[12, 5, 5 , 1, 875 , 10],
	[13, 5, 6 , 1, 850 , 10],
	[14, 5, 6 , 1, 825 , 10],
	[15, 5, 6 , 1, 800 , 10],
	[16, 5, 7 , 1, 775 , 10],
	[17, 5, 7 , 1, 750 , 10],
	[18, 5, 7 , 1, 725 , 10],
	[19, 5, 7 , 1, 700 , 10],
	[20, 5, 8 , 1, 690 , 10],
	[21, 5, 8 , 1, 680 , 10],
	[22, 5, 8 , 1, 670 , 10],
	[23, 5, 8 , 1, 660 , 10],
	[24, 5, 9 , 1, 650 , 10],
	[25, 5, 9 , 1, 640 , 10],
	[26, 5, 9 , 1, 630 , 10],
	[27, 5, 9 , 1, 620 , 10],
	[28, 5, 10, 1, 610 , 10],
	[29, 5, 10, 1, 600 , 10],
	[30, 5, 10, 1, 590 , 10],
	[31, 5, 10, 1, 580 , 10],
	[32, 5, 11, 1, 570 , 10],
	[33, 5, 11, 1, 560 , 10],
	[34, 5, 11, 1, 550 , 10],
	[35, 5, 11, 1, 540 , 10],
	[36, 5, 12, 1, 530 , 10],
	[37, 5, 12, 1, 520 , 10],
	[38, 5, 12, 1, 510 , 10],
	[39, 5, 12, 1, 500 , 10],
	[40, 5, 13, 1, 490 , 10],
	[41, 5, 13, 1, 480 , 10],
	[42, 5, 13, 1, 470 , 10],
	[43, 5, 13, 1, 460 , 10],
	[44, 5, 13, 1, 450 , 10],
	[45, 5, 14, 1, 440 , 10],
	[46, 5, 14, 1, 430 , 10],
	[47, 5, 14, 1, 420 , 10],
	[48, 5, 14, 1, 415 , 10],
	[49, 5, 15, 1, 410 , 10],
	[50, 5, 15, 1, 405 , 10],
	[51, 5, 15, 1, 400 , 10],
	[52, 5, 15, 1, 395 , 10],
	[53, 5, 15, 1, 390 , 10],
	[54, 5, 16, 1, 385 , 10],
	[55, 5, 16, 1, 380 , 10],
	[56, 5, 16, 1, 375 , 10],
	[57, 5, 16, 1, 370 , 10],
	[58, 5, 17, 1, 365 , 10],
	[59, 5, 17, 1, 360 , 10],
	[60, 5, 17, 1, 355 , 10],
	[61, 5, 18, 1, 350 , 10],
	[62, 5, 18, 1, 345 , 10],
	[63, 5, 18, 1, 340 , 10],
	[64, 5, 19, 1, 335 , 10],
	[65, 5, 19, 1, 330 , 10],
	[66, 5, 19, 1, 325 , 10],
	[67, 5, 20, 1, 320 , 10],
	[68, 5, 20, 1, 315 , 10],
	[69, 5, 20, 1, 310 , 10],
	[70, 5, 21, 1, 305 , 10],
	[71, 5, 21, 1, 300 , 10],
	[72, 5, 21, 1, 295 , 10],
	[73, 5, 22, 1, 290 , 10],
	[74, 5, 22, 1, 285 , 10],
	[75, 5, 22, 1, 280 , 10],
	[76, 5, 23, 1, 275 , 10],
	[77, 5, 23, 1, 270 , 10],
	[78, 5, 23, 1, 265 , 10],
	[79, 5, 24, 1, 260 , 10],
	[80, 5, 24, 1, 255 , 10],
	[81, 5, 24, 1, 250 , 10],
	[82, 5, 25, 1, 250 , 10],
	[83, 5, 25, 1, 250 , 10],
	[84, 5, 25, 1, 250 , 10],
	[85, 5, 26, 1, 250 , 10],
	[86, 5, 26, 1, 250 , 10],
	[87, 5, 26, 1, 250 , 10],
	[88, 5, 27, 1, 250 , 10],
	[89, 5, 27, 1, 250 , 10],
	[90, 5, 28, 1, 250 , 10],
	[91, 5, 29, 1, 250 , 10],
	[92, 5, 30, 1, 250 , 10],
	[93, 5, 31, 1, 250 , 10],
	[94, 5, 32, 1, 250 , 10],
	[95, 5, 33, 1, 250 , 10],
	[96, 5, 34, 1, 250 , 10],
	[97, 5, 35, 1, 250 , 10],
	[98, 5, 36, 1, 250 , 10],
	[99, 5, 37, 1, 250 , 10]
];

export const cambioDeNivel = (nroNivel, respuesta) => {
    const aciertosMaximo = niveles[nroNivel][1];
	if (respuesta + 1 >= aciertosMaximo) {
		return true;
	}
    return false;
}