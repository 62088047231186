
import { useState, useEffect } from 'react';

import style from "../../../theme/PopoverNeuronas.module.css";

import { useAppDispatch, useAppSelector } from '../../../store'; 
import BotonesJuegos from './BotonesJuegos';
import { obtenerMensaje } from '../../../utils/resultadoUtil';
import useNeurona from '../../../hooks/controlador/useNeurona';

interface PropNeuronas{
    nuevasNeuronas: number;
    mensajeInfo: string;
    nivel : number;
    funcionReiniciar?: () => void;
    funcionVolver?: () => void;
    esJuego?: boolean;
    esEntrenamiento?:boolean;
}
const PopoverNeuronas: React.FC<PropNeuronas> = (props) => {
    
    const nuevasNeuronas: number = props.nuevasNeuronas;
    const totalNeuronas = useAppSelector((state) => state.auth.neuronas);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const mensajeInformacion: string[] = props.mensajeInfo.split(props.nivel.toString())
    const cantidadCorrecta: number = props.nivel;
    const [mensajePersonalizado, setMensajePersonalizado] = useState("");
    
    const setMensajes = () => {
        let mensajePersonalizado = "";
        if (cantidadCorrecta > 5 ){
            mensajePersonalizado = obtenerMensaje(0.65);
            
        }else if (cantidadCorrecta > 0 ){
            mensajePersonalizado = obtenerMensaje(0.4);
        }
        else {
            mensajePersonalizado = obtenerMensaje(0);
        }
        setMensajePersonalizado(mensajePersonalizado);
        
    }

    const getMensajes = () => {
        if (cantidadCorrecta > 0) {
            return (
                <>
                    <p className={style.parrafoRespuestas}>
                        {mensajeInformacion[0]}
                        {props.esEntrenamiento !== true && <span className={style.respuestasCorrectas}> {cantidadCorrecta}</span>}
                        {mensajeInformacion[1]}
                    </p>
                    <p className = {style.neuronas}>Ganaste {nuevasNeuronas} neuronas</p>
                    <p className={style.totalNeuronas}> TOTAL: {totalNeuronas}</p>
                </>
            );
        } else {
            return (
              <></>
            );
        }
    }


    const closePopover = () => {
        setIsOpen(false);
    }; 
    useEffect(() => {
        setMensajes();
        return () => {
            closePopover();
          };
      }, []);

    return (
        <>
            {isOpen === true && 
            <div className={[style.contenedor].join(" ")} >
                <div className={style.contenido}>
                    <div className={style.circulo}>
                        <div className={style.bordesNeuronas}>
                            <div className={style.logoNeuronas} style={cantidadCorrecta === 0 ? {background:'var(--color-red)'}:{background:'var(--color-green)'}}>
                                {/* Aqui color variable */}
                                <img className={style.svgNeurona} src='/assets/icon/icon_neuronas.svg'></img>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className = {style.rectangulo}>
                        <h2 className = {`${style.mensajePersonalizado} ${cantidadCorrecta === 0 ? style.marginMensaje : ""}`}>{mensajePersonalizado}</h2>
                        {getMensajes()}
                        <BotonesJuegos funcionReiniciar={props.funcionReiniciar} funcionVolver={props.funcionVolver} esJuego={props.esJuego}/>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default PopoverNeuronas;

