// /** Externa */
// import {
//     IonPage,
//     IonContent,
//     IonHeader,
//     IonTitle,
//     IonToolbar,
//     IonButton,
//     IonLabel,
//     IonButtons,
//     IonRow, IonItem, IonCol, IonGrid, IonIcon, IonCard, IonBackButton, IonLoading
//   } from '@ionic/react';
  
  // import { addCircleOutline, saveOutline, happyOutline, tennisballOutline, peopleCircle, search } from 'ionicons/icons';
  
  /** Interna */
  import { useDato } from '../../auth';

import { useAppDispatch, useAppSelector } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { useTests } from '../../hooks/controlador/useTests';
import { useEffect, useState } from 'react';
import { Programa } from '../../modelos/Programa';
import Loading from '../Loading';
import { load } from '../../hooks/reducer/reducerAuth';
import { useJuegos } from '../../hooks/controlador/useJuegos';
import { changePrograma } from '../../hooks/reducer/reducerActividad';

type Params = {
  nombre:string
}
  
  /**
   * Pagina principal de test
   */
  const TestAdminPage: React.FC = () => {

    const {nombre} = useParams<Params>();
    const {
          state,
      } = useDato();


    const {
      consultarProgramasTest,
      consultarRutaPrograma,
      consultarRanking,
    } = useTests();

    const navigate = useNavigate();
    
    const {
      getCalificacion,
    } = useJuegos();

    const [programas, setProgramas] = useState<Programa[]>([])
    const [loading,setLoading] = useState(false);
    const userId = useAppSelector((state) => state.auth.userId);
    const testId = useAppSelector((state) => state.activity.id);
    const dispatch = useAppDispatch();
    
    const consulta = async () => {
      console.log("🚀 ~ file: TestAdminPage.tsx:60 ~ consulta ~ testId:", testId)
      setLoading(true)
      const respuesta = await consultarProgramasTest(testId);            
      console.log("🚀 ~ file: TestAdminPage.tsx:54 ~ userId:", userId)
      respuesta.map(async (element) => {
        element.ruta = `/my/ShowInfoTest/${element.nombre}`
        setProgramas((prevState) => [
          ...prevState,
        element
        ]);
      })
      console.log(programas);
      setLoading(false)
    }

    const handleReduxProgram = (id:string,ruta:string) => {
      console.log("🚀 ~ file: TestPage.tsx:85 ~ handleReduxProgram ~ id:", id)
      dispatch(changePrograma({id}));
      navigate(ruta)
    }

    useEffect(() => {
        setProgramas([])
        consulta();
    }, []);

  
    return (
      <></>
      // <IonPage>
      //   <IonLoading isOpen={loading}></IonLoading>
      //   <IonHeader>
      //     <IonToolbar>
      //       <IonButtons>
      //       </IonButtons>
      //       <h1 className="ion-text-center title">{nombre}</h1>
      //     </IonToolbar>
      //   </IonHeader>
      //   <IonContent className='ion-padding'>
  
      //     <IonGrid>

      //       {programas.map((element,index) =>{
      //         return(
      //           <IonRow key={index}>
      //             <IonCol>
      //               <IonCard style={{ width: '100%' }}>
      //                 <IonItem onClick={() => handleReduxProgram(element.id,element.ruta)}>
      //                   <IonIcon icon={tennisballOutline} slot="start" />
      //                   <IonLabel>{element.nombre}</IonLabel>
      //                 </IonItem>
      //               </IonCard>
      //             </IonCol>
      //           </IonRow>
      //         )
      //       })}
            
      //     </IonGrid>
      //   </IonContent>
      // </IonPage>
    );
  }
  
  export default TestAdminPage;
  