import { getFunctions, httpsCallable } from 'firebase/functions';

export interface CallMessageData {
  idTemplate?: string;      // Opcional
  telefono: string;         // Obligatorio
  variables?: object;       // Opcional
  realizarLlamada?: boolean; // Opcional
  urlAudio?: string;        // Opcional
}

export const CallSendCallMessage = async (data: CallMessageData) => {
  const functions = getFunctions();
  const sendCallMessage = httpsCallable(functions, 'SendCallMessageCallable');

  try {
    const response = await sendCallMessage({
      idTemplate: data.idTemplate || null,
      telefono: data.telefono,
      variables: data.variables || null,
      realizarLlamada: data.realizarLlamada || false,
      urlAudio: data.urlAudio || null
    });

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error calling sendCallMessage:', error);
    throw error;
  }
};