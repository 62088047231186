import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Pregunta } from '../../modelos/Respuesta';
interface cuestionario {
    preguntas: Pregunta[];
    valor?:number[];
}
const initialState: cuestionario = {
    preguntas: null
}

export const authSliceCuestionario = createSlice({
    name: 'cuestionario',
    initialState,
    reducers:{
        add: (state,action: PayloadAction<{cuestionario:Pregunta[],valores:number[]}>) => {
            console.log("🚀 ~ file: reducerCuestionario.ts:12 ~ action.payload.cuestionario :", action.payload.cuestionario )
            state.preguntas = action.payload.cuestionario            
            state.valor = action.payload.valores
        },
        clear: (state) =>{
            state = null
        }
    }
});

export const { add, clear} = authSliceCuestionario.actions;