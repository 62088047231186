import { DocumentSnapshot, DocumentData } from "firebase/firestore";


export interface Pregunta {
  respuestaCorrecta: number;
  respuestas: string[];
  textoPregunta: string;
}
  
export interface CuestionarioActividad {
  id?: string;
  titulo: string;
  descripcion: string;
  preguntas: Pregunta[];    
}

export const aCuestionarioActividad = (titulo: string, descripcion: string, preguntas: Pregunta[]): CuestionarioActividad => {
  return {
    titulo: titulo,
    descripcion: descripcion,
    preguntas: preguntas
  };
}
  


type FireBaseDocument = DocumentSnapshot<DocumentData>


export const toCuestionarioActividad = (doc: FireBaseDocument): CuestionarioActividad | null => {
  const data = doc.data();
  
  if (data) {
    
    // Devuelve el objeto CuestionarioActividad
    return {
      id: doc.id,
      titulo: data.titulo,
      descripcion: data.descripcion,
      preguntas: data.preguntas as Pregunta[]
    } as CuestionarioActividad;
  }
  
  return null;
};
