import React, { useEffect, useRef, useState } from 'react';
import { useIA } from "../../hooks/controlador/useIA";
import StyleChat from "../../theme/chatIA.module.css"
import "../../theme/chatIA.css"
import { useToast } from "../../hooks/useToast";
import { useAppDispatch, useAppSelector } from "../../store";
import useNeurona from "../../hooks/controlador/useNeurona";
import { useDato } from "../../auth";
import { sumaNeuronas } from "../../hooks/reducer/reducerAuth";
import { useLocation } from "react-router-dom";
import Loading from "../entrenamiento/componentes/Loading";

const ConsultaIA: React.FC = () => {

  var preguntasPredeterminadas = ["¿Qué es la neuroplasticidad?", "¿Cómo funciona la memoria a corto plazo?", "¿Cómo se puede mejorar mi concentración?"]
  var costo = 100;
  const SYSTEM_CHAT_IA = "system_chat_ia";

  const location = useLocation();
  
  const [mensajeInicial, setMensajeInicial] = useState(null)
  const [openMensaje, setOpenMensaje] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consulta, setConsulta] = useState("");
  const [historial, setHistorial] = useState([]);
  
  const [habilitarChat, setHabilitarChat] = useState(false)

  const userId = useAppSelector((state) => state.auth.userId);
  const neuronas = useAppSelector((state) => state.auth.neuronas);

  const inputRef = useRef(null);
  const chatRef = useRef(null);
  const dispatch = useAppDispatch();

  const {
    restarNeurocoins,

  } = useNeurona()

  const {
    state
  } = useDato()

  const {
    presentMensajePersonalizado
  } = useToast()

  const {
    consultarChat,
    obtenerConfiguracionMensaje
  } = useIA();

  const ajustarScroll = () => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;

  }

  const preguntaPredeterminada = async (pregunta: string) => {
    if (habilitarChat) {
      await ajustarScroll()
      setLoading(true)
      await setHistorial((state) => [...state, { role: 'user', content: pregunta }]);
      await consultarIA([...historial, { role: 'user', content: pregunta }])
      await ajustarScroll()
      setLoading(false)
    } else {
      // presentMensajePersonalizado("No puede conversar con Supercebro")
      setOpenMensaje(true)
    }
  }

  const funcionBarril = async (input?: string) => {
    if (habilitarChat) {
      await ajustarScroll()
      console.log("🚀 ~ file: ConsultaIA.tsx:56 ~ funcionBarril ~ historial:", historial)
      if (historial.length < 21) {
        let contenido = input ? input : consulta
        if (contenido == null || contenido == "") {
          console.log("no hay pregunta")
          await ajustarScroll()
          presentMensajePersonalizado("No hay pregunta")
        } else {
          setLoading(true)
          await setHistorial((state) => [...state, { role: 'user', content: contenido }]);
          await consultarIA([...historial, { role: 'user', content: contenido }])
          await setConsulta("")
          await ajustarScroll()
          setLoading(false)
        }
      } else {
        console.log("Ya no mas token")
        setHabilitarChat(false)
        setOpenMensaje(true)
      }
    } else {
      // presentMensajePersonalizado("No puede conversar con Supercebro")
      setOpenMensaje(true)
    }

  }

  const comprarPase = async () => {
    
    console.log("🚀 ~ file: ConsultaIA.tsx:111 ~ comprarPase ~ neuronas:", neuronas)
    if (neuronas < costo) {
      presentMensajePersonalizado("No tienes suficientes neurocoins. ")
    } else {
      // modificar
      await setHistorial([
        {
          role: "system",
          content: mensajeInicial,
        }
      ]);
      await restarNeurocoins(userId, neuronas - costo, state._usuarioDato.nombres)
      dispatch(sumaNeuronas({ cantidad: -costo }))
      setHabilitarChat(true)
      setOpenMensaje(false)
      presentMensajePersonalizado("Ya puedes preguntale. ")
    }
  }

  const consultarIA = async (input?: any) => {
    let resp: string;
    try {
      console.log("🚀 ~ file: ConsultaIA.tsx:135 ~ consultarIA ~ input:", input)
      resp = await consultarChat(input);
    } catch (error) {
      return console.error("Error:", error);
    }
    //resp = "HOILA"
    await setHistorial((state) => [...state, { role: 'assistant', content: resp }]);
  }

  useEffect(() => {
    async function obtenerConfiguracion() {
      setMensajeInicial(await obtenerConfiguracionMensaje(SYSTEM_CHAT_IA)); // Reemplaza "tuIdMensaje" con el ID correcto
      if (mensajeInicial !== null) {
        setHistorial([
          {
            "role": "system",
            "content": mensajeInicial,
          },
        ]);
      }
    }
    obtenerConfiguracion();
  }, []);

  useEffect(() => {
    if(location.pathname == "/my/consultaIA" && habilitarChat == false){
      setOpenMensaje(true)
    }
  }, [location])
  

  return (
    <div id='page'>
      <Loading isOpen={loading}></Loading>
      <div id="toolbar_default">
        <h1 className=" title">CHAT</h1>
      </div>
      <div id="content_default" style={historial.length > 2 ? {maxHeight:'86svh',paddingBottom:'2svh'}:{maxHeight:'90svh',paddingBottom:'3%'}}>
        <div className={historial.length > 2 ? "chatIA_banner" : "chatIA_banner-default"}>
          {historial.length <= 2 && <div className={StyleChat.mensajeDefault}>Pregúntame lo que quieras</div>}
          <img src="/assets/images/supercerebro/SuperCerebroChat.png"></img>
        </div>
        <div ref={chatRef} id="chatconelIA" className="chatContent" style={historial.length <= 2 ? {borderRadius:'15px 15px 0px 0px',boxShadow:'0px -4px 2px black',height:'fit-content'}:{flex:'1',}}>
          {historial.length > 2 ? <>
            {historial.map((message, index) => (
              message.role !== "system" ? (
                <div key={index} className={["globo", "i", message.role === 'user' ? "abajo-derecha" : "abajo-izquierda"].join(' ')} style={message.role === 'user' ? { backgroundColor: 'var(--color-Pink-Light)', color: 'white' } : {}}>
                  {/* <strong>{message.role === 'user' ? 'Tú: ' : 'IA: '}</strong> */}
                  {message.content}
                </div>
              ) : null
            ))}
          </> : <div style={{ overflow: 'visible', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
            {preguntasPredeterminadas.map((message, index) => (
              <div key={index} className={["selectPredeterminada"].join(' ')}  onClick={() => preguntaPredeterminada(message)}>
                <p>{message}</p>
              </div>
            ))}
          </div>}
        </div>
        <div id="inputdeChat" style={{ display: 'flex', flexDirection: 'row', position: 'relative', height: 'fit-content', width: '90%', margin: '1% 5%', alignItems: 'center', columnGap: '2%' }}>
          <input style={{color:'black'}} type="text" value={consulta} placeholder="Preguntame" onChange={(event) => { setConsulta(event.target.value) }} onKeyDown={(e) => { if (e.key == "Enter") { funcionBarril() } }} className="inputChat" />
          <div onClick={() => funcionBarril()}>
            <img src="/assets/icon/flechaRosadaSend.svg"></img>
          </div>
        </div>
      </div>
      {openMensaje &&
      <div id="modal_cortina_default" onClick={() => { setOpenMensaje(false) }}>
        <div id="modal_default" className={`${StyleChat.modal} scroll-up`}>
          <img className={StyleChat.imagenChat} src="/assets/images/supercerebro/SuperCerebroChat.png"></img>
          <div className={StyleChat.contenedorNeuronas}>
              <div className={StyleChat.circuloNeuronas}>
              </div>
              <span className={StyleChat.neuronas}>{costo}</span>
          </div>
          <label className="texto_aviso">CHAT CON SUPERCEREBRO</label>
          <label className="texto_aviso" style={{ fontWeight: '400',fontSize:'1.66svh',marginBottom:'5px'}}>
            ¿Tienes alguna pregunta sobre entrenamiento mental? Estoy aquí para ayudarte. Por solo {costo} neurocoins, responderé a cualquier inquietud que tengas.
          </label>
          <div className='linea' style={{height:'1px',background:'#D74286'}}></div>
          <label className="texto_aviso" style={{ fontWeight: '500', color: '#D74286'}}>
            Tienes: {neuronas} neurocoins
          </label>
          <button id="button_rosado_default" onClick={comprarPase} className={StyleChat.buttonPreguntame} >
            Pregúntame
          </button>

        </div>
      </div>

      }
    </div>

  );
};

export default ConsultaIA;
