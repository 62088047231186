/** Externa */
import { useEffect, useState } from 'react';

/** Interna */
import { niveles } from '../../../datos/DatosSumas1';

// estilos de diseño
import style from "../../../theme/programas/calculo/Game1.module.css";
import estiloNeuronas from "../../../theme/PopoverNeuronas.module.css";

import { useAppDispatch, useAppSelector } from '../../../store';
import { test_general } from '../../../datos/DatosTests';
import { useLocation } from 'react-router-dom';
import { EtapaJuegoSumas1 } from '../componentes/EtapaJuegoSumas';
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { obtenerMensaje } from '../../../utils/resultadoUtil';

/**
 * Juego para sumar numeros
 */	

interface Props{
    estado: number;
	nivel: number;
    onReinicio: (diferencia: number, suma: number) => void;
}

const PartidaSumas = ({estado, nivel, onReinicio}: Props) => {

	const userId = useAppSelector((state) => state.auth.userId);
	const esJuego = useAppSelector((state) => state.activity.id === test_general);
	const dispatch = useAppDispatch();
	const location = useLocation();

	/*
	const handleBackButtonClick = () => {
		history.goBack();
	  };*/

	const {
      guardarDatosCalificacionTestGeneral,		
			getCalificacionGeneral,
    } = useJuegos();
			
	/**
	 * INICIALIZAR
	 */

	const [etapa, setEtapa] = useState(0);

	const [nivelActual, setNivelActual] = useState<number>(0);
	const [maxNivel, setMaxNivel] = useState(1);

	const [aciertos, setAciertos] = useState(1);
	const [maxAciertos, setMaxAciertos] = useState(1);

	const [cantidadDeNumeros, setCantidadDeNumeros] = useState(1);
	const [cantidadDeDigitos, setCantidadDeDigitos] = useState(1);

	const [velocidad, setVelocidad] = useState<number>(1);

	const [suma, setSuma] = useState<number>(1);
	const [respuesto, setRespuesto] = useState<number>(-1);
	const [resultado, setResultado] = useState<boolean>(false);

	const [nuevasNeuronas, setNuevasNeuronas] = useState<number>(0); 

    
	/**
	 * AYUDANTES
	 */

		
	/**
	 * GETTERS
	 */

	/*

	  SETTERS

	  */

	const setCantidadDeNumerosWrapper = (nuevoNivel: number) => {
		// cantidad numeros a sumar = (3/10)*nivel+2
		const PENDIENTE = (3/10);
		const CORTE = 2;

		const nuevaCantidad = Math.floor(PENDIENTE * nuevoNivel + CORTE);

		setCantidadDeNumeros(nuevaCantidad);
	}
	const setVelocidadWrapper = (nuevoNivel: number) => {
		// velocidad = 1325*0.953^x+240
		const MULTIPLICADOR = 1325;
		const BASE = 0.953;
		const ASINTOTA = 240;

		const nuevaVelocidad = Math.floor(MULTIPLICADOR * Math.pow(BASE, nuevoNivel) + ASINTOTA);
		setVelocidad(nuevaVelocidad);
	}

	const setCantidadDeDigitosWrapper = (nuevoNivel: number) => {
		setCantidadDeDigitos(niveles[nuevoNivel][3]);
	}

	/**
	 * funcion que se encarga de actualizar los datos de la partida y setear el nivel,
	 * @param nuevoNivel
	 * @param actualizaBD
	 * 		- true: actualiza la base de datos
	 * 		- false: no actualiza la base de datos
	*/
	const setNivelWrapper = (nuevoNivel: number) => {				
		//setMaxAciertosWrapper(nuevoNivel);
		setCantidadDeNumerosWrapper(nuevoNivel);
		//setCantidadDeDigitosWrapper(nuevoNivel);
		setVelocidadWrapper(nuevoNivel);
		setAciertos(0);
	}


	/*

	HANDLERS

	*/

	const onEnviar = (sumaEnviado: number, respuestoEnviado: number) => {
		let diferencia = Math.abs(sumaEnviado - respuestoEnviado)
		setResultado(!diferencia);
		setSuma(sumaEnviado);
		setRespuesto(respuestoEnviado);
		onReinicio(diferencia, sumaEnviado);
		setEtapa(2);
	}

	/*
	  CONSTRUCTOR
	  */

	useEffect(() => {
		setNivelWrapper(nivel);
	},[nivel]);
    
    useEffect(() => {
		switch(estado){
		case(0):
			setEtapa(0);
			break;
		case(1):
			setEtapa(1);
			break;
		default:
			break;
		}
    }, [estado])
	
	return (
		<>
{/*
  PANTALLA 2
*/}
			<EtapaJuegoSumas1
				cantidadDigitos={cantidadDeDigitos}
				cantidadNumeros={cantidadDeNumeros}
				etapa={etapa}
				velocidad={velocidad}
				onEnviar={onEnviar}
			/>
{/*
  PANTALLA 4
*/}
		 	{etapa === 2 &&
				    
				<div className={style.contenido_segundario} >
					<div className={estiloNeuronas.mensajes}>
						<p style={{fontSize:"4svh"}}
							className={estiloNeuronas.mensajePersonalizado + (resultado? (" " + style.mensajeCorrecto): "")}>
							{resultado? obtenerMensaje(0.65): obtenerMensaje(0)}
						</p>
						<br></br>
						{!resultado &&
							<p className={style.subMensaje}>
								LA RESPUESTA CORRECTA ERA: {suma} 
							</p>
						}
					</div>
					{

					}
					<div className={style.numero_principal_respuesta} style={{margin:'0',height:'30svh'}}>
						<div className={style.resultado}
							style={{color: (resultado? "var(--color-green)": "#CB4141")}}
						>{respuesto}</div>
					</div>
				</div>

			}
		</>
	);
};

export default PartidaSumas;