import { CSSProperties } from 'react';
import style from '../../../theme/programas/calculo/Game1.module.css'

interface Props{
  tamaño: number;
  posicion: number;
  styleContador?:CSSProperties;
  styleBarra?:CSSProperties;
}

export const ProgressBar:React.FC<Props> = ({tamaño, posicion, styleContador,styleBarra}) => {

  return (
    <>
    <div className={style.contadorPartida} style={styleContador}>
      {posicion}/{tamaño}
    </div>
    <div className={style.contenedor_barra} style={{gridTemplateColumns: `repeat(${tamaño}, 1fr)`,...styleBarra}}>
      {Array(tamaño).fill(0).map((v, i) => 
        <div key={i} className={style.bordeBarra} style={{marginLeft: (i > 0? "-2px": "")}}>
          <div className={style.fondoBarra} style={{width: (i < posicion? "100%": "0%")}}></div>
        </div>)}
    </div>
    </>
  )
}

export default ProgressBar;
