
import { BlobOptions } from "buffer";
import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";

/**
 * Interfaz para los datos de jugadores
 */
export interface Sesion {
    id?: string;

    orden: number;

    nombre: string;     

    tieneBloqueo: boolean;

    tiempoBloqueo?: number;

    fechaInicio?: Timestamp;

    fechaFin?: Timestamp;

    completado?: boolean;
}

export const transformSesion = (orden:number, nombre:string, completado?: boolean, id?:string,tiempoFin?:Timestamp) : Sesion => {
    const sesion:Sesion ={
        orden: orden,
        nombre: nombre,
        completado: completado,
        id: id,
        fechaFin: tiempoFin,
        tieneBloqueo: false,
    }
    return (sesion)
}

export const aSesion = (orden:number, nombre:string, tieneBloqueo:boolean, tiempoBloqueo?:number, completado?: boolean) : Sesion => {
    
    const sesion: Sesion = SesionVacia;

    if (orden !== null && orden !== undefined) {
        sesion.orden = orden;
    }

    if (nombre !== null && nombre !== undefined) {
        sesion.nombre = nombre;
    }       
       
    if (tieneBloqueo!== null &&tieneBloqueo !== undefined) {
        sesion.tieneBloqueo =tieneBloqueo;
    } 

    if (tiempoBloqueo!== null &&tiempoBloqueo !== undefined) {
        sesion.tiempoBloqueo =tiempoBloqueo;
    } 
       
    if (completado!== null &&completado !== undefined) {
        sesion.completado =completado;
    }      
    return sesion;       
}

// Sesion prueba

export const SesionVacia = {
    id: null,
    orden: null,
    nombre: null,
    tieneBloqueo: false,
    tiempoBloqueo: null,
    completado: false,
} as Sesion

/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toSesion = (doc : FireBaseDocument) : Sesion => {    
    if(doc.exists()){        
        const sesion = doc.data() as Sesion;
        sesion.id = doc.id;
        return sesion
    }
    return undefined;
    
}

