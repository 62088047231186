

import { Calificacion, aCalificacionUsos } from '../../modelos/Calificacion';
import { aCalificacionUsuario, aCalificacionTest, toCalificacion, mejoroResultado } from '../../modelos/Calificacion';

// Hooks
import { useBaseDeDatos } from '../baseDeDatos/useBaseDeDatos';

import { nomProgMemNombres, nomProgMemPalabras, nomProgMemNumeros, nomProgSumas1, nomUsuarioTests, nomUsuarioTestProgramas,
    nomUsuarioTestProgramaUsos, nomUsuarios, nomTest, nomTestProgramas, nomTestProgramaUsuarios,nomConfiguracionJuegos, nomProgramas, nomProgParejas } from '../../datos/nombresBD';


import { test_general } from '../../datos/DatosTests';
import { ConfiguracionJuego, aConfiguracion, toConfiguracion } from '../../modelos/ConfigJuego';
import { useNeurona } from './useNeurona';
import { obtenerNeuronas } from '../../utils/neuronasUtil';
import { useDato } from '../../auth';
import { JuegoMemoriaParejas, toJuegoMemoriaParejas } from '../../modelos/MemoriaParejas';
import { Timestamp } from 'firebase/firestore';

export const useJuegos = () => {

    /**
     * Funciones para acceder el base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,        
        recogerDoc,
        agregarCollection,
    } = useBaseDeDatos();

    const {
        actualizarNeuronas,
    } = useNeurona();

    const {
        state
    } = useDato();

 
    /** Juegos */
    //---------------------------------------------------------------------//

    /**
     * Initializar datos del los juegos
     * @param {string} userId - Id del usuario para firestore 
     */
    const initializarJuegos = async (userId: string): Promise<void> => {

        var calificacionMemoria = aCalificacionUsuario(0, null, null)
        var calificacionSumas = aCalificacionUsuario(0, null, null)

        await initializarJuego(nomProgSumas1, userId, calificacionSumas)   
        await initializarJuego(nomProgMemNumeros, userId, calificacionMemoria)
        await initializarJuego(nomProgMemPalabras, userId, calificacionMemoria)
        await initializarJuego(nomProgMemNombres, userId, calificacionMemoria)
    }

    /**
     * Initializar datos de un juego
     * @param {string} userId - Id del usuario para firestore 
     */
    const initializarJuego = async (nombre: string, userId: string, calificacion: Calificacion): Promise<void> => {        
        await agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + test_general + "/" + nomUsuarioTestProgramas +"/" + nombre, calificacion)
    }

    
    const guardarDatosCalificacionTestGeneral = async (userId: string, nomJuego: string, neuronas: number, nivel: number, resultado: number, tiempo: number, completado: boolean, nombre: string, pais: string,resultado2?: number,total?:number): Promise<void> => {
        

        // No guarda el registro de usos en el test general por el momento
        var calificacionUsos = aCalificacionUsos(resultado,total,tiempo);
        guardarRegistroUsoJuego(userId, nomJuego, calificacionUsos)      
        
        // Guarda el ranking y el registro en registro programa solo si mejoro el resultado, 
        // debe consultar el ranking actual para comparar resultados
        const calificacion = aCalificacionUsuario(total, total, Timestamp.now());
        const rankingAct = toCalificacion(await recogerDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + test_general + "/" + nomUsuarioTestProgramas + "/" + nomJuego))
        
        const rankingNuevo = aCalificacionTest(total, total, total, Timestamp.now(), nombre, pais,null)
        
        if(typeof rankingAct === undefined || mejoroResultado(rankingAct, rankingNuevo)){            
            console.log(" guardarDatosCalificacionTestGeneral Se va a reemplazar el guarda UsuarioTestPrograma")
            guardarUsuarioTestPrograma(userId, test_general, nomJuego, calificacion);
            guardarRanking(userId,test_general,nomJuego,rankingNuevo)
        }   
        
        // Actualiza las neuronas
        actualizarNeuronas(userId, neuronas,state._usuarioDato.nombres);
	}


    const guardarDatosCalificacion = async (userId: string, testId: string, nomJuego: string, neuronas: number, nivel: number, resultado: number, tiempo: number, completado: boolean, nombre: string, pais: string, resultado2?: number): Promise<void> => {
        
        // Guarda la calificacion del programa en la rama de usuario        
        const calificacionPrograma = aCalificacionUsuario(resultado, resultado2, Timestamp.now()) 
        guardarUsuarioTestPrograma(userId, testId, nomJuego, calificacionPrograma)
        
        // Guarda la calificacion del programa en la rama de test
        const calificacionTest = aCalificacionTest(nivel, resultado, resultado2, Timestamp.now(), nombre, pais,null)
        guardarRanking(userId, testId, nomJuego, calificacionTest)  

        // Actualiza las neuronas
        actualizarNeuronas(userId, neuronas,state._usuarioDato.nombres);
	}


     
     
    /** Partidas */
    //---------------------------------------------------------------------//

    /**
     * Sube datos de una sola partida
     * @param {string} userId - Id del usuario para firestore 
     * @param {Partida} partida - Datos de una partida
     */
    const guardarRegistroUsoJuego = async (userId: string, nomJuego: string, calificacion: any): Promise<void> => {
        agregarCollection(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + test_general + "/" + nomUsuarioTestProgramas +"/" + nomJuego + "/" + nomUsuarioTestProgramaUsos, calificacion)
    }


    /** Calificaciones */
    //---------------------------------------------------------------------//

    /**
     * guarda el registro de un programa en la rama de usuarios 
     * usuarios/tests/programa 
     * @param {string} userId - Id del usuario para firestore 
     * @param {Calificacion} calificacion - Datos del jugador en un juego en particular
     */
    const guardarUsuarioTestPrograma = async (userId: string, nombreTest: string, nombrePrograma: string, calificacion: any): Promise<void> => {
        console.log("🚀 ~ guardarUsuarioTestPrograma ~ nomUsuarios:", nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + nombreTest + "/" + nomUsuarioTestProgramas + "/" + nombrePrograma)
        agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + nombreTest + "/" + nomUsuarioTestProgramas + "/" + nombrePrograma, calificacion)    
    }

    /**
     * Guarda el registro del programa en la rama test 
     * tests/programas/usuario
     * @param {string} userId - Id del usuario para firestore 
     * @param {string} nomJuego - Nombre del juego
     * @param {Ranking} ranking - ranking del jugador en un juego en particular
     */
    const guardarRanking = async (userId: string, nombreTest: string, nomJuego: string, ranking: any): Promise<void> => {
        agregarDoc(nomTest + "/" + nombreTest + "/" + nomTestProgramas + "/" + nomJuego + "/" + nomTestProgramaUsuarios +"/" + userId, ranking)
        console.log("🚀 ~ guardarRanking ~ ranking:", ranking)
    }
 

    // guarda configuracion de los juegos
    const guardarConfiguracionJuego = async (userId: string, juego: string,digitos:number): Promise<void> => {
        
        const configuracionJuego =aConfiguracion(digitos);
        agregarDoc(nomUsuarios + "/" + userId + "/" + nomConfiguracionJuegos+ "/" + juego, configuracionJuego );
    }
    /**
     * Funcion para consultar el nivel en el que se encuentra un usuario en un juego especifico
     * @param {string} userId - Id del usuario para firestore 
     * @param {string} juego - nombre del juego
     * @returns {number} nivel en el que se encuentra el usuario 
     */
    const getNivel = async (userId: string, juego: string): Promise<number> => {        
        const nivelActual = (await recogerDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + test_general + "/" + nomUsuarioTestProgramas +"/" + juego)).data().nivel;
        console.log("use contro - nivel actual: " + nivelActual)
        return nivelActual;
    }
    const getConfiguracionJuego = async(userId: string, juego: string):Promise<ConfiguracionJuego> =>{
        const documento = await recogerDoc(nomUsuarios + "/" + userId + "/" + nomConfiguracionJuegos + "/" + juego)
        const confiActual = documento === null ? aConfiguracion(1) : toConfiguracion(documento);
        return confiActual;
    }

    const getCalificacion = async (userId: string, nombreTest: string, juego: string): Promise<Calificacion> => {
        const calificacionData = await recogerDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + nombreTest + "/" + nomUsuarioTestProgramas +"/" + juego);          
        
        if(calificacionData.exists()){
            const calificacion = toCalificacion(calificacionData)
            return calificacion;
        }else{
            return undefined;
        }        
    }

    const getCalificacionGeneral = async (userId: string, juego: string): Promise<Calificacion> => {  
        return getCalificacion(userId, test_general, juego);             
    }

    const getRutasImagenesParejas = async (cantidad: number) : Promise<string[]> => {

        const doc = await recogerDoc(`${nomProgramas}/${nomProgParejas}`);
        if(doc.exists()){
            const rutas = (doc.data() as {rutas: string[]}).rutas?.sort(() => Math.random() - 0.5);
            if(rutas && rutas.length >= cantidad){
                return rutas.slice(0,cantidad);
            }
        }
        
        return [];
    }
    
    const getRecord = async (userId: string, nombrePrograma: string) => {
        const doc = await recogerDoc(nomTest + "/" + test_general + "/" + nomTestProgramas + "/" + nombrePrograma + "/" + nomTestProgramaUsuarios +"/" + userId);
        return doc.data();
    }

    const GuardarRegistroSumas = async (userId: string, calificacion: {puntaje: number, nivel: number}, nombre: string, pais: string) => {
        console.log("registro guardado");
        const registro = {...calificacion, fecha: Timestamp.now()}
        guardarRegistroUsoJuego(userId, nomProgSumas1, registro);
        guardarUsuarioTestPrograma(userId, test_general, nomProgSumas1, registro);
        

        const record = await getRecord(userId, nomProgSumas1);
        console.log("record:", record);

        if(!record || !record.total || record.total < registro.puntaje){

            guardarRanking(userId, test_general, nomProgSumas1, {
                tiempo: registro.fecha,
                total: registro.puntaje,
                nivel: registro.nivel,
                nombre,
                dependencia: pais
            });
        }

        actualizarNeuronas(userId, registro.puntaje,state._usuarioDato.nombres);
    }
    

    const guardarPuntajeParejas = async (userId: string, calificacion: {puntaje:number, tiempo:number, intentos:number}) => {
        console.log("puntaje guardado");
        const registro = {...calificacion, fecha: Timestamp.now()}

        guardarRegistroUsoJuego(userId, nomProgParejas, registro);

        const record = await getRecord(userId, nomProgParejas);
        
        if(!record || !record.total || record.total < registro.puntaje){
            guardarUsuarioTestPrograma(userId, test_general, nomProgParejas, registro);
            const {nombres, pais} = state;
            guardarRanking(userId, test_general, nomProgParejas, {
                nombre: nombres,
                dependencia: pais,
                tiempo: registro.tiempo,
                total: registro.puntaje,
            });
        }
        actualizarNeuronas(userId, registro.puntaje,state._usuarioDato.nombres);
    }

    
    return {
        initializarJuegos,   
        guardarDatosCalificacion,
        guardarDatosCalificacionTestGeneral,        
        getCalificacion,
        getCalificacionGeneral,
        getNivel,
        guardarUsuarioTestPrograma,
        guardarRanking,
        getConfiguracionJuego,
        guardarConfiguracionJuego,
        getRutasImagenesParejas,
        guardarPuntajeParejas,
        GuardarRegistroSumas
    }
}
