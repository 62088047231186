
import { Route, Routes} from 'react-router-dom';
import AgilidadMental from './vista/programas/AgilidadMental';
import Memoria from './vista/programas/Memoria';
import Tab1 from './vista/Tab1';
import JuegoSumas1 from './vista/programas/calculo/Sumas1';
import CalculoFecha from './vista/programas/calculo/Fecha';
import NeuralTrainer from './vista/programas/calculo/NeuralTrainer';
import JuegoSuperCerebrosDice from './vista/programas/memoria/JuegoSuperCerebrosDice';
import JuegoMemoriaPalabras from './vista/programas/memoria/MemoriaPalabras';
import JuegoMemoriaNombres from './vista/programas/memoria/MemoriaNombres';
import MemoriaParejas from './vista/programas/memoria/MemoriaParejas';
import MemoriaNumeros from './vista/programas/memoria/MemoriaNumeros';
import RankingPage from './vista/programas/resultados/RankingPage';
import { Competencia } from './vista/programas/simultaneo/Competencia';


const TabsJuegos = () => {

  return (
    <Routes>
      <Route path='/' element={<Tab1/>}/>
      <Route path="AgilidadMental" element={<AgilidadMental />} />
      <Route path="AgilidadMental/Sumas1" element={<JuegoSumas1 />}/>
      <Route path="AgilidadMental/CalculoFecha" element={<CalculoFecha />}/>								
			<Route path="AgilidadMental/NeuralTrainer" element={<NeuralTrainer />}/>

	    <Route path="Memoria" element={<Memoria />} />
      <Route path="Memoria/SuperCerebrosDice" element={<JuegoSuperCerebrosDice />}/>					
      <Route path="Memoria/Nombres" element={<JuegoMemoriaNombres />}/>
      <Route path="Memoria/versus" element={<Competencia />}/>
      <Route path="Memoria/Palabras" element={<JuegoMemoriaPalabras />}/>
      <Route path="Memoria/Parejas" element={<MemoriaParejas />}/>	
      <Route path="Memoria/Numeros" element={<MemoriaNumeros  />}/>
      <Route path="ranking" element={<RankingPage />}/>
   </Routes>
    
  );
};

export default TabsJuegos;
