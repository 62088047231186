import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Sesion } from '../../modelos/entrenamiento/Sesion';
import { Actividad } from '../../modelos/entrenamiento/Actividad';
import { Entrenamiento } from '../../modelos/entrenamiento/Entrenamiento';
import { RegistroEntrenamiento } from '../../modelos/entrenamiento/RegistroEntrenamiento';

interface EntrenamientoRedux {
    entrenamientos_app:Entrenamiento[] | null;
    entrenamientos_user:string[] | null;
    current_entrenamiento:Entrenamiento | null;
    registros_usuario:RegistroEntrenamiento[] | null;
    current_registro_usuario:RegistroEntrenamiento | null;
    current_sesion:number | null;
    current_actividad:number | null;
    actividades_entrenamiento: Actividad[][] | null;
    current_actividades:Actividad[] | null;
    index?:number;
    cargando:boolean;
    finishSesion:number|null;
}

const initialState: EntrenamientoRedux = {
    entrenamientos_app : null,
    entrenamientos_user : null,
    current_entrenamiento : null,
    registros_usuario : null,
    current_registro_usuario : null,
    current_sesion : null,
    current_actividad : null,
    actividades_entrenamiento: null,
    current_actividades : null,
    cargando:true,
    finishSesion:null,
}

export const sliceEntrenamiento = createSlice({
    name: 'entrenamiento',
    initialState,
    reducers:{
        ChangeRedux_entrenamientos_app: (state,action: PayloadAction<{entrenamientos:Entrenamiento[]}>) => {
            state.entrenamientos_app = action.payload.entrenamientos;
        },
        ChangeRedux_entrenamientos_user: (state,action: PayloadAction<{entrenamientos:string[]}>) => {
            state.entrenamientos_user = action.payload.entrenamientos;
        },
        ChangeRedux_current_entrenamiento: (state,action: PayloadAction<{entrenamiento:Entrenamiento}>) => {
            state.current_entrenamiento = action.payload.entrenamiento;
        },
        ChangeRedux_registros_usuario_push: (state,action: PayloadAction<{registro:RegistroEntrenamiento,entrenamientoID:string}>) => {
            let copyRegistros:RegistroEntrenamiento[] = state.registros_usuario
            let copy = {...action.payload.registro}
            copy.idEntrenamiento = action.payload.entrenamientoID
            copy.fechaInicioGrupo = {seconds: copy.fechaInicioGrupo?.seconds,nanoseconds: copy.fechaInicioGrupo?.nanoseconds}
            copy.fechaSesion = {seconds: copy.fechaSesion?.seconds,nanoseconds: copy.fechaInicio?.nanoseconds}
            copy.fechaInicio = {seconds: copy.fechaInicio?.seconds,nanoseconds: copy.fechaInicio?.nanoseconds}
            copyRegistros.push(copy)
            state.registros_usuario = copyRegistros
        },
        ChangeRedux_registros_usuario: (state,action: PayloadAction<{registros:RegistroEntrenamiento[]}>) => {
            let tempRegistros:RegistroEntrenamiento[] = [];
            action.payload.registros.forEach(element => {
                let copy = {...element}
                copy.fechaInicioGrupo = {seconds: copy.fechaInicioGrupo?.seconds,nanoseconds: copy.fechaInicioGrupo?.nanoseconds}
                copy.fechaSesion = {seconds: copy.fechaSesion?.seconds,nanoseconds: copy.fechaInicio?.nanoseconds}
                copy.fechaInicio = {seconds: copy.fechaInicio?.seconds,nanoseconds: copy.fechaInicio?.nanoseconds}
                tempRegistros.push(copy)
            });
            state.registros_usuario = tempRegistros;
        },
        ChangeRedux_current_registro_usuario: (state,action: PayloadAction<{registro:RegistroEntrenamiento}>) => {
            let registro = action.payload.registro;
            state.current_registro_usuario = registro;
            
            let index = state.registros_usuario.findIndex(val => val.idEntrenamiento === registro?.idEntrenamiento);
            if(index >= 0){
                let tempRegistros = [...state.registros_usuario]
                tempRegistros[index] = registro;
                state.registros_usuario = tempRegistros;
            }
        },
        ChangeRedux_current_sesion: (state,action: PayloadAction<{sesion:number}>) => {
            state.current_sesion = action.payload.sesion;
        },
        ChangeRedux_actividades_entrenamiento: (state,action: PayloadAction<{actividades_entrenamiento:Actividad[][]}>) => {
            state.actividades_entrenamiento = action.payload.actividades_entrenamiento;
        },
        ChangeRedux_current_actividades: (state,action: PayloadAction<{current_actividades:Actividad[]}>) => {
            state.current_actividades = action.payload.current_actividades;
        },
        ChangeRedux_current_indice_actividad: (state,action: PayloadAction<number>) => {
            state.current_actividad = action.payload;
        },
        ChangeRedux_cargando: (state, action:PayloadAction<boolean>) => {
            state.cargando = action.payload
        },
        ChangeRedux_finishSesion: (state, action:PayloadAction<number|null>) => {
            state.finishSesion = action.payload
        },
        CleanEntrenamiento:  (state) => {
            state.entrenamientos_app = initialState.entrenamientos_app;
            state.entrenamientos_user = initialState.entrenamientos_user;
            state.current_entrenamiento = initialState.current_entrenamiento;
            state.registros_usuario = initialState.registros_usuario;
            state.current_registro_usuario = initialState.current_registro_usuario;
            state.current_sesion = initialState.current_sesion;
            state.current_actividad = initialState.current_actividad;
            state.actividades_entrenamiento = initialState.actividades_entrenamiento;
            state.current_actividades = initialState.current_actividades;
            state.cargando = initialState.cargando;
            state.finishSesion = initialState.finishSesion;
        },
    }
});

export const { 
    ChangeRedux_entrenamientos_app,
    ChangeRedux_entrenamientos_user,
    ChangeRedux_current_entrenamiento,
    ChangeRedux_registros_usuario,
    ChangeRedux_current_sesion,
    ChangeRedux_actividades_entrenamiento,
    ChangeRedux_current_actividades,
    ChangeRedux_current_registro_usuario,
    ChangeRedux_registros_usuario_push,
    ChangeRedux_cargando,
    ChangeRedux_current_indice_actividad,
    ChangeRedux_finishSesion,
    CleanEntrenamiento
} = sliceEntrenamiento.actions;