
import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";

export interface integrante{
    puntaje?: number;
    nombre: string;
    telefono?: string;
    uid?: string;
    pareja?: string[];
    aceptado?: string;
}

export interface Sala {
    id?: string;

    juego?:string[];

    abierto?:boolean;

    iniciar: number;

    integrantes: integrante[];
   
}

export interface Ranking{
    nombre: string;
    indiceJuego: number;
    telefono: string;
    tiempo: Timestamp;
    total: number;
    ultimoResultado: number;
}

/**
 * Crear una Sala objecto 
 * @function
 * @param {boolean} iniciar - El nivel actual del jugador.
 * @param {integrante[]} integrantes - Primer resultado del programa.
 * @returns {Sala} - Construido objecto de Sala
 */

export const aSalaUsuario = (iniciar:number,integrante:integrante[]) : Sala => {
    
    const sala: Sala = {      
        iniciar : iniciar,
    } as Sala;
  
    if (integrante !== null && integrante !== undefined) {
        sala.integrantes = integrante;
    }    
    
    return sala;
}

/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toSala = (doc : FireBaseDocument) : Sala => {    
    if(doc.exists()){        
        const sala = doc.data() as Sala;
        sala.id = doc.id;
        return sala
    }
    return undefined;
    
}

