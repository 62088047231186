import React, { useEffect, useState } from 'react'
import { useCuestionario } from '../../../hooks/controlador/useCuestionario'
import Cuestionario from './Cuestionario'
import { CuestionarioActividad, Pregunta, aCuestionarioActividad } from '../../../modelos/JuegoCuestionario'
import style from '../../../theme/programas/cuestionarios/CuestionarioActividad.module.css'

const FormCuestionario = () => {
  const [idCuestionario, setIdCuestionario] = useState('')
  const [tituloCuestionario, setTituloCuestionario] = useState('')
  const [currentCuestionario, setCurrentCuestionario] = useState(null)
  const [opciones, setOpciones] = useState<CuestionarioActividad[]>([])

  const {
    consultarCuestionarioByTitulo,
    CrearCuestionario,
    GuardarCambios,
    BorrarCuestionario,
  } = useCuestionario();

  const HandleConsultarCuestionario = async () => {
    console.log("🚀 ~ HandleConsultarCuestionario", ('nuevo cuestionario').includes(tituloCuestionario))
    let resp = await consultarCuestionarioByTitulo(tituloCuestionario)
    setOpciones(resp)
    console.log("🚀 ~ HandleConsultarCuestionario ~ resp:", resp)
  }

  const HandleCrearCuestionario = () => {
    let CuestionarioVacio = aCuestionarioActividad('','',[])
    setCurrentCuestionario(CuestionarioVacio)
  }

  const HandleModificarCuestionario = (campo: string, valor: any) => {
    let copy:CuestionarioActividad =  { ...currentCuestionario }
    copy[campo] = valor;
    setCurrentCuestionario(copy);
  }

  const modificarPregunta = (index: number, campo: string, valor: any) => {
    let copy:CuestionarioActividad = {...currentCuestionario}
    const nuevasPreguntas = [...copy.preguntas];
    nuevasPreguntas[index] = {
      ...nuevasPreguntas[index],
      [campo]: valor
    };
    copy.preguntas = nuevasPreguntas
    setCurrentCuestionario(copy);
  };

  const EliminarRespuesta = (indexPregunta: number,indexRespuesta:number) => {
    let copy: CuestionarioActividad = { ...currentCuestionario };
    const pregunta: Pregunta = {...copy.preguntas[indexPregunta]};
    let nuevasRespuestas: string[] = [...pregunta.respuestas];

    nuevasRespuestas = [...nuevasRespuestas.slice(0, indexRespuesta), ...nuevasRespuestas.slice(indexRespuesta + 1)]
    copy.preguntas[indexPregunta].respuestas = nuevasRespuestas

    setCurrentCuestionario(copy);
  };

  const modificarRespuesta = (indexPregunta: number,indexRespuesta:number, valor: any) => {
    let copy: CuestionarioActividad = { ...currentCuestionario };
    const pregunta: Pregunta = { ...copy.preguntas[indexPregunta] };
    const nuevasRespuestas: string[] = [...pregunta.respuestas];

    nuevasRespuestas[indexRespuesta] = valor;
    pregunta.respuestas = nuevasRespuestas;
    copy.preguntas[indexPregunta] = pregunta;

    setCurrentCuestionario(copy);
  };
  
  const agregarPregunta = () => {
    let copy:CuestionarioActividad = {...currentCuestionario}
    copy.preguntas = [...copy.preguntas, { respuestaCorrecta: 0, respuestas: [], textoPregunta: '' }]
    setCurrentCuestionario(copy);
  };

  const HandleGuardarCambios = async () => {
    // Verificar que currentCuestionario no sea null ni undefined
    if (!currentCuestionario) {
        console.error("No se ha especificado ningún cuestionario.");
        return;
    }

    // Verificar que el título y la descripción no estén vacíos
    if (!currentCuestionario.titulo || !currentCuestionario.descripcion) {
        console.error("Por favor, complete todos los campos del cuestionario.");
        return;
    }

    // Verificar que haya al menos una pregunta
    if (currentCuestionario.preguntas.length === 0) {
        console.error("El cuestionario debe tener al menos una pregunta.");
        return;
    }

    // Verificar que cada pregunta tenga más de dos respuestas
    for (const pregunta of currentCuestionario.preguntas) {
        if (pregunta.respuestas.length < 2) {
            console.error("Cada pregunta debe tener al menos dos respuestas.");
            return;
        }
    }

    // Verificar que no haya ningún atributo vacío dentro de las preguntas
    for (const pregunta of currentCuestionario.preguntas) {
        if (!pregunta.respuestas.every(respuesta => respuesta.trim() !== "") || !pregunta.textoPregunta.trim()) {
            console.error("Por favor, complete todos los atributos de todas las preguntas.");
            return;
          }
        }

    if (idCuestionario === '') {
        await CrearCuestionario(currentCuestionario);
    } else {
      await GuardarCambios(idCuestionario, currentCuestionario);
    }
    console.log("Guardado Correctamente");

    // Reiniciar los estados
    setCurrentCuestionario(null);
    setIdCuestionario('');
};

  const HandleBorrar = async (cuestionario:CuestionarioActividad) => {
    await BorrarCuestionario(cuestionario.id)
    setCurrentCuestionario(null)
    setOpciones([])
  }

  const OnCancel = () => {
    setCurrentCuestionario(null)
    setIdCuestionario('')
  }

  return (
    <div id='page'>
      <div id='toolbar_default'>
        <h1 className='title'>FORMS</h1>
      </div>
      <div id='content_default' style={{padding:'5%',overflowY:'auto'}}>
      {currentCuestionario === null ? 
      <>
        <input type='text' value={tituloCuestionario} onChange={(e) => setTituloCuestionario(e.target.value)} placeholder='Ingrese id cuestionario'></input>
        <button onClick={HandleConsultarCuestionario}>Cargar Cuestionario</button>
        <button onClick={HandleCrearCuestionario}>Nuevo Cuestionario</button>
        {opciones.length !== 0 && opciones.map((cuestionario) => (
          <div>
            <p>{cuestionario.titulo}</p>
            <button onClick={() => setCurrentCuestionario(cuestionario)}>Editar</button>
            <button onClick={() => HandleBorrar(cuestionario)}>Borrar</button>
          </div>
        ))}
      </>:
      <>
        <label>Titulo:</label>
        <input type='text' value={currentCuestionario?.titulo} onChange={(e) => HandleModificarCuestionario('titulo',e.target.value)}></input>
        <label>Descripcion:</label>
        <input type='text' value={currentCuestionario?.descripcion} onChange={(e) => HandleModificarCuestionario('descripcion',e.target.value)}></input>
        <h3>Preguntas</h3>
        {currentCuestionario?.preguntas?.map((pregunta, index) => (
          <div key={index} className={style.card_Pregunta}>
            <label>Pregunta: </label>
            <input
              type="text"
              value={pregunta.textoPregunta}
              onChange={(e) => modificarPregunta(index, 'textoPregunta', e.target.value)}
              />
            <label>Selecciona Opcion Correcta: </label>
            <select
              value={pregunta.respuestaCorrecta}
              onChange={(e) => modificarPregunta(index, 'respuestaCorrecta', parseInt(e.target.value))}
            >
              {pregunta.respuestas.map((respuesta, respuestaIndex) => (
                <option key={respuestaIndex} value={respuestaIndex}>
                  {respuestaIndex}
                </option>
              ))}
            </select>
            <div>
              {pregunta.respuestas.map((respuesta, respuestaIndex) => (
                <>
                  <label key={respuestaIndex} >Respuesta {respuestaIndex}: </label>
                  <input value={respuesta} onChange={(e) => modificarRespuesta(index, respuestaIndex, e.target.value)}></input>
                  <button onClick={() => EliminarRespuesta(index, respuestaIndex)}>
                    Eliminar Respuesta
                  </button>
                </>
                ))}
              <button onClick={() => modificarPregunta(index, 'respuestas', [...pregunta.respuestas, ''])}>
              Agregar Respuesta
              </button>
            </div>
          </div>
        ))}
        <button onClick={agregarPregunta}>Agregar Pregunta</button>
        <button onClick={HandleGuardarCambios}>Guardar Cambios</button>
        <button onClick={OnCancel}>Cancelar</button>
      </>}
      </div>
    </div>
  )
}

export default FormCuestionario