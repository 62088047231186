import { DocumentSnapshot, DocumentReference, DocumentData } from 'firebase/firestore';

/** Interfaces */

export interface UsuarioDato {
    id? : string; 
    nombres : string;
    //alias : string;     
    correo: string;
    codigo:  string;
    telefono: string;
    telefonoCompleto: string;
    fechaDeNacimiento : string;
    pais : string;
    photoPerfilURL? : string;
}

export interface Usuario {
    administrador: boolean;
    datosGuardado : boolean;
    initializado? : boolean;
    usuarioDato : UsuarioDato;
}

export interface FakeUsuario {
    datosGuardado : boolean;
    initializado : boolean;
    datos : {
	nombres : string;
	fechaDeNacimiento : string;
	pais : string;
    }
}

export interface Fecha {
    ano : number;
    mes : number;
    dia : number;
}


export class Aplicacion {
    private _administrador:boolean;
    private _datosGuardado: boolean;
    private _initializado: boolean;
    private _usuarioDato: UsuarioDato = {} as UsuarioDato;
    id?: string;

    constructor(){}
    
    /** Getters */

    public get usuarioDato(): UsuarioDato {
        return this._usuarioDato;
    }
    public get pais(): string {
        return this._usuarioDato.pais;
    }
    // public get fechaDeNacimiento(): string {
    //     // return this._usuarioDato.fechaDeNacimiento;
    // }
    public get nombres(): string {
        return this._usuarioDato.nombres;
    }
    /*
    public get alias(): string {
        return this._usuarioDato.alias;
    }*/
    public get correo(): string {
        return this._usuarioDato.correo;
    }
    public get codigo(): string {
        return this._usuarioDato.codigo;
    }
    public get telefono(): string {
        return this._usuarioDato.telefono;
    }
    public get telefonoCompleto(): string {
        return this._usuarioDato.telefonoCompleto;
    }
    public get initializado(): boolean {
        return this._initializado;
    }
    public get datosGuardado(): boolean {
        return this._datosGuardado;
    }
    public get administrador(): boolean {
        return this._administrador;
    }
    
    /** Setters */

    public set nombres(value: string) {
        this._usuarioDato.nombres = value;
    }
    /*
    public set alias(value: string) {
        this._usuarioDato.alias = value;
    }*/
    public set correo(value: string) {
        this._usuarioDato.correo = value;
    }
    public set codigo(value: string) {
        this._usuarioDato.codigo = value;
    }
    public set telefono(value: string) {
        this._usuarioDato.telefono = value;
    }
    public set telefonoCompleto(value: string) {
        this._usuarioDato.telefonoCompleto = value;
    }
    public set fechaDeNacimiento(value: string) {
        this._usuarioDato.fechaDeNacimiento = value;
    }
    public set pais(value: string) {
        this._usuarioDato.pais = value;
    }
    public set datosGuardado(value: boolean) {
        this._datosGuardado = value;
    }
    public set initializado(value: boolean) {
        this._initializado = value;
    }
    public set administrador(value: boolean) {
        this._administrador = value;
    }
    /** Grupo Setters */
    
    public setUsuario(usuario: Usuario): void {
        this._administrador = usuario?.administrador;
        this._datosGuardado = usuario?.datosGuardado;
        this._initializado = usuario?.initializado;
	    this._usuarioDato = {} as UsuarioDato;
        this.nombres = usuario?.usuarioDato?.nombres;
        //this.alias = usuario?.usuarioDato?.alias;
        this.correo = usuario?.usuarioDato?.correo;
        this.codigo = usuario?.usuarioDato?.codigo;
        this.telefono = usuario?.usuarioDato?.telefono;
        this.telefonoCompleto = usuario?.usuarioDato?.telefonoCompleto;
        this.fechaDeNacimiento = usuario?.usuarioDato?.fechaDeNacimiento;
        this.pais = usuario?.usuarioDato?.pais;
    }

    /** Convertadores */
    public toUsuario() : Usuario {
	return {
        administrador: this._administrador,
	    datosGuardado : this._datosGuardado,
	    initializado : this._initializado, 
	    usuarioDato : this._usuarioDato,
	} as Usuario;
    }

}

/** Constants */
const USUARIO_DATO_VACIO_ALT = {
    nombres : "",
    correo:"" ,
    codigo: "+57",
    telefono: "",
    telefonoCompleto: "",
    fechaDeNacimiento : "",
    pais : "Colombia"
}

export const USUARIO_DATO_VACIO = {
    nombres : null,
    //alias : "",
    correo : "",
    codigo : "",
    telefono : "",
    telefonoCompleto : "",
    fechaDeNacimiento : "",
    pais : "",
}

export const USUARIO_DATO_VACIO_CON_CORREO = (email:string) => {
    return {
        nombres: null,
        //alias : "",
        correo: email,
        codigo: "+57",
        telefono: "",
        telefonoCompleto: "",
        fechaDeNacimiento: null,
        pais: "Colombia",
    } as UsuarioDato
}

export const USUARIO_DATO_DUMMY = {
    nombres : "nombreDummy",
    // fechaDeNacimiento : "fechaDummy",
    pais : "paisDummy",
}

export const USUARIO_DUMMY = {
    datosGuardado : true,
    initializado : true,
    usuarioDato : USUARIO_DATO_DUMMY,
}

export const USUARIO_VACIO = {
    administrador: false,
    datosGuardado : false,
    initializado : false,
    usuarioDato : USUARIO_DATO_VACIO,
}

export const USUARIO_VACIO_INICIALIZADO = {
    administrador: false,
    datosGuardado : false,
    initializado : true,
    usuarioDato : USUARIO_DATO_VACIO,
}

/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toAplicacion = (doc : FireBaseDocument) : Aplicacion => {
    const user = doc.data() as Aplicacion;

    if(doc.exists()){
        user.id = doc.id;
    }

    return user
}
export const toUsuario = (doc : FireBaseDocument) : Usuario => {
    const user = doc.data() as Usuario;

    if(doc.exists() && user?.usuarioDato?.photoPerfilURL == undefined){
        user.usuarioDato.photoPerfilURL = "vacio"
    }

    return user
}

export const toUsuarioCorreo = (doc : FireBaseDocument) : UsuarioDato => {
    const data = doc.data();
    return {   
        id : doc.id,  
        nombres : data.nombres,   
        correo : data.correo,        
    } as UsuarioDato;
}

export const createUsuario = (admin: boolean,datosGuardado : boolean, initializado : boolean, usuarioDato : UsuarioDato) : Usuario => {
    if(admin == undefined) admin = false;
    usuarioDato.telefonoCompleto = usuarioDato.codigo + usuarioDato.telefono;
    return {
    administrador: admin,
	datosGuardado : datosGuardado,
	initializado : initializado,
	usuarioDato : usuarioDato
    } as Usuario;
}

/** Comparaciones */

export const compararFechas = (a : Fecha, b : Fecha) : boolean => {
    if(a.ano < b.ano) {
	return true;
    } else if (a.ano === b.ano){
	if(a.mes < b.mes){
	    return true;
	} else if( a.mes === b.mes){
	    if(a.dia < b.dia){
		return true;
	    } else {
		return false;
	    }
	} else {
	    return false;
	}
    } else {
	return false;
    }
}
