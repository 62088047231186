
import { CollectionReference, DocumentSnapshot, Timestamp, collection } from 'firebase/firestore';

import { ParticipanteTest, Test, toTest, toParticipanteTest, TestInicial, aTestInicialUsuario, toTestInicial } from '../../modelos/Test';
import { Programa, toPrograma, toTestPrograma } from '../../modelos/Programa';
import { nomProgLecturaVelocidad, nomProgramas, nomTest, nomTestAdministradores, nomTestParticipantes, nomTestProgramaUsuarios, nomTestSumas1, nomUsuarioAdminTests, nomUsuarios, nomUsuarioTests } from '../../datos/nombresBD';

// Hooks
import { useBaseDeDatos } from '../baseDeDatos/useBaseDeDatos';

import { test_general, test_inicial, tipo_test } from '../../datos/DatosTests';
import { nomTestProgramas } from '../../datos/nombresBD';
import { Calificacion } from '../../modelos/Calificacion';
import { firestore } from '../../firebase';
import { useDBRanking } from '../baseDeDatos/useBDRanking';

export const useTests = () => {

    /**
     * Funciones para acceder el base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,  
        recogerDoc,      
        recogerDocs,
        agregarCollection,    
        recogerDocsFiltrados,
        recogerDocsOrdenados,
        recogerDocsFiltrados2Campos,
    } = useBaseDeDatos();

    const {
        consultarOrdenadosDosCriterios
    } = useDBRanking();

    
 
    const consultarTests = async (nombreTest: string): Promise<Test[]> => {        
        const arrTests : Test[] = [];
        var arrDoc : DocumentSnapshot[] = [];
        if(nombreTest === ""){
            arrDoc = await recogerDocs(nomTest);
        }else{
            arrDoc = await recogerDocsFiltrados(nomTest, "nombre", nombreTest);
        }         
        for (const doc of  arrDoc) {             
            arrTests.push(toTest(doc));  
        }
        return arrTests;
    }

    const consultarTestsInicialAbierto = async (): Promise<Test[]> => {        
        const arrTests : Test[] = [];
        var arrDoc : DocumentSnapshot[] = [];
        
        arrDoc = await recogerDocsFiltrados2Campos(nomTest, "inicial", true, "abierto", true);
                
        for (const doc of  arrDoc) {             
            arrTests.push(toTest(doc));  
        }
        return arrTests;
    }

    const consultarTestsAdminUsuario = async (userId: string): Promise<Test[]> => {  
        const arrTests : Test[] = [];
        var arrDoc : DocumentSnapshot[] = [];       
        // arrDoc = await recogerDocsFiltrados(nomUsuarios + "/" + userId + "/" + nomUsuarioAdminTests,'torneo',false);
        arrDoc = await recogerDocs(nomUsuarios + "/" + userId + "/" + nomUsuarioAdminTests);
                       
        for (const doc of  arrDoc) {   
            const test = toTest(doc); 
            arrTests.push(test);
            // if(test.id !== test_general){
            //     const testOriginal:Test = await consultarTest(test.id);
            //     test.fechaInicio = testOriginal.fechaInicio
            //     test.fechaFin = testOriginal.fechaFin
            //     arrTests.push(test);
            // }   
        }
        
        return arrTests;
    }

    const consultarTestsUsuario = async (userId: string): Promise<Test[]> => {  
        const arrTests : Test[] = [];
        var arrDoc : DocumentSnapshot[] = [];       
        arrDoc = await recogerDocsFiltrados(nomUsuarios + "/" + userId + "/" + nomUsuarioTests,'torneo',false);
                       
        for (const doc of  arrDoc) {   
            const test = toTest(doc); 
            if(test.id !== test_general){
                const testOriginal:Test = await consultarTest(test.id);
                test.fechaInicio = testOriginal.fechaInicio
                test.fechaFin = testOriginal.fechaFin
                arrTests.push(test);
            }   
        }
        
        return arrTests;
    }


    const consultarTestsInicialUsuario = async (userId: string): Promise<Test[]> => {  
        const arrTests : Test[] = [];
        var arrDoc : DocumentSnapshot[] = [];       
        arrDoc = await recogerDocsFiltrados2Campos(nomUsuarios + "/" + userId + "/" + nomUsuarioTests, "inicial", true, "torneo", false);
                       
        for (const doc of  arrDoc) {   
            const test = toTest(doc); 
            if(test.id !== test_general){
                const testOriginal:Test = await consultarTest(test.id);
                test.fechaInicio = testOriginal.fechaInicio
                test.fechaFin = testOriginal.fechaFin
                arrTests.push(test);
            }   
        }
        
        return arrTests;
    }

       

    const consultarProgramasParaTest = async (): Promise<Programa[]> => {        
        const arrProgramas : Programa[] = [];
        var arrDoc : DocumentSnapshot[] = [];        
        arrDoc = await recogerDocsFiltrados(nomProgramas, "tipo", tipo_test);
                 
        for (const doc of  arrDoc) {             
            arrProgramas.push(toPrograma(doc));  
        }
        return arrProgramas;
    }

    const consultarProgramasTest = async (idTest: string): Promise<Programa[]> => {        
        const arrProgramas : Programa[] = [];
        var arrDoc : DocumentSnapshot[] = [];        
        arrDoc = await recogerDocs(nomTest + "/" + idTest + "/" + nomTestProgramas);               
        for (const doc of  arrDoc) {             
            arrProgramas.push(toTestPrograma(doc));  
        }
        return arrProgramas;
    }

    const consultarModeradoresTest = async (idTest: string): Promise<ParticipanteTest[]> => {        
        const arrModeradores : ParticipanteTest[] = [];
        var arrDoc : DocumentSnapshot[] = [];        
        arrDoc = await recogerDocs(nomTest + "/" + idTest + "/" + nomTestAdministradores);
             
        for (const doc of  arrDoc) {             
            arrModeradores.push(toParticipanteTest(doc));  
        }
        return arrModeradores;
    }

    const consultarParticipantesTest = async (idTest: string): Promise<ParticipanteTest[]> => {        
        const arrParticipantes : ParticipanteTest[] = [];
        var arrDoc : DocumentSnapshot[] = [];        
        arrDoc = await recogerDocs(nomTest + "/" + idTest + "/" + nomTestParticipantes);
             
        for (const doc of  arrDoc) {             
            arrParticipantes.push(toParticipanteTest(doc));  
        }
        return arrParticipantes;
    }

    const crearTest = async (test: Test) => {
        await agregarCollection(nomTest, test);
    }

    const crearTestInicialUsuario = async (userId: string) => {        
        const testInicial:TestInicial = aTestInicialUsuario(false);
        agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests+ "/" + test_inicial, testInicial);
    }

    const completarTestInicialUsuario = async (userId: string) => {        
        const testInicial:TestInicial = aTestInicialUsuario(true);
        agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests+ "/" + test_inicial, testInicial);
    }

    const consultarTest = async (idTest:string): Promise<Test> => {
        var arrDoc : DocumentSnapshot;        
        arrDoc = await recogerDoc(nomTest + "/" + idTest);
        const doc:Test = toTest(arrDoc)
        return doc;        
    }

    const consultarTestInicialUsuario = async (userId:string): Promise<TestInicial> => {
        var arrDoc : DocumentSnapshot;        
        arrDoc = await recogerDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + test_inicial);
        if (arrDoc.exists()) {
            const doc:TestInicial = toTestInicial(arrDoc)
            return doc; 
        }
        return undefined;               
    }

    const consultarRutaPrograma = async (idPrograma:string): Promise<Programa> => {
        var arrDoc : DocumentSnapshot;        
        arrDoc = await recogerDoc(nomProgramas + "/" + idPrograma);
        const doc:Programa = toPrograma(arrDoc)
        return doc;
        
    }

    

    const consultarRanking = async (idTest:string, idProgram: string): Promise<Calificacion[]> => {
        let cri1 = "total";
        let cri2 = "tiempo";
        let cri1Asc = false;
        let cri2Asc = true;
        
        const ruta: CollectionReference = await collection(firestore,nomTest + "/" + idTest + "/" + nomTestProgramas + "/" + idProgram + "/" + nomTestProgramaUsuarios);
        const resultados = await consultarOrdenadosDosCriterios(ruta, cri1, cri1Asc, cri2, cri2Asc);
        return resultados
    }

    const crearUsuarioTest = async (userId: string, idTest: string, test: Test): Promise<void> => {             
        await agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioTests + "/" + idTest, test)
    }

    const crearUsuarioAdminTest = async (userId: string, idTest: string, test: Test): Promise<void> => {             
        await agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioAdminTests + "/" + idTest, test)
    }

    const crearProgramaTest = async (idTest: string, idPrograma: string, programa: Programa): Promise<void> => {
        //return await agregarCollection(nomTest + "/" + idTest + "/" + nomTestProgramas, programa);
        return await agregarDoc(nomTest + "/" + idTest + "/" + nomTestProgramas + "/" + idPrograma, programa);
    }

    const crearParticipanteTest = async (idTest: string, idParticipante: string, participante: ParticipanteTest) : Promise <void> => {        
        await agregarDoc(nomTest + "/" + idTest + "/" + nomTestParticipantes + "/" + idParticipante , participante);        
    }

    const crearAdminTest = async (idTest: string, idParticipante: string, participante: ParticipanteTest) : Promise <void> => {        
        await agregarDoc(nomTest + "/" + idTest + "/" + nomTestAdministradores + "/" + idParticipante , participante);        
    }
    
  
    return {        
        crearUsuarioAdminTest,
        crearUsuarioTest,
        crearTest,
        consultarTests,
        consultarRutaPrograma,
        consultarRanking,
        consultarTestsInicialAbierto,
        consultarTestsUsuario,
        consultarTestsInicialUsuario,
        consultarTestInicialUsuario,
        consultarTestsAdminUsuario,
        crearParticipanteTest,
        crearAdminTest,
        crearProgramaTest,
        consultarProgramasTest,
        consultarParticipantesTest,
        consultarModeradoresTest,
        consultarProgramasParaTest,
        crearTestInicialUsuario,
        completarTestInicialUsuario,        
    }
}
