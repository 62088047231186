import { DocumentSnapshot, DocumentData } from "firebase/firestore";

export interface IntegranteEntidad {
    id? : string;   
    correo : string;
}

export const aIntegrante = (correo : string) : IntegranteEntidad => {
    return {         
        correo : correo,
    } as IntegranteEntidad;    
}

export const haciaIntegrante = (id:string, correo : string) : IntegranteEntidad => {
    return {         
        id: id,
        correo : correo,
    } as IntegranteEntidad;    
}

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toIntegrante = (doc : FireBaseDocument) : IntegranteEntidad => {
    const data = doc.data();
    return {   
        id : doc.id,     
        correo : data.correo,
    } as IntegranteEntidad;
}