
export const ArrayDeNumeros = (cuenta: number, datos:string[] | number) => {

    const tamMaximo = typeof datos === 'number' ? datos : datos.length;


    let arr: string[] = [];
    let i = 0;
    const max = typeof datos === 'number' ? tamMaximo: tamMaximo - 1;
    const min =  typeof datos === 'number' ? 1:0;
    let num = null;
    while (i !== cuenta) {
        num = Math.floor(Math.random() * (max - min + 1)) + min;
        if (!arr.includes(num)) {
            arr.push(num);
            i++;
        }
    }        
    return arr;
}






