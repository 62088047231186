import  "../../../theme/main.css"; 
import  estiloRanking from '../../../theme/ranking.module.css'; 
import estiloJuegos from "../../../theme/Juegos.module.css"
import { useEffect, useState } from "react";
import { useTests } from "../../../hooks/controlador/useTests";
import { useAppSelector } from "../../../store";
import { Calificacion } from "../../../modelos/Calificacion";
import RankingJuegos from "./componentes/RankingJuegos";
import { format, fromUnixTime } from "date-fns";
import { nomNeuronas, nomProgCodigoPalabras, nomProgLecturaVelocidad, nomProgMemNombres, nomProgMemNumeros, nomProgMemPalabras, nomProgParejas, nomProgSumas1, nomProgSuperCerebrosDice, nomTestSumas1 } from "../../../datos/nombresBD";
import Loading from "../../entrenamiento/componentes/Loading";
import { Link } from "react-router-dom";
import RankingNeuronas from "./componentes/RankingNeuronas";

export const RankingPage:React.FC = () => {
    const idTest = useAppSelector((state) => state.activity.id);
    const idProgram = useAppSelector((state) => state.activity.id_program);
    const [openDetalle,setOpenDetalle] = useState(false);
    const [loading,setLoading] = useState(true);
    const [noMostrarResultado,setNoMostrarResultado] = useState(false);
    const [lectura,setLectura] = useState(false);
    const { consultarRanking } = useTests();
    const [printFecha,setPrintFecha] = useState("");
    const [selectCalificacion,setSelectCalificacion] = useState<Calificacion>();
    const [rankingData,setRankingData] = useState<Calificacion[]>([]);
    
    const consulta = async () => {
        setLoading(true)
        
        let resultado;
        resultado = await consultarRanking(idTest,idProgram);
        resultado.forEach((item) => {
            setRankingData((prevState) => [...prevState,item])
        })
        console.log("🚀 ~ resultado.forEach ~ resultado:", resultado)
        
        setLoading(false)
    }

    const convertirFecha = () =>{
        if(selectCalificacion?.tiempo){
            const timestamp = selectCalificacion?.tiempo.seconds + selectCalificacion?.tiempo.nanoseconds / 1e9;
            const date = fromUnixTime(timestamp);
            const dateString = format(date, 'yyyy-MM-dd HH:mm:ss');
            setPrintFecha(dateString)
        }
    } 

    useEffect(() => {
        convertirFecha();
    }, [selectCalificacion])

    useEffect(() => {
        setRankingData([]);
        consulta();
        if(idProgram == nomProgSumas1 || idProgram == nomProgSuperCerebrosDice || idProgram == nomNeuronas){
            setNoMostrarResultado(true);
        }else{
            setNoMostrarResultado(false);
        }   
        if(idProgram == nomProgLecturaVelocidad){
            setLectura(true)
        }else{
            setLectura(false)
        }
    
    },[])

    const titulo = () =>{
        switch(idProgram){
            case nomProgLecturaVelocidad:
                return "ranking lectura"
            case nomProgSumas1:
                return "ranking sumas"
            case nomProgCodigoPalabras:
                return "codigo de Palabras"
            case nomProgSuperCerebrosDice:
                return "Supercerebros Dice"
            case nomProgMemNombres:
                return "Memoria Nombres"
            case nomProgMemNumeros:
                return "Memoria de Números"
            case nomProgMemPalabras:
                return "Memoria Palabras"
            case nomProgParejas:
                return "Memoria Parejas"
            case nomNeuronas:
                return "Neuronas"
            default:
                return "Ranking"
        }
    }
 
    return(
        <div id="page">
            <Loading isOpen={loading}></Loading>
            <div id='toolbar_default' style={{display:'flex',flexDirection:'row'}}>
                {idProgram !== nomNeuronas ? 
                    <Link className={estiloJuegos.backJuegos} to=".." relative='path'></Link>
                : <br></br>}
                <h1 className="title_default">{titulo()}</h1>
            </div>
            <div id="content_default">
                <RankingNeuronas rankingData={rankingData} noMostrarResultado={noMostrarResultado} lectura={lectura} setOpenDetalle={setOpenDetalle} setSelectCalificacion={setSelectCalificacion} type="juego" tipo={null}/>
            </div>
            {idProgram !== nomNeuronas && openDetalle==true && 
                <div id="modal_cortina_default" onClick={() => setOpenDetalle(false)}>
                    <div id="modal_default" className={`scroll-up ${estiloRanking.modal}`}>
                        <div className={estiloRanking.top}>
                            <label>{selectCalificacion?.nombre}<br/></label>
                            <label style={{color:'inherit'}}>{selectCalificacion?.dependencia}<br/></label>
                            <label style={{color:'inherit'}}>{printFecha} <br/> </label>
                        </div>
                        <div className={estiloRanking.bot}>
                            <label>{selectCalificacion?.total}<br/> puntos</label>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default RankingPage