import { configureStore, createSerializableStateInvariantMiddleware, getDefaultMiddleware } from '@reduxjs/toolkit'
import { authSliceAuth } from './hooks/reducer/reducerAuth'
import { authSliceActivity } from './hooks/reducer/reducerActividad'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { authSliceCuestionario } from './hooks/reducer/reducerCuestionario'
import { sliceEntrenamiento } from './hooks/reducer/reducerEntrenamiento'
import { sliceJuegoSimultaneo } from './hooks/reducer/reducerJuegoSimultaneo'
import { sliceShoppingCart } from './hooks/reducer/reducerShoppingCart'

export const store = configureStore({
    middleware: [
        ...getDefaultMiddleware({
        serializableCheck: false
        }),
    ],
    reducer:{
        auth: authSliceAuth.reducer,
        activity: authSliceActivity.reducer,
        cuestionario: authSliceCuestionario.reducer,
        entrenamiento: sliceEntrenamiento.reducer,
        juegoSimultaneo: sliceJuegoSimultaneo.reducer,
        shoppingCart: sliceShoppingCart.reducer,
    },
}
)

export const useAppDispatch: () => typeof store.dispatch=useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;