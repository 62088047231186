
import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";

/**
 * Interfaz para los datos de jugadores
 */
export interface Calificacion {
    id?: string;

    /** Fecha y hora de uso del programa */
    fecha : Timestamp;

    /** Nivel del jugador */
    nivel? : number;

    /** Resultado del juego */
    resultado : number;
    resultado2? : number;
    
    total?: number;

    /** Tiempo utilizado en el juego */
    tiempo : Timestamp;

    /** Indicador si completo el nivel */
    completado? : boolean;

    // Para el ranking
     /** Nombre de la persona */
     nombre : string;

     /** Dependencia de la persona */
     dependencia : string;
   
}

/**
 * Crear una Calificacion objecto 
 * @function
 * @param {number} nivel - El nivel actual del jugador.
 * @param {number} resultado - Primer resultado del programa.
 * @param {number} resultado2 - segundo resultado del programa.
 * @param {number} tiempo - Tiempo demorado en la ejecucion del programa.
 * @param {boolean} completado - Si completo el programa.
 * @returns {Calificacion} - Construido objecto de Calificacion
 */

export const aCalificacionUsuario = ( resultado : number|null, resultado2 : number|null, tiempo : Timestamp|null) : Calificacion => {
    
    const calificacion: Calificacion = {
        fecha : Timestamp.now()
    } as Calificacion;

    if (resultado !== null && resultado !== undefined) {
        calificacion.resultado = resultado;
    }    
    if (resultado2 !== null && resultado2 !== undefined) {
        calificacion.total = resultado2;
    }    
    if (tiempo !== null && tiempo !== undefined) {
        calificacion.tiempo = tiempo;
    }    
    
    return calificacion;       
}

export const aCalificacionTest = (total : number|null, resultado : number|null, resultado2:number|null, tiempo : Timestamp|null, nombre: string, dependencia: string,id : string|null) : Calificacion => {
    
    const calificacion: Calificacion = {
        fecha : Timestamp.now(),       
        nombre : nombre,
        dependencia : dependencia,
    } as Calificacion;

    if (resultado !== null && resultado !== undefined) {
        calificacion.resultado = resultado;
    }    
    if (total !== null && total !== undefined) {
        calificacion.total = total;
    }    
    if (tiempo !== null && tiempo !== undefined) {
        calificacion.tiempo = tiempo;
    }   
    if (id !== null && id !== undefined) {
        calificacion.id = id;
    }     
    return calificacion;       
}

export const aCalificacionUsos = (nivel : number, total? : number, tiempo?: number) : Calificacion => {
    return {
        fecha: Timestamp.now(),
        correctas: nivel,
        total: total ? total : null,
        tiempo: tiempo ? tiempo : null,
    } as unknown as Calificacion;        
}


export const mejoroResultado = (rankingAct: Calificacion, rankingNuevo: Calificacion): boolean => {
    if (rankingAct === undefined || rankingAct === null){
        return true
    }else if(rankingAct?.resultado < rankingNuevo?.resultado || rankingAct?.resultado !== rankingAct.total){
        return true;
    }else if(rankingAct?.total < rankingNuevo?.total || rankingAct?.resultado !== rankingAct.total){
        return true;
    }else if( typeof(rankingAct?.tiempo)  === typeof(0) || rankingAct?.tiempo > rankingNuevo?.tiempo){
        return true;
    }
    return false;
}

export const mejoroResultadoLectura = (rankingAct: Calificacion, rankingNuevo: Calificacion): boolean => {
    if(rankingAct.resultado2 < rankingNuevo.resultado2){
        return true;
    }else if(rankingAct.resultado2 === rankingNuevo.resultado2){
        if (rankingAct.resultado < rankingNuevo.resultado) {
            return true;
        } 
    }        
    return false;
}

/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toCalificacion = (doc : FireBaseDocument) : Calificacion => {    
    if(doc.exists()){        
        const calify = doc.data() as Calificacion;
        console.log("🚀 ~ toCalificacion ~ doc.data():", doc.data())
        console.log("🚀 ~ toCalificacion ~ doc.data():", doc.data().tiempo)
        calify.id = doc.id;
        return calify
    }
    return undefined;
    
}

