import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTests } from "../../hooks/controlador/useTests";
import ModalCrearProgramasTest from "../test/componentes/ModalCrearProgramasTest";
import ModalCrearParticipantesTest from "../test/componentes/ModalCrearParticipantesTest";
import { Test } from "../../modelos/Test";
import ModalCrearAdminsTest from "../test/componentes/ModalCrearAdminsTest";
import style from '../../theme/admin/programa.module.css'

type Params = {
    idTests: string;
    nombreTest: string;
}

const AdminProgramasPage:React.FC = ({}) => {
    const { idTests, nombreTest } = useParams<Params>();
    const [openVentanaPrograma,setOpenVentanaPrograma] = useState(false);
    const [openVentanaAdministradoresTest,setOpenVentanaAdministradoresTest] = useState(false);
    const [openVentanaUsuario,setOpenVentanaUsuario] = useState(false);
    const [arrayPrograms, setArrayPrograms] = useState([]);
    const [arrayParticipantes, setArrayParticipantes] = useState([]);
    const [arrayModeradores, setArrayModeradores] = useState([]);
    const [test,setTest] = useState<Test>();
    const navigate = useNavigate();
    
    const { 
        consultarTests, 
        consultarProgramasTest, 
        consultarParticipantesTest,
        consultarModeradoresTest,
     } = useTests();
    

    const consulta = async () => {
        const resultadosProgramas = await consultarProgramasTest(idTests);
        setArrayPrograms(resultadosProgramas);
        const resultadosModeradores = await consultarModeradoresTest(idTests);
        setArrayModeradores(resultadosModeradores);
        const resultadosParticipantes = await consultarParticipantesTest(idTests);
        setArrayParticipantes(resultadosParticipantes);
        const actualTest = await consultarTests(nombreTest);    
        console.log("🚀 ~ file: AdminProgramasPage.tsx:47 ~ consulta ~ actualTest:", actualTest)
        setTest(actualTest[0])        
        console.log("🚀 ~ file: AdminProgramasPage.tsx:52 ~ consulta ~ resultadosProgramas:", resultadosProgramas)
    }
    
    
    useEffect(() => {
        setArrayPrograms([])
        setArrayModeradores([])
        setArrayParticipantes([])
        consulta()
    },[openVentanaPrograma,openVentanaUsuario])


    return(
        <div className={style.page}>
            <header className={style.header}>
                <div className={style.toolbar}>
                    <button className={style.backButton} onClick={() => navigate(-1)}/>
                    <h1 >{nombreTest}</h1>
                </div>
            </header>
            <div className={style.content}>
                <p>Programas</p>
                <div className={style.lista}>
                    {arrayPrograms.map((element,index) => {
                        if(element.nombre){
                            return(
                                <div className={style.card} key={index}>
                                    <p>
                                        {element.nombre}
                                    </p>
                                </div>
                            )
                        }
                    }   
                    )}
                    {arrayPrograms.length === 0 ? <p>No hay Programas</p>: <br/>}
                </div>
                <button className={style.button} onClick={()=>{setOpenVentanaPrograma(true)}}>Crear Programa</button>
                <p>Administradores</p>
                <div className={style.lista}>
                    {arrayModeradores.map((element,index) => {
                        return(
                            <div className={style.card} key={index}>
                                <p >
                                    {element.correo}
                                </p>
                            </div>
                        )
                    }
                    )}
                    {arrayModeradores.length === 0 ? <p>No hay Administradores</p>: <br/>}
                </div>
                <button className={style.button} onClick={()=>{setOpenVentanaAdministradoresTest(true)}}>Agregar Administradores</button>
                <p>Participantes</p>
                <div className={style.lista}>
                    {arrayParticipantes.map((element,index) => {
                        return(
                            <div className={style.card} key={index}>
                                <p >
                                    {element.correo}
                                </p>
                            </div>
                        )
                    }
                    )}
                    {arrayParticipantes.length === 0 ? <p>No hay Participantes</p>: <br/>}
                </div>
                <button className={style.button} onClick={()=>{setOpenVentanaUsuario(true)}}>Agregar Usuarios</button>
            </div>
                <ModalCrearProgramasTest openVentanaPrograma={openVentanaPrograma} setOpenVentanaPrograma={setOpenVentanaPrograma} nombreTest={idTests}/>
                <ModalCrearAdminsTest openVentanaAdministradoresTest={openVentanaAdministradoresTest} setOpenVentanaAdministradoresTest={setOpenVentanaAdministradoresTest} test={test}/>
                <ModalCrearParticipantesTest openVentanaUsuario={openVentanaUsuario} setOpenVentanaUsuario={setOpenVentanaUsuario} test={test}/>
        </div>
    );
}

export default AdminProgramasPage;