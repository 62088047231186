
import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";

/**
 * Interfaz para los datos de Lectura
 */
export interface Lectura {
    id?: string;
    titulo: string
    nivel : number;
    texto : string;
    autor : string;
    idioma : string;
    idCuestionario? : string;
}

/**
 * Crear una Lectura objecto 
 * @function
 * @param {number} nivel - El nivel actual del jugador.
 * @param {number} resultado - El acierts del nivel actual del jugador.
 * @param {number} tiempo - El acierts del nivel actual del jugador.
 * @param {boolean} completado - El acierts del nivel actual del jugador.
 * @returns {Lectura} - Construido objecto de Lectura
 */

export const aLectura = (id: string, titulo: string,nivel: number,texto: string, autor: string, idioma: string,idCuestionario?: string,
    ) : Lectura => {
        return {
            id: id,
            titulo: titulo,
            nivel: nivel,
            texto: texto,
            autor: autor,
            idioma: idioma,
            idCuestionario: idCuestionario
        } as Lectura;        
}

/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toLectura = (doc : FireBaseDocument) : Lectura => {
    const lectura = doc.data() as Lectura;
    lectura.id = doc.id;
    return lectura;
}

