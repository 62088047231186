import React, { useEffect, useRef, useState } from 'react'
import { useStorgeImagenes } from '../../../hooks/baseDeDatos/useStorageImagenes';
import { useAppSelector } from '../../../store';
import { useDato } from '../../../auth';
import { useToast } from '../../../hooks/useToast';
import '../../../theme/pages.css';
interface Props{
  openModal:boolean;
  setOpenModal:React.Dispatch<React.SetStateAction<boolean>>;
  photoPerfil: string;
  setPhotoPerfil: (newPhoto:string) => void;
}

const PhotoModal:React.FC<Props> = (props) => {
  const [image, setImage] = useState(props.photoPerfil);
  const fileInputRef = useRef<HTMLInputElement>();
  
  const userId = useAppSelector((state) => state.auth.userId);

  const {
    agregarImg
  } = useStorgeImagenes();

  const {
    photoFormatoIncorrecto,
    photoMuyGrande,
  } = useToast();
  
  const {
    state
	} = useDato();

  
  useEffect(() => {
    return () =>{
      if(image.startsWith('blob:')){
        URL.revokeObjectURL(image)
      }
    };
  }, [image])

  const handleSaveImage = async () => {
    const response = await fetch(image);
    const blob = await response.blob();
    const picture = await agregarImg("usuarios" + "/" + userId + "/" + "imagenes" + "/" + "fotoPerfil", blob);
    props.setPhotoPerfil(image);
    props.setOpenModal(false);
  }
  

  const handleInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Obtener el primer archivo seleccionado
    const allowedTypes = ['image/jpeg', 'image/png']; // Tipos MIME permitidos
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB en bytes
  
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        photoFormatoIncorrecto();
        return;
      }
  
      if (file.size > maxSizeInBytes) {
        photoMuyGrande();
        return;
      }
  
      const pictureURL = URL.createObjectURL(file);
      setImage(pictureURL);
    }
  }; 

  return (
    <div id="modal_cortina_default" className='modalCortinaDatosPersonales' onClick={() => props.setOpenModal(false)}>
      <div id="modal_default" className='modalDatosPersonales' onClick={(e) => e.stopPropagation()}>
        
        <img src='/assets/icon/iconClose.svg' className='close' onClick={() => props.setOpenModal(false)}/>
        <input type='file' accept='image/*' style={{position: 'absolute',top:'5vh'}} onChange={(event) => handleInputFile(event)} hidden ref={fileInputRef}/>
        <div className="marco-foto-morado marco-foto" onClick={(e) => e.stopPropagation()}>
          <div className="contenedor-foto-datos contenedor-foto">
            <img  src={image} alt="Foto de usuario" className='foto-usuario'></img>
          </div>
        </div >
        {image === props.photoPerfil ?
          <button className='buttonMorado' onClick={() => fileInputRef.current.click()}>CAMBIAR</button>
          :<>
            <button id='button_rosado_default' style={{marginTop:'3.67svh',marginBottom:'1svh'}} className='buttonsModal' onClick={() => handleSaveImage()}>GUARDAR CAMBIOS</button>
            <button id='button_bordes_default' style={{marginBottom:'6.5svh'}} className='buttonsModal' onClick={() => props.setOpenModal(false)}>CANCELAR</button>
          </>
        }

      </div>
    </div>
  )
}

export default PhotoModal
