
const test_general = "test_general";
const test_inicial = "test_inicial";

const nombre_test_inicial = "Test inicial";
const descripcion_test_inicial = "Test inicial general";

// tipos de los programas
const tipo_test = "test";
const tipo_juego = "juego";

// Estados de los test
const estado_activo = "activo";
const estado_inactivo = "inactivo";
const estado_finalizado = "finalizado";


export {    
    test_general,
    test_inicial,   
    nombre_test_inicial,   
    descripcion_test_inicial,  
    estado_activo,
    estado_inactivo,
    estado_finalizado,
    tipo_test,
    tipo_juego
};
