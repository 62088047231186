export const buttons = [
		
    [
        { value: "7", special: false },
        { value: "8", special: false },
        { value: "9", special: false },
    ],
    [
        { value: "4", special: false },
        { value: "5", special: false },
        { value: "6", special: false },
    ],
    [
        { value: "1", special: false },
        { value: "2", special: false },
        { value: "3", special: false },
    ],
    [
        { value: "Borrar", special: true, send:false },
        { value: "0", special: false },
        { value: "Enviar", special: true, send: true }
    ]
];