import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Producto, UsuarioCliente } from '../../modelos/Producto';

interface ShopppingCart {
    infoCliente: UsuarioCliente;
    productos: Producto[];
    total: number;
}

const initialState:ShopppingCart = {
    infoCliente:null,
    productos: [],
    total:0,
}

const calcularTotal = (productos: Producto[]) => {
    return productos.reduce((total, producto) => total + (producto.valor * producto.precio), 0);
};

export const sliceShoppingCart = createSlice({
    name: 'shopppingCart',
    initialState,
    reducers:{
        addProductoCarrito:(state,action: PayloadAction<{producto:Producto}>) => {
            state.productos.push(action.payload.producto);
            state.total = calcularTotal(state.productos);
        },
        eliminarProductoCarrito: (state, action: PayloadAction<{ id: string }>) => {
            state.productos = state.productos.filter(producto => producto.id !== action.payload.id);
        },
        cantidadProducto: (state, action: PayloadAction<{ id: string, cantidad: number }>) => {
            const producto:Producto = state.productos.find(producto => producto.id === action.payload.id);
            if (producto) {
              producto.precio = action.payload.cantidad;
            }
            state.total = calcularTotal(state.productos);
        },
        agregarInfoCarrito: (state, action: PayloadAction<{ usuario: UsuarioCliente }>) => {
            state.infoCliente = action.payload.usuario;
        },
        limpiarCarrito:(state) => {
            state.productos = [];
            state.total = 0;
        }
    }
});

export const { addProductoCarrito, eliminarProductoCarrito, cantidadProducto, agregarInfoCarrito, limpiarCarrito } = sliceShoppingCart.actions;