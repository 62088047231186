
import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";
import { integrante } from "./Sala";


export interface Versus {
    id?: string;

    juego?:string;

    iniciar?: number;

    mensaje?: mensaje[];

    integrantes: integrante[];
   
}

export interface mensaje{
    nombre:string;
    mensaje:string;
}

export interface Ranking{
    nombre: string;
    tiempo: Timestamp;
    total: number;
}

/**
 * Crear una Sala objecto 
 * @function
 * @param {boolean} iniciar - El nivel actual del jugador.
 * @param {integrante[]} integrantes - Primer resultado del programa.
 * @returns {Versus} - Construido objecto de Sala
 */

export const aVersusUsuario = (iniciar:number,integrante:integrante[],juego:string,mensaje:mensaje[]) : Versus => {
    
    const sala: Versus = {      
        iniciar,
        juego,
        mensaje,
    } as Versus;
  
    if (integrante !== null && integrante !== undefined) {
        sala.integrantes = integrante;
    }    
    
    return sala;
}

/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toVersus = (doc : FireBaseDocument) : Versus => {    
    if(doc.exists()){        
        const sala = doc.data() as Versus;
        sala.id = doc.id;
        return sala
    }
    return undefined;
    
}

export const toIntegrantesVersus = (doc : FireBaseDocument) : integrante => {    
    if(doc.exists()){        
        const sala = doc.data() as integrante;
        return sala
    }
    return undefined;
    
}

