import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface Actividad {
    name: string;
    id: string;
    confirmacion?:number;
    torneo?: boolean;
    id_program?: string;
    tiene_bloqueo?: number;
    tipoPregunta?: string;
    subtitulo?: string;
}

const initialState: Actividad = {
    name: null,
    id: null,
}

export const authSliceActivity = createSlice({
    name: 'activity',
    initialState,
    reducers:{
        newActivity: (state,action: PayloadAction<{id:string, name:string}>) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
        },
        newTorneo: (state,action: PayloadAction<{id:string, name:string, torneo: boolean}>) => {
            state.id = action.payload.id;
            state.torneo = action.payload.torneo;
            console.log("🚀 ~ file: reducerActividad.ts:27 ~ action.payload.torneo:", action.payload.torneo)
            state.name = action.payload.name;
        },
        checkOutActivity: (state) => {
            state.id = null;
            state.name = null
            state.id_program = null
        },
        changePrograma: (state, action: PayloadAction<{id:string}>) => {
            state.id_program = action.payload.id;
        },
        changeCondicion: (state, action: PayloadAction<{condicion:number}>) => {
            state.confirmacion = action.payload.condicion;
        },
        addParametroBloqueo: (state, action: PayloadAction<{tiene_bloqueo:number}>) => {
            state.tiene_bloqueo = action.payload.tiene_bloqueo;
        },
        addTipoPregunta: (state, action: PayloadAction<{tipoPregunta:string}>) => {
            state.tipoPregunta = action.payload.tipoPregunta;
        },
        guardarSubtitulo: (state, action: PayloadAction<{subtitulo:string}>) => {
            state.subtitulo = action.payload.subtitulo;
        },
    }
});

export const { guardarSubtitulo, addParametroBloqueo, addTipoPregunta, changeCondicion, changePrograma, checkOutActivity, newActivity, newTorneo} = authSliceActivity.actions;