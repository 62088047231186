import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { auth } from '../../firebase';


interface Auth {
    loadingAuth : boolean;
    loading : boolean;
    loggedIn : boolean;
    creatingUsers: boolean;
    userId : string;
    email: string;
    neuronas: number;
    tests_inicial_realizado: boolean;
}

const initialState: Auth = {
    loadingAuth: false,
    loading: false,
    loggedIn:false,
    creatingUsers:false,
    userId: null,
    email:null,
    neuronas: 0,
    tests_inicial_realizado: false,
}

export const authSliceAuth = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        login: (state,action: PayloadAction<{userId:string, email:string}>) => {
            state.loading = true;
            if(state.creatingUsers===false){
                state.userId = action.payload.userId;
                state.email = action.payload.email;
                state.loggedIn = true;
                auth.currentUser.getIdToken()
                .then( token =>
                    localStorage.setItem('accessToken', token)
                );
            }
            state.loading = false;
        },
        logout: (state) => {
            state.loading = true;
            if(!state.creatingUsers){
                state.email = initialState.email;
            }
            state.loggedIn = initialState. loggedIn;
            state.creatingUsers = initialState.creatingUsers;
            state.userId = initialState.userId;
            state.loading = initialState.loading;
            localStorage.removeItem('accessToken');        
        },
        creater: (state) =>{
            state.loading = !state.loading;
            state.creatingUsers = !state.creatingUsers;
        },
        loadAuth: (state) =>{
            state.loadingAuth = !state.loadingAuth;
        },
        load: (state) =>{
            state.loading = !state.loading;
        },
        initialNeuronas: (state, action: PayloadAction<{cantidad: number}>) => {
            state.neuronas = action.payload.cantidad;
        },
        sumaNeuronas: (state, action: PayloadAction<{cantidad: number}>) => {
            state.neuronas = state.neuronas + action.payload.cantidad;
        },
        modTestInicial: (state, action: PayloadAction<{condicion: boolean}>) => {
            state.tests_inicial_realizado = action.payload.condicion;
        },

    }
});

export const { modTestInicial, sumaNeuronas, initialNeuronas, loadAuth, load, creater, login, logout} = authSliceAuth.actions;