import  "../../../theme/main.css"; 
import style from "../../../theme/ranking.module.css"
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store";
import { Calificacion} from "../../../modelos/Calificacion";
import { format, fromUnixTime } from "date-fns";
import { nomNeuronas } from "../../../datos/nombresBD";
import RankingNeuronas from "./componentes/RankingNeuronas";
import useNeurona from "../../../hooks/controlador/useNeurona";
import { useLocation } from "react-router-dom";
import Loading from "../../entrenamiento/componentes/Loading";

export const RankingPageNeuronas:React.FC = () => {
    const location = useLocation();
    const idTest = useAppSelector((state) => state.activity.id);
    const idProgram = useAppSelector((state) => state.activity.id_program);
    const [openDetalle,setOpenDetalle] = useState(false);
    const [loading,setLoading] = useState(true);
    const [tipo,setTipo] = useState("SEMANAL");
    const [noMostrarResultado,setNoMostrarResultado] = useState(false);
    const [lectura,setLectura] = useState(false);
    const [criterio1,setCriterio1] = useState("");
    const [criterio2,setCriterio2] = useState("");
    const [printFecha,setPrintFecha] = useState("");
    const [selectCalificacion,setSelectCalificacion] = useState<Calificacion>();
    //const [rankingDataTotal,setRankingDataTotal] = useState<Calificacion[]>([]);
    const [rankingData,setRankingData] = useState<Calificacion[]>([]);
    
    const { consultarTodasNeuronas, consultarNeuronasSemana } = useNeurona();


    const consulta = async () => {
        console.log("🚀 ~ file: RankingPage.tsx:65 ~ consulta ~ idProgram:", idProgram)
        console.log("🚀 ~ file: RankingPage.tsx:65 ~ consulta ~ idTest:", idTest)
        setLoading(true)
        
        console.log("🚀 ~ file: RankingPage.tsx:67 ~ consulta ~ criterio1:", criterio1)
        console.log("🚀 ~ file: RankingPage.tsx:67 ~ consulta ~ criterio2:", criterio2)
        //const resultado = await consultarRanking(idTest,idProgram,criterio1,criterio2);
        let resultado;
        resultado = await consultarNeuronasSemana();
        console.log("🚀 ~ file: RankingPage.tsx:56 ~ consulta ~ resultado:", resultado)

        resultado.forEach((item) => {
            setRankingData((prevState) => [...prevState,item])
        })

        setLoading(false)
    }

    const convertirFecha = () =>{
        if(selectCalificacion?.fecha){
            const timestamp = selectCalificacion?.fecha.seconds + selectCalificacion?.fecha.nanoseconds / 1e9;
            const date = fromUnixTime(timestamp);
            const dateString = format(date, 'yyyy-MM-dd HH:mm:ss');
            setPrintFecha(dateString)
        }
    } 

    useEffect(() => {
        convertirFecha();
    }, [selectCalificacion])

    useEffect(() => {
        if(location.pathname == "/my/rankingNeuronas"){
            setRankingData([])
            //setRankingDataTotal([])
            console.log("🚀 ~ file: RankingPage.tsx:83 ~ useEffect ~ idProgram:", idProgram)
            consulta()
            setNoMostrarResultado(true);
            setLectura(false)
            
        }
    },[location])

    const titulo = () =>{
        switch(idProgram){
            case nomNeuronas:
                return "Neuronas"
            default:
                return "Ranking"
        }
    }
 
    return(
        <div id='page'>
            <Loading isOpen={loading}></Loading>
            <div id='toolbar_default' >
                <h1 className="title_default">{titulo()}</h1>
            </div>
            <div id='content_default' >
                <div className={style.paddingRanking}>
                    <RankingNeuronas rankingData={rankingData} noMostrarResultado={noMostrarResultado} lectura={lectura} setOpenDetalle={setOpenDetalle} setSelectCalificacion={setSelectCalificacion} type="juego" tipo={tipo}/>
                </div>
            </div>
        </div>
    );
}

export default RankingPageNeuronas