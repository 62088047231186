import React, { useEffect, useState } from "react";
import estiloTest from "../../../../theme/testInicial.module.css"
interface prop{
    porcentaje: number;
}
export const CircularChart:React.FC<prop>= (props)=> {

  const [rotationLeft, setRotationLeft] = useState(0);
  const [grados, setGrados] = useState(0);
  const [rotationRight, setRotationRight] = useState(0);
  const myClass = estiloTest['testInicial_right__tR+Vb'];

  useEffect(() => {
    const grados = (props.porcentaje / 100) * 360; // Calcular el ángulo de rotación
    setGrados(grados);
    if(grados>180){
      setRotationLeft(180);
      setRotationRight(grados-180);
    }else{
      setRotationLeft(grados);
      setRotationRight(0);
    }
  }, [props.porcentaje]);

  return (
    <div className={estiloTest.circular}>
      <style>
      {`
        @keyframes rotateLeft {
          100% {
            transform: rotate(${rotationLeft}deg);
          }
        }
        
        @keyframes rotateRight {
          100% {
            transform: rotate(${rotationRight}deg);
          }
        }
        
        .${estiloTest.left} .${estiloTest.progress} {
          animation: rotateLeft 1s linear both;
        }
        
        .progresoRight {
          animation: rotateRight 1s linear both;
        }
      `}
    </style>
      <div className={`${estiloTest.interno} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.bordesRosados:estiloTest.bordesMorados:''}`}></div>
      <div className={`${estiloTest.numero} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.numeroRosado:estiloTest.numeroMorado:''}`}>
          {props.porcentaje}<span className={estiloTest.porcentaje}>%</span>
      </div>
      <div className={estiloTest.circulo}>
          <div className={`${estiloTest.bar} ${estiloTest.left}`}>
            <div className={`${estiloTest.progress} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.progresoRosado:estiloTest.progresoMorado:''}`}></div>
          </div>
          <div className={`${estiloTest.bar} ${estiloTest.right}`}>
            <div className={`${estiloTest.progress} progresoRight ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.progresoRosado:estiloTest.progresoMorado:''}`}></div>
          </div>
      </div>
      <div className={`${estiloTest.bordes} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.bordesRosados:estiloTest.bordesMorados:''}`}>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l10} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l20} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l30} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l40} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l50} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l60} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l70} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l80} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l90} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l100} ${props.porcentaje>10 ? props.porcentaje >40 ?estiloTest.lineaRosada:estiloTest.lineaMorada:''}`}></div>
        
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l10Blanca} `}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l20Blanca} `}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l30Blanca} `}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l40Blanca} `}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l50Blanca} `}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l60Blanca} `}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l70Blanca} `}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l80Blanca} `}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l90Blanca} `}></div>
        <div className={`${estiloTest.lineaCirculo} ${estiloTest.l100Blanca} `}></div>
      </div>
      
    </div>
    
  );
};
  
  export default CircularChart;
  