import { useState, useEffect } from 'react';
import { Howl } from 'howler';

import style from "../../../theme/SuperCerebrosDice.module.css";
import estiloJuegos from "../../../theme/Juegos.module.css";
import "../../../theme/pages.css";

import { nomProgSuperCerebrosDice} from '../../../datos/nombresBD';
import { useDato } from "../../../auth";
import { initialNeuronas } from '../../../hooks/reducer/reducerAuth';
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { useAppDispatch, useAppSelector } from '../../../store';
import { test_general } from '../../../datos/DatosTests';
import { obtenerNeuronas } from '../../../utils/neuronasUtil';
import { changeCondicion, changePrograma, newActivity } from '../../../hooks/reducer/reducerActividad';
import { Link, useNavigate } from 'react-router-dom';
import PopoverSuperCerebrosDice from './componentes/PopoverSuperCerebrosDice';
import { changePuntaje, terminadoJuego } from '../../../hooks/reducer/reducerJuegoSimultaneo';


const JuegoSuperCerebrosDice = () => {

    const sonidos = {
    rojo: new Howl({src: ['/assets/sounds/sound_do.mp3']}),
    azul: new Howl({ src: ['/assets/sounds/sound_re.mp3'] }),
    amarillo: new Howl({ src: ['/assets/sounds/sound_mi.mp3'] }),
    rosado: new Howl({ src: ['/assets/sounds/sound_fa.mp3'] }),
    morado: new Howl({ src: ['/assets/sounds/sound_sol.mp3'] }),
    verde: new Howl({ src: ['/assets/sounds/sound_la.mp3'] }),

}

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userId = useAppSelector((state) => state.auth.userId);
    const testId = useAppSelector((state) => state.activity.id);
    const esJuego = useAppSelector((state) => state.activity.id === test_general);
    const neuronas = useAppSelector((state) => state.auth.neuronas);
    const esEntrenamiento = useAppSelector((state) => state.activity.id == "entrenamiento");
    const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento?.id);

    const {				
		state,
	} = useDato();
	const {
        guardarDatosCalificacion,	
        guardarDatosCalificacionTestGeneral,	
		getCalificacionGeneral,        
    } = useJuegos();

    const segundaEtapa = 2;

    const [etapa, setEtapa] = useState<number>(1);
    const [secuencia, setSecuencia] = useState<string[]>([]); // Secuencia de colores generada por el juego
    const [secuenciaUsuario, setSecuenciaUsuario] = useState<string[]>([]); // Secuencia ingresada por el usuario
    const [turno, setTurno] = useState<number>(0); // Turno actual
    const [juegoTerminado, setJuegoTerminado] = useState<boolean>(false); // Indica si el juego ha terminado o no
    const [isVisible, setIsVisible] = useState(false);
    const [botonResaltado, setBotonResaltado] = useState<string>(''); // Estado para almacenar el color del botón resaltado
    const [cantidadCorrecta, setCantidadCorrecta] = useState<number>(0);
    const [ultimoPuntaje, setUltimoPuntaje] = useState<number>(0); // Ultimo puntaje obtenido
    const [contador, setContador] = useState(10);
    const colores = ['rojo', 'verde', 'azul', 'amarillo','rosado', 'morado']; // Colores disponibles
    const [nuevasNeuronas, setNuevasNeuronas] = useState<number>(0); 
    const [iniciado, setIniciado] = useState(false);
    const [turnoJugador, setTurnoJugador] = useState(false);
    const [mensaje, setMensaje] = useState<string>("");

    const reproducirSonido = (tecla) => {
        return new Promise((resolve) => {
            sonidos[tecla].play();
            sonidos[tecla].once('end', () => {
                resolve(undefined);
            });
        });
    };

    
    // Función para generar una nueva secuencia de colores
    const GenerarSecuencia = () => {
        setMensaje("SuperCerebros Dice")
        const nuevaSecuencia = [];
        var nuevoColor = colores[Math.floor(Math.random() * 6)];
        nuevaSecuencia.push(nuevoColor);
        console.log("🚀 ~ GenerarSecuencia ~ nuevaSecuencia:", nuevaSecuencia)
        setSecuencia(nuevaSecuencia);
        setCantidadCorrecta(0);
        setTurno(0);
        setSecuenciaUsuario([]);
        setJuegoTerminado(false);
        setIsVisible(true);
        setTurnoJugador(false)
        setTimeout(() => {
            setIsVisible(false);
            mostrarSecuencia(nuevaSecuencia);
        }, 1000);
        
    };
    const aumentarSecuencia = () => {
        const nuevaSecuencia = [...secuencia];
        const nuevoColor = colores[Math.floor(Math.random() * 6)];
        
        nuevaSecuencia.push(nuevoColor);
        setSecuencia(nuevaSecuencia);
        return nuevaSecuencia;
    } 
    // Función para mostrar la secuencia de colores al jugador
    const mostrarSecuencia = (secuencia) => {
        let i = 0;
        const intervalo = setInterval(() => {
            setTurnoJugador(false)
            resaltarColor(secuencia[i]);
            
            i++;
            if (i >= secuencia.length) {
                reiniciarContador()
                
                setTimeout(() => {
                    setIsVisible(true);
                }, 300); 
                setMensaje("Tu Turno");
                setTimeout(() => {
                    setTurnoJugador(true)
                    setIsVisible(true);
                }, 1300); 
                clearInterval(intervalo);
            }
        }, 500);
        
    };
    
    // Función para resaltar un color durante un breve período de tiempo
    const resaltarColor = (color:string) => {
        setBotonResaltado(color); // Establece el color del botón a resaltar
        reproducirSonido(color);
        setTimeout(() => {
          setBotonResaltado(''); // Quita el resaltado del botón después de un tiempo
        }, 300);
    };
    
    // Función para manejar el clic del usuario en un color
    const manejarClicColor = (color:string) => {
        
        reiniciarContador();
        const nuevaSecuenciaUsuario = [...secuenciaUsuario];
        nuevaSecuenciaUsuario.push(color);
        setSecuenciaUsuario(nuevaSecuenciaUsuario);
        
        if (color.length !== 0) {
            resaltarColor(color);
            if (nuevaSecuenciaUsuario.length === secuencia.length) {
                verificarSecuencia(nuevaSecuenciaUsuario);
            }else{
                reiniciarContador();
            }
        }else{
            verificarSecuencia(nuevaSecuenciaUsuario);
        }
    };
    // Función para verificar si la secuencia ingresada por el usuario es correcta
    const verificarSecuencia = (usuarioSecuencia:string[]) => {
        let cantidadCorrectaTemporal =usuarioSecuencia.length;
        if (JSON.stringify(secuencia) === JSON.stringify(usuarioSecuencia)) {
                
            setTurno(turno + 1);
            setSecuenciaUsuario([]);

            setTurnoJugador(false)
            setMensaje("SuperCerebros Dice");
            setIsVisible(true);
            // console.log("verificar")
            const nuevaSecuencia = aumentarSecuencia();
            setTimeout(() => {
                setIsVisible(false);
                mostrarSecuencia(nuevaSecuencia);
            }, 1000);
            
            setCantidadCorrecta(cantidadCorrectaTemporal );
        } else {
            setJuegoTerminado(true);
            aTerceraPantalla();
            setCantidadCorrecta(cantidadCorrectaTemporal -1);
            if(cantidadCorrectaTemporal -1>ultimoPuntaje){
                setUltimoPuntaje(cantidadCorrectaTemporal -1);
            }

            // Obtener Neuronas
            const neuronasNuevas = obtenerNeuronas(nomProgSuperCerebrosDice, ultimoPuntaje, cantidadCorrectaTemporal-1)
            // console.log("🚀 ~ file: JuegoSuperCerebrosDice.tsx:141 ~ verificarSecuencia ~ neuronasNuevas:", neuronasNuevas)
            
            setNuevasNeuronas(neuronasNuevas);
            const neuronasTotales = neuronas + neuronasNuevas
            dispatch(initialNeuronas({cantidad:neuronasTotales}))

            // Guarda la configuracion cuando es el juego o cuando es un test
            if(testId === test_general){  
                guardarDatosCalificacionTestGeneral(userId, nomProgSuperCerebrosDice, neuronasNuevas, cantidadCorrectaTemporal-1, 0, 0, false, state.nombres, state.pais,0,cantidadCorrectaTemporal-1);
            }else{
                guardarDatosCalificacion(userId, testId, nomProgSuperCerebrosDice, neuronasNuevas, cantidadCorrectaTemporal-1, 0, 0, false, state.nombres, state.pais);
            }
        }
    };

    /*
      GETTERS
    */

    const getDescripcionJuego = () => {        
        return (
            <div className={estiloJuegos.descripcionJuego}>
                Observa y repite la secuencia de colores que se te muestra.  <br></br> Cada ronda se vuelve un poco más desafiante.  
            </div>
        );
    }
    
    const reiniciarContador = () => {
        setContador(10);
        setJuegoTerminado(false)
    };

    const IniciarContador = () => {
        setIniciado(true);
    };


    const aTerceraPantalla = () => {
        setSecuencia([]);
        dispatch(terminadoJuego({ ultimoResultado: (cantidadCorrecta * 10), tiempo: 0, finalizado: true }))
        setEtapa(3);
    }

    const handlePlay = () => {		
        GenerarSecuencia();
        IniciarContador()
		setEtapa(segundaEtapa);
	}
    const handleRanking = () => {		
        navigate('/my/juegos/ranking');
	}
    async function handleBackButtonClick(): Promise<void> {
		if(esEntrenamiento){
			await dispatch(newActivity({id:idEntrenamiento,name:"entrenamiento"}))
			await dispatch(changePrograma({id:idEntrenamiento}))
			dispatch(changeCondicion({condicion:10}))
			navigate(-2);
		}else{
			if (testId === 'juego_linea') {
                dispatch(changePuntaje({verPuntaje:true}))
			} else {
				navigate(-1);
			}
		}
	  }
    
    const constructor = () => {    
        
        getCalificacionGeneral(userId, nomProgSuperCerebrosDice).then((calificacion) => {
            if(calificacion !== undefined){
                setUltimoPuntaje(calificacion.total ?? 0);
            }else{
                setUltimoPuntaje(0)
            }
        });        
    }

    useEffect(() => {
        // console.log("entreCONTADOR");
        // console.log("🚀 ~ file: JuegoSuperCerebrosDice.tsx:262 ~ useEffect ~ iniciado:", iniciado)
        // console.log("🚀 ~ file: JuegoSuperCerebrosDice.tsx:263 ~ useEffect ~ juegoTerminado:", !juegoTerminado)
        if(iniciado && !juegoTerminado){
    
            // Función para contar hacia atrás y reiniciar el contador
            const interval = setInterval(() => {
                // console.log("🚀 ~ file: JuegoSuperCerebrosDice.tsx:267 ~ interval ~ contador:", contador)
                if (contador > 0) {
                    setContador(contador - 1);
                }else{
                    setJuegoTerminado(true);
                    manejarClicColor("");
                    clearInterval(interval); 
                }
            }, 1000);
        
            // Limpia el intervalo cuando el componente se desmonta
            return () => clearInterval(interval);
        }
    }, [contador,juegoTerminado,iniciado]);

    useEffect(() => {
        // console.log("entre SC");
        constructor();
    }, []);
    
    
    return (
        <div id='page' style={(testId === 'juego_linea'? {height: "100%"}: {})}>

            {(testId!== 'juego_linea' || etapa === 1) &&
            <div className={etapa === 1 ? estiloJuegos.toolbarSCDice : estiloJuegos.toolbar_default}>
                {esJuego && <Link  className={estiloJuegos.backJuegos} to=".." relative='path'></Link>}
                <h1 className={`title ${estiloJuegos.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'2.37svh'} :{}}> SuperCerebros DICE  </h1>
            </div>
            }
            {etapa === 1 && 
                <div className={estiloJuegos.contenedorIconoSC}>
                    <img src='/assets/componentes/Juegos/SC.svg' className={estiloJuegos.icono}></img>
                </div>
            }
            

            <div id='content_default' className={style.fondo}>
                {etapa === 1 &&
                
                <div className={estiloJuegos.primeraPantalla}>
                    {getDescripcionJuego()} 
                    <button className={estiloJuegos.buttonEmpezar} onClick={handlePlay}>
                        EMPEZAR
                    </button>
                    {/* {esJuego &&
                        <button id='button_bordes_default' onClick={handleRanking}>
                            RANKING
                        </button>
                    }*/}

                </div>
                
                }
                {etapa === 3 &&
                    <div className={style.popover}>
                        <PopoverSuperCerebrosDice nuevasNeuronas={nuevasNeuronas} mensajeInfo= {`Obtuviste ${cantidadCorrecta} secuencias correctas`} nivel={cantidadCorrecta} funcionReiniciar={handlePlay} funcionVolver={handleBackButtonClick} esJuego={esJuego}/>
                    </div>
                }
                {etapa !== 1 && 
                <div className={style.supercerebrosDice}>
                    {isVisible &&
                        <div className={style.mensajeTurno}>{mensaje}</div>
                    }
                    <div className={style.cortina} style={turnoJugador?{display:'none'}:{}}></div>
                    <div className={style.tablero}>
                        <div className={style.fila}>
                            <div
                            className={[style.color,style.rojo, botonResaltado === 'rojo' ? style.resaltado : ''].join(" ")} 
                            onClick={() => manejarClicColor('rojo')}
                            >
                            </div>
                            <div
                            className={[style.color,style.azul, botonResaltado === 'azul' ? style.resaltado : ''].join(" ")}  
                            onClick={() => manejarClicColor('azul')}>
                            </div>
                            
                        </div>
                        <div className={style.fila}>
                            
                            <div
                            className={[style.color,style.amarillo, botonResaltado === 'amarillo' ? style.resaltado : ''].join(" ")} 
                            onClick={() => manejarClicColor('amarillo')}>
                            </div>
                            <div
                            className={[style.color,style.rosado, botonResaltado === 'rosado' ? style.resaltado : ''].join(" ")}  
                            onClick={() => manejarClicColor('rosado')}>
                            </div>
                        </div>
                        <div className={style.fila}>
                            <div
                            className={[style.color,style.morado, botonResaltado === 'morado' ? style.resaltado : ''].join(" ")} 
                            onClick={() => manejarClicColor('morado')}>
                            </div>
                            <div
                            className={[style.color,style.verde, botonResaltado === 'verde' ? style.resaltado : ''].join(" ")} 
                            onClick={() => manejarClicColor('verde')}>
                            </div>
                        </div>
                    </div>
                    <p className={style.nivel}>
                        <span>RECORD: {ultimoPuntaje}</span> <span>Actual: {cantidadCorrecta}</span> 
                    </p>
                    
                </div>
                }
                
            </div>
        </div>
    );
};

export default JuegoSuperCerebrosDice;

