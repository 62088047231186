import { Route, Routes } from "react-router-dom";
import SettingsPage from "./vista/usuario/SettingsPage";
import HomePage from "./vista/HomePage";
import EditarUsuarioPage from "./vista/usuario/EditarUsuarioPage";
import DatosPersonalesPage from "./vista/usuario/DatosPersonalesPage";
import PoliticasPrivacidad from "./vista/usuario/componentes/PoliticasPrivacidad";
import PagoPage from "./vista/admin/PagoPage";
import EntidadesPage from "./vista/admin/EntidadesPage";
import EntidadPage from "./vista/admin/EntidadPage";
import AreaPage from "./vista/admin/AreaPage";

export const TabsHome = () =>{
    return(
        <Routes>
            <Route path='/' element={<HomePage/>}/>
            {/* datosUsuario */}
            <Route path="settings" element={<SettingsPage />}/>	
            <Route path="editarUsuario" element={<EditarUsuarioPage />}/>
            <Route path="datosPersonales" element={<DatosPersonalesPage />}/>
            <Route path="PoliticasPrivacidad" element={<PoliticasPrivacidad />}/>

            {/* paginas pagos de cursos */}
            <Route path="PagoCurso/:titleEntrenamiento" element={<PagoPage/>}/>
            <Route  path="PagoCurso/:titleEntrenamiento/:codigoParams" element={<PagoPage/>}/>

            {/* entidades */}
            <Route path="entidades" element={<EntidadesPage/>}/>	
            <Route path="entidades/:nombreEntidad/:id" element={<EntidadPage/>}/>								
			<Route path="entidades/:nombreEntidad/:idEntidad/:idArea" element={<AreaPage/>}/>	
        </Routes>
    );
}