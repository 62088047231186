import style from "../../../theme/programas/cuestionarios/juegoCodificacion.module.css"; 
import styleGame from "../../../theme/programas/calculo/Game1.module.css";
import styleGame2 from "../../../theme/Juego2.module.css"; 
import estiloJuegos from "../../../theme/Juegos.module.css";
import estiloNeuronas from "../../../theme/PopoverNeuronas.module.css";

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeCondicion } from '../../../hooks/reducer/reducerActividad';

import Loading from '../../entrenamiento/componentes/Loading'
import { EtapaJuegoSumas1 } from '../componentes/EtapaJuegoSumas';
import Button from '../../componentes/Button';
import { buttons } from '../../../utils/Buttons';
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import BotonBordeDegradado from "../../componentes/BotonBordeDegradado";
import ResultadosNumPal from "../resultados/componentes/ResultadosNumPal";
import { obtenerMensaje } from "../../../utils/resultadoUtil";
import BotonesJuegos from "../componentes/BotonesJuegos";
import { useAppSelector } from "../../../store";
import useRegistroEntrenamiento from "../../../hooks/controlador/useRegistroEntrenamiento";

type Params = {
    nivel:string;
  }

const JuegoCodificacion = () => {

    const palabras = [
        "todo","tina","toma","moto","mano","nada","mono","tono","nota","tema","mina",
        "dona","dato","dedo","dama","nido","duna","timo","moda","manta","mato","tono",
        "mina","doma","dona","toma","meta","ñandú","tonta","día","tina","dime","manía",
        "tona","doña","adn","timón","nimio","nanda","monte","nene","mamá","mando",
        "manto","tendí","ten","tendón","tendí","mente","mino","mondé","mónada","tienta",
        "tándem","menú","tonto","anónima","antón","dañó","diman","tonta","tándem",
        "tanteo","tendón","maté","monte","ondea","mandato","diente","don","mina",
        "diente","endo","den","amando","tantea","tinto","teína","nomada","ande","denota",
        "minan","tono","madame","adonde","ten","tante","adonde","mañana","denuedo",
        "medan","donan","todo","onde","mono","denota","manda","adn","dote","denota",// indices 0-99 nivel 1
        /*------------------------------*/
        "reloj","regla","largo","jarrón","loro","gallo","gol","jarra","gorra","rojo","rima","llora",
        "ligero","gorila","relajo","riego","lloro","gel","gloria","llegar","logro","rallar","relajar",
        "gorgojo","gargajo","rollo","ramita","juramento","jaleo","rallar","júramelo","gorrión","lago","llenar",
        "general","lograr","leer","llaga","jarra","lujo","grillar","llamear","jolgorio","llano","ligar",
        "relleno","girar","lila","jalar","rogar","gajo","galleta","alargar","garganta","grano","roer",
        "grillar","golear","lograr","gritar","regar","llamar","lagrimear","tigre","tragar","aligerar","grulla",
        "rollo","alegría","arreglar","lograr","relatar","jarro","delirio","delineado","allegar","girar","allegar",
        "jeringa","gallina","real","alegre","gelatina","jaleo","gorjear","gil","relajo","llagar","llegar",
        "galla","arrollar","arrojar","allegar","allegro","gallardo","regar","regir","jerga","jalar","jalaron",// indices 100-199 nivel 2
        /*------------------------------*/
        "casa","café","vaso","pizza","beso","jefe","vaso","beso","cepa","paz","pez",
        "foco","cabo","pez","cepa","base","capa","pesa","sofá","cava","papa","pavo",
        "copa","sopa","paso","caso","boca","saco","peso","paz","baza","feo","cabo","paso",
        "foca","sopa","vaca","seco","cepo","cabo","pico","zapato","pesa","fase","cima",
        "piso","foso","buzo","besa","casa","piso","pasa","copa","cifra","saca","pez",
        "base","vaso","cepo","casa","zapato","foco","vaca","base","pesa","pasa","zafa",
        "café","cepa","foca","caso","cabo","pazo","peso","cima","sopla","vacía","pesa",
        "pieza","sopa","fresa","zoco","café","cepa","besa","capa","foso","cabo","pico",
        "pesa","fase","vacío","soca","sofoco","bota","pavo","pizza","cabra","copa","cepa",// indices 200-299 nivel 3
        //indices 0-299 nivel 4
    ];

    const { actualizarRegistroEntrenamiento } = useRegistroEntrenamiento();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {nivel} = useParams<Params>();

    const [etapa, setEtapa] = useState(0);
    const [loading, setLoading] = useState(false);
    const [palabrasAPreguntar, setPalabrasAPreguntar] = useState([]);
    const [indicePalabraAMostrar, setIndicePalabraAMostrar] = useState(0);
    const [inputsUsuario, setInputsUsuario] = useState(new Array(10).fill(''));
    const parametro = useAppSelector((state) => state.activity?.tiene_bloqueo ?? 0);
    const userId = useAppSelector((state) => state.auth.userId);
    const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento?.id ?? null);
    const indiceActividad = useAppSelector((state) => state.entrenamiento.current_actividad);
    const indiceSesion = useAppSelector((state) => state.entrenamiento.current_sesion);
    const lenghtActividades = useAppSelector((state) => state.entrenamiento.current_actividades?.length);

    
    const HandleBackButtonClick = async() => {
        if(ContarRespuestas >= parametro){
            actualizarRegistroEntrenamiento(userId,idEntrenamiento,indiceSesion+1,indiceActividad+1);
            dispatch(changeCondicion({condicion:ContarRespuestas}))
            console.log("🚀 ~ HandleBackButtonClick ~ /my/entrenamiento/${idEntrenamiento}/${indiceSesion}:", `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`)
            navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
        }else if(etapa === 2){
            HandleEmpezarJuego()
        }else{
            navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
        }
    }

    function CalcularRespuesta(palabra) {
        const diccionarioConsonantes = {
            'b': 9, 'c': 7, 'd': 1, 'f': 8, 'g': 6, 'j': 6,
            'k': 7, 'l': 5, 'm': 3, 'n': 2, 'ñ': 2, 'p': 9,
            'q': 7, 'r': 4, 's': 0, 't': 1, 'v': 8, 'x': 0,
            'z': 0
        };
    
        let resultado = "";
    
        for (let i = 0; i < palabra.length; i++) {
            let letra = palabra[i].toLowerCase();
            if (diccionarioConsonantes.hasOwnProperty(letra) && (letra !== palabra[i-1]?.toLowerCase() || letra === 'z')
            ) {
                resultado += diccionarioConsonantes[letra];
            }
        }
    
        return resultado;
    }

    function GenerarIndicesValidosRandom(count: number, rangeStart: number = 0, rangeEnd: number = 100) {
        if (count < 10) {
            throw new Error("El tamaño del array debe ser al menos 10.");
        }
        if(rangeStart > rangeEnd)[rangeStart, rangeEnd] = [rangeEnd, rangeStart];

        const rangeDiff = rangeEnd - rangeStart;
        if(rangeDiff < count) return [];
      
        const indices = new Set<number>();
        while (indices.size < count) {
            const indiceAleatorio = Math.floor(Math.random() * rangeDiff + rangeStart);
            indices.add(indiceAleatorio);
        }
      
        return Array.from(indices);
    }

    const HandleEmpezarJuego = () => {
        let rangeStart = 0, rangeEnd = 100;

        const nivelDefinido = parseInt(nivel) || 1;
        if(nivelDefinido >= 4){
            rangeEnd = 300;
        }else if(nivelDefinido > 0){
            rangeStart = (nivelDefinido - 1)*100;
            rangeEnd = rangeStart + 100;
        }
        let palabrasIndices = GenerarIndicesValidosRandom(10, rangeStart, rangeEnd);
        console.log(palabrasIndices);
        setPalabrasAPreguntar(palabrasIndices)
        setIndicePalabraAMostrar(0)
        setInputsUsuario(new Array(10).fill(''));
        setEtapa(1)
    }

    const LayoutCalculator = () => {
        return (
            <div className={styleGame.calculadoraSumas1} style={{}}>
				{buttons.flat().map((button,_index) => {
                    return <Button key={_index} value={button.value} special={button.special} send={button?.send} clickEvent={HandleInput} />;
                })}
			</div>
		);
	}

    const GetMensajes = () =>{
        let porcentaje = ContarRespuestas/10;
        let mensajePersonalizado =obtenerMensaje(porcentaje);
        if (porcentaje > 0) {
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p  className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                </div>
            );
        }else{
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p style={{marginBottom:"2vh"}} className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                </div>
            );
        }   
    }

    const HandleInput = (e:any,textoInput:any) => {
        if(textoInput === 'Enviar'){
            if(indicePalabraAMostrar === 9){
                return aResultadoFinal()
            }else{
                setIndicePalabraAMostrar((prev) => prev+1)
                return
            }
        }
        setInputsUsuario((prev) => {
            const nuevoInputsUsuario = [...prev];
            if (textoInput === 'Borrar') {
                // Elimina el último carácter del valor actual en el índice correspondiente
                nuevoInputsUsuario[indicePalabraAMostrar] = nuevoInputsUsuario[indicePalabraAMostrar].slice(0, -1);
            } else {
                // Actualiza el valor con el textoInput
                nuevoInputsUsuario[indicePalabraAMostrar] = nuevoInputsUsuario[indicePalabraAMostrar] + textoInput;
            }
            return nuevoInputsUsuario;
        })
    }

    function ChecarRespuestas() {
        let correctas: boolean[] = [];
    
        for (let i = 0; i < inputsUsuario.length; i++) {
            const indicePalabra = palabrasAPreguntar[i];
            const palabra = palabras[indicePalabra];
            const respuestaCorrecta = CalcularRespuesta(palabra);
            const respuestaUsuario = inputsUsuario[i];
    
            const esCorrecta = respuestaUsuario === respuestaCorrecta;
            correctas.push(esCorrecta)
        }
    
        return correctas;
    }

    const correctas= useMemo(() => (etapa === 2)? ChecarRespuestas():[], [etapa])
    const ContarRespuestas = useMemo(() => correctas.filter(Boolean).length, [correctas]);
    
    const aResultadoFinal = () => {
        setEtapa(2)
    }

    function getPalabraWrapper(index:number):string {
        return palabras[palabrasAPreguntar[index]]
    }

    useLayoutEffect(() => {
        if(etapa === 0) HandleEmpezarJuego();
    }, [etapa])

    return (
        <div id='page'>
            <Loading isOpen={loading}/>
            <div id='toolbar_default' className={style.toolbar}>
                <div className={estiloJuegos.backJuegos} onClick={() =>{HandleBackButtonClick()}}/>
                <h1 className="title">Cuestionario </h1> 
            </div>
            <div id='content_default'>
                {etapa === 0 && <div>
                    <button onClick={HandleEmpezarJuego}>Empezar</button>
                </div>
                }
                {etapa === 1 && <div className={style.containWindow_1}>
                    {/* <BotonBordeDegradado style={{width:'40%',alignSelf:'end'}} onClick={aResultadoFinal}>Finalizar</BotonBordeDegradado> */}
                    <label className={`${style.frasePrincipal_codify} ${style.frasePrincipal}`}>
                        {palabras[palabrasAPreguntar[indicePalabraAMostrar]]}
                        {/* + {CalcularRespuesta(palabras[palabrasAPreguntar[indicePalabraAMostrar]])} */}
                    </label>
                    <div style={{display:'flex',gap:'2svh'}}>
                        <input type={'text'} readOnly placeholder={''} onChange={(e) => HandleInput(e,e.target.value)} 
                        style={{color:'var(--color-Dark-Grey)',textAlign:'center',fontSize:'3svh',fontWeight:'700'}} value={inputsUsuario[indicePalabraAMostrar]}></input>
                    </div>
                    {LayoutCalculator()}
                </div>}
                {etapa === 2 && <div style={{width:'100%', margin:'3svh 0', height: "100svh"}}>
                    <div className={styleGame2.contenido} style={{padding:`0 5% ${ContarRespuestas<=0? "5%":""}`, height: "84%", display: "flex", flexDirection: "column"}}>
                        {GetMensajes()}
                        <ResultadosNumPal
                            userInputs={inputsUsuario.slice(0, 10)}
                            calificaciones={correctas}
                            getPalabraWrapper={getPalabraWrapper}
                            game={'PALABRAS'}
                            tituloPalabras='Palabra'
                        />
                        <div style={{
                            display: "grid", gridAutoFlow: "column", gridTemplateRows: "auto auto",
                            justifyContent: "space-evenly", alignItems: "center"
                        }}>
                            <p className={styleGame2.textoTuviste}>tuviste</p>
                            <p className={styleGame2.resultado}>{`${ContarRespuestas}/${10}`}</p>
                            <button className={estiloJuegos.buttonPlayAgain}
                            style={{
                                color: "white"   , fontSize: "1.2rem"    , fontWeight: "600",
                                maxHeight: "3rem", padding: "0 2rem"     ,
                                border: "none"   , borderRadius: "0.8rem",
                                gridRow: "span 2"
                            }}
                            onClick={HandleBackButtonClick}>
                                {ContarRespuestas > parametro?"Finalizar":"Volver a Jugar"}
                            </button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default JuegoCodificacion