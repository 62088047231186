import { DocumentSnapshot } from "firebase/firestore";
import { Pregunta, Respuesta, aPregunta, toPregunta, toRespuesta } from "../../modelos/Respuesta";
import { useBaseDeDatos } from "../baseDeDatos/useBaseDeDatos";
import { Cuestionario, toCuestionario } from "../../modelos/Cuestionario";
import { CuestionarioActividad, aCuestionarioActividad, toCuestionarioActividad } from "../../modelos/JuegoCuestionario";
import { nomProgCuestionario } from "../../datos/nombresBD";

export const useCuestionario = () => {

    /**
     * Funciones para acceder a la base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,
        borrarDoc,
        reemplazarDoc,
        recogerDoc,
        agregarCollection,
        recogerDocsFiltrados,
        borrarCollection,
        recogerDocs,
        UnirCampoArregloDoc,
        recogerDocsFiltroString
    } = useBaseDeDatos();

    const consultarRespuestas = async (idCuestionario:string,idPregunta):Promise<Respuesta[]> => {
        const respuestas : Respuesta[] = [];
        var arrDoc : DocumentSnapshot[] = [];

        arrDoc = await recogerDocs("cuestionarios" + "/" + idCuestionario + "/" + "preguntas" + "/" + idPregunta + "/" + "respuestas");

        for(const doc of arrDoc){
            respuestas.push(toRespuesta(doc));
        }

        return respuestas
    }

    const consultarSoloPreguntas = async (idCuestionario:string):Promise<Pregunta[]> => {
        const preguntas : Pregunta[] = [];
        var arrDoc : DocumentSnapshot[] = [];

        arrDoc = await recogerDocs("cuestionarios" + "/" + idCuestionario + "/" + "preguntas");
        
        for(const doc of arrDoc){
            const respuestas:Respuesta[] = await consultarRespuestas(idCuestionario,doc.id);
            const pregunta = toPregunta(doc,respuestas);
            preguntas.push(pregunta)
        }

        return preguntas
    }

    const consultarSoloCuestionario = async (idCuestionario:string):Promise<Cuestionario> => {
        let cuestionario : Cuestionario = null;
        var Doc : DocumentSnapshot;

        Doc = await recogerDoc("cuestionarios" + "/" + idCuestionario);

        cuestionario = toCuestionario(Doc);
        return cuestionario
    }

    const consultarCuestionarioActividad = async (idCuestionario:string):Promise<CuestionarioActividad> => {
        var Doc : DocumentSnapshot;

        Doc = await recogerDoc("cuestionarios" + "/" + idCuestionario);

        let cuestionario : CuestionarioActividad = toCuestionarioActividad(Doc);
        return cuestionario;
    }

    const consultarCuestionarioByTitulo= async (tituloCuestionario:string):Promise<CuestionarioActividad[]> => {
        let docs: DocumentSnapshot[] = [];
        docs = await recogerDocsFiltroString("cuestionarios", 'titulo', tituloCuestionario);
        
        let cuestionarios: CuestionarioActividad[] = [];
        docs.forEach(doc => {
            const cuestionario = toCuestionarioActividad(doc);
            cuestionarios.push(cuestionario);
        });
        
        return cuestionarios;
    }

    const consultarPreguntas = async (idCuestionario:string):Promise<Cuestionario> => {
        const preguntas : Pregunta[] = [];
        let cuestionario : Cuestionario = null;
        var Doc : DocumentSnapshot;
        var arrDoc : DocumentSnapshot[] = [];

        Doc = await recogerDoc("cuestionarios" + "/" + idCuestionario);

        cuestionario = toCuestionario(Doc);

        arrDoc = await recogerDocs("cuestionarios" + "/" + idCuestionario + "/" + "preguntas");
        
        for(const doc of arrDoc){
            const respuestas:Respuesta[] = await consultarRespuestas(idCuestionario,doc.id);
            const pregunta = toPregunta(doc,respuestas);
            preguntas.push(pregunta)
        }

        cuestionario.preguntas = preguntas;
        return cuestionario
    }

    const CrearCuestionario = async(Cuestionario:CuestionarioActividad) => {
        let resp = await agregarCollection('cuestionarios', Cuestionario)
        return resp
    }

    const GuardarCambios = async (idCuestionario:string,Cuestionario:CuestionarioActividad) => {
        await agregarDoc('cuestionarios' + "/" + idCuestionario,Cuestionario)
    }
    
    const BorrarCuestionario = async (idCuestionario:string) => {
        await borrarDoc('cuestionarios' + "/" + idCuestionario)
    }

    return {
        consultarSoloCuestionario,
        consultarSoloPreguntas,
        consultarPreguntas,
        consultarCuestionarioActividad,
        consultarCuestionarioByTitulo,
        BorrarCuestionario,
        CrearCuestionario,
        GuardarCambios,
    }
}
