/** Externa */


/** Local */
import { storage } from '../../firebase';
import { UploadTaskSnapshot, getDownloadURL, ref, uploadBytes } from 'firebase/storage';

/**
 * Hook para manejar acceso al base de datos
 *
 * @author [Aiden Cullo](https://github.com/cullo7)
 */
export const useStorgeImagenes = () => {

    const agregarImg = async(ruta: string, file: Blob):Promise<void> => {
        const dRef = ref(storage,ruta);
        const uploadTask = uploadBytes(dRef, file);
        uploadTask.then(async (snapshot: UploadTaskSnapshot) => {
            // Aquí puedes realizar otras operaciones después de la subida exitosa
            console.log("Subida Exitosa ed photo perfil")
        }).catch((error: any) => {
            console.error('Error al subir el archivo', error);
            // Aquí puedes manejar el error de subida
        });
    }

    const obtenerURL = async(ruta: string):Promise<string> => {
        try {
            const dRef = ref(storage,ruta);
            const url = await getDownloadURL(dRef);
            return url
            
        } catch (error) {
            console.log(error)
            return null
        }
    }

       
    return {
        agregarImg,
        obtenerURL,
    }
}
