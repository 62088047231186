import { collection, DocumentSnapshot } from "firebase/firestore";
import { nomEntrenamiento, nomEntidadEntrenamiento, nomEntrenamientoSesiones, nomEntrenamientoActividades, nomUsuarioEntrenamiento, nomUsuarioEntrenamientoSesiones, nomUsuarioEntrenamientoActividades, nomUsuarios, nomEntidades, nomCampañas, nomEntrenamientoVentas, nomRegistroEntrenamiento } from "../../datos/nombresBD";
import { useBaseDeDatos } from "../baseDeDatos/useBaseDeDatos";
import { Entrenamiento, toEntrenamiento, toEntrenamientoExists } from "../../modelos/entrenamiento/Entrenamiento";
import { Sesion, toSesion } from "../../modelos/entrenamiento/Sesion";
import { Actividad, toActividad } from "../../modelos/entrenamiento/Actividad";
import useRegistroEntrenamiento from "./useRegistroEntrenamiento";
import { toCampaña } from "../../modelos/entrenamiento/Campaña";
import { Producto, toProducto } from "../../modelos/Producto";


export const useEntrenamiento = () => {

    /**
     * Funciones para acceder el base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,    
        recogerDocs,
        recogerDoc,
        recogerDocsOrdenados,
        recogerDocsFiltrados, 
        agregarCollection,     
        incrementarVisitasPagina,      
    } = useBaseDeDatos();

    const { actualizarRegistroEntrenamiento } = useRegistroEntrenamiento();

    const entidadAsignada = async (idEntidad:string,idEntrenamiento:string): Promise<Boolean> => {
        var Docs : DocumentSnapshot;
        Docs = await recogerDoc(nomEntidades + "/" + idEntidad + "/" + nomEntidadEntrenamiento + "/" + idEntrenamiento)
        return Docs.exists()
    }

    const incrementarVisitas = async(landing:string): Promise<void> => {
        incrementarVisitasPagina(landing)
    }  

    const asignadoEntrenamiento = async (userId:string,idEntrenamiento:string): Promise<Entrenamiento> => {
        var Docs : DocumentSnapshot;
        var entrenamientos: Entrenamiento;
        Docs = await recogerDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento + "/" + idEntrenamiento)
        if(Docs.exists()){
            entrenamientos = toEntrenamiento(Docs)
            console.log("🚀 ~ file: useEntrenamiento.ts:40 ~ asignadoEntrenamiento ~ entrenamientos:", entrenamientos)
            return entrenamientos
        }else{
            return null
        }
    }

    const consultarEntrenamientos = async (userId:string): Promise<Entrenamiento[]> => {
        var Docs : DocumentSnapshot[] = [];
        var entrenamientos: Entrenamiento[] = [];
        Docs = await recogerDocs(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento);
        for(const doc of Docs){
            entrenamientos.push(toEntrenamiento(doc))
        }
        return entrenamientos
    }

    const consultarEntrenamientosApp = async (): Promise<Entrenamiento[]> => {
        var Docs : DocumentSnapshot[] = [];
        var entrenamientos: Entrenamiento[] = [];
        Docs = await recogerDocs(nomEntrenamiento);
        for(const doc of Docs){
            entrenamientos.push(toEntrenamientoExists(doc));
        }
        return entrenamientos
    }

    const consultarNombresEntrenamientosApp = async (): Promise<string[]> => {
        var Docs : DocumentSnapshot[] = [];
        var entrenamientos: string[] = [];
        Docs = await recogerDocs(nomEntrenamiento);
        for(const doc of Docs){
            entrenamientos.push(doc.id);
        }
        return entrenamientos
    }

    const coleccionEntrenamientos = async (userId: string) => {
        return await recogerDocs(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento);
    }
    
    const consultarEntrenamientoVentas = async (idEntrenamiento:string): Promise<Entrenamiento> => {
        var Docs : DocumentSnapshot;
        Docs = await recogerDoc(nomEntrenamientoVentas + "/" + idEntrenamiento)
        return toEntrenamiento(Docs)
    }

    const consultarProductoVentas = async (idEntrenamiento:string): Promise<Producto> => {
        var Docs : DocumentSnapshot;
        Docs = await recogerDoc(nomEntrenamientoVentas + "/" + idEntrenamiento)
        return toProducto(Docs)
    }

    const consultarEntrenamiento = async (idEntrenamiento:string): Promise<Entrenamiento> => {
        var Docs : DocumentSnapshot;
        Docs = await recogerDoc(nomEntrenamiento + "/" + idEntrenamiento)
        return toEntrenamiento(Docs)
    }

    const consultarSesiones = async (entrenamientoId: string, userId?:string): Promise<any> => {
        var DocsArray : DocumentSnapshot[] = [];
        var SesionArray : Sesion[] = [];
        if(userId){
            DocsArray = await recogerDocsOrdenados(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento + "/" + entrenamientoId + "/" + nomUsuarioEntrenamientoSesiones,'orden',true)
        }else{
            DocsArray = await recogerDocsOrdenados(nomEntrenamiento + "/" + entrenamientoId + "/" + nomEntrenamientoSesiones,'orden',true);
        }
        for(const document of DocsArray){
            SesionArray.push(toSesion(document))
        }
        return SesionArray
    }

    const consultarActividadesSinSesion = async (entrenamientoId: string): Promise<any> => {
        var DocsArray : DocumentSnapshot[] = [];
        var ActividadRetornada : Actividad[] = [];
        DocsArray = await recogerDocsOrdenados(nomEntrenamiento + "/" + entrenamientoId + "/" + nomEntrenamientoActividades,'orden',true);
        console.log("🚀 ~ file: useEntrenamiento.ts:87 ~ consultarActividades ~,ruta ", nomEntrenamiento + "/" + entrenamientoId + "/" + nomEntrenamientoActividades)
        console.log("🚀 ~ file: useEntrenamiento.ts:87 ~ consultarActividades ~ DocsArray:", DocsArray)
        for(const document of DocsArray){
            ActividadRetornada.push(toActividad(document))
        }
        return ActividadRetornada
    }

    const consultarActividades = async (entrenamientoId: string, idSesion:string): Promise<Actividad[]> => {
        var DocsArray : DocumentSnapshot[] = [];
        var ActividadRetornada : Actividad[] = [];
        DocsArray = await recogerDocsOrdenados(nomEntrenamiento + "/" + entrenamientoId + "/" + nomEntrenamientoSesiones + "/" + idSesion + "/" + nomEntrenamientoActividades,'orden',true);
        
        for(const document of DocsArray){
            ActividadRetornada.push(toActividad(document))
        }
        return ActividadRetornada
    }
    
    const consultarSesionPorOrden = async (entrenamientoId: string, ordenSesion: number): Promise<any> => {
        var DocsArray : DocumentSnapshot[] = [];
        var SesionArray : Sesion[] = [];
        DocsArray = await recogerDocsFiltrados(nomEntrenamiento + "/" + entrenamientoId + "/" + nomEntrenamientoSesiones,"orden",ordenSesion);
        for(const document of DocsArray){
            SesionArray.push(toSesion(document))
        }
        return SesionArray[0]
    }

    /**
     * Reemplazar datos en documento o documento en coleccion
     * @function
     * @param {string} entrenamientoId - id del entrenamiento.
     * @param {string} idSesion - id de la sesion a consultar.
     */
    const consultarSesion = async (entrenamientoId: string, idSesion: string): Promise<any> => {
        var DocsArray : DocumentSnapshot;
        var SesionArray : Sesion;
        DocsArray = await recogerDoc(nomEntrenamiento + "/" + entrenamientoId + "/" + nomEntrenamientoSesiones + "/" + idSesion);
        SesionArray = (toSesion(DocsArray))
        return SesionArray
    }

    const registroEntrenamiento = async(userId:string, entrenamientoId:string,entrenamiento:Entrenamiento): Promise<void> => {
        await agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento + "/" + entrenamientoId, entrenamiento)
    }
    const registroSesion = async(userId:string, entrenamientoId:string,sesionId:string, sesion:Sesion): Promise<void> => {
        await agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento + "/" + entrenamientoId + "/" + nomUsuarioEntrenamientoSesiones + "/" + sesionId, sesion)
    }

    const registroActividad = async(userId:string, entrenamientoId:string, sesionId:string, actividad:Actividad): Promise<void> => {
        // actualizarRegistroEntrenamiento(userId);
        await agregarCollection(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento + "/" + entrenamientoId + "/" + nomUsuarioEntrenamientoSesiones + "/" + sesionId + "/" + nomUsuarioEntrenamientoActividades, actividad)
    }
    
    const consultarCampaña = async(codigo:string) => {
        var Doc:DocumentSnapshot = await recogerDoc(nomCampañas + "/" + codigo)
        if(Doc.exists()){
            return toCampaña(Doc)
        }else{
            return null
        }
    }

    const consultarActividadesEntrenamiento = async(entrenamiento:Entrenamiento) => {
        const limConcur = 10
        let promiseArr: Promise<Actividad[]>[] = [];
        let returnArr: Actividad[][] = []

        const tamaño = entrenamiento.sesiones.length;

        for(let i = 0; i < tamaño; i += limConcur){
            for(let j = i; j < (i + 10) && j < tamaño; j++){
                promiseArr.push(consultarActividades(entrenamiento.id,entrenamiento.sesiones[j].id));
            }
            
            returnArr.push(...(await Promise.all(promiseArr)));
            promiseArr = [];
        }

        return returnArr;
    }
    
    return {
        entidadAsignada,
        consultarEntrenamientos,
        consultarEntrenamientosApp,
        coleccionEntrenamientos,
        consultarActividades,
        consultarEntrenamiento,
        consultarEntrenamientoVentas,
        consultarProductoVentas,
        consultarSesiones,
        consultarSesion,
        consultarSesionPorOrden,
        consultarActividadesSinSesion,
        registroEntrenamiento,
        registroSesion,
        registroActividad,
        asignadoEntrenamiento,
        consultarCampaña,
        incrementarVisitas,
        consultarActividadesEntrenamiento,
        consultarNombresEntrenamientosApp,
    }
}
