import React from "react";
import estiloJuegos from "../../theme/Juegos.module.css";

interface Props{
    children?: any
    onClick: () => void;
    style?: React.CSSProperties;
    paddingInterno?: string;
    className?:string;
}

const BotonBordeDegradado = ({children, onClick, style, paddingInterno, className}: Props) => {

    return (
        <button style={style} onClick={onClick} className={estiloJuegos.botonVolver + (className ?? "") + " centrar"}>
            <div style={{padding: (paddingInterno ?? "")}}>
                <span className={estiloJuegos.degradadoTexto}>{children ?? ""}</span>
            </div>
        </button>
    );
}

export default BotonBordeDegradado;
