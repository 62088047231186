import { useEffect, useLayoutEffect, useState } from 'react';
import styleSheet from '../../theme/Modal.module.css';

interface Props{
    open: boolean;
    className?: string;
    style?: React.CSSProperties;
    overrideDefaultStyle?: boolean;
    fillPercentage?: number
    onClickOutside?: () => void;
    children?: React.ReactNode;
}

const Modal = ({open, className, style, overrideDefaultStyle = false, fillPercentage = 100, onClickOutside, children}:Props) => {
    const [estiloCartel, setEstiloCartel] = useState(open ? " " : styleSheet.cartelOculto);
    const [openBackground, setOpenBackground] = useState(open);

    const HandleCerrar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if(onClickOutside) onClickOutside();
    }

    const cartelStyle = () => {
        if(overrideDefaultStyle){
            return style;
        }
        return ((fillPercentage > 98)?
            { width: `${fillPercentage}%`,padding: "10px"}:
            {
                ...style,
                width: `${fillPercentage}%`,
                border: "5px solid var(--color-purple-dark)",
                borderRadius: "15px",
                backgroundColor: "white",
            });
    }

    const ChangeShowOrNot = () => {
        if(open){
            setOpenBackground(true);
            setEstiloCartel("");
        }else{
            setEstiloCartel(styleSheet.cartelOculto);
            setTimeout(() => setOpenBackground(false), 500);
        }
    }

    useEffect(() => {
        ChangeShowOrNot()
    }, [open])

    return(
    <div className={styleSheet.contenedor} style={openBackground?{}:{display:"none"}} onClick={HandleCerrar}>
        <div className={styleSheet.infoCartel + " " + estiloCartel + " " + (className?? "")} style={cartelStyle()}>
            {children}
        </div>
    </div>
    );
}

export default Modal;
