/** Importaciones de React */
import React, { useState, useEffect } from 'react';

/** Importaciones de Firebase */

/** Importaciones de modulos personalizados */
import { paises } from '../../../datos/paises';
import Selector from '../../entrenamiento/componentes/Selector';

/** Importaciones Hooks personalizados */

/** Importaciones Interfaces */

/** Interfaces Locales*/

interface Props {
    setDatoLocal : (a : string , b : string) => void;
    datoAnterior? : string;
}


const PaisComponent: React.FC<Props> = ({ setDatoLocal, datoAnterior }) => {

    const [paisActual, setPaisActual] = useState(datoAnterior);

    useEffect(() => {
        if(datoAnterior) {
            setPaisActual(datoAnterior);
        }
    }, [datoAnterior]);

    const handleSelect = (respuesta : string) => {
        if(respuesta !== paisActual) {
            setPaisActual(respuesta)
            setDatoLocal("pais", respuesta)
        }
    }

    
    return (
	    <div className='select-contenedor'>
            <Selector selection={paisActual} opciones={
                Array.from({ length: paises.length }, (_,index) => ({ name: paises[index].name , value: paises[index].name }))
            } setSelection={handleSelect}/>
        </div>
    );

}

export default PaisComponent;
