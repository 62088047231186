/** Externa */
import {
    collection,
    getDocs,
    getDoc,
    setDoc,
    doc,
    addDoc,
    deleteDoc,
    QuerySnapshot,
    CollectionReference,
    QueryDocumentSnapshot,
    DocumentReference,
    DocumentSnapshot,
    DocumentData,
    query,
    orderBy,
    where,
    updateDoc,
    increment,
    serverTimestamp,
    arrayUnion,
    FieldValue,
    arrayRemove,
} from 'firebase/firestore';

/** Local */
import { firestore } from '../../firebase';
import { Factura } from '../../modelos/Facturas';

/**
     * Agregar un documento a una coleccion
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @param {Objecto} dato - El documento que guardaremos.
     */
export const agregarCollection = async (ruta: string, dato: Object): Promise<string> => {
    const cRef: CollectionReference = collection(firestore, ruta)        
    const docRef = await addDoc(cRef, dato)
    return docRef.id;
}

/**
 * Hook para manejar acceso al base de datos
 *
 * @author [Aiden Cullo](https://github.com/cullo7)
 */
export const useBaseDeDatos = () => {

    type FilterValue = string | boolean | number;

    /** Collections */
    //---------------------------------------------------------------------//

    /**
     * Agregar un documento a una coleccion
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @param {Objecto} dato - El documento que guardaremos.
     */
    const agregarCollection = async (ruta: string, dato: Object): Promise<string> => {
        const cRef: CollectionReference = collection(firestore, ruta)        
        const docRef = await addDoc(cRef, dato)
        return docRef.id;
    }

    /**
     * Borrar todos los documentos en una coleccion
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     */
    const borrarCollection = async (ruta: string): Promise<void> => {
        const cRef: CollectionReference = collection(firestore, ruta)
        const querySnapshot: QuerySnapshot = await getDocs(cRef)
        querySnapshot.forEach(async (docRef: QueryDocumentSnapshot) => {
            await deleteDoc(docRef.ref)
        })
    }


    
      

    /**
     * Borrar una collection y luego agregar un documento
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @param {Objecto} dato - El documento que guardaremos.
     */
    const reemplazarCollection = async (ruta: string, dato: Object): Promise<void> => {
        await borrarCollection(ruta)
        await agregarCollection(ruta, dato)
    }

    /**
     * Recoger una referencia a una coleccion
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @returns {CollectionReference} - Referencia a una coleccion
     */
    const recogerCollection = (ruta: string): CollectionReference => {
        return collection(firestore, ruta);
    }

    /** Documentos */
    //---------------------------------------------------------------------//

    /**
     * Agregar datos a un documento o un documento a una coleccion
     * @function
     * @param {string} ruta - la ruta al coleccion/documento en base de datos.
     * @param {Objecto} dato - El documento/los datos que guardaremos.
     */
    const agregarDoc = async (ruta: string, dato: Object) => {
        const dRef: DocumentReference = doc(firestore, ruta)        
        await setDoc(dRef, dato)
    }

    const incrementarValorNeuronas = async (ruta: string, nombreUsuario: string, valor: number) => {
        console.log("incrementarValor: " + ruta + " " + nombreUsuario + " " + valor)
        const dRef: DocumentReference = doc(firestore, ruta)
                      
        // Verificar si el documento existe
        const docSnap = await getDoc(dRef);

        if (!docSnap.exists()) {
            // Si el documento no existe, establecer el valor inicial
            console.log("no existe")
            await setDoc(dRef, {
                total: valor,
                ultimoRegistro: serverTimestamp(), 
                nombre: nombreUsuario
                });
        } else {
            console.log("existe")
            // Si el documento existe, incrementar el valor
            await updateDoc(dRef, {
                total: increment(valor),
                ultimoRegistro: serverTimestamp(),
                nombre: nombreUsuario
            });
        }
    }

    const incrementarVisitasPagina = async (ruta: string) => {
        console.log("incrementar Visita pagina: " + ruta)
        const dRef: DocumentReference = doc(firestore, "campanas/" + ruta)
                      
        // Verificar si el documento existe
        const docSnap = await getDoc(dRef);

        if (!docSnap.exists()) {
            // Si el documento no existe, establecer el valor inicial
            console.log("no existe")
            await setDoc(dRef, {
                visitas: 1,
                ultimaVisita: serverTimestamp()
                });
        } else {
            console.log("existe")
            // Si el documento existe, incrementar el valor
            await updateDoc(dRef, {
                visitas: increment(1),
                ultimaVisita: serverTimestamp()
            });
        }
    }

    /**
     * Borrar datos en documento o documento en coleccion
     * @function
     * @param {string} ruta - la ruta al coleccion/documento en base de datos.
     */
    const borrarDoc = async (ruta: string): Promise<void> => {
        const dRef: DocumentReference = doc(firestore, ruta)
        await deleteDoc(dRef)
    }

    /**
     * Reemplazar datos en documento o documento en coleccion
     * @function
     * @param {string} ruta - la ruta al coleccion/documento en base de datos.
     * @param {Objecto} dato - El documento/los datos que guardaremos.
     */
    const reemplazarDoc = async (ruta: string, dato: Object): Promise<void> => {
        const dRef: DocumentReference = doc(firestore, ruta)
        await setDoc(dRef, dato)
    }
    const ActualizarDoc = async (ruta: string, datos:any): Promise<void> => {
        const dRef: DocumentReference = doc(firestore, ruta)
        await updateDoc(dRef, datos)
    }
    /**
     * Recoger datos en documento o documento en coleccion
     * @function
     * @param {string} ruta - la ruta al coleccion/documento en base de datos.
     * @returns {Promise<DocumentSnapshot>} - Promise que resuelve a un DocumentSnapshot
     */
    const recogerDoc = async (ruta: string): Promise<DocumentSnapshot> => {
        const dRef: DocumentReference = doc(firestore, ruta)
        const respuesta = await getDoc(dRef)
        return respuesta
    }


    /**
     * Obtiene todos los documentos de una coleccion
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @returns {} - Un array con todos los documentos de la coleccion
     */    
    const recogerDocs = async (ruta: string) => {
        const cRef: CollectionReference<DocumentData> = collection(firestore, ruta); 
               
        const querySnapshot: QuerySnapshot = await getDocs(cRef);
        return querySnapshot.docs;
    }

    const recogerDocsList= async (rutas: string[]) => {
        let docs: DocumentReference[] = rutas.map(ruta => doc(firestore, ruta));
        return await Promise.all(
            docs.map(docRef => getDoc(docRef))
        );
    }

    /**
     * Obtiene todos los documentos de una coleccion de manera ordenada por un campo
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @param {string} nomCampo - el nombre del campo por el cual ordenar
     * @param {boolean} ascendente - si es true, ordena de manera ascendente, si es false, ordena de manera descendente
     * @returns {} - Un array con todos los documentos de la coleccion
     */   
    const recogerDocsOrdenados = async (ruta: string, nomCampo: string, ascendente: boolean): Promise<any[]> => {
        const arrDoc : DocumentSnapshot[] = [];
        const cRef: CollectionReference<DocumentData> = collection(firestore, ruta); 
        const valorAscendente = ascendente ? 'asc' : 'desc';
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(query(cRef, orderBy(nomCampo, valorAscendente)));

        for (const docRef of querySnapshot.docs) {
            arrDoc.push(docRef);            
        }
        return arrDoc;
    }

    /**
     * Obtiene todos los documentos de una coleccion filtrada por un campo
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @param {string} nomCampo - el nombre del campo por el cual se va a filtrar
     * @returns {} - Un array con todos los documentos de la coleccion
     */   
    const recogerDocsFiltrados = async (ruta: string, nomCampo: string, valorDeFiltro: FilterValue): Promise<DocumentSnapshot[]> => {
        const arrDoc : DocumentSnapshot[] = [];
        const cRef: CollectionReference<DocumentData> = collection(firestore, ruta);        
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(query(cRef, where(nomCampo, "==", valorDeFiltro)));
       
        for (const docRef of querySnapshot.docs) {            
            const docSnapshot = await getDoc(docRef.ref);            
            arrDoc.push(docSnapshot);
        }
        return arrDoc;
    }

    /**
     * Obtiene todos los documentos de una coleccion filtrada por un campo
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @param {string} nomCampo - el nombre del campo por el cual se va a filtrar
     * @returns {} - Un array con todos los documentos de la coleccion
     */   
    const recogerDocsFiltroString = async (ruta: string, nomCampo: string, valorDeFiltro: string): Promise<DocumentSnapshot[]> => {
        console.log("🚀 ~ recogerDocsFiltroString ~ valorDeFiltro:", valorDeFiltro)
        let arrDoc: DocumentSnapshot[] = [];
        let docs: DocumentSnapshot[] = [];
    
        try {
            const cRef: CollectionReference<DocumentData> = collection(firestore, ruta);  
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(query(cRef, 
                where(nomCampo, ">=", valorDeFiltro.toLowerCase())));
            const querySnapshot2: QuerySnapshot<DocumentData> = await getDocs(query(cRef, 
                where(nomCampo, "<=", valorDeFiltro.toLowerCase() + '\uf8ff')));
                
            const snapshot1Docs = querySnapshot.docs;
            console.log("🚀 ~ recogerDocsFiltroString ~ snapshot1Docs:", snapshot1Docs)
            const snapshot2Docs = querySnapshot2.docs;
            console.log("🚀 ~ recogerDocsFiltroString ~ snapshot2Docs:", snapshot2Docs)
            docs = [...snapshot1Docs, ...snapshot2Docs];
            docs.forEach(doc => {
                const data = doc.data();
                if ((data.titulo) && (data.titulo.toLowerCase().includes((valorDeFiltro.toString().toLowerCase())))) {
                    arrDoc.push(doc);
                }
            });
        } catch (error) {
            console.error("Error al obtener documentos:", error);
        }
        
        return arrDoc;
    }

    /**
     * Obtiene todos los documentos de una coleccion filtrada por un campo
     * @function
     * @param {string} ruta - la ruta al coleccion en base de datos.
     * @param {string} nomCampo1 - el nombre del campo por el cual se va a filtrar
     * @returns {} - Un array con todos los documentos de la coleccion
     */   
    const recogerDocsFiltrados2Campos = async (ruta: string, nomCampo1: string, valorDeFiltro1: FilterValue, nomCampo2: string, valorDeFiltro2: FilterValue): Promise<DocumentSnapshot[]> => {
        const arrDoc : DocumentSnapshot[] = [];
        const cRef: CollectionReference<DocumentData> = collection(firestore, ruta);        
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(
            query(cRef,
                where(nomCampo1, "==", valorDeFiltro1),
                where(nomCampo2, "==", valorDeFiltro2)
            )
        );
       
        for (const docRef of querySnapshot.docs) {            
            const docSnapshot = await getDoc(docRef.ref);            
            arrDoc.push(docSnapshot);
        }
        return arrDoc;
    }

    const UnirCampoArregloDoc = async (ruta: string, field: string, element: any) => {
        const docRef = doc(firestore, ruta);
        await updateDoc(docRef, {[field]: arrayUnion(element)})
    }

    const EliminarCampoArregloDoc = async (ruta: string, field: string, uid: any) => {
        const docRef = doc(firestore, ruta);
        await updateDoc(docRef, {[field]: arrayRemove({ uid })})
    }
    
    const IncrementarCampo = async(ruta:string,field:string,cantidad:number) => {
        const docRef = doc(firestore, ruta);
        await updateDoc(docRef, { [field]: increment(cantidad) });
    }

    const ActualizarUnCampo = async (ruta: string, field: string, input: any) => {
        const salaRef = doc(firestore, ruta);
        const updateField = { [field]: input };
        await updateDoc(salaRef, updateField);
    };
       
    return {
        agregarDoc,
        borrarDoc,
        reemplazarDoc,
        recogerDoc,
        agregarCollection,
        borrarCollection,
        recogerDocs,
        recogerDocsList,
        recogerDocsOrdenados,
        recogerDocsFiltrados,
        recogerDocsFiltrados2Campos,
        ActualizarDoc,
        incrementarValorNeuronas,
        incrementarVisitasPagina,
        UnirCampoArregloDoc,
        IncrementarCampo,
        ActualizarUnCampo,
        EliminarCampoArregloDoc,
        recogerDocsFiltroString
    }
}
