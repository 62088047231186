

/** Internas */
import { useDato } from '../../auth';
import DatosComponent from './componentes/DatosComponent';
import  "../../theme/pages.css"; 
import { useAppDispatch } from '../../store';
import { logout } from '../../hooks/reducer/reducerAuth';

const EditarUsuarioPage: React.FC = () => {

	const {
		logOutCuenta
	} = useDato();

	const dispatch = useAppDispatch();

	const cerrarSesion = async () => {
		logOutCuenta();
		await dispatch(logout());
	}
	

	return (
		<div id='page'>
			<div id='toolbar_default' >
				<h1 className="title">Datos personales</h1>
			</div>
			<div id='content_default' style={{padding:'5% 5%'}}>
				<div className='contenido-pages'>
					<DatosComponent firstTime={false}/>
				</div>
				
				
			</div>
		</div>
	);

}

export default EditarUsuarioPage;
