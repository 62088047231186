import { useEffect, useRef, useState } from "react";

export enum Divisor {
    UNI = 1,
    DEC = 10,
    CENT = 100,
}

interface Props{
    inicial: number;
    contar: boolean;
    ascendente: boolean;
    reiniciar?: number;
    precision?: Divisor;
    conteoTermino: () => void;
    tiempoActual: (tiempo: number) => void;
}


export const FormatoTiempo = (tiempo: number, divisor: Divisor = Divisor.CENT) => {
    let textoSubSegundo = "";
    let unidadSubSegundo = 0;

    if(divisor >= 100){
        divisor = 100;
        unidadSubSegundo = tiempo % divisor
        textoSubSegundo =`.${(unidadSubSegundo < 10)? `0${unidadSubSegundo}`: unidadSubSegundo}`;
    }else if(divisor >= 10){
        divisor = 10
        unidadSubSegundo = Math.floor(tiempo / 10) % divisor;
        textoSubSegundo = `.${unidadSubSegundo}`;
    }else {
        divisor = 1;
    }

    const neg = tiempo < 0;

    tiempo = Math.abs(tiempo);
    
    const tiempoSegundos = Math.floor(tiempo / Divisor.CENT);
    const segundos = tiempoSegundos % 60
    const minutos = Math.floor(tiempoSegundos / 60);
    
    return `${neg? "-":""}${(minutos < 10)? `0${minutos}`: minutos}:${(segundos < 10)? `0${segundos}`: segundos}` + textoSubSegundo;
}

export const aSegundos = (tiempo: number, divisor: Divisor = Divisor.CENT) => {
    return tiempo/divisor;
}

export const TiempoSubSec = (props: Props) => {
    const {inicial, contar, ascendente, reiniciar, conteoTermino, tiempoActual} = props;
    const presicion = props.precision ?? Divisor.CENT;

    const [tiempo, setTiempo] = useState<number>(inicial);
    
    const inter = useRef<NodeJS.Timer>(null);

    useEffect(() => {
        if(contar){
            if(inter.current)clearInterval(inter.current);
            inter.current = setInterval(() => {
                setTiempo((prev) => prev + ((ascendente)? 1: -1));
            }, 10);
        }else{
            tiempoActual(tiempo);
            if(inter.current)clearInterval(inter.current);
            inter.current = null;
        }

        return (() => {
            if(inter.current)clearInterval(inter.current);
            inter.current = null;
        });
    }, [contar, ascendente, reiniciar]);

    useEffect(() => {
        if(!ascendente && tiempo <= 0){
            tiempoActual(0);
            setTiempo(0);
            conteoTermino();
            if(inter.current) clearInterval(inter.current);
            inter.current = null;
        }
    }, [tiempo])

    useEffect(() => {setTiempo(inicial)}, [reiniciar]);

    return  (
        <p style={{color:'white', margin: "0"}}>{FormatoTiempo(tiempo, presicion)}</p>
    );
}
