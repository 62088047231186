import { ref, getDownloadURL, uploadBytes, listAll, ListResult } from 'firebase/storage';
import { storage } from '../../firebase';


export const useAlmacenamiento = () => {

    const guardarArchivo = async (file: File, nombre: string) => {
        const storageRef = ref(storage, nombre); 
        await uploadBytes(storageRef, file);
        console.log('Archivo subido correctamente');
    };

    const obtenerURLArchivo = async (nombre: string): Promise<string> => {    
        const storageRef = ref(storage, nombre); 
        const downloadURL = await getDownloadURL(storageRef);
        console.log('URL de descarga del archivo:', downloadURL);
        return downloadURL;
    };

    const obtenerListaArchivos = async (nombre: string): Promise<ListResult> => {
        const storageRef = ref(storage, nombre)
        return await listAll(storageRef);
    }
    return {
        guardarArchivo,
        obtenerURLArchivo,
        obtenerListaArchivos
    }

}