const CodLetras = {
  data: {},

  addEntry: function (key,sonido, letra) {
    if (!this[key]) {
      this[key] = { sonido: sonido, letras: [letra] };
    } else {
      this[key].letras.push(letra);
    }
  },

  removeEntry: function (key) {
    delete this.data[key];
  },

  getSonido: function (key) {
    return this.data[key] && this.data[key].sonido ? this.data[key].sonido : '';
  },

  getLetra: function (key) {
    return this.data[key] && this.data[key].letras ? this.data[key].letras : '';
  },

};

CodLetras.addEntry(1,'Dental', 'T');
CodLetras.addEntry(1,'Dental', 'D');
CodLetras.addEntry(2,'Nasal', 'N');
CodLetras.addEntry(2,'Nasal', 'Ñ');
CodLetras.addEntry(3,'Mugiente', 'M');
CodLetras.addEntry(4,'Vibrante', 'R');
CodLetras.addEntry(4,'Vibrante', 'RR');
CodLetras.addEntry(5,'Lingual', 'L');
CodLetras.addEntry(5,'Lingual', 'LL');
CodLetras.addEntry(6,'Gutural', 'G');
CodLetras.addEntry(6,'Gutural', 'J');
CodLetras.addEntry(7,'Palatal', 'C');
CodLetras.addEntry(7,'Palatal', 'Q');
CodLetras.addEntry(7,'Palatal', 'K');
CodLetras.addEntry(8,'Fricativas', 'F');
CodLetras.addEntry(8,'Fricativas', 'V');
CodLetras.addEntry(9,'Explosivas', 'P');
CodLetras.addEntry(9,'Explosivas', 'B');
CodLetras.addEntry(0,'Silvantes', 'S');
CodLetras.addEntry(0,'Silvantes', 'X');
CodLetras.addEntry(0,'Silvantes', 'Z');
CodLetras.addEntry(0,'Silvantes', 'CH');

export default CodLetras;
