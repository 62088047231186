import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store';
import { useDato } from '../../../auth';
import { useCuestionario } from '../../../hooks/controlador/useCuestionario';
// estilos
import style from "../../../theme/codigoPalabras.module.css"; 
import estiloJuegos from "../../../theme/Juegos.module.css";
import style2 from "../../../theme/Juego2.module.css"; 
import { changeCondicion } from '../../../hooks/reducer/reducerActividad';
// import { arrowBackOutline } from 'ionicons/icons';
import BotonesJuegos from '../componentes/BotonesJuegos';
import { CuestionarioActividad } from '../../../modelos/JuegoCuestionario';
import ResultadosCuestionario from '../resultados/componentes/ResultadoCuestionario';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../entrenamiento/componentes/Loading';
import { Link } from 'react-router-dom';
import BotonBordeDegradado from '../../componentes/BotonBordeDegradado';
import { useEntrenamiento } from '../../../hooks/controlador/useEntrenamiento';
import useRegistroEntrenamiento from '../../../hooks/controlador/useRegistroEntrenamiento';


type Params = {
  id:string;
}

const JuegoCuestionario = () => {
  const [cuestionario, setCuestionario] = useState<CuestionarioActividad>();
  const [cantidadDePreguntas,setCantidadDePreguntas] = useState<number>(0);
  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState<number[]>();
  const [etapa, setEtapa] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [correctas, setCorrectas] = useState<number>(0);
  const [seleccionada,setSeleccionada] = useState<number []>([]);
  const opciones = ["A", "B", "C", "D","E","F","G","H"];
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {id} = useParams<Params>();
  
  const userId = useAppSelector((state) => state.auth.userId);
  const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento.id);
  const indiceSesion = useAppSelector((state) => state.entrenamiento.current_sesion);
  const indiceActividad = useAppSelector((state) => state.entrenamiento.current_actividad);
  const esEntrenamiento = useAppSelector((state) => state.activity.id == "entrenamiento");
  const parametro = useAppSelector((state) => state.activity.tiene_bloqueo);
  const lenghtActividades = useAppSelector((state) => state.entrenamiento.current_actividades?.length);
  

  const{
    consultarCuestionarioActividad,
  } = useCuestionario();
  
  const{
    actualizarRegistroEntrenamiento,
  } = useRegistroEntrenamiento();


  const scrollLeft = () => {
    
    if(index>0 ){
      setIndex((prevIndex) => prevIndex -1);
    };
  }
  const scrollRight = () => {
    if(index < cantidadDePreguntas-1){
      setIndex((prevIndex) => prevIndex +1);
    }
  }
  const verificar = (index:number) => {
    let respuesta = false;
    if(cuestionario.preguntas[index].respuestaCorrecta === opcionesSeleccionadas[index]){
      respuesta = true
    }
    return respuesta;
  }
  
  const verificarRespuesta = () => {
    let count = 0;
    for (let i=0; i<=(cantidadDePreguntas-1); i++){
      if (cuestionario.preguntas[i].respuestaCorrecta ===  opcionesSeleccionadas[i] ){
        count++;
      }
    }
    setCorrectas(count);
    
  };
  const HandleFinalizar = () => {
    setEtapa(2);
    verificarRespuesta();
    setIndex(0);

  }
  const HandleClick = (botonSeleccionada: number) =>{

    const opcionSeleccionada = [...opcionesSeleccionadas];
    opcionSeleccionada[index] = botonSeleccionada;
    console.log("🚀 ~ HandleClick ~ opcionSeleccionada:", opcionSeleccionada)
    setOpcionesSeleccionadas(opcionSeleccionada);

    const nuevaSeleccionada = [...seleccionada];
    nuevaSeleccionada[index] = botonSeleccionada;
    setSeleccionada(nuevaSeleccionada);
    
    setTimeout(() => {
      scrollRight();
    }, 500); 
    

  }
  const getPregunta= () => {
    return (
      <>
        <div className={style.indexPregunta} >{index+1}.</div>
        <div key={index} className={style.pregunta}>{cuestionario.preguntas[index]?.textoPregunta}</div>
      </>
    );
  }

  const getOpciones = () => {
    if (cuestionario && cuestionario.preguntas) {
      const pregunta = cuestionario.preguntas[index];
      return (
        <>
          {pregunta?.respuestas.map((respuestaKey,indexArray) => (
            	<div className={`${seleccionada[index] === (indexArray)  ? style.seleccionadaCuestionario : ""} ${style.respuesta}`}
            	onClick={() => HandleClick(indexArray)}
            	key={respuestaKey}>
              	<div className={style.opcionesLetras}>{opciones[indexArray]}</div>
              	<p>
                	{respuestaKey}
              	</p>
            	</div>
          	))
          }
        </>
      );
    }
  
    return <></>;
  };
  
  const aPrimeraPantalla = () => {
    Constructor();
  } 
  const Constructor = () => {
    ConsultarElCuestionario();
    setSeleccionada([]);
    setIndex(0);
    console.log("🚀 ~ Constructor ~ 0:", 0)
  }

  const ConsultarElCuestionario = async () => {
    setLoading(true);
    try {
      let cuestionario = await consultarCuestionarioActividad(id);
      console.log('cuestionario ',cuestionario)
      setCuestionario(cuestionario);
      const cantidadDePreguntas = Object.keys(cuestionario.preguntas).length
      setCantidadDePreguntas(cantidadDePreguntas);
      setOpcionesSeleccionadas(new Array(cantidadDePreguntas))
      setEtapa(1);
    } catch (error) {
      console.error("Error al consultar el cuestionario:", error);
    }
    setLoading(false);    
  }

  const handleBackButtonClick = async() => {
    setIndex(0)
    console.log("🚀 ~ handleBackButtonClick ~ correctas:", correctas)
    console.log("🚀 ~ handleBackButtonClick ~ parametro:", parametro)
    if(correctas>=parametro || Number.isNaN(parametro)){
      actualizarRegistroEntrenamiento(userId,idEntrenamiento,indiceSesion+1,indiceActividad+1);
      navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
    }else{
      navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
    }
  }

  useEffect(() => {
    Constructor();
  }, []);
  return (
    <div id='page'>
      <Loading isOpen={loading}/>
      <div className={`${style.toolbarCuestionario}`}>
        <div className='contenedor_titulo'>
            <div className={`${estiloJuegos.backJuegos}`} onClick={() =>{handleBackButtonClick()}}></div>
            <h1 className={`ion-text-center title ${estiloJuegos.tituloJuegos}`}>
              CUESTIONARIO
            </h1>
        </div>
      </div>
      <div id='content_default' style={{position:'relative',padding:'5%'}}>
        {etapa === 1 &&
          <div className={style.contenedorCuestionario} style={{padding:'10% 10% 0'}}>  
            <div className={style.pregunta} style={{marginBottom:'7.23%'}}>
              {getPregunta()}
            </div>
            <div className={style.contenedorRespuestas}>
              {getOpciones()}
            </div>
            <div className={style.botonesVolver}>
              <button style={{margin:'0', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',border:' 2px solid var(--color-purple-dark)',background:'white',color:'var(--color-purple-dark)',zIndex:'1'}} id='button_rosado_default' onClick={scrollLeft}>ANTERIOR</button>
              <button style={{margin:'0', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',background:'var(--color-purple-dark)',zIndex:'1'}} id='button_rosado_default' onClick={index === cantidadDePreguntas-1 ? HandleFinalizar : scrollRight}> {index === cantidadDePreguntas-1 ? 'FINALIZAR':'SIGUIENTE'}</button>
            </div>
            {!esEntrenamiento && <label style={{fontWeight:'600',fontSize:'2svh', color:'var(--color-Medium-Grey)'}}>¿Deseas abandonar el test? <span style={{color:'var(--color-Pink-Light)'}} onClick={HandleFinalizar}>Salir</span></label>}
          </div>
        }
        {etapa === 2 &&
          <div className={style2.contenido} style={{display:'flex',flexDirection:'column',gap:'1em'}}>
              <div> 
                  <p className={style2.textoTuviste}>tuviste</p>
                  <p className={style2.resultado}>{`${correctas}/${cantidadDePreguntas}`}</p>
              </div>
              <ResultadosCuestionario getResultado={verificar} 
              cuestionario={cuestionario}
              opcionesSeleccionada={opcionesSeleccionadas} />
              <div className={style.botonesVolver} style={{height:'fit-content'}}>
                <button style={{fontSize:'0.8em',margin:'0', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',border:' 2px solid var(--color-purple-dark)',background:'white',color:'var(--color-purple-dark)',zIndex:'1'}} id='button_rosado_default' onClick={handleBackButtonClick}>VOLVER</button>
                <button style={{fontSize:'0.8em',margin:'0', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',background:'var(--color-purple-dark)',zIndex:'1'}} id='button_rosado_default' onClick={aPrimeraPantalla}> JUGAR DE NUEVO</button>
              </div>
              {!Number.isNaN(parametro) && 
              <>
                {esEntrenamiento && parametro!==0 &&  <p style={{textAlign:'center',width:'100%',fontSize:'2.5vh',fontWeight:'400',color: '#45225F'}}>{correctas>=parametro ? "¡Felicidades ya puedes continuar con tu entrenamiento!":`¡Lastima necesitas ${parametro} correctas!`}</p>}
              </>}
        </div>
        }
        <img className='imagenDificultad' src={`/assets/componentes/ActividadVideo/fondo_neuronas_gris.svg`}></img>
      </div>
    </div>
  )
}

export default JuegoCuestionario;
