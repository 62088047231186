import { DocumentSnapshot, DocumentData } from "firebase/firestore";

export interface Programa {
    id? : string;   
    nombre : string;
    descripcion? : string;
    tipo? : string;
    ruta? : string;
}

export const aPrograma = (nombre : string) : Programa => {
    return {         
        nombre : nombre,
    } as Programa;    
}

export const aTestPrograma = (nombre : string, ruta: string) : Programa => {
    return {         
        nombre : nombre,        
        ruta : ruta,
    } as Programa;    
}


type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toPrograma = (doc : FireBaseDocument) : Programa => {
    const data = doc.data();
    return {
        id : doc.id,
        nombre : data.nombre,
        descripcion : data.descripcion,
        tipo : data.tipo,
        ruta : data.ruta,
    } as Programa;
}

export const toTestPrograma = (doc : FireBaseDocument) : Programa => {
    const data = doc.data();
    return {
        id : doc.id,
        nombre : data.nombre,       
        ruta : data.ruta,
    } as Programa;
}