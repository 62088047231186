import React, { useEffect, useState } from 'react'
import style from '../../../theme/programas/simultaneo/competencia.module.css'
import Loading from '../../entrenamiento/componentes/Loading'
import { nomJuegoSimultaneo, nomProgMemNombres, nomProgMemPalabras, nomProgParejas, nomProgSuperCerebrosDice } from '../../../datos/nombresBD'
import { useSala } from '../../../hooks/controlador/useSala'
import { Versus } from '../../../modelos/Versus'
import { useDato } from '../../../auth'
import { useAppSelector } from '../../../store'
import { integrante } from '../../../modelos/Sala'
import { useDispatch } from 'react-redux'
import { changePuntaje, terminadoJuego } from '../../../hooks/reducer/reducerJuegoSimultaneo'
import Game4 from '../memoria/MemoriaNombres'
import Juego2 from '../memoria/MemoriaPalabras'
import JuegoDeMemoria from '../memoria/MemoriaParejas'
import JuegoSuperCerebrosDice from '../memoria/JuegoSuperCerebrosDice'
import Juego1 from '../calculo/Sumas1'
import { newActivity } from '../../../hooks/reducer/reducerActividad'

export const Competencia = () => {

    const uid = useAppSelector((state) => state.auth.userId)
    const finalizadoJuego = useAppSelector((state) => state.juegoSimultaneo.finalizado)
    const puntaje = useAppSelector((state) => state.juegoSimultaneo.total)
    const verPuntaje = useAppSelector((state) => state.juegoSimultaneo.verPuntaje)
    const dispatch = useDispatch();

    const [count, setCount] = useState(10)
    const [etapa, setEtapa] = useState(0)
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [verResultados, setVerResultado] = useState(false)
    const [finished, setFinished] = useState<integrante[]>([])
    const [sala, setSala] = useState<Versus>()
    const [mensaje, setMensaje] = useState('')
    const [listeningSala, setListeningSala] = useState()
    const [currentUsuario, setCurrentUsuario] = useState<integrante>(null)
    const [usuariosActivos, setUsuariosActivos] = useState<integrante[]>([])
    const [listeningVersusAbiertos, setListeningVersusAbiertos] = useState<any>(null)

    const { state } = useDato();
    
    const {
        BuscarVersus,
        CrearVersus,
        AgregarJugadorVersus,
        BorrarJugadorVersus,
        EnviarInvitacionVersus,
        EnviarAceptación,
        EnviarMensaje,
        EnviarReady,
        subscribirDoc,
        desubscribir,
        GuardarPuntaje,
        BorrarSala,
    } = useSala();
    
    const HandleRetarPersona = () => {
    
        // Inicializar la variable random
        let random;
    
        // Repetir hasta encontrar un usuario aleatorio con un UID diferente al del usuario actual
        do {
            random = Math.floor(Math.random() * usuariosActivos.length);
        } while (usuariosActivos[random]?.uid === uid);
        console.log("🚀 ~ HandleRetarPersona ~ random:", random)
    
        console.log("🚀 ~ HandleRetarPersona ~ random:", random);
        EnviarInvitacionVersus(state?._usuarioDato?.nombres, usuariosActivos[random]?.uid);
    }

    const HandleAceptarInvitacion = async (nombre: string) => {
        const resp = await EnviarAceptación(nombre,currentUsuario)
        console.log("🚀 ~ HandleAceptarInvitacion ~ resp:", resp)
        if(resp !== false){
            setEtapa(2)
            desubscribir(listeningVersusAbiertos,setListeningVersusAbiertos,setUsuariosActivos)
            subscribirDoc(`${nomJuegoSimultaneo}/versus/salas/${resp}`,setSala,setListeningSala)
        }
    }
    
    const HandleEntrarSala = async (thisCurrentUsuario:integrante) => {
        console.log("🚀 ~ HandleEntrarSala ~ HandleEntrarSala:")
        await CrearVersus(thisCurrentUsuario.aceptado,uid)
        await BorrarJugadorVersus(uid)
        setEtapa(2)
        desubscribir(listeningVersusAbiertos,setListeningVersusAbiertos,setUsuariosActivos)
        subscribirDoc(`${nomJuegoSimultaneo}/versus/salas/${thisCurrentUsuario.aceptado}`,setSala,setListeningSala)
    }

    const HandleSendMessage = async () => {
        await EnviarMensaje(sala.id,state._usuarioDato.nombres,mensaje)
        setMensaje('')
    }

    const HandleReady = async () => {
        setReady((prev) => !prev)
        await EnviarReady(sala.id,1)
    }

    const LlamarJuego = (juego):JSX.Element => {
        if(verPuntaje === true){
          console.log('Termino juego:', juego)
          setEtapa(4)
          setLoading(false)
          return <br></br>
        }else{
          console.log('Inicio juego:', juego)
          switch (juego){
            case nomProgMemNombres:
              return <Game4></Game4>
              
            case nomProgMemPalabras:
              return <Juego2></Juego2>
              
            case nomProgParejas:
              return <JuegoDeMemoria></JuegoDeMemoria>
            
            case nomProgSuperCerebrosDice:
              return <JuegoSuperCerebrosDice></JuegoSuperCerebrosDice>
    
            default:
              return <Juego1></Juego1>
          }
        }
      }

    const HandlePuntajes = () => {
        if(sala?.integrantes.length >= 1 ){
            console.log("🚀 ~ sala.integrantes.forEach ~ sala.integrantes:", sala.integrantes)
            for (const integ of sala.integrantes) {
                if(integ.uid !== uid && typeof integ.puntaje === 'number' && !isNaN(integ.puntaje)){
                    console.log("🚀 ~ sala.integrantes.forEach ~ integ:", integ)
                    setVerResultado(true)
                    desubscribir(listeningSala,setListeningSala,setSala)
                    setFinished(sala.integrantes)
                    return true
                }
            }
        }
    }

    const ResetAll = async () => {
        console.log("🚀 ~ return ~ desubscribir:", 'desubscribir')
        console.log("🚀 ~ ResetAll ~ sala:", sala)
        if(sala !== null){
            console.log("🚀 ~ ResetAll ~ sala?.id:", sala?.id)
            BorrarSala(sala?.id)
        }
        dispatch(terminadoJuego({ultimoResultado:0,tiempo:0,finalizado:false}))
        dispatch(changePuntaje({verPuntaje:false}))
        await BorrarJugadorVersus(uid)
        await desubscribir(listeningVersusAbiertos,setListeningVersusAbiertos,setUsuariosActivos)
        await desubscribir(listeningSala,setListeningSala,setSala)
        setVerResultado(false)
        setFinished([])
        setCount(10)
        setEtapa(0)
        setSala(null)
        setMensaje('')
        setCurrentUsuario(null)
        setUsuariosActivos([])
    }

    const InitialConfiguration = async () => {
        await AgregarJugadorVersus(state._usuarioDato.nombres,uid)
        BuscarVersus(nomJuegoSimultaneo + "/versus",setUsuariosActivos,setListeningVersusAbiertos)
        setEtapa(1)
    }

    useEffect(() => {
        dispatch(newActivity({id:'juego_linea',name:'juegos simultaneos'}))
        setLoading(true)
        if(etapa === 0){
            InitialConfiguration()
        }
        setLoading(false)
        return () => {
            setLoading(true)
            ResetAll()
            setLoading(false)
        }
    }, [])
    
    useEffect(() => {
        console.log("🚀 ~ Competencia ~ versusAbiertos:", usuariosActivos);

        // Buscar el usuario con el mismo uid que uid
        const usuarioEncontrado = usuariosActivos?.find(usuario => usuario.uid === uid);

        // Verificar si se encontró el usuario
        if (usuarioEncontrado) {
            // Guardar el usuario encontrado en setCurrentUsuario
            if( etapa === 0 || etapa === 1 && usuarioEncontrado !== null){
                setCurrentUsuario(usuarioEncontrado);
            }
            if(usuarioEncontrado.aceptado !== null){
                HandleEntrarSala(usuarioEncontrado)
            }
        }
    }, [usuariosActivos])    

    useEffect(() => {
        console.log("🚀 ~ Competencia ~ sala:", sala)
        if(sala?.iniciar > 1 && ready){
            setEtapa(3)
        }
        if(etapa === 4 || etapa === 3 ){
            HandlePuntajes()
        }
    }, [sala])

    useEffect(() => {
        if(ready){
            setTimeout(()=>{
                if(etapa === 2) EnviarReady(sala.id,-1);
                setReady(false)
            },10000)
        }
    }, [ready])

    useEffect(() => {
        if(etapa === 3){
            setCount(0);
        }
        if(etapa === 0){
            AgregarJugadorVersus(state._usuarioDato.nombres,uid)
            BuscarVersus(nomJuegoSimultaneo + "/versus",setUsuariosActivos,setListeningVersusAbiertos)
            setEtapa(1)
        }
    }, [etapa])

    useEffect(() => {
      if(finalizadoJuego){
        GuardarPuntaje(sala?.id,uid,currentUsuario?.nombre,puntaje)
      }
      if(verPuntaje){
        setEtapa(4)
      }
    }, [finalizadoJuego])
    

    return (
        <div id='page'>
            <div id='toolbar_default'>
                <h1 className='title'> Bienvenido al versus </h1>
            </div>
            <div id='content_default' style={{padding:'5%'}}>
                {etapa === 0 && <Loading isOpen={loading}></Loading>}
                {etapa === 1 && <>
                    {currentUsuario?.aceptado === null && <div className={style.contain_invitaciones}>
                        {currentUsuario?.pareja?.map((invitacion,index) => (
                            <>
                                <h1 key={index}><strong>{invitacion}</strong> quiere jugar contigo</h1>
                                <button onClick={() => HandleAceptarInvitacion(invitacion)}>Aceptar</button>
                            </>
                        ))}
                    </div>}
                    <div className={style.contain_integrantes}>
                    <h1>Integrantes:</h1>
                    {usuariosActivos?.map((usuario) => (
                        <>
                            {usuario.uid !== uid && <h1 key={usuario.uid}>{usuario.nombre}</h1>}
                        </>
                    ))}
                    </div> 
                    <button id='button_rosado_default' 
                        className={usuariosActivos?.length === 0 ? style.block : ''}
                        onClick={HandleRetarPersona}
                    >Buscar Partida</button>
                </>}
                {etapa === 2 && <div>
                    Juego
                    <div>
                        {sala?.mensaje?.map((text)=> (
                            <>
                                <h1>{text?.nombre}</h1>
                                <h1>{text?.mensaje}</h1>
                            </>
                        ))}
                    </div>
                    <div>
                        <input type='text' value={mensaje} onChange={(e) => setMensaje(e.target.value)} placeholder='Mensaje'/>
                        <button onClick={HandleSendMessage}>send</button>
                    </div>
                    {!ready && <button onClick={HandleReady}>Ready</button>}
                </div>}
                {etapa === 3 && <>
                    {count > 0 && <div>
                        {count}
                    </div>}
                    {count <= 0 && <div style={{position: 'absolute', top: '0' ,height: 'calc(100svh - 8.151%)', width: '100%'}}>
                        {LlamarJuego(sala?.juego)}
                    </div>
                    }
                </>}
                {etapa === 4 && <div>
                    { verResultados ? 
                    <>
                        {finished?.map((intg) => (
                            <div>
                                <div>{intg.nombre}</div>:
                                <div>{intg.puntaje}</div>
                            </div>
                        ))}
                        <button id='button_rosado_default' style={{width:'fit-content'}} onClick={ResetAll} >FINALIZAR</button>
                    </>:
                    <div>Esperando</div>}
                </div>}
            </div>
        </div>
    )
}

export default Competencia;