  import React, { useEffect, useState } from 'react'
import '../../../../theme/main.css'
import styleRecorrerValores from '../../../../theme/programas/cuestionarios/recorrerValores.module.css'
import estiloJuegos from"../../../../theme/Juegos.module.css";
import CodLetras from '../../../../datos/DatosCodificacionLetras';
// import  "../../theme/pages.css"; 

interface Preguntas{
  codigo: number[];
  opcion1: number[];
  opcion2: number[];
  opcion3: number[];
  opcionSeleccionada:number;
}

interface Prop {
  tipoPregunta:string;
  tipoRespuesta:string;
  aSiguientePantalla:(id: number) => void;
  posicion: number;
  setPosicion: React.Dispatch<React.SetStateAction<number>>;
  preguntas: Preguntas;
  setPreguntas: React.Dispatch<React.SetStateAction<Preguntas[]>>;
  setSeleccion: (number:number) => void;
}

const RecorrerValoresAsociados:React.FC<Prop> = (props) => {
  const [etapa, setEtapa] = useState(0);
  const [seleccion, setSeleccion] = useState(-1);

  const handleClick = (index: number) => {
    if(props.posicion <= 9 && props.posicion >= 0){
      props.setSeleccion(index)
      setSeleccion(props.preguntas?.opcionSeleccionada)
    }else{
      props.setSeleccion(index)
      setSeleccion(props.preguntas?.opcionSeleccionada)
    }
    pasar()
  }

  const atras = () => {
    if(props.posicion > 0){
      props.setPosicion((state) => state-1)
    }
  }
  const pasar = () => {
    if(props.posicion < 9){
      props.setPosicion((state) => state+1)
    }
  }

  useEffect(() => {
    setSeleccion(props.preguntas?.opcionSeleccionada)
  }, [props.posicion])
  
  
  useEffect(() => {
    const calculo = (100 / (3)) - 2;
    setEtapa(1)
    setSeleccion(props.preguntas.opcionSeleccionada)
  },[])

  
  return (
    <>
      <div style={{margin:'5% 0',width:'34vh',height:'34vh',display:'flex',flexDirection:'row',alignItems:'center'}}>
        <div style={{width:'100%',display:'flex',justifyContent: 'center',alignSelf:'center',flexDirection:'column',height:'34vh',border:'5px solid #512362',padding:'5%',borderRadius:'20px'}}>
            <label className='ion-text-center' style={{fontSize:'600%',fontWeight:'700',color:'#512362',textTransform:'uppercase',textAlign:'center'}}>{CodLetras[props.preguntas?.codigo[0]]?.letras[props.preguntas?.codigo[1] % CodLetras[props.preguntas?.codigo[0]].letras?.length]}</label>
            <p style={{color:'#512362',fontSize: '1.5rem',margin:'0%',textAlign:'center'}}>{CodLetras[props.preguntas?.codigo[0]]?.sonido}</p>
        </div>
      </div>
      <div style={{width: '81%', height: 'fit-content',top:'10%', textAlign:'center'}}>
          <div className={styleRecorrerValores.gridBotones}>
                <button className={seleccion == 0 ? styleRecorrerValores.buttonRosado : estiloJuegos.botonVolver} onClick={() => handleClick(0)}>
                  <div>
                    <span className={`${estiloJuegos.degradadoTexto} ${styleRecorrerValores.spanResultado}`} style={{fontSize:'2.37svh'}}> {props.preguntas?.opcion1[0]} </span>
                  </div>
                </button>
                <button className={seleccion == 1 ? styleRecorrerValores.buttonRosado : estiloJuegos.botonVolver} onClick={() => handleClick(1)}>
                  <div>
                    <span className={`${estiloJuegos.degradadoTexto} ${styleRecorrerValores.spanResultado}`} style={{fontSize:'2.37svh'}}> {props.preguntas?.opcion2[0]} </span>
                  </div>
                </button>
                <button className={seleccion == 2 ? styleRecorrerValores.buttonRosado : estiloJuegos.botonVolver} onClick={() => handleClick(2)}>
                  <div>
                    <span className={`${estiloJuegos.degradadoTexto} ${styleRecorrerValores.spanResultado}`} style={{fontSize:'2.37svh'}}> {props.preguntas?.opcion3[0]} </span>
                  </div>
                </button>
          </div>
          <div className={styleRecorrerValores.gridNavegacion}>
            <button className={estiloJuegos.botonVolver} style={{height:'100%',border:'none',gridColumn:'1'}} hidden={props.posicion === 0} onClick={() => {if(props.posicion > 0 ){atras()}}} >
              <div>
                <span className={estiloJuegos.degradadoTexto}> VOLVER </span>
              </div>
            </button>
            <button id='button_rosado_default' style={{margin:'0',height:'100%',gridColumn:'2'}} hidden={props.posicion >= 9} onClick={() => {if(props.posicion < 10){pasar()}}} >
              SIGUIENTE
            </button>
            <button id='button_rosado_default' style={{margin:'0',height:'100%',gridColumn:'2'}} hidden={props.posicion !== 9} onClick={() => props.aSiguientePantalla(3)} >
              FINALIZAR
            </button>
          </div>
      </div>
    </>
  )
}

export default RecorrerValoresAsociados;
