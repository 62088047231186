import React, { useEffect, useLayoutEffect, useState } from 'react'
import Loading from './componentes/Loading'
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { useEntrenamiento } from '../../hooks/controlador/useEntrenamiento';
import useRegistroEntrenamiento from '../../hooks/controlador/useRegistroEntrenamiento';
import { Actividad } from '../../modelos/entrenamiento/Actividad';
import { Entrenamiento } from '../../modelos/entrenamiento/Entrenamiento';
import { RegistroEntrenamiento } from '../../modelos/entrenamiento/RegistroEntrenamiento';
import { newActivity } from '../../hooks/reducer/reducerActividad';
import { ChangeRedux_actividades_entrenamiento, ChangeRedux_cargando, ChangeRedux_current_actividades, ChangeRedux_current_entrenamiento, ChangeRedux_current_registro_usuario, ChangeRedux_current_sesion } from '../../hooks/reducer/reducerEntrenamiento';
import { useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { useStore } from 'react-redux';
import { id } from 'date-fns/locale';


export const useProxyEntrenamiento = (idEntrenamiento?:string,indexSesion?:string,indexActivity?:string) => {
    const { consultarActividadesEntrenamiento, consultarActividades} = useEntrenamiento();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    let CargarTodo = false;
    const entrenamientos_app = useAppSelector((state) => state.entrenamiento.entrenamientos_app)
    const registros_user = useAppSelector((state) => state.entrenamiento.registros_usuario)
    const redux_current_registro = useAppSelector((state) => state.entrenamiento.current_registro_usuario)
    const redux_current_entrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento)
    const redux_current_sesion = useAppSelector((state) => state.entrenamiento.current_sesion)
    const redux_actividades_entrenamiento = useAppSelector((state) => state.entrenamiento.actividades_entrenamiento)

    let caso = ():string => {
        let url = window.location.pathname
        if(url === `/my/entrenamiento/${idEntrenamiento}/${indexSesion}/${indexActivity}`){
            return 'carga-actividad'
        }
        if(url === `/my/entrenamiento/${idEntrenamiento}/${indexSesion}` || indexSesion){
            return 'carga-actividad'
        }
        if(url === `/my/entrenamiento/${idEntrenamiento}`){
            return 'carga-registro'
        }
        return 'carga-registro'
    }

    const CargaEntrenamientoRegistroActual = () => {
        let returnEntrenamiento: Entrenamiento | null = null;
        let returnRegistro: RegistroEntrenamiento | null = null;
        if(redux_current_entrenamiento?.id !== idEntrenamiento){
            CargarTodo = true
            let entrenamiento = entrenamientos_app?.find((entrenamiento) => entrenamiento.id === idEntrenamiento)
            if(!entrenamiento) {
                console.error("No se encontro el index del registro al que pertenece", idEntrenamiento, entrenamientos_app)
                dispatch(ChangeRedux_current_entrenamiento({entrenamiento:null}))
            }else{
                dispatch(ChangeRedux_current_entrenamiento({entrenamiento}))
                returnEntrenamiento = entrenamiento
            }
        }else{
            returnEntrenamiento = redux_current_entrenamiento
        }
        if(redux_current_registro?.idEntrenamiento !== idEntrenamiento){
            CargarTodo = true
            let registro = registros_user?.find((registro) => registro.idEntrenamiento === idEntrenamiento)
            if(registro){
                dispatch(ChangeRedux_current_registro_usuario({registro}))
                returnRegistro = registro
            }else{
                dispatch(ChangeRedux_current_registro_usuario({registro:null}))
                console.error("No se encontro el index del registro al que pertenece", idEntrenamiento, registros_user)
            }
        }else{
            returnRegistro = redux_current_registro
        }
        return {returnEntrenamiento, returnRegistro}
    }

    const CargarActividades = async (actualEntrenenamiento:Entrenamiento,actualRegistro:RegistroEntrenamiento) => {
        if(EncuentraMenorARegistro(Number(indexSesion)+1,1,actualRegistro) !== EstadosSesion.INCOMPLETO){
            let actividades: Actividad[] | null = redux_actividades_entrenamiento? redux_actividades_entrenamiento[indexSesion]: null;
            if(!actividades || CargarTodo){
                actividades = await consultarActividades(actualEntrenenamiento.id,actualEntrenenamiento.sesiones[indexSesion].id)
                console.log("🚀 ~ CargarActividades ~ actividades:", actividades)
                if(actualRegistro.ordenSesion === Number(indexSesion)+1 && actualRegistro.ordenActividad ===  actividades.length){
                    let copy = {...actualRegistro}
                    copy.ordenSesion += 1
                    copy.ordenActividad = 0
                    copy.completadoSesion = false
                    console.log("🚀 ~ CargarActividades ~ copy:", copy)
                    dispatch(ChangeRedux_current_registro_usuario({registro:copy}))
                }
            }
            dispatch(ChangeRedux_current_actividades({current_actividades: actividades}))
            dispatch(ChangeRedux_current_sesion({sesion:Number(indexSesion)}))
        }else{
            console.error("No cumple las condiciones para ir a la sesion",indexSesion+1," actividad ",indexActivity)
            navigate(`/my/entrenamiento/${idEntrenamiento}`)
        }
    }

    const CargaTodasLasActividadesDelEntrenamiento = async (actualEntrenenamiento:Entrenamiento) => {
        if(CargarTodo || !redux_actividades_entrenamiento){
            let consultarTodasActividades = await consultarActividadesEntrenamiento(actualEntrenenamiento)
            dispatch(ChangeRedux_actividades_entrenamiento({actividades_entrenamiento:consultarTodasActividades}))
        }
    }

    return async (cargarSesion?: boolean) => {
        dispatch(newActivity({id:"entrenamiento",name:"entrenamiento"}));
        const {returnEntrenamiento,returnRegistro} = CargaEntrenamientoRegistroActual()
        if(caso() === 'carga-actividad' || (cargarSesion && returnRegistro)){
            if(cargarSesion) indexSesion = (returnRegistro.ordenSesion - 1).toString();
            console.log("CargarActividades");
            CargarActividades(returnEntrenamiento,returnRegistro)
            CargaTodasLasActividadesDelEntrenamiento(returnEntrenamiento)
        }else{
            CargaTodasLasActividadesDelEntrenamiento(returnEntrenamiento)
        }
    }
  
}

export enum EstadosSesion{
    INCOMPLETO,
    COMPLETADO,
    ACTUAL
}
//Devuelve True si el orden y actividad que se pasan se puede acceder (es decir o ya se completo o se encuentra en ese punto)
//devuelve 2 si es el actual y 1 si esta completado y 0 si no se ha realizado
export const CalcularEstadoDeSesion = (ordenSesion:number,currentRegistroUsuario:RegistroEntrenamiento) => {
    if(currentRegistroUsuario?.completadoEntrenamiento){
        return EstadosSesion.COMPLETADO
    }
    if(ordenSesion === currentRegistroUsuario?.ordenSesion+1 && currentRegistroUsuario?.completadoSesion){
        return EstadosSesion.ACTUAL
    }
    if(ordenSesion < currentRegistroUsuario?.ordenSesion){
        return EstadosSesion.COMPLETADO
    }
    if(ordenSesion === currentRegistroUsuario?.ordenSesion){
        if(currentRegistroUsuario.completadoSesion){
            return EstadosSesion.COMPLETADO
        }else{
            return EstadosSesion.ACTUAL
        }
    }
    return EstadosSesion.INCOMPLETO
}

export const EncuentraMenorARegistro = (ordenSesion:number,ordenActividad:number,currentRegistroUsuario:RegistroEntrenamiento) => {
    if(currentRegistroUsuario?.completadoEntrenamiento){
        return EstadosSesion.COMPLETADO
    }
    let Sesion = CalcularEstadoDeSesion(ordenSesion,currentRegistroUsuario)
    if(Sesion === EstadosSesion.COMPLETADO){
        return EstadosSesion.COMPLETADO
    }else if (Sesion === EstadosSesion.ACTUAL){
        if(currentRegistroUsuario?.ordenActividad === 0 && ordenActividad === 1){
            return EstadosSesion.ACTUAL
        }
        if(ordenActividad <= currentRegistroUsuario?.ordenActividad){
            return EstadosSesion.COMPLETADO
        }else if(ordenActividad-1 === currentRegistroUsuario?.ordenActividad){
            return EstadosSesion.ACTUAL
        }
    }
    return EstadosSesion.INCOMPLETO;
}

export const useGenerarSiguienteRegistro:() => Promise<RegistroEntrenamiento> = () => {
    const store = useStore();

    let unsubscribe = null
    const esperarActividadesCargadas = new Promise<RegistroEntrenamiento>((resolve) => {
        unsubscribe = store.subscribe(() => {
            const state:any = store.getState();
            const actividades:Actividad[][] = state.entrenamiento?.actividades_entrenamiento;
            if (actividades?.length > 0) {
                resolve(calcularNuevoRegistro(actividades,state.entrenamiento?.current_registro_usuario));
                unsubscribe();
            }
        });
    });
    
    const calcularNuevoRegistro = (actividades:Actividad[][],registroActual:RegistroEntrenamiento) => {
        if(!registroActual?.completadoEntrenamiento){
            let nuevoResgistro = {...registroActual};
            let cantidadActividades = actividades[registroActual?.ordenSesion-1]?.length;
            nuevoResgistro.fechaInicio = new Timestamp(nuevoResgistro?.fechaInicio?.seconds,nuevoResgistro?.fechaInicio?.nanoseconds);
            nuevoResgistro.fechaSesion = Timestamp.now();
            nuevoResgistro.ordenActividad += 1;
            if(nuevoResgistro.ordenActividad === cantidadActividades && nuevoResgistro?.ordenSesion === actividades.length){
                nuevoResgistro.completadoSesion = true;
                nuevoResgistro.completadoEntrenamiento = true;
                return nuevoResgistro;
            }
            if(nuevoResgistro.ordenActividad === cantidadActividades){
                nuevoResgistro.ordenSesion += 1;
                nuevoResgistro.ordenActividad = 0;
                nuevoResgistro.completadoSesion = false;
            }
            return nuevoResgistro;
        }
        console.log("No se puede calcular el siguiente registro se retorna el actual");
        return registroActual;
    };

    return esperarActividadesCargadas;
    
}