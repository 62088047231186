
import { DocumentSnapshot, DocumentData } from "firebase/firestore";
import { Pregunta } from "./Respuesta";

/**
 * Interfaz para los datos de jugadores
 */
export interface Cuestionario {
    id?: string;

    nombre: string;

    cantPreguntas: number;

    tipoPregunta: string;

    tipoRespuesta: string;

    preguntas?: Pregunta[]
}

export const aCuestionario = (nombre:string, tipoPregunta:string, tipoRespuesta:string, preguntas?: Pregunta[]) : Cuestionario => {
    
    const cuestionario: Cuestionario = {
        nombre :nombre,
        tipoPregunta: tipoPregunta,
        tipoRespuesta: tipoRespuesta
    } as Cuestionario;

    if (preguntas !== null && preguntas !== undefined) {
        cuestionario.preguntas = preguntas;
    }
    return cuestionario;       
}

/** Conversiones */

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toCuestionario = (doc : FireBaseDocument) : Cuestionario => {    
    if(doc.exists()){        
        const calify = doc.data() as Cuestionario;
        calify.id = doc.id;
        return calify
    }
    return undefined;
    
}

