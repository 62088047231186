import React from 'react';

const ButtonRow = props => {

    return (
        <div >
            { props.children }
        </div>
    );
}

export default ButtonRow;
