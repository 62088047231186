import React, { useState, useEffect, useRef } from 'react';
import '../../../theme/MemoriaParejas.css';
import estiloJuegos from "../../../theme/Juegos.module.css";
import { obtenerNeuronas } from '../../../utils/neuronasUtil';
import { nomProgParejas } from '../../../datos/nombresBD';
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../entrenamiento/componentes/Loading';
import TiempoWrapper from '../componentes/TiempoWrapper';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useDato } from '../../../auth';
import { useNeurona } from '../../../hooks/controlador/useNeurona';
import { initialNeuronas } from '../../../hooks/reducer/reducerAuth';
import PopoverNeuronasParejas from './componentes/PopoverNeuronasParejas';
import TableroParejas from './componentes/TableroParejas';
import { TiempoSubSec, FormatoTiempo, aSegundos } from './componentes/TemporizadorSubSegundo';
import { changePuntaje, terminadoJuego } from '../../../hooks/reducer/reducerJuegoSimultaneo';
import { test_general } from '../../../datos/DatosTests';

interface Nivel{
    cantidad: number;         // cantidad de cartas
    tiempo: number;         // tiempo etapa de revelacion centesimas de segundo
    multiplicador: number;  // multiplicador de tiempo a etapa de juego
}

const JuegoDeMemoria = () => {
    const datosNiveles = [
        {cantidad: 4 , tiempo: 300 , multiplicador: 4},
        {cantidad: 6 , tiempo: 500 , multiplicador: 4},
        {cantidad: 8 , tiempo: 600 , multiplicador: 4},
        {cantidad: 12, tiempo: 800 , multiplicador: 5},
        {cantidad: 16, tiempo: 1200, multiplicador: 5}
    ];

	const esJuego = useAppSelector((state) => state.activity.id === test_general);
    const userId = useAppSelector((state) => state.auth.userId);
	const idActividad = useAppSelector((state) => state.activity.id);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { guardarPuntajeParejas } = useJuegos();

    const [puntaje, setPuntaje] = useState<number>(0);

    const [neuronas, setNeuronas] = useState(useAppSelector((state) => state.auth.neuronas));

    const [subEtapa, setSubEtapa] = useState<number>(3);
    const [etapa, setEtapa] = useState<number>(0);
    const [nivel, setNivel] = useState<number>(0);

    const [cargado, setCargado] = useState<boolean[]>(Array(datosNiveles.length).fill(false));

    const [tiempo, setTiempo] = useState<number>(datosNiveles[nivel].tiempo);
    const [totalTiempo, setTotalTiempo] = useState<number>(0);

    const [intentos, setIntentos] = useState<number>(0);

    const final = useRef<boolean>(false);

    //-- functional

    const enCarga = (indice: number) => {
        setCargado(
            prev => {
                let nuevo = [...prev];
                nuevo[indice] = true;
                return nuevo
            }
        );
    }

    const Salida = () => {
        if(idActividad === 'juego_linea'){
            dispatch(changePuntaje({verPuntaje:true}))
        }else{
            navigate("/my/juegos/Memoria")
        }
    }

    //-- GameLogic

    const Reiniciar = () => {

        setCargado(Array(datosNiveles.length).fill(false));
        setNivel(0);
        setEtapa(0);
        setSubEtapa(3);
        setTotalTiempo(0);
        setIntentos(0);
        setTiempo(datosNiveles[0].tiempo);
    }

    const IniciarJuego = () => {
        setEtapa(1);
        setSubEtapa(0);
    }

    const getEstadoCartel = () => {
        let estadoCartel = "";
        if(etapa === 0 || !cargado[nivel] || etapa > 1){
            estadoCartel = "ocultoAbajo";
        }else if(subEtapa === 1){
            estadoCartel = "ocultoArriba";
        }

        return estadoCartel;
    }

    const ManejarFinalizoConteo = () => {
        switch(etapa){
            case 1:
                setTiempo(datosNiveles[nivel].tiempo * datosNiveles[nivel].multiplicador);
                setSubEtapa(2);
                setTimeout(() => {
                    setEtapa(2);
                }, 1200);
                break;
            case 2:
                setEtapa(3);
                break;
            default:
                break;
        }
    }

    const ActualizarTiempo = (tiempo: number) =>{
        if(subEtapa > 1) setTotalTiempo(prev => prev + tiempo);
        setTiempo(tiempo);
    }

    const IrSiguienteNivel = () => {
        if(nivel + 1 < datosNiveles.length){
            IniciarJuego();
            setNivel(nivel + 1);
            setTiempo(datosNiveles[nivel + 1].tiempo)
        }else{
            Reiniciar();
        }
    }

    const Victoria = (intentosNivel: number) => {
        setIntentos(prev => prev + intentosNivel);
        if(nivel >= (datosNiveles.length - 1)){
            final.current = true;
        }
        setEtapa(3);
        setTimeout(() => {
            if(etapa === 3) setSubEtapa(0);
        }, 800);
    }

    //-- effects

    useEffect(() => {
        if(etapa === 1 && cargado[nivel]){
            final.current = false;
            setTimeout(() => {
                setSubEtapa(1);
            }, 1200);
        }
        if(etapa === 3 && tiempo === 0){
            console.log("final no alcanzado");
            if(idActividad !== 'juego_linea') {
                guardarPuntajeParejas(userId, {puntaje, tiempo: aSegundos(totalTiempo), intentos})
                // letneuronasNuevas = obtenerNeuronas(nomProgMemNombres, nivel, cantidadCorrectaTemporal)
            }else{
                dispatch(terminadoJuego({ultimoResultado:puntaje,tiempo:aSegundos(totalTiempo),finalizado:true}))
            };
        }
    }, [cargado, etapa]);

    useEffect(() => {
        if(etapa === 3){
            let nuevoPuntaje = Math.ceil(totalTiempo/10 - intentos*100);
            console.log("🚀 ~ useEffect ~ nuevoPuntaje:", nuevoPuntaje)
            if(nuevoPuntaje < 10) nuevoPuntaje = 10;

            if(nivel >= (datosNiveles.length - 1) && final.current){
                console.log("final alcanzado");
                nuevoPuntaje = nuevoPuntaje + 300;
                if(idActividad !== 'juego_linea') {
                    guardarPuntajeParejas(userId, {puntaje: nuevoPuntaje, tiempo: aSegundos(totalTiempo), intentos}) 
                }else{
                    dispatch(terminadoJuego({ultimoResultado:(nuevoPuntaje < 16)? 1: Math.round(nuevoPuntaje / 1600 * 100),tiempo:aSegundos(totalTiempo),finalizado:true}))
                };
            }
            
            if(idActividad === 'juego_linea'){
                nuevoPuntaje = (nuevoPuntaje < 16)? 1: Math.round(nuevoPuntaje / 1600 * 100)
            }else{
                nuevoPuntaje = nuevoPuntaje / 10
            }
            const neuronasTotales = (neuronas + nuevoPuntaje).toFixed(0);
            console.log("🚀 ~ useEffect ~ neuronas + nuevoPuntaje:", neuronas + nuevoPuntaje)
            dispatch(initialNeuronas({cantidad:Number(neuronasTotales)}))
            setPuntaje(nuevoPuntaje);
        }
    }, [totalTiempo])

    return(
        <div id='page' style={(idActividad === 'juego_linea'? {height: "100%"}: {})}>

        {etapa > 0 && !cargado[nivel] && 
            <Loading isOpen={true}/>
        }

        {(idActividad !== 'juego_linea' || etapa === 0) &&
        <div  className={etapa === 0 ? estiloJuegos.toolbarParejas : estiloJuegos.toolbar_default}>
            { esJuego && <Link  className={estiloJuegos.backJuegos}  to=".." relative='path'>
            </Link>}
            <h1 className={`title ${estiloJuegos.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'2.37svh'} :{}}> PAREJAS </h1>
        </div>
        }

        <div id='content_default' style={(etapa === 3)?{position: "relative"}:{}}>

            {etapa === 0 &&
            <>
                <div className={estiloJuegos.contenedorIconoParejas}>
                    <img src='/assets/componentes/Juegos/Parejas.svg' className={estiloJuegos.icono}></img>
                </div>
                <div className={estiloJuegos.primeraPantalla}>
                    <div className={estiloJuegos.descripcionJuego}>
                    ¡Mira bien las cartas durante unos segundos y trata de recordar dónde está cada pareja! Luego, voltea las cartas de a dos para encontrar las que son iguales.
                    </div>
                    <button className={estiloJuegos.buttonEmpezar} onClick={IniciarJuego}>
                        EMPEZAR
                    </button>
                    {esJuego && <button id='button_bordes_default' onClick={() => navigate("/my/juegos/ranking")}>
                        RANKING
                    </button>}
                </div>            
            </>
            }


            {etapa > 0 &&
                    <div className="topInfoContainer" style={{margin:'1rem 0.5rem 0 0.5rem'}}>
                        <img src="/assets/icon/relojBlanco2.svg" alt="⏱"/>
                        {!cargado[nivel] && "00:00.0"}
                        {cargado[nivel] && etapa > 0 &&
                            <TiempoSubSec
                                inicial={tiempo}
                                contar={subEtapa > 0 && etapa < 3}
                                ascendente={false}
                                conteoTermino={ManejarFinalizoConteo}
                                tiempoActual={ActualizarTiempo}
                                reiniciar={etapa}
                            />
                        }
                    </div>
            }
            <div className={`cartelPreSubEtapa ${getEstadoCartel()}`}>
            {(subEtapa < 2)? "¡Memoriza las cartas!": "¡Encuentra las parejas!"}
            </div>
            {
                datosNiveles.map((datos, indice) => (
                    <TableroParejas
                        key={indice}
                        cantidad={datos.cantidad}
                        visible={etapa > 0 && nivel === indice}
                        estado={subEtapa}
                        onLoad={() => enCarga(indice)}
                        onWin={Victoria}
                    />)
                )
            }

            <div className={(etapa === 3? "": "desaparecer")}>
                <div className="opaqueFinal"></div>
                <PopoverNeuronasParejas
                    tiempoTotal={totalTiempo}
                    tiempo={tiempo}
                    intentos={intentos}
                    nivel={nivel}
                    nivelFinal={nivel >= (datosNiveles.length - 1)}
                    estado={tiempo > 0}
                    puntaje={puntaje}
                    enLinea={idActividad === 'juego_linea'}
                    onContinuar={IrSiguienteNivel}
                    onReiniciar={Reiniciar}
                    onSalir={Salida}
                />
            </div>
        </div>
      </div>
    )
}

export default JuegoDeMemoria;

