/** Importaciones de React */
import React, { useState, useEffect } from 'react';


/** Impornntaciones Interfaces */
import { UsuarioDato, createUsuario,USUARIO_DATO_VACIO } from '../../../modelos/usuario';

/** Importaciones de Firebase */

/** Importaciones de modulos personalizados */
import { useDato, } from '../../../auth';
import NombresComponent from './NombresComponent';

/** Importaciones de modulos personalizados */
import { useToast } from '../../../hooks/useToast';
import TelefonoComponent from './TelefonoComponent';
import { palabrasInvalidas } from '../../../utils/NombresInvalidos';
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { useAppSelector } from '../../../store';
import { auth } from '../../../firebase';
import { updatePassword } from 'firebase/auth';
import { useNavigate, useLocation} from 'react-router-dom'
import PaisComponent from './PaisComponent';
import FechaDeNacimientoComponent from './FechaDeNacimientoComponent';
interface Props {
	firstTime:Boolean;
}

const DatosComponent: React.FC<Props> = (props) => {
	const location = useLocation();
	const { 
		presentDatosIncompletos, 
		presentPasswordInvalido, 
		presentDatosCompletos,
		presentNombresValidos,
		presentTelefonoValidos,
		presentPoliticasPrivacidadValidos
	} = useToast();
	const userId = useAppSelector((state) => state.auth.userId);
	
	const email = useAppSelector((state) => state.auth.email);
	// const origDate = format(new Date(), 'yyyy-MM-dd');
	const [errorPassword,setErrorPassword] = useState(false);
	const [aceptaTerminos,setAceptaTerminos] = useState(true);
	const {
		state,
		guardarDatos,
	} = useDato();
	const navigate  = useNavigate();
	const {
        initializarJuegos,
    } = useJuegos();

	useEffect(() => {
		agregarDatoLocal("correo",email)
		setPassword('')
		console.log("DatosComponent.tsx");
	}, []);	

	const usuarioExisteBD = state?.usuarioDato?.nombres ? true : false;
	if(state?.codigo === undefined){
		state.codigo = "+57";
	};

	if(state?.pais === undefined){
		state.pais = "Colombia";
	};
	
	const [datosLocales, setDatosLocales] = useState<UsuarioDato>(state?._usuarioDato);
	// console.log("🚀 ~ file: DatosComponent.tsx:73 ~ state?._usuarioDato:", state?._usuarioDato)
	const [password, setPassword] = useState('');
	
	const checkDatosLocal = () => {
		datosLocales.telefonoCompleto = datosLocales.codigo + datosLocales.telefono;
		console.log("🚀 ~ checkDatosLocal ~ datosLocales:", datosLocales)
		if (datosLocales === undefined) {
			return false;
		}
		if (Object.values(datosLocales).some((dato) => !dato)) {
			return false;
		}

		return Object.values(datosLocales).every((dato) => dato?.trim()?.length > 0)
	}

	const limpiarString = (str: string) => {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
	  }
	  
	  const compararPalabras = (palabra: string, arrayPalabras: string[]): boolean => {
		for (let i = 0; i < arrayPalabras.length; i++) {
		  if (limpiarString(palabra) === limpiarString(arrayPalabras[i])) {
			return true;
		  }
		}
		return false;
	  };
	  
	  const comprobacionNombres = (datos) => {
		const nombres = datos.split(" ");
		console.log("🚀 ~ comprobacionNombres ~ nombres:", nombres);
	  
		for (let i = 0; i < nombres.length; i++) {
		  // Limpiar la palabra de espacios en blanco al final
		  const trimmedWord = nombres[i].replace(/\s+$/, '');
	  
		  // Verificar si la palabra contiene alguna de las palabras invalidas
		  if (palabrasInvalidas.some(palabraInvalida => limpiarString(trimmedWord).includes(limpiarString(palabraInvalida)))) {
			return true;
		  }
		}
		return false;
	  };
	const borrarEspacios = (datos) => {
		// Trim spaces at the beginning and end of the string
		const trimmedString = datos.trim();

		// Split the trimmed string into words and join them with a single space
		const nombreSinEspacios = trimmedString.split(' ').filter(word => word !== '').join(' ');
	
		return nombreSinEspacios;
	}


	function verPoliticas(){
			navigate('/my/home/PoliticasPrivacidad');
	}
	
	function validarTelefono(telefono) {
		const SoloNumeros = /^[0-9]+$/;
		
		if (SoloNumeros.test(telefono)) {
		  return true;
		} else {
		  return false;
		}
	  }
	  
	  const checarGuardar = () => {
		if (!props.firstTime && !checkDatosLocal()) {
			console.log(checkDatosLocal());
			presentDatosIncompletos();
		}
		else {
			if(comprobacionNombres(datosLocales.nombres) ){
				presentNombresValidos();
			}
			/*
			else if(comprobacionNombres(datosLocales.alias) ){
				presentAliasValidos();
			}*/
			else if(!validarTelefono(datosLocales.telefono)){
				presentTelefonoValidos();
			}
			else if(!aceptaTerminos){
				presentPoliticasPrivacidadValidos();
			}
			else{				
				if(password !== '' && (props.firstTime && state?._initializado)){
					const user = auth.currentUser;
					updatePassword(user, password).then(() => {
						// Update successful.
						console.log("Successfull Change Password")
						guardarDatos(userId, createUsuario(state?._administrador,true, true, datosLocales));
						presentDatosCompletos();
						setErrorPassword(false)
					}).catch((error) => {
						// An error ocurred
						presentPasswordInvalido();
						setErrorPassword(true)
						console.log("Error Change Password, ",error)
						// ...
					});
					if(!usuarioExisteBD){
						initializarJuegos(userId);
					} 
				}else{
					if(props.firstTime && state?._initializado){
						presentDatosIncompletos();
					}else{
						guardarDatos(userId, createUsuario(state?._administrador,true, true, datosLocales));
						if(!usuarioExisteBD){
							initializarJuegos(userId);
						} 
						presentDatosCompletos();
						if(location.pathname == '/my/home/editarUsuario'){
							navigate('/my/home/datosPersonales');
						}
						
					}
				}
			}
						
		}
	}

	const agregarDatoLocal = (campo, valor) => {
		const datosLocalesTmp = { ...datosLocales };
		if(campo == "nombres"){
			valor = borrarEspacios(valor)
		}
		datosLocalesTmp[campo] = valor;
		console.log("🚀 ~ agregarDatoLocal ~ datosLocalesTmp:", datosLocalesTmp)
		setDatosLocales(datosLocalesTmp)
	}
	

	return (
		<>
		
			<NombresComponent setDatoLocal={agregarDatoLocal}  datoAnterior={state?._usuarioDato.nombres}/>	
			<div className='item-correo' style={{marginTop:'2.36svh'}}>
				<label className='label-datos'>Correo Electrónico</label>
				<input type="email" style={{background:'#F6F4F7'}} value={datosLocales?.correo} onChange={(e) => agregarDatoLocal("nombres",e.target.value)}/>
			</div>
			<div className='list-inputs-datos'>
				<label>País</label>
				<label>Fecha de Nacimiento</label>
				<PaisComponent setDatoLocal={agregarDatoLocal} datoAnterior={state?._usuarioDato.pais} />			
				<FechaDeNacimientoComponent setDatoLocal={agregarDatoLocal} datoAnterior={state?._usuarioDato.fechaDeNacimiento} />
			</div>
							
			<TelefonoComponent setDatoLocal={agregarDatoLocal} datoAnterior={state?._usuarioDato.telefono} codigoAnterior={state?._usuarioDato.codigo}/>
			{(props.firstTime && state?._initializado) && <div className='item-contraseña'>
				<label>Contraseña</label>
				<input type="password" placeholder="•••••••••••" value={password} onChange={(event) => {setPassword(event.target.value)} }  
					/>
				{errorPassword && <label className='error'>Mínimo 6 caracteres</label>}
			</div>}
			<br></br>
			<div className='item-terminos'>

				<div className='checkmarkPersonalizada' style={{width: '4vh'}} onClick={() => setAceptaTerminos((state) => !state)}>
					{aceptaTerminos && <img src='/assets/icon/chuloMorado.svg' style={{height:'150%',width:'150%',position:'relative',top:'-50%',right:'-20%',maxWidth:'none'}}></img>}
				</div>
				<label className='tratamiento-datos'>
					*Acepto los términos, condiciones y el tratamiento de mis datos personales conforme a la <wbr/>
					<a className="ver-politicas" onClick={verPoliticas} >política de privacidad</a>
				</label>
			</div>
			<button className="button-principal" style={{margin:'5% 0'}}  onClick={checarGuardar} >GUARDAR</button>

		</>
	);
}

export default DatosComponent;
