import { useState, useEffect } from 'react';
import "../../../theme/variables.css"
import { buttons } from "../../../utils/Buttons";
import Button from '../../componentes/Button';
import { logObj } from '../../../utils/ayudantes';

import style from "../../../theme/Juego2.module.css"; 
import estiloJuegos from "../../../theme/Juegos.module.css";
import stylePal from "../../../theme/memoriaPalabras.module.css";
import estiloNeuronas from "../../../theme/PopoverNeuronas.module.css";

import {palabras, niveles}  from '../../../datos/DatosMemPalabras';
import { ArrayDeNumeros } from '../../../utils/ArrayDeNumeros' ;
import Toolbar from '../componentes/Toolbar';
import SegundaPantalla from './componentes/SegundaPantalla';
import TerceraPantalla from './componentes/TerceraPantalla';

import { nomJuegoSimultaneo, nomProgMemPalabras} from '../../../datos/nombresBD';
import { useDato } from "../../../auth";
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { useAppDispatch, useAppSelector } from '../../../store';
import { test_general } from '../../../datos/DatosTests';
import { obtenerNeuronas } from '../../../utils/neuronasUtil';
import { initialNeuronas } from '../../../hooks/reducer/reducerAuth';
import ResultadosNumPal from '../resultados/componentes/ResultadosNumPal';

import BotonesJuegos from '../componentes/BotonesJuegos';
import { obtenerMensaje } from '../../../utils/resultadoUtil';
import { changeCondicion, changePrograma, newActivity } from '../../../hooks/reducer/reducerActividad';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import SelectorNivel from '../componentes/SelectorNivel';
import Loading from '../../entrenamiento/componentes/Loading';
import { changePuntaje, terminadoJuego } from '../../../hooks/reducer/reducerJuegoSimultaneo';


const Juego2 = () => {
    const navigate = useNavigate();
    const userId = useAppSelector((state) => state.auth.userId);
    const testId = useAppSelector((state) => state.activity.id);
    const esJuego = useAppSelector((state) => state.activity.id === test_general);
    const dispatch = useAppDispatch();
    const neuronas = useAppSelector((state) => state.auth.neuronas);
    const esEntrenamiento = useAppSelector((state) => state.activity.id == "entrenamiento");
    const idEntrenamiento = useAppSelector((state) => state.entrenamiento?.current_entrenamiento?.id ?? null);

	
    const {				
		state,
	} = useDato();

	const {
        guardarDatosCalificacion,	
        guardarDatosCalificacionTestGeneral,	
		getCalificacionGeneral,
    } = useJuegos();

    const primeraEtapa = 1;
    const segundaEtapa = 2;
    const terceraEtapa = 3;
    const inicialValorNumero = -1;
    const inicialValorArrayString: string[] = [];
    const inicialValorArrayBoolean: boolean[] = [];
    const inicialValorArrayAny: any[] = [];


    const titulo = "Memoria de palabras";
    const [tiempo, setTiempo] = useState<number>(0);
    const [etapa, setEtapa] = useState<number>(1);
    const [nivel, setNivel] = useState<number>(0);
    const [nivelActual, setNivelActual] = useState<number>(0);
    const [cantidadCorrecta, setCantidadCorrecta] = useState<number>(inicialValorNumero);
    const [maxTiempo, setMaxTiempo] = useState<number>(inicialValorNumero);
    const [userInputs, setUserInputs] = useState<string[]>(inicialValorArrayString);
    const [calificaciones, setCalificaciones] = useState<boolean[]>(inicialValorArrayBoolean);
    const [inputRefs, setInputRefs] = useState<any[]>(inicialValorArrayAny);
    const [cantidadDePalabras, setCantidadDePalabras] = useState<number>(0);
    const [maxID, setMaxID] = useState<number>(1);
    const [palabraIds, setPalabraIds] = useState<string[]>([]);
    const [palabraIdIndice, setPalabraIdIndice] = useState<number>(0);
    const [nuevasNeuronas, setNuevasNeuronas] = useState<number>(0); 
    
    
  
    
/*
  AYUDANTE FUNCIONES
  */
    const guardaInputAyudante = (target: any) => {
        const indice = Number(target.id.split('-')[1]);

        const userInputsTemporal = userInputs.slice();
        userInputsTemporal[indice] = target.value;
        setUserInputs(userInputsTemporal);
        
        const inputRefsTemporal = inputRefs.slice();
        inputRefsTemporal[indice] = target;
        setInputRefs(inputRefsTemporal);
    }

    const guardaInput = (event: any) => {
        
        if (event.target.name === null || event.target.name === undefined) {
            console.log("hubo un error, no se reconoce el input");
        } else {
            guardaInputAyudante(event.target);
        }
    };

    const ChecarRespuesta = (numeroIndice: number, calificacionesTemporal: boolean[]) => {
        let calificacionIndice = numeroIndice;
        const palabraOriginal = getPalabraWrapper(numeroIndice);        
        const palabraMemorizada = userInputs[numeroIndice];

        if (compararPalabras(palabraOriginal, palabraMemorizada)) {            
            calificacionesTemporal[calificacionIndice++] = true;            
        }
    }

    // esta funcion es para limpiar los acentos y mayusculas de las palabras
    const compararPalabras = (palabra1: string, palabra2: string) => {
        const limpiarString = (str: string) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
        }

        if (limpiarString(palabra1) === limpiarString(palabra2)) { 
            return true;
        }else{
            return false;
        }
    }

    const ChecarRespuestas = () => {
        const calificacionesTemporal = calificaciones.slice();
                
        for (let indice = 0; indice < userInputs.length; indice++) {
            ChecarRespuesta(indice, calificacionesTemporal);
        }

        setCalificaciones(calificacionesTemporal);

        const cantidadCorrectaTemporal = calificacionesTemporal.reduce((x, y) => x + Number(y), 0);
        setCantidadCorrecta(cantidadCorrectaTemporal);

        var nivelCompleto = false;        
        const porcentajeCorrectas = cantidadCorrectaTemporal * 100 / (maxID + 1);        
        

        /*
        if (cantidadCorrectaTemporal === cantidadDePalabras) {
            subirNivel();
            nivelCompleto = true;
        }*/

        // Obtener Neuronas
		const neuronasNuevas = obtenerNeuronas(nomProgMemPalabras, nivel, cantidadCorrectaTemporal)
		setNuevasNeuronas(neuronasNuevas);
        const neuronasTotales = neuronas + neuronasNuevas
		dispatch(initialNeuronas({cantidad:neuronasTotales}))
        
        // Guarda la configuracion cuando es el juego o cuando es un test
        console.log("🚀 ~ ChecarRespuestas ~ tiempo/1000:", tiempo/1000)
        if(nivel >= nivelActual){
            if(testId === test_general){            
                var total = cantidadCorrectaTemporal * 10;
                total = total + (total === 100 ? (tiempo/1000)*10 : 0);                 
                guardarDatosCalificacionTestGeneral(userId, nomProgMemPalabras, neuronasNuevas, nivel, cantidadCorrectaTemporal, tiempo/1000, nivelCompleto, state.nombres, state.pais, (maxID + 1),total);
            }else{
                if(testId === 'juego_linea'){
                    console.log("second")
                    var total = cantidadCorrectaTemporal * 10;
                    total = total + (total === 100 ? (30-(Math.abs(tiempo)/100)) : 0);
                    dispatch(terminadoJuego({ultimoResultado:total,tiempo:30-Math.abs(tiempo)/100,finalizado:true}))
                }else{
                    guardarDatosCalificacion(userId, testId, nomProgMemPalabras, neuronasNuevas, null, cantidadCorrectaTemporal, tiempo/1000, true, state.nombres, state.pais);
                }
            }        
        }             
    }

    /*
      HANDLERS
    */
    const handleTiempo = () => {
        if (esSegundaEtapa()) {
            aTerceraPantalla();
        } else if (esTerceraEtapa()) {
            aCuartaPantalla();
        } else {
            throw new Error("tiempo iniciado fuera de lugar");
        }
    }
    const handleRanking = () => {		
        navigate('/my/juegos/ranking');
	}

    const ResetContenido = () => {
        setPalabraIdIndice(0);
    }
    
    const AumentarIndiceDeNavegacion = () => {
        if (palabraIdIndice < cantidadDePalabras - 1) {
            setPalabraIdIndice(palabraIdIndice + 1);
        }
    }

    const RestarIndiceDeNavegacion = () => {
        if (palabraIdIndice > 0) {
            setPalabraIdIndice(palabraIdIndice - 1);
        }
    }

    useEffect(() => {
        console.log("🚀 ~ Juego2 ~ palabraIdIndice:", palabraIdIndice)
    }, [palabraIdIndice])
    

    const aPrimeraPantalla = () => {
        setEtapa(primeraEtapa);
    }
    const aTerceraPantalla = () => {
        ConstruirTerceraPantalla();
    }

    const aCuartaPantalla = () => {
        ConstruirCuartaPantalla();
    }

    /*
      SETTERS
    */

    const setMaxTiempoMemorizarWrapper = () => {
        
        setMaxTiempo(niveles[nivel][2]);
    }
    
    const setMaxTiempoRecordarWrapper = () => {
       
        setMaxTiempo(niveles[nivel][3]);
    }
    
        
    const setNivelWrapper = (nuevoNivel: number) => {
        
        setNivel(nuevoNivel);
       
    }

    
    const setCalificacionesWrapper = () => {
        setCalificaciones(Array(getCantidadDeNumerosCrudo(nivel)).fill(false));
    }
    
    /*
      GETTERS
    */
    const TiempoMemorizando = (tiempoMem:number) =>{
        if(etapa==2){
            setTiempo(tiempoMem);
        }
    }
    
    const GetDescripcion = () => {
        return (
            <div className={estiloJuegos.descripcionJuego}>
                ¡Desafía tu memoria! <br></br> Tienes 30 segundos para memorizar esta lista de palabras.
            </div>
        );
    }
    const GetMensajes = () =>{
        
        let porcentaje = cantidadCorrecta/(maxID + 1);
        let mensajePersonalizado =obtenerMensaje(porcentaje);
        if (porcentaje > 0) {
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p  className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                    { esJuego && <h3 style={{margin:"0",color:'var(--color-Medium-Grey)'}} className = {estiloNeuronas.totalNeuronas}>Ganaste +{nuevasNeuronas} neuronas</h3>}
                    <h3 className={estiloNeuronas.totalNeuronas} style={{color:'var(--color-Medium-Grey)',marginBottom:'5%'}}> TOTAL: {neuronas}</h3>
                </div>

            );
        }else{
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p style={{marginBottom:"2vh"}} className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                    
                </div>

            );
        }
            
        
    }

    const getCantidadDeNumerosCrudo = (nuevoNivel: number) => {
        
        return niveles[nuevoNivel][1];
        
    }

    const getPalabraWrapper = (indice : number) => {
        const indicePalabra = palabraIds[indice];        
	    return palabras[indicePalabra];
    }

    const esSegundaEtapa = () => {
        return etapa === segundaEtapa;
    }

    const esTerceraEtapa = () => {
        return etapa === terceraEtapa;
    }
   
    const HandlePlay = () => {
		InitializarConfig();
		setEtapa(segundaEtapa);
	}
  
    const InitializarConfig = () => {
        const cantidadDePalabrasTemporal = niveles[nivel][1];
        const palabraIdsTemporal = ArrayDeNumeros(cantidadDePalabrasTemporal,palabras);

        setCantidadDePalabras(cantidadDePalabrasTemporal);
        setPalabraIds(palabraIdsTemporal);
        setPalabraIdIndice(0);
        setMaxID(0);
        setUserInputs(Array(cantidadDePalabrasTemporal).fill(""));
        setCalificaciones(Array(cantidadDePalabrasTemporal).fill(false));

        setMaxTiempoMemorizarWrapper();
        
        setInputRefs(Array(cantidadDePalabrasTemporal).fill(null));
        setCalificacionesWrapper();
    }

    const ConstruirTerceraPantalla = () => {
        setMaxTiempoRecordarWrapper();
        setEtapa(3);
    }

    const ConstruirCuartaPantalla = () => {
        ChecarRespuestas();
        setTimeout(() => setEtapa(4), 10);
    }
    
    const constructor = async () => {
        /** Carga el nivel 10 por defecto */        
        setNivelWrapper(1);
        aPrimeraPantalla();
    }
    
    async function handleBackButtonClick(): Promise<void> {
      if(esEntrenamiento){
          await dispatch(newActivity({id:idEntrenamiento,name:"entrenamiento"}))
          await dispatch(changePrograma({id:idEntrenamiento}))
          dispatch(changeCondicion({condicion:10}))
          navigate(-2);
      }else{
          if(testId === 'juego_linea'){
              dispatch(changePuntaje({verPuntaje:true}))
          }else{
              navigate(-1);
          }
      }
    }

    useEffect(() => {
        if(etapa == 1){
            constructor();
        }
      }, [etapa]);

    useEffect(() => {
        if(maxID < palabraIdIndice && etapa === 2){
            setMaxID(palabraIdIndice)
        }
    }, [palabraIdIndice])
      
    
    return (
        <div id='page' style={(testId === 'juego_linea'? {height: "100%"}: {})}>
            {nivel === 0 && <Loading isOpen={true}/>}

            {(testId !== 'juego_linea' || etapa === 1) &&
            <div className={etapa === 1 ? estiloJuegos.toolbarMemoriaPalabras : estiloJuegos.toolbar_default}>
            
                {esJuego &&
                    <Link  className={estiloJuegos.backJuegos} to=".." relative='path'></Link>
                }
                <h1 className={`title ${estiloJuegos.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'2.37svh'} :{}}> {titulo} </h1>
                    
            </div>
            }
            
            {etapa === 1 && 
                <div className={estiloJuegos.contenedorIconoPalabras}>
                    <img src='/assets/componentes/Juegos/ABC.svg' className={estiloJuegos.icono}></img>
                </div>
            }
            <div id='content_default' className={etapa === 3 ? 'teclado' : ''} style={{height: "90%"}}>
                {etapa !== 1 && 
                    <Toolbar
                    esJuego={esJuego}
                    nivel={nivel}
                    etapa={etapa}
                    handleTiempo={handleTiempo}
                    maxTiempo={maxTiempo}
                    setTiempoMemoria={TiempoMemorizando}
                    />
                }
            
                {etapa === 1 &&
                    <>
                        <div className={estiloJuegos.primeraPantalla}>
                           
                           {GetDescripcion()}  
                            <button className={estiloJuegos.buttonEmpezar} onClick={HandlePlay}>
                                EMPEZAR
                            </button>
                            {/* {esJuego &&
                                <button id='button_bordes_default' onClick={handleRanking}>
                                    RANKING
                                </button>}
                            */}
          
                       </div>
                        
                    </>
                }
                {etapa === 2 &&
                <div className={stylePal.segundaPantallaPalabras}>
                    <p className={stylePal.mensaje}>Memoriza las siguientes  {niveles[nivel][1]} palabras</p>
                    <SegundaPantalla
                    tituloPalabras='Palabras'
                    digitos={0}
                    datoPrincipal={palabras[palabraIds[palabraIdIndice]]}
                    resetContenido={ResetContenido}
                    contenidoIdIndice={palabraIdIndice}
                    cantidadDeValores={cantidadDePalabras}
                    leftSubmit={RestarIndiceDeNavegacion}
                    rightSubmit={AumentarIndiceDeNavegacion}
                    aSiguientePantalla={aTerceraPantalla}
                    />
                   
                </div>
                    
                }
                {etapa === 3 &&
                    <TerceraPantalla
                    esJuego={true}
                    aCuartaPantalla={aCuartaPantalla}
                    userRespuestas={userInputs.slice(0, (maxID + 1))}
                    guardaInputs={guardaInput}
                    inputType='text'
                    />
                }
                {etapa === 4 &&
                    <>
                        
                        <div className={style.contenido} style={{padding:`0 5% ${cantidadCorrecta<=0? "5%":""}`, height: "84%", display: "flex", flexDirection: "column"}}>
                            {GetMensajes()}

                            <ResultadosNumPal
                            userInputs={userInputs.slice(0, maxID + 1)}
                            calificaciones={calificaciones}
                            getPalabraWrapper={getPalabraWrapper}
                            game={titulo}
                            tituloPalabras='Palabra'
                            />
                            <div style={{margin: "auto"}}>
                                <p className={style.textoTuviste}>tuviste</p>
                                <p className={style.resultado}>{`${cantidadCorrecta}/${maxID + 1}`}</p>
                            </div>
                        </div>
                        <BotonesJuegos funcionReiniciar={aPrimeraPantalla} funcionVolver={handleBackButtonClick} esJuego={esJuego}/>
                    </>
                }

            </div>
        </div>
    );
};

export default Juego2;
