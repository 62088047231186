import React, { useEffect, useLayoutEffect, useState } from 'react'
import estiloPago from '../../../theme/pagoPage.module.css'
import { ProductoReferencia, TiposProductos } from '../../../modelos/Facturas'
import Selector, { opcion } from '../../entrenamiento/componentes/Selector';
import { useEntidades } from '../../../hooks/controlador/useEntidades';
import { Alert, Spinner } from 'react-bootstrap';
import BotonBordeDegradado from '../../componentes/BotonBordeDegradado';
import { useEntrenamiento } from '../../../hooks/controlador/useEntrenamiento';

interface Props{
  deleteProducto: () => void;
  pushProducto: (e:ProductoReferencia) => void;
  setVisible: (e:boolean) => void;
  initialProducto: ProductoReferencia|null;
  index: number|null;
}

const opciones: opcion[] = [
  { value: null, name: "Sin seleccionar" }, // Opción por defecto
  { value: TiposProductos.tipoEntrenamiento, name: "Entrenamiento" },
  { value: TiposProductos.tipoLibroDigital, name: "Libro Digital" },
  { value: TiposProductos.tipoTaller, name: "Taller" },
  { value: TiposProductos.tipoAsignacion, name: "Asignación" },
];

const descripcionProductos: Record<TiposProductos, string> = {
  [TiposProductos.tipoEntrenamiento]: "Asigna un entrenamiento al usuario",
  [TiposProductos.tipoLibroDigital]: "Envia un correo de pdf al correo del usuario.",
  [TiposProductos.tipoLibroFisico]: "Envía un libro físico a la dirección del usuario.",
  [TiposProductos.tipoTaller]: "Agrega el uid del usuario a un taller",
  [TiposProductos.tipoAsignacion]: "Se le agrega una entidad, grupo y anida la fechaInicioGrupo con un entrenamiento (debe estar asignado el entrenamiento).",
};

const CrearProducto:React.FC<Props> = (props) => {
  const {consultarEntidades, ConsultarGrupos} = useEntidades();
  const {consultarNombresEntrenamientosApp} = useEntrenamiento();

  const [loading, setLoading] = useState(false)
  const [tipoProducto, setTipoProducto] = useState<TiposProductos|null>(props.initialProducto?.tipo ?? null)
  const [opcionesSelector1, setOpcionesSelector1] = useState<opcion[]>([])
  const [opcionesSelector2, setOpcionesSelector2] = useState<opcion[]>([])
  const [opcionesSelector3, setOpcionesSelector3] = useState<opcion[]>([])
  const [optionSelecto1, setOptionSelector1] = useState<string|null>(null)
  const [optionSelecto2, setOptionSelector2] = useState<string|null>(null)
  const [optionSelecto3, setOptionSelector3] = useState<string|null>(null)

  const ConsultarOpciones = async(op:TiposProductos) => {    
    setOptionSelector1(null)
    setLoading(true)
    let resp;
    let options;
    switch(op){
      case(TiposProductos.tipoTaller):
        setOpcionesSelector1([{value:null,name:'Sin seleccionar'},{value:'taller',name:'taller'}])
        setOptionSelector1('taller')
        break
      case(TiposProductos.tipoAsignacion):
        resp = await consultarEntidades()
        options = resp.map(entidad => ({
            value: entidad,
            name: entidad
          } as opcion))
        setOpcionesSelector1([{value:null,name:'Sin seleccionar'}, ...options])
        break
      case(TiposProductos.tipoEntrenamiento):
        resp = await consultarNombresEntrenamientosApp()  
        console.log("🚀 ~ ConsultarOpciones ~ resp:", resp)
        options = resp.map(ent => ({
          value: ent,
          name: ent
        } as opcion))
        setOpcionesSelector1([{value:null,name:'Sin seleccionar'}, ...options])
        break
      case(TiposProductos.tipoLibroDigital):
        setOptionSelector1('entrena_tu_mente_pdf')
        setOpcionesSelector1([{value:null,name:'Sin seleccionar'},{value:'entrena_tu_mente_pdf',name:'entrena_tu_mente_pdf'}])
        break
      default:
        break
    }
    if(props.initialProducto !== null){
      if(props.initialProducto.tipo === TiposProductos.tipoAsignacion){
        let idEntidad = props.initialProducto.id_referencia.split('-',3)
        console.log("🚀 ~ ConsultarOpciones ~ idEntidad:", idEntidad)
        setOptionSelector1(idEntidad[0])
      }else{
        setOptionSelector1(props.initialProducto.id_referencia)
      }
    }else{
      setOptionSelector1(null)
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log("🚀 ~ tipoProducto:", tipoProducto)
    ConsultarOpciones(tipoProducto)
  }, [tipoProducto])

  const ConsultarOpciones2 = async(op:TiposProductos) => {
    setOptionSelector2(null)
    setLoading(true)
    let resp;
    let options;
    switch(op){
      case(TiposProductos.tipoTaller):
        resp = await ConsultarGrupos(optionSelecto1)
        options = resp.map( grTaller=> ({
          value: grTaller,
          name: grTaller
        } as opcion))
        setOpcionesSelector2([{value:null,name:'Sin seleccionar'}, ...options])
        break
      case(TiposProductos.tipoAsignacion):
        resp = await ConsultarGrupos(optionSelecto1)
        options = resp.map(grupo => ({
          value: grupo,
          name: grupo
        } as opcion))
        setOpcionesSelector2([{value:null,name:'Sin seleccionar'}, ...options])
        break
      case(TiposProductos.tipoEntrenamiento):
        break
      default:
        break
    }
    if(props.initialProducto !== null){
      if(props.initialProducto.tipo === TiposProductos.tipoAsignacion){
        let idEntidad = props.initialProducto.id_referencia.split('-',3)
        setOptionSelector2(idEntidad[1])
      }else if(props.initialProducto.tipo === TiposProductos.tipoTaller){
        setOptionSelector2(props.initialProducto.id_referencia)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log("🚀 ~ optionSelecto1:", optionSelecto1)
    if(optionSelecto1){
      ConsultarOpciones2(tipoProducto)
    }
  }, [optionSelecto1])

  const ConsultarOpciones3 = async(op:TiposProductos) => {
    setOptionSelector3(null)
    setLoading(true)
    let resp;
    let options;
    switch(op){
      case(TiposProductos.tipoAsignacion):
        resp = await consultarNombresEntrenamientosApp()
        console.log("🚀 ~ ConsultarOpciones3 ~ resp:", resp)
        options = resp.map(grupo => ({
          value: grupo,
          name: grupo
        } as opcion))
        setOpcionesSelector3([{value:null,name:'Sin seleccionar'}, ...options])
        break
      default:
        break
    }
    if(props.initialProducto !== null){
      let idEntidad = props.initialProducto.id_referencia.split('-',3)
      setOptionSelector3(idEntidad[2])
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log("🚀 ~ optionSelecto2:", optionSelecto2)
    if(optionSelecto2){
      ConsultarOpciones3(tipoProducto)
    }
  }, [optionSelecto2])
  
  const HandleCancelar = () => {
    setOpcionesSelector1([])
    setOpcionesSelector2([])
    setOpcionesSelector3([])
    setTipoProducto(null)
    setOptionSelector1(null)
    setOptionSelector2(null)
    setOptionSelector3(null)
    props.setVisible(false)
  }

  const ValidarDatos = () => {
    switch (tipoProducto) {
      // @ts-ignore
      case TiposProductos.tipoAsignacion:
        if(optionSelecto3 === null) {
          return false
        }
      // @ts-ignore
      case TiposProductos.tipoTaller:
        if(optionSelecto2 === null) {
          return false
        }
      // @ts-ignore
      case TiposProductos.tipoEntrenamiento:
        if(optionSelecto1 === null) {
          return false
        }
    }
    return true
  }

  const HandleAgregarProductos = () => {
    if(ValidarDatos()){
      let idRefentenciaCurrent = ''
      switch(tipoProducto){
        case TiposProductos.tipoEntrenamiento:
          idRefentenciaCurrent += optionSelecto1
          break
        case TiposProductos.tipoLibroDigital:
          idRefentenciaCurrent += optionSelecto1
          break
        case TiposProductos.tipoTaller:
          idRefentenciaCurrent += optionSelecto1
          idRefentenciaCurrent += '-'
          idRefentenciaCurrent += optionSelecto2
          idRefentenciaCurrent += '-'
          idRefentenciaCurrent += optionSelecto3
          break
        case TiposProductos.tipoAsignacion:
          idRefentenciaCurrent += optionSelecto1
          idRefentenciaCurrent += '-'
          idRefentenciaCurrent += optionSelecto2
          idRefentenciaCurrent += '-'
          idRefentenciaCurrent += optionSelecto3
          break
        default:
          idRefentenciaCurrent = null
          break
      }
      let newProducto = {tipo:tipoProducto, id_referencia:idRefentenciaCurrent} as ProductoReferencia
      props.pushProducto(newProducto)
      HandleCancelar()
    }else{
      alert("Falta seleccionar algo")
    }
  }  

  return (
    <div className={estiloPago.modal}>
      <div className={estiloPago.content} style={{display:'flex',flexDirection:'column',gap:'2svh'}}>
        <h2>Agrega un producto</h2>
        <div className={estiloPago.linea}></div>
        {loading ? <Spinner></Spinner>: 
        <>
          <Selector style_cortina={{width:'100svw'}} setSelection={setTipoProducto} selection={tipoProducto} opciones={opciones}></Selector>
          {tipoProducto !== null && <label style={{fontSize:'0.6em'}}>{descripcionProductos[tipoProducto]}</label>}
          {tipoProducto !== null && <>
            <Selector style_contain={{border:'2px solid black',}} setSelection={setOptionSelector1} selection={optionSelecto1} opciones={opcionesSelector1}></Selector>
          </>}
          {optionSelecto1 !== null && tipoProducto !== TiposProductos.tipoEntrenamiento && tipoProducto !== TiposProductos.tipoLibroDigital &&
            <Selector style_cortina={{width:'100svw'}} setSelection={setOptionSelector2} selection={optionSelecto2} opciones={opcionesSelector2}></Selector>
          }
          {optionSelecto2 !== null && tipoProducto === TiposProductos.tipoAsignacion && 
            <Selector style_cortina={{width:'100svw'}} setSelection={setOptionSelector3} selection={optionSelecto3} opciones={opcionesSelector3}></Selector>
          } 
          <BotonBordeDegradado onClick={HandleAgregarProductos}>{props.initialProducto !== null ? 'Guardar cambios' : 'Agregar Producto'}</BotonBordeDegradado>          
          {props.initialProducto !== null && <BotonBordeDegradado onClick={props.deleteProducto} style={{background:'red',color:'red'}}>Borrar Producto</BotonBordeDegradado>}          
          <BotonBordeDegradado onClick={HandleCancelar}>Cancelar</BotonBordeDegradado>
        </>}
      </div>
    </div>
  )
}

export default CrearProducto