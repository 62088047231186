
export const niveles = [[0, 0, 0, 0],
[1, 10, 30, 120]];

export const calificacionPalabras = (nroNivel, respuesta) => {
    const respuestaTotal = niveles[nroNivel][1];
    return Math.floor((respuesta * 10) / respuestaTotal);
}

export const palabras = [
    "Silla",
    "Mesa",
    "Libro",
    "Lámpara",
    "Tijeras",
    "Reloj",
    "Bolsa",
    "Botella",
    "Llave",
    "Vaso",
    "Pelota",
    "Cama",
    "Espejo",
    "Armario",
    "Cuchillo",
    "Anillo",
    "Escoba",
    "Caja",
    "Lápiz",
    "Bolígrafo",
    "Cuchara",
    "Tenedor",
    "Plato",
    "Taza",
    "Sartén",
    "Olla",
    "Cucharón",
    "Batidora",
    "Cafetera",
    "Nevera",
    "Televisor",
    "Computadora",
    "Teclado",
    "Ratón",
    "Pantalla",
    "Impresora",
    "DVD",
    "Zapato",
    "Pantalón",
    "Vestido",
    "Sombrero",
    "Gorra",
    "Bufanda",
    "Guantes",
    "Botón",
    "Libreta",
    "Papel",
    "Cuaderno",
    "Carpeta",
    "Regla",
    "Pegamento",
    "Goma",
    "Alfombra",
    "Colcha",
    "Bombilla",
    "Cable",
    "Fregadero",
    "Lavaplatos",
    "Aspiradora",
    "Fregona",
    "Peine",
    "Maquillaje",
    "Champú",
    "Jabón",
    "Toalla",
    "Perro",
    "Gato",
    "León",
    "Elefante",
    "Jirafa",
    "Tigre",
    "Oso",
    "Cebra",
    "Mono",
    "Caballo",
    "Vaca",
    "Conejo",
    "Canguro",
    "Pato",
    "Gallina",
    "Cerdo",
    "Pavo",
    "Loro",
    "Tortuga",
    "Cocodrilo",
    "Delfín",
    "Ballena",
    "Pulpo",
    "Mariposa",
    "Abeja",
    "Serpiente",
    "Águila",
    "Puma",
    "Lince",
    "Camello",
    "Foca",
    "Pingüino",
    "Avestruz",
    "Búho",
    "Flamenco",
    "Hipopótamo",
    "Rinoceronte",
    "Koala",
    "Cisne",
    "Pez",
    "Lobo",
    "Ardilla",
    "Rana",
    "Gorila",
    "Tucán",
    "Pájaro",
    "Ciervo",
    "Alce",
    "Cepillo",
    "Shampoo",
    "Acondicionador",
    "Esponja",
    "Cortina",
    "Rasuradora",
    "Loción",
    "Bañera",
    "Grifo",
    "Ducha",
    "Cortaúñas",
    "Clip",
    "Grapadora",
    "Marcador",
    "Calendario",
    "Sobres",
    "Etiqueta",
    "Cinta",
    "Ordenador",
    "Escáner",
    "Teléfono",
    "Calculadora",
    "Sello",
    "Almanaque",
    "Rotulador",
    "Corchete",
    "Perforadora",
    "Camisa",
    "Corbata",
    "Blusa",
    "Calcetín",
    "Zapatilla",
    "Suéter",
    "Falda",
    "Chaqueta",
    "Guante",
    "Cinturón",
    "Camiseta",
    "Sandalia",
    "Pijama",
    "Chaleco",
    "Gafa",
    "Collar",
    "Pendiente",
    "Pulsera",
    "Cartera",
    "Bolso",
    "Pañuelo",
    "Cachucha",
    "Tablet",
    "Auricular",
    "Cámara",
    "Radio",
    "Router",
    "Monitor",
    "Mouse",
    "Batería",
    "Cargador",
    "Altavoz",
    "Proyector",
    "Consola",
    "Refrigerador",
    "Estufa",
    "Lavadora",
    "Secadora",
    "Ventilador",
    "Horno",
    "Tostador",
    "Licuadora",
    "Microondas",
    "Afeitadora",
    "Despertador",
    "Cazuela",
    "Espátula",
    "Batidor",
    "Molcajete",
    "Colador",
    "Rallador",
    "Mortero",
    "Jarra",
    "Termo",
    "Prensa",
    "Rodillo",
    "Tabla",
    "Pelador",
    "Exprimidor",
    "Salero",
    "Pimentero",
    "Tetera",
    "Vasija",
    "Fuente",
    "Automóvil",
    "Motocicleta",
    "Bicicleta",
    "Camión",
    "Tren",
    "Tractor",
    "Carro",
    "Autobús",
    "Furgoneta",
    "Avión",
    "Helicóptero",
    "Barco",
    "Yate",
    "Lancha",
    "Canoa",
    "Patinete",
    "Monopatín",
    "Triciclo",
    "Cuatrimoto",
    "Camioneta",
    "Limusina",
    "Convertible",
    "Submarino",
    "Carguero",
    "Jet",
    "Planeador",
    "Kayak",
    "Velero",
    "Ambulancia",
    "Bomberos",
    "Taxi",
    "Carreta",
    "Funicular",
    "Parapente",
    "Dirigible",
    "Monorraíl",
    "Metro",
    "Tranvía",
    "Rafting",
    "Motonieve",
    "Trineo",
    "Carruaje",
    "Góndola",
    "Bicitaxi",
    "Muñeca",
    "Balón",
    "Yoyo",
    "Trompo",
    "Peluche",
    "Lego",
    "Rompecabezas",
    "Canicas",
    "Payaso",
    "Robot",
    "Dados",
    "Bloques",
    "Rayuela",
    "Ajedrez",
    "Damas",
    "Frisbee",
    "Burbujas",
    "Cometa",
    "Baraja",
    "Silbato",
    "Marioneta",
    "Peonza",
    "Patineta",
    "Jenga",
    "Maracas",
    "Tambor",
    "Xilófono",
    "Flauta",
    "Pandereta",
    "Corneta",
    "Lazo",
    "Bumerán",
    "Cubo",
    "Spinner",
    "Deslizador",
    "Casita",
    "Cocinita",
    "Guitarrita",
    "Títeres",
    "Lápices",
    "Plastilina",
    "Orbeez",
    "Bicicletita",
    "Dinosaurios",
    "Slinky",
    "Marionetas",
    "Calcomanías",
    "Manzana",
    "Arroz",
    "Pan",
    "Carne",
    "Queso",
    "Leche",
    "Café",
    "Pollo",
    "Pasta",
    "Salmón",
    "Cerveza",
    "Limón",
    "Tomate",
    "Cebolla",
    "Chocolate",
    "Fresa",
    "Brócoli",
    "Espárrago",
    "Pizza",
    "Miel",
    "Cereal",
    "Huevo",
    "Yogur",
    "Guisantes",
    "Mango",
    "Naranja",
    "Té",
    "Tofu",
    "Tortilla",
    "Uva",
    "Galleta",
    "Chorizo",
    "Batido",
    "Maíz",
    "Banana",
    "Jamón",
    "Cangrejo",
    "Helado",
    "Kiwi",
    "Lentejas",
    "Mora",
    "Nuez",
    "Ostra",
    "Pimienta",
    "Quínoa",
    "Rúcula",
    "Sardina",
    "Trucha",
    "Uchuva",
    "Vino",
    "Whisky",
    "Zanahoria",
    "Aceituna",
    "Sillón",
    "Escritorio",
    "Estantería",
    "Ropero",
    "Banqueta",
    "Cómoda",
    "Butaca",
    "Taburete",
    "Librero",
    "Alacena",
    "Sofá",
    "Hamaca",
    "Biombo",
    "Perchero",
    "Tocador",
    "Aparador",
    "Canapé",
    "Mecedora",
    "Puf",
    "Repisa",
    "Buró",
    "Diván",
    "Vitrina",
    "Cuna",
    "Futón",
    "Baúl",
    "Cabecero",
    "Mesita",
    "Banco",
    "Termómetro",
    "Inhalador",
    "Tensiómetro",
    "Microscopio",
    "Bisturí",
    "Pinza",
    "Jeringa",
    "Camilla",
    "Vendaje",
    "Férula",
    "Broche",
    "Diadema",
    "Tiara",
    "Tobillera",
    "Colgante",
    "Gargantilla",
    "Medallón",
    "Gemelos",
    "Sortija",
    "Piercing",
    "Cadena",
    "Brazalete",
    "Pasador",
    "Pectoral",
    "Zarcillo",
    "Ancla",
    "Gafas",
    "Mochila",
    "Tirantes",
    "Banda",
    "Orejeras",
    "Turbante",
    "Boina",
    "Gorro",
    "Visera",
    "Monedero",
    "Maleta",
    "Ladrillo",
    "Madera",
    "Concreto",
    "Vidrio",
    "Metal",
    "Azulejo",
    "Granito",
    "Arena",
    "Yeso",
    "Cerámica",
    "Cobre",
    "Zinc",
    "Hierro",
    "Plástico",
    "Acero",
    "Mármol",
    "Cemento",
    "Plomo",
    "Pizarra",
    "Aluminio",
    "Escultura",
    "Pintura",
    "Grabado",
    "Mosaico",
    "Dibujo",
    "Fotografía",
    "Collage",
    "Instalación",
    "Relieve",
    "Mural",
    "Acuarela",
    "Retrato",
    "Caricatura",
    "Cuadro",
    "Cojín",
    "Tapiz",
    "Vela",
    "Busto",
    "Jarrón",
    "Maceta",
    "Placa",
    "Rosa",
    "Tulipán",
    "Orquídea",
    "Girasol",
    "Cactus",
    "Margarita",
    "Violeta",
    "Bonsái",
    "Helecho",
    "Lavanda",
    "Roble",
    "Pino",
    "Rubor",
    "Perfume",
    "Colonia",
    "Sombras",
    "Labial",
    "Brocha",
    "Delineador",
    "Esmalte",
    "Cremas",
    "Desodorante",
    "Lociones",
    "Exfoliante",
    "Protector",
    "Base",
    "Corrector",
    "Polvo",
    "Bronceador",
    "Iluminador",
    "Paleta",
    "Máscara",
    "Limpiador",
    "Tónico",
    "Gel",
    "Spray",
    "Pestañas",
    "Quitaesmalte",
    "Hidratante",
    "Pinzas",
    "Pincel",
    "Aceites"
]
