import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Pregunta } from '../../modelos/Respuesta';
import JuegoSimultaneo from '../../vista/programas/simultaneo/JuegoSimultaneo';

interface juegoSimultaneo {
    nombre: string;
    correo : string;
    telefono: string;
    ultimoResultado : number;
    total : number;
    tiempo : number;
    finalizado : boolean;
    verPuntaje : boolean;
    cantidadIntegrates : number;
    sala : string;
}

const initialState = {
    nombre: null,
    correo : null,
    telefono: null,
    ultimoResultado : 0,
    total : 0,
    tiempo : null,
    finalizado : false,
    verPuntaje : false,
    cantidadIntegrates : 0,
    sala : null,
} as juegoSimultaneo

export const sliceJuegoSimultaneo = createSlice({
    name: 'juegoSimultaneo',
    initialState,
    reducers:{
        addIntegrante: (state,action: PayloadAction<{nombre:string, telefono: string, sala :string}>) => {
            state.nombre = action.payload.nombre
            state.telefono = action.payload.telefono
            state.sala = action.payload.sala
        },
        addCantidadIntegrantes: (state,action: PayloadAction<{cantidadIntegrates:number, sala: string}>) => {
            state.cantidadIntegrates = action.payload.cantidadIntegrates;
            state.sala = action.payload.sala
        },
        resincronizarJuego: (state,action: PayloadAction<{ultimoResultado:number,total:number, tiempo: number,finalizado:boolean}>) =>{
            state.ultimoResultado = action.payload.ultimoResultado;
            state.total = action.payload.total;
            state.tiempo = action.payload.tiempo;
            state.finalizado = action.payload.finalizado;
        },
        terminadoJuego: (state,action: PayloadAction<{ultimoResultado:number, tiempo: number,finalizado:boolean}>) =>{
            state.ultimoResultado = action.payload.ultimoResultado;
            state.total = (Number(action.payload.ultimoResultado) ?? 0) + (Number(state.total) ?? 0);
            state.tiempo = action.payload.tiempo;
            state.finalizado = action.payload.finalizado;
        },
        changePuntaje: (state,action: PayloadAction<{verPuntaje:boolean}>) => {
            state.verPuntaje = action.payload.verPuntaje;
        },
        cleanJuego: (state) =>{
            state.nombre = null;
            state.correo = null;
            state.telefono = null;
            state.ultimoResultado = 0;
            state.total = 0;
            state.tiempo = null;
            state.finalizado = false;
            state.cantidadIntegrates = 0;
            state.sala = null;
        },
        clear: (state) =>{
            state = null
        }
    }
});

export const { addIntegrante, addCantidadIntegrantes, terminadoJuego, resincronizarJuego, changePuntaje, cleanJuego, clear} = sliceJuegoSimultaneo.actions;