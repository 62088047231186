import { Route, Routes } from "react-router-dom";
import EntrenamientoPage from "./vista/entrenamiento/EntrenamientoPage";
import FirstTimeEntrenamiento from "./vista/entrenamiento/FirstTimeEntrenamiento";
import ActividadesPage from "./vista/entrenamiento/ActividadesPage";
import RealizarActividadPage from "./vista/entrenamiento/RealizarActividadPage";
import ResponseePayco from "./RespuestaEpayco";
import JuegoValoresAsociados from "./vista/programas/cuestionarios/JuegoValoresAsociados";
import JuegoCodigodePalabras from "./vista/programas/cuestionarios/JuegoCodigodePalabras";
import VelocidadLectura from "./vista/programas/lectura/VelocidadLectura";
import Cuestionario from "./vista/programas/cuestionarios/Cuestionario";
import JuegoCuestionario from "./vista/programas/cuestionarios/JuegoCuestionario";
import ConsultaIngles from "./vista/inteligenciaArtificial/ConsultaIngles";
import LandingCurso from "./vista/entrenamiento/LandingPage/LandingCurso";
import JuegoCodificacion from "./vista/programas/cuestionarios/JuegoCodificacion";
import { useEffect, useLayoutEffect, useState } from "react";
import { useEntrenamiento } from "./hooks/controlador/useEntrenamiento";
import { useAppDispatch, useAppSelector } from "./store";
import { Entrenamiento } from "./modelos/entrenamiento/Entrenamiento";
import { ChangeRedux_cargando, ChangeRedux_entrenamientos_app, ChangeRedux_entrenamientos_user, ChangeRedux_registros_usuario } from "./hooks/reducer/reducerEntrenamiento";
import useRegistroEntrenamiento from "./hooks/controlador/useRegistroEntrenamiento";
import LayoutEntrenamiento from "./vista/entrenamiento/LayoutEntrenamiento";
import SesionesPage from "./vista/entrenamiento/SesionesPage";

export const TabsEntreno = () =>{
    const {coleccionEntrenamientos, consultarEntrenamientosApp} = useEntrenamiento();
    const {consultarRegistros} = useRegistroEntrenamiento();
    const dispatch = useAppDispatch();

    const userId = useAppSelector((state) => state.auth.userId);
    const asignados = useAppSelector((state) => state.entrenamiento.entrenamientos_user);
    const entrenamientos = useAppSelector(state => state.entrenamiento.entrenamientos_app);
    const registros = useAppSelector(state => state.entrenamiento.registros_usuario)

    const [loading, setLoading] = useState(true);

    const CargarSiEstaAldia = async (entrenamientosIds:string[]) => {
        let ids: string[] = [];
        (await coleccionEntrenamientos(userId)).forEach(doc => ids.push(doc.id));
        if(!(JSON.stringify(ids) === JSON.stringify(entrenamientosIds))){
            dispatch(ChangeRedux_entrenamientos_user({entrenamientos:ids}));
        }
        return asignados??ids;
    }

    const cargarAsignados = async () => {
        let ids: string[] = []
        if(!asignados){
            (await coleccionEntrenamientos(userId)).forEach(doc => ids.push(doc.id));
            dispatch(ChangeRedux_entrenamientos_user({entrenamientos:ids}));
        }
        return asignados??ids;
    }
    const cargaEntrenamientos = async () => {
        if(!entrenamientos){
            const datos = await consultarEntrenamientosApp();
            dispatch(ChangeRedux_entrenamientos_app({entrenamientos: datos}));
        }
    }
    const cargarRegistros = async (idsAsignados: string[]) => {
        if(!registros){
            const datos = await consultarRegistros(userId, idsAsignados);
            dispatch(ChangeRedux_registros_usuario({registros: datos}));
        }
    }
    const consulta = async () => {
        setLoading(true);
        const promesaIds = await cargarAsignados();
        CargarSiEstaAldia(promesaIds)
        const promesaEntrenamientos = cargaEntrenamientos();
        const ids = await promesaIds;
        await Promise.all([cargarRegistros(ids), promesaEntrenamientos]);
        setLoading(false);
    }

    useEffect(() => {
        dispatch(ChangeRedux_cargando(loading))
    }, [loading]);

    useLayoutEffect(() => {
        consulta();
    }, []);

    return(
        <Routes>
            
            <Route path="/" element={<EntrenamientoPage cargando={loading}/>}/>
            {/* juegos en los entrenamientos */}
            <Route path="codificacion/:nivel?" element={<JuegoCodificacion />}/>								
            <Route path="JuegoCorrespondencia/:limit" element={<JuegoValoresAsociados />}/>								
			<Route path="CodigoPalabras/:limit" element={<JuegoCodigodePalabras />}/>																				
			<Route path="LecturaVelocidad" element={<VelocidadLectura />}/>									
			<Route path="LecturaComprension" element={<Cuestionario />}/>								
			<Route path="cuestionario/:id" element={<JuegoCuestionario />}/>	

            {/* path de entrenamiento*/}
            <Route path=":idEntrenamiento" element={<LayoutEntrenamiento/>}>
                <Route path="promocion/:titleEntrenamiento" element={<LandingCurso />}/>									
                <Route path="firstTime/:titleEntrenamiento" element={<FirstTimeEntrenamiento />}/>							
                <Route path=":id/:index" element={<RealizarActividadPage />}/>	
                <Route path=":id" element={<ActividadesPage />}/>	
                <Route path="" element={<SesionesPage />}/>
            </Route>
            <Route path="consultaIngles"element={<ConsultaIngles />}/>		
            <Route path="response" element={<ResponseePayco/>}/>

									
        </Routes>
    );
}