import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTests } from "../../../hooks/controlador/useTests";
import { useToast } from "../../../hooks/useToast";
// import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
// import { closeCircle } from "ionicons/icons";
import { Test, aParticipanteTest } from "../../../modelos/Test";
import { useControl } from "../../../hooks/controlador/useControl";

interface Props {
    openVentanaUsuario:boolean;
    setOpenVentanaUsuario: Dispatch<SetStateAction<boolean>>;
    test: Test;

}

const ModalCrearParticipantesTest: React.FC<Props> = (props) => {
    const [nuevosCheck, setNuevosCheck] = useState([]);
    const [nuevosUsuarios, setNuevosUsuarios] = useState("");

    const { 
        consultarTests, 
        consultarProgramasTest, 
        consultarProgramasParaTest,
        crearProgramaTest,
        consultarParticipantesTest,
        crearUsuarioTest,
        crearParticipanteTest,
     } = useTests();

    const {
        consultarUsuario,
    } = useControl();
    
    const {
        presentNoIngresoUsuario
    } = useToast();

    const checking = () => {
        const nuevos = nuevosUsuarios.split("\n").filter(elemento => elemento.trim() !== '');
        nuevos.map(async (element,index) => {
            const respuesta = await consultarUsuario(element);
            if(respuesta !== null){
                await crearParticipanteTest(props.test.id,respuesta.id,aParticipanteTest(element));
                await crearUsuarioTest(respuesta.id,props.test.id,props.test);
                const copy = nuevosCheck;
                setNuevosCheck((prevState) => [
                    ...prevState,
                    { correo: element, error: false },
                ])
            }else{
                const copy = nuevosCheck;
                setNuevosCheck((prevState) => [
                    ...prevState,
                    { correo: element, error: true },
                ])

            }
        })
        
    };
    
    const capturar = async () => {
        if (nuevosUsuarios === "") {
            presentNoIngresoUsuario();
        } else {
            checking();
        }
    }
    
    useEffect(()=>{
        setNuevosCheck([])
        setNuevosUsuarios("")
    },[])

    return (
        <></>
        // <IonModal isOpen={props.openVentanaUsuario}>
        //     <IonHeader>
        //     <IonToolbar>
        //         <IonButtons slot="end">
        //         <IonButton
        //             onClick={() => {
        //             props.setOpenVentanaUsuario(false);
        //             }}
        //         >
        //             <IonIcon icon={closeCircle} />
        //         </IonButton>
        //         </IonButtons>
        //         <h1 className="ion-text-center title">Crear Usuarios</h1>
        //     </IonToolbar>
        //     </IonHeader>
        //     <IonContent className="ion-padding">
        //     <IonList>
        //         <IonItem>
        //         <IonLabel position="floating">Usuarios</IonLabel>
        //         <IonTextarea
        //             autoGrow={true}
        //             placeholder="example1@gmail.com &#10; example2@hotmail.com &#10; example2@hotmail.com"
        //             value={nuevosUsuarios}
        //             onIonChange={(event) => setNuevosUsuarios(event.detail.value)}
        //         />
        //         </IonItem>
        //         <IonButton expand="block" onClick={capturar}>
        //         Agregar Usuarios
        //         </IonButton>
        //     </IonList>
        //     <IonList>
        //         {nuevosCheck.map((element, index) => (
        //             <IonItem key={index}>
        //                 <IonLabel>{element.error ? "No Encontrado": "Ok"}</IonLabel>
        //                 <IonLabel>{element.correo}</IonLabel>
        //             </IonItem>
        //         ))}
        //         {nuevosCheck.length !== 0 && 
        //         <IonButton expand="block" onClick={() => {
        //             props.setOpenVentanaUsuario(false);
        //             setNuevosCheck([]);
        //             setNuevosUsuarios("");
        //         }}>
        //         ok
        //         </IonButton>}
        //     </IonList>
        //     <ul>
        //         {statusCreation.map((error) => (
        //         <li>
        //             <IonLabel>{error?.mensaje}</IonLabel>
        //         </li>
        //         ))}
        //     </ul>
        //     </IonContent>
        // </IonModal>
    );
}

export default ModalCrearParticipantesTest