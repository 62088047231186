
import { useEffect, useState } from "react";
import  "../theme/main.css"; 

  const Loading: React.FC = () => {
    
    return (
        <div className="loading centrar">
          <img className="logo" src={"assets/images/Logo-blanco.png"} alt="logo blanco"></img>
        </div>
    );
  }
export default Loading;