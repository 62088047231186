import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";

export interface Factura {
    fecha_creacion?: Timestamp;
    id_entrenamiento?: string;
    id_usuario?: string;
    nombre_usuario?: string;
    correo_usuario?:string;
    codigo_telefono?:string;
    telefono_usuario?:string;
    recordatorios?: number;
    costo_pagar?:number;
    valor_pagado: number;
    tipo_moneda: string;
    fecha_transaction:Timestamp;
    ref_epayco: number;
    estado_transaction: string;
    motivo_transaction: string;
    medioPago: string;
    codigo_campaña: string;
    prueba_gratuita?: number;
    id_productos?: ProductoReferencia[];
    url_compra?: string;
    direccion_usuario?: string;
    documento_identidad?: number;
}

export enum TiposProductos{
    tipoEntrenamiento = "entrenamiento",
    tipoLibroDigital = "e-book",
    tipoLibroFisico = "libro",
    tipoTaller = "taller",
    tipoAsignacion = "asignacion"
}

export interface ProductoReferencia {
    id_referencia:string;
    tipo:TiposProductos;
}

export const aFactura = (
    id_productos : ProductoReferencia[], id_usuario : string, estado_transaction : string, costo_pagar:number, medioPago:string, codigo_campaña : string,
    opciones?: {
        nombre_usuario?:string, telefono_usuario?:string, codigo_telefono?:string, correo_usuario?: string, valor_pagado? : number, tipo_moneda? : string,
        fecha_transaction? : Timestamp, ref_epayco? : number, motivo_transaction? : string, pruebaGratuita?: number, url_compra?: string, direccion_usuario?:string,
        documento_identidad?:number
    }
) : Factura => {
    const myFactura: Factura = {
        fecha_creacion : Timestamp.now()
    } as Factura;
    myFactura.id_productos = id_productos == null || id_productos == undefined ? null : id_productos;
    myFactura.id_usuario = id_usuario == null || id_usuario == undefined ? null : id_usuario;
    myFactura.estado_transaction = estado_transaction == null || estado_transaction == undefined ? null : estado_transaction;
    myFactura.medioPago = medioPago == null || medioPago == undefined ? null : medioPago;
    myFactura.costo_pagar = costo_pagar == null || costo_pagar == undefined ? null : costo_pagar;
    myFactura.codigo_campaña = codigo_campaña == null || codigo_campaña == undefined ? null : codigo_campaña;
    if(opciones){
        myFactura.nombre_usuario = opciones.nombre_usuario ?? null;
        myFactura.correo_usuario = opciones.correo_usuario == null || opciones.correo_usuario == undefined ? null : opciones.correo_usuario;
        myFactura.codigo_telefono = opciones.codigo_telefono == null || opciones.codigo_telefono == undefined ? null : opciones.codigo_telefono;
        myFactura.telefono_usuario = opciones.telefono_usuario == null || opciones.telefono_usuario == undefined ? null : opciones.telefono_usuario;
        myFactura.valor_pagado = opciones.valor_pagado == null || opciones.valor_pagado == undefined ? null : opciones.valor_pagado;
        myFactura.tipo_moneda = opciones.tipo_moneda == null || opciones.tipo_moneda == undefined ? null : opciones.tipo_moneda;
        myFactura.fecha_transaction = opciones.fecha_transaction == null || opciones.fecha_transaction == undefined ? null : opciones.fecha_transaction;
        myFactura.ref_epayco = opciones.ref_epayco == null || opciones.ref_epayco == undefined ? null : opciones.ref_epayco;
        myFactura.motivo_transaction = opciones.motivo_transaction == null || opciones.motivo_transaction == undefined ? null : opciones.motivo_transaction;
        myFactura.prueba_gratuita = opciones.pruebaGratuita?? null;
        myFactura.url_compra = opciones.url_compra?? null;
        myFactura.direccion_usuario = opciones.direccion_usuario?? null;
        myFactura.documento_identidad = opciones.documento_identidad?? null;
    }
    return myFactura;        
}

type FireBaseDocument = DocumentSnapshot<DocumentData>
export const toFactura = (doc : FireBaseDocument) : Factura => {    
    if(doc.exists()){        
        const facture= doc.data() as Factura;
        return facture
    }
    return undefined;
    
}