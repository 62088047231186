import { forwardRef } from 'react';
import style from '../../../theme/web/FooterWebPage.module.css';
import IconOutlineFacebook from './IconOutlineFacebook';
import IconOutlineInstagram from './IconOutlineInstagram';
import IconOutlineLinkedin from './IconOutlineLinkedin';
import IconOutlineX from './IconOutlineX';
import IconOutlineYoutube from './IconOutlineYoutube';

const FooterWebPage = forwardRef<HTMLDivElement>(({}, ref) => {
    return(
        <div className={`${style.fondoMorado} ${style.footPage}`}>
            <div ref={ref} className={style.redes}>
                <IconOutlineX color='#FFFFFF' height='5svh' ruta='https://twitter.com/Aprende_rapido' />
                <IconOutlineLinkedin color='#FFFFFF' height='5svh' ruta='https://co.linkedin.com/company/supercerebros-colombia' />
                <IconOutlineFacebook color='#FFFFFF' height='5svh' ruta='https://www.facebook.com/SuperCerebrosColombia/?locale=es_LA' />
                <IconOutlineYoutube color='#FFFFFF' height='5svh' ruta='https://www.youtube.com/@SuperCerebros' />
                <IconOutlineInstagram color='#FFFFFF' height='5svh' ruta='https://www.instagram.com/supercerebroscolombia/' />
            </div>
            <div className={style.linea}></div>
            <p className={style.info}>
            SuperCerebros SAS <br></br>
            Calle 19 #6-68 Oficina 603, Bogotá <br></br>
            310 311 3156 <br></br>
            </p>
        </div>
    );
});

export default FooterWebPage;
