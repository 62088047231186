/** Importaciones de Ionic */

// import {
//     useIonToast,
// } from '@ionic/react';

export const useToast = () => {

    /** Declaraciones */

    // const [present] = useIonToast();

    /** Metodos */

    const presentMensajePersonalizado = (mensaje:string,time?:number) => {
        presentMensaje(mensaje,time);
    }

    const presentMensajePersonalizadoSquareCenter = (mensaje:string,time?:number) => {
        presentMensaje(mensaje,time,'show_center');
    }

    const presentDatosIncompletos = () => {
        presentMensaje('por favor entra datos en cada seccion');
    }

    const presentDatosCompletos = () => {
        presentMensaje('datos guardados');
    }
    const presentNombresValidos = () => {
        presentMensaje('Nombre invalido, por favor ingresa uno valido');
    }
    const presentAliasValidos = () => {
        presentMensaje('Alias invalido, por favor ingresa uno valido');
    }
    const presentTelefonoValidos = () => {
        presentMensaje('Telefono invalido, por favor ingresa uno valido');
    }
    const presentPoliticasPrivacidadValidos = () => {
        presentMensaje('politica de privacidad debe ser seleccionado, por favor seleccionelo');
    }

    const presentSignOut = () => {
        presentMensaje('reautenticar para borrar usuario, logging out...');
    }

    const presentCorreoMandado = () => {
        presentMensaje('correo mandado')
    }

    const presentNoUsuario = () => {
        presentMensaje('no hay un usuario con ese correo')
    }

    const presentBorrarUsuario = () => {
        presentMensaje('usuario borrado')
    }

    const presentEntrarCorreoRecuperar = () => {
        presentMensaje('por favor, entre un correo electronico')
    }

    const presentCorreoInvalido = () => {
        presentMensaje('por favor, entre un correo electronico valido')
    }
    const presentPasswordInvalido = () => {
        presentMensaje('por favor, entre una contraseña valida (Minimo 6 caracteres validos)')
    }

    const presentNoIngresoUsuario = () => {
        presentMensaje('No ingreso usuarios a registrar')
    }

    const photoMuyGrande = () => {
        presentMensaje('La imagen es muy grande (maximo 2MB)')
    }

    const photoFormatoIncorrecto = () => {
        presentMensaje('El formato de la imagen no se reconoce.')
    }

    const noHayTestInicial = () => {
        presentMensaje('No ha desbloqueado esta función, realice el test incial.')
    }

    const presentMensaje = (mensaje: string,time?:number,type?:string) => {
        const t = document.getElementById("toast");
        console.log("🚀 ~ presentMensaje ~ t:", t)
        if (t) {
            t.textContent = mensaje;
            t.className = t.className.replace("", type ?? "show");
            setTimeout(() => {
                t.className = t.className.replace(type ?? "show", "");
            }, time ? time *1000 : 3000);
        }
    };

    return {
        presentMensajePersonalizado,      
        presentMensajePersonalizadoSquareCenter,  
        presentBorrarUsuario,
        presentSignOut,
        photoMuyGrande,
        photoFormatoIncorrecto,
        presentDatosCompletos,
        presentDatosIncompletos,
        presentCorreoMandado,
        presentCorreoInvalido,
        presentNoUsuario,
        presentPasswordInvalido,
        presentEntrarCorreoRecuperar,
        presentTelefonoValidos,
        presentNombresValidos,
        presentNoIngresoUsuario,
        noHayTestInicial,
        presentAliasValidos,
        presentPoliticasPrivacidadValidos
    }
}
