export const paises = [
  { name: 'Afganistán'                     , value: '+93'              },
  { name: 'Albania'                        , value: '+355'             },
  { name: 'Alemania'                       , value: '+49'              },
  { name: 'Andorra'                        , value: '+376'             },
  { name: 'Angola'                         , value: '+244'             },
  { name: 'Anguila'                        , value: '+1 264'           },
  { name: 'Antigua y Barbuda'              , value: '+1 268'           },
  { name: 'Arabia Saudita'                 , value: '+966'             },
  { name: 'Argelia'                        , value: '+213'             },
  { name: 'Argentina'                      , value: '+54'              },
  { name: 'Armenia'                        , value: '+374'             },
  { name: 'Aruba'                          , value: '+297'             },
  { name: 'Australia'                      , value: '+61'              },
  { name: 'Austria'                        , value: '+43'              },
  { name: 'Azerbaiyán'                     , value: '+994'             },
  { name: 'Bahamas'                        , value: '+1 242'           },
  { name: 'Bahréin'                        , value: '+973'             },
  { name: 'Bangladesh'                     , value: '+880'             },
  { name: 'Barbados'                       , value: '+1 246'           },
  { name: 'Bélgica'                        , value: '+32'              },
  { name: 'Belice'                         , value: '+501'             },
  { name: 'Benín'                          , value: '+229'             },
  { name: 'Bermudas'                       , value: '+1 441'           },
  { name: 'Bielorrusia'                    , value: '+375'             },
  { name: 'Bolivia'                        , value: '+591'             },
  { name: 'Bosnia y Herzegovina'           , value: '+387'             },
  { name: 'Botsuana'                       , value: '+267'             },
  { name: 'Brasil'                         , value: '+55'              },
  { name: 'Brunéi'                         , value: '+673'             },
  { name: 'Bulgaria'                       , value: '+359'             },
  { name: 'Burkina Faso'                   , value: '+226'             },
  { name: 'Burundi'                        , value: '+257'             },
  { name: 'Bután'                          , value: '+975'             },
  { name: 'Cabo Verde'                     , value: '+238'             },
  { name: 'Camboya'                        , value: '+855'             },
  { name: 'Camerún'                        , value: '+237'             },
  { name: 'Canadá'                         , value: '+1'               },
  { name: 'Chad'                           , value: '+235'             },
  { name: 'Chile'                          , value: '+56'              },
  { name: 'China'                          , value: '+86'              },
  { name: 'Chipre'                         , value: '+357'             },
  { name: 'Colombia'                       , value: '+57'              },
  { name: 'Comoras'                        , value: '+269'             },
  { name: 'Congo'                          , value: '+242'             },
  { name: 'Corea del Norte'                , value: '+850'             },
  { name: 'Corea del Sur'                  , value: '+82'              },
  { name: 'Costa de Marfil'                , value: '+225'             },
  { name: 'Costa Rica'                     , value: '+506'             },
  { name: 'Croacia'                        , value: '+385'             },
  { name: 'Cuba'                           , value: '+53'              },
  { name: 'Curazao'                        , value: '+599'             },
  { name: 'Dinamarca'                      , value: '+45'              },
  { name: 'Dominica'                       , value: '+1 767'           },
  { name: 'Ecuador'                        , value: '+593'             },
  { name: 'Egipto'                         , value: '+20'              },
  { name: 'El Salvador'                    , value: '+503'             },
  { name: 'Emiratos Árabes Unidos'         , value: '+971'             },
  { name: 'Eritrea'                        , value: '+291'             },
  { name: 'Eslovaquia'                     , value: '+421'             },
  { name: 'Eslovenia'                      , value: '+386'             },
  { name: 'España'                         , value: '+34'              },
  { name: 'Estados Unidos'                 , value: '+1'               },
  { name: 'Estonia'                        , value: '+372'             },
  { name: 'Etiopía'                        , value: '+251'             },
  { name: 'Filipinas'                      , value: '+63'              },
  { name: 'Finlandia'                      , value: '+358'             },
  { name: 'Fiyi'                           , value: '+679'             },
  { name: 'Francia'                        , value: '+33'              },
  { name: 'Gabón'                          , value: '+241'             },
  { name: 'Gambia'                         , value: '+220'             },
  { name: 'Georgia'                        , value: '+995'             },
  { name: 'Ghana'                          , value: '+233'             },
  { name: 'Gibraltar'                      , value: '+350'             },
  { name: 'Granada'                        , value: '+1 473'           },
  { name: 'Grecia'                         , value: '+30'              },
  { name: 'Groenlandia'                    , value: '+299'             },
  { name: 'Guadalupe'                      , value: '+590'             },
  { name: 'Guam'                           , value: '+1 671'           },
  { name: 'Guatemala'                      , value: '+502'             },
  { name: 'Guayana Francesa'               , value: '+594'             },
  { name: 'Guernsey'                       , value: '+44 1481'         },
  { name: 'Guinea'                         , value: '+224'             },
  { name: 'Guinea Ecuatorial'              , value: '+240'             },
  { name: 'Guinea Bisáu'                   , value: '+245'             },
  { name: 'Guyana'                         , value: '+592'             },
  { name: 'Haití'                          , value: '+509'             },
  { name: 'Honduras'                       , value: '+504'             },
  { name: 'Hong Kong'                      , value: '+852'             },
  { name: 'Hungría'                        , value: '+36'              },
  { name: 'India'                          , value: '+91'              },
  { name: 'Indonesia'                      , value: '+62'              },
  { name: 'Irak'                           , value: '+964'             },
  { name: 'Irán'                           , value: '+98'              },
  { name: 'Irlanda'                        , value: '+353'             },
  { name: 'Isla de Man'                    , value: '+44 1624'         },
  { name: 'Isla de Navidad'                , value: '+61'              },
  { name: 'Isla Norfolk'                   , value: '+672'             },
  { name: 'Islandia'                       , value: '+354'             },
  { name: 'Israel'                         , value: '+972'             },
  { name: 'Italia'                         , value: '+39'              },
  { name: 'Jamaica'                        , value: '+1 876'           },
  { name: 'Japón'                          , value: '+81'              },
  { name: 'Jersey'                         , value: '+44'              },
  { name: 'Jordania'                       , value: '+962'             },
  { name: 'Kazajistán'                     , value: '+7'               },
  { name: 'Kenia'                          , value: '+254'             },
  { name: 'Kirguistán'                     , value: '+996'             },
  { name: 'Kiribati'                       , value: '+686'             },
  { name: 'Kuwait'                         , value: '+965'             },
  { name: 'Laos'                           , value: '+856'             },
  { name: 'Lesoto'                         , value: '+266'             },
  { name: 'Letonia'                        , value: '+371'             },
  { name: 'Líbano'                         , value: '+961'             },
  { name: 'Liberia'                        , value: '+231'             },
  { name: 'Libia'                          , value: '+218'             },
  { name: 'Liechtenstein'                  , value: '+423'             },
  { name: 'Lituania'                       , value: '+370'             },
  { name: 'Luxemburgo'                     , value: '+352'             },
  { name: 'Macao'                          , value: '+853'             },
  { name: 'Macedonia del Norte'            , value: '+389'             },
  { name: 'Madagascar'                     , value: '+261'             },
  { name: 'Malasia'                        , value: '+60'              },
  { name: 'Malaui'                         , value: '+265'             },
  { name: 'Maldivas'                       , value: '+960'             },
  { name: 'Mali'                           , value: '+223'             },
  { name: 'Malta'                          , value: '+356'             },
  { name: 'Marruecos'                      , value: '+212'             },
  { name: 'Martinica'                      , value: '+596'             },
  { name: 'Mauricio'                       , value: '+230'             },
  { name: 'Mauritania'                     , value: '+222'             },
  { name: 'Mayotte'                        , value: '+262'             },
  { name: 'México'                         , value: '+52'              },
  { name: 'Micronesia'                     , value: '+691'             },
  { name: 'Moldavia'                       , value: '+373'             },
  { name: 'Mónaco'                         , value: '+377'             },
  { name: 'Mongolia'                       , value: '+976'             },
  { name: 'Montenegro'                     , value: '+382'             },
  { name: 'Montserrat'                     , value: '+1 664'           },
  { name: 'Mozambique'                     , value: '+258'             },
  { name: 'Myanmar (Birmania)'             , value: '+95'              },
  { name: 'Namibia'                        , value: '+264'             },
  { name: 'Nauru'                          , value: '+674'             },
  { name: 'Nepal'                          , value: '+977'             },
  { name: 'Nicaragua'                      , value: '+505'             },
  { name: 'Níger'                          , value: '+227'             },
  { name: 'Nigeria'                        , value: '+234'             },
  { name: 'Niue'                           , value: '+683'             },
  { name: 'Noruega'                        , value: '+47'              },
  { name: 'Nueva Caledonia'                , value: '+687'             },
  { name: 'Nueva Zelanda'                  , value: '+64'              },
  { name: 'Omán'                           , value: '+968'             },
  { name: 'Países Bajos'                   , value: '+31'              },
  { name: 'Pakistán'                       , value: '+92'              },
  { name: 'Palaos'                         , value: '+680'             },
  { name: 'Palestina'                      , value: '+970'             },
  { name: 'Panamá'                         , value: '+507'             },
  { name: 'Papúa Nueva Guinea'             , value: '+675'             },
  { name: 'Paraguay'                       , value: '+595'             },
  { name: 'Perú'                           , value: '+51'              },
  { name: 'Polinesia Francesa'             , value: '+689'             },
  { name: 'Polonia'                        , value: '+48'              },
  { name: 'Portugal'                       , value: '+351'             },
  { name: 'Puerto Rico'                    , value: '+1 787 or +1 939' },
  { name: 'Qatar'                          , value: '+974'             },
  { name: 'Reino Unido'                    , value: '+44'              },
  { name: 'República Centroafricana'       , value: '+236'             },
  { name: 'República Checa'                , value: '+420'             },
  { name: 'República Democrática del Congo', value: '+243'             },
  { name: 'República Dominicana'           , value: '+1 809 or +1 829' },
  { name: 'Reunión'                        , value: '+262'             },
  { name: 'Ruanda'                         , value: '+250'             },
  { name: 'Rumanía'                        , value: '+40'              },
  { name: 'Rusia'                          , value: '+7'               },
  { name: 'Sáhara Occidental'              , value: '+212'             },
  { name: 'Samoa'                          , value: '+685'             },
  { name: 'San Bartolomé'                  , value: '+590'             },
  { name: 'San Cristóbal y Nieves'         , value: '+1 869'           },
  { name: 'San Marino'                     , value: '+378'             },
  { name: 'San Vicente y las Granadinas'   , value: '+1 784'           },
  { name: 'Santa Elena'                    , value: '+290'             },
  { name: 'Santa Lucía'                    , value: '+1 758'           },
  { name: 'Santo Tomé y Príncipe'          , value: '+239'             },
  { name: 'Senegal'                        , value: '+221'             },
  { name: 'Serbia'                         , value: '+381'             },
  { name: 'Seychelles'                     , value: '+248'             },
  { name: 'Sierra Leona'                   , value: '+232'             },
  { name: 'Singapur'                       , value: '+65'              },
  { name: 'Siria'                          , value: '+963'             },
  { name: 'Somalia'                        , value: '+252'             },
  { name: 'Sri Lanka'                      , value: '+94'              },
  { name: 'Sudáfrica'                      , value: '+27'              },
  { name: 'Sudán'                          , value: '+249'             },
  { name: 'Sudán del Sur'                  , value: '+211'             },
  { name: 'Surinam'                        , value: '+597'             },
  { name: 'Svalbard y Jan Mayen'           , value: '+47'              },
  { name: 'Suecia'                         , value: '+46'              },
  { name: 'Suiza'                          , value: '+41'              },
  { name: 'Tailandia'                      , value: '+66'              },
  { name: 'Taiwán'                         , value: '+886'             },
  { name: 'Tanzania'                       , value: '+255'             },
  { name: 'Tayikistán'                     , value: '+992'             },
  { name: 'Timor Oriental'                 , value: '+670'             },
  { name: 'Togo'                           , value: '+228'             },
  { name: 'Tokelau'                        , value: '+690'             },
  { name: 'Tonga'                          , value: '+676'             },
  { name: 'Trinidad y Tobago'              , value: '+1 868'           },
  { name: 'Túnez'                          , value: '+216'             },
  { name: 'Turkmenistán'                   , value: '+993'             },
  { name: 'Turquía'                        , value: '+90'              },
  { name: 'Tuvalu'                         , value: '+688'             },
  { name: 'Ucrania'                        , value: '+380'             },
  { name: 'Uganda'                         , value: '+256'             },
  { name: 'Uruguay'                        , value: '+598'             },
  { name: 'Uzbekistán'                     , value: '+998'             },
  { name: 'Vanuatu'                        , value: '+678'             },
  { name: 'Venezuela'                      , value: '+58'              },
  { name: 'Vietnam'                        , value: '+84'              },
  { name: 'Wallis y Futuna'                , value: '+681'             },
  { name: 'Yemen'                          , value: '+967'             },
  { name: 'Yibuti'                         , value: '+253'             },
  { name: 'Zambia'                         , value: '+260'             },
  { name: 'Zimbabue'                       , value: '+263'             },
]                                            

export const meses = {
  '01': 'Enero',
  '02': 'Febrero',
  '03': 'Marzo',
  '04': 'Abril',
  '05': 'Mayo',
  '06': 'Junio',
  '07': 'Julio',
  '08': 'Agosto',
  '09': 'Septiembre',
  '10': 'Octubre',
  '11': 'Noviembre',
  '12': 'Diciembre'
};