import React from 'react'
import estiloJuego2  from '../../../../theme/Juego2.module.css'

interface Props{
    resetContenido?: () => void;
    rightSubmit: () => void;
    leftSubmit: () => void;
    contenidoIdIndice: number;
    cantidadDeValores: number;
  }

const BotonesNavegacion:React.FC<Props> = (props) => {
  const HandleReset = () => {
      props.contenidoIdIndice > 0 ? props.resetContenido(): console.log("Accion invalida")
    }

  const HandleClickLeft = () => {
      props.contenidoIdIndice > 0 ? props.leftSubmit(): console.log("Accion invalida")
    }
  
  const HandleClickRight = () => {
      props.contenidoIdIndice < props.cantidadDeValores - 1 ? props.rightSubmit(): console.log("Accion invalida")
  }

  return (
    <div className={estiloJuego2.div_nv_button}>
        <button className={`${estiloJuego2.nv_button} ${estiloJuego2.nv_button_small} ${props.contenidoIdIndice === 0 && estiloJuego2.nv_button_desactive}`} onClick={HandleReset}>
          <img src='/assets/icon/flechaBlancoSend.svg' alt='Back' style={{transform:'rotate(180deg)',width:'8px',height:'8px'}}></img>
          <img src='/assets/icon/flechaBlancoSend.svg' alt='Back' style={{transform:'rotate(180deg)',width:'8px',height:'8px'}}></img>
        </button>
        <button className={`${estiloJuego2.nv_button} ${props.contenidoIdIndice === 0 && estiloJuego2.nv_button_desactive}`} onClick={HandleClickLeft}>
          <img src='/assets/icon/flechaBlancoSend.svg' alt='Back' style={{transform:'rotate(180deg)'}}></img>
        </button>
        <button className={`${estiloJuego2.nv_button} ${props.contenidoIdIndice >= props.cantidadDeValores - 1 && estiloJuego2.nv_button_desactive}`} onClick={HandleClickRight}>
          <img src='/assets/icon/flechaBlancoSend.svg' alt='Go'></img>
        </button>
    </div>
  )
}

export default BotonesNavegacion