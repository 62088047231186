// import { IonIcon } from "@ionic/react";
// import { closeCircle } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useTests } from "../../hooks/controlador/useTests";
import { Test, aTest } from "../../modelos/Test";
import { useToast } from "../../hooks/useToast";
import { useNavigate } from 'react-router-dom';

// css
import style from '../../theme/admin/tests.module.css';

const AdminTestsPage:React.FC = () => {

    const navigate = useNavigate();

    const [openVentana,setOpenVentana] = useState(false);
    const [obligatorio,setObligatorio] = useState(false);
    const [torneo,setTorneo] = useState(false);
    const [nombreTest,setNombreTest] = useState('');
    const [descripcion,setDescripcion] = useState('');
	const today = new Date();
    const [minimo,setMinimo] = useState(today.toISOString());
    const [fechaInicio,setFechaInicio] = useState('');
    const [fechaFin,setFechaFin] = useState('');
    const { 
        consultarTests,
        crearTest,
    } = useTests();
    const { presentDatosIncompletos } = useToast();
    const [arrayTests, setArrayTests] = useState<Test[]>([]);

    const consulta = async () => {
        const resultados = await consultarTests("");       
        setArrayTests(resultados);
    }
    
    useEffect(() =>{
        consulta()
    },[openVentana])

    const handleDateInicio = (date) => {
        console.log(date);
        setFechaInicio(date);
        setMinimo(date);

    }

    const handleDateFin = (date) => {
        setFechaFin(date);
    }

    const createTest = async () => {
        if(fechaInicio !== fechaFin && fechaInicio !== '' && fechaFin !== ''){
            if(nombreTest!=="" && descripcion!=""){                               
                const data = aTest(nombreTest,descripcion,fechaInicio,fechaFin, torneo,obligatorio);                
                console.log("🚀 ~ file: AdminTestsPage.tsx:52 ~ createTest ~ data:", data)
                await crearTest(data);                
                setTorneo(false)
                setObligatorio(false)
                setNombreTest("")
                setDescripcion("")
                setMinimo(today.toISOString())
                setOpenVentana(false)
            }else{
                presentDatosIncompletos()
            }
        }else{
            presentDatosIncompletos()
        }
    }

    const hadleRedirect = ( ruta:string) => {
        navigate(ruta)
    }

    return(
        <div id="page">
            <div id="toolbar_default">
                <h1 className="title_default">Tests</h1>
            </div> 
            <div id='content_default'>
                {arrayTests.map((element,index) => 
                    <div className={style.card_test} key={index} onClick={() => hadleRedirect(`/my/AdminTests/${element.id}/${element.nombre}`)}>
                        {element.nombre}
                    </div>
                )}
                {arrayTests.length === 0 ? <h1 className={style.no_hay_test}>No hay Tests</h1>: <br/>}
            
                <button className={style.button_crear_test} onClick={()=>{setOpenVentana(true)}}>Crear Test</button>
            </div>

            {openVentana && <div className={`${style.modal_overlay} ${openVentana ? 'scroll-up': ''}`}>
                <div  id="toolbar_default">
                    <div onClick={() => setOpenVentana(false)}>
                        {/* <IonIcon icon={closeCircle} /> */}
                        
                    </div>
                    <h1 className="title_default">Crear Test</h1>
                </div>
                <div id='content_default' className={style.content_modal}>
                    <div>
                        <p >Nombre</p>
                        <input 
                        value={nombreTest} 
                        onChange={(event) => {setNombreTest(event.target.value)}}/>
                    </div>
                    
                    <div>
                        
                        <label>Descripción <input type="text" id="Descripcion"
                            placeholder="Ingrese una descripción del área a crear"
                            value={descripcion}
                            onChange={(event) => { setDescripcion(event.target.value) }}/></label>
                    </div>

                    <div>
                        
                        <label>
                            Fecha de Inicio:
                            <input type="datetime-local" value={fechaInicio} onChange={(event) => handleDateInicio(event.target.value)} required />
                        </label>
                    </div>
                    <label>
                        Fecha de Fin:
                        <input type="datetime-local" value={fechaFin} onChange={(event) => handleDateFin(event.target.value)} required />
                    </label><br />
                    <label>
                        <input type="checkbox" id="torneo" onChange={(event) => {
                            setTorneo(event.target.checked)}} /> Torneo
                    </label>
                    <br />
                    <label>
                        <input type="checkbox" id="obligatorio" onChange={(event) => {
                            setObligatorio(event.target.checked)
                            }} /> Obligatorio
                    </label>
                    <br />
                    
                    
                    <button className={style.button_crear_test}  onClick={createTest}>Crear</button>
                        
                </div>
            </div>}
        </div>
    );
}

export default AdminTestsPage;