import { nomProgMemNombres, nomProgMemNumeros, nomProgMemPalabras, nomProgSumas1, nomProgSuperCerebrosDice,
    nomTestMemNombresInicial, nomTestMemNumerosInicial, nomTestMemPalabrasInicial, nomTestSumas1, nomProgParejas } from "../datos/nombresBD";
import { calificacionNombres } from "../datos/DatosMemNombres";
import { calificacionNumeros } from "../datos/DatosMemNumeros";
import { calificacionPalabras } from "../datos/DatosMemPalabras";
import { cambioDeNivel } from "../datos/DatosSumas1";

export const obtenerNeuronas = (nomJuego: string, nivel:number, resultado:number) => {

    let neuronas:number = 0;  
    const cambioNivelSumas:number = 10;
    const cambioNivelMemoria:number = 20;
    const juegoSumas:number = 1;
    const juegoMemoria:number = 2;

    let valRespCorrectas:number = 0;

    switch (nomJuego) {
        case nomProgSuperCerebrosDice: 
            neuronas = neuronas + (10 * resultado);
            break;
        case nomProgSumas1:            
            neuronas = neuronas + nivel - resultado;
            if(neuronas < 1) neuronas = 1; 
            break;

        case nomProgMemNombres:            
            valRespCorrectas = calificacionNombres(nivel, resultado);
            if(valRespCorrectas >= 6){
                neuronas = neuronas + (nivel * cambioNivelMemoria);
            }
            neuronas = neuronas + (valRespCorrectas * juegoMemoria);
            break;

        case nomProgMemPalabras:            
            valRespCorrectas = calificacionPalabras(nivel, resultado);
            if(valRespCorrectas >= 6){
                neuronas = neuronas + (nivel * cambioNivelMemoria); 
            }
            neuronas = neuronas + (valRespCorrectas * juegoMemoria);
            break;

        case nomProgMemNumeros:            
            valRespCorrectas = calificacionNumeros(nivel, resultado);
            if(valRespCorrectas >= 6){
                neuronas = neuronas + (nivel * cambioNivelMemoria);
            }
            neuronas = neuronas + (valRespCorrectas * juegoMemoria);
            break;    
        case nomTestMemNombresInicial:
            neuronas += 100;
            break;    
        case nomTestMemPalabrasInicial:
            neuronas += 100;
            break;  
        case nomTestMemNumerosInicial:
            neuronas += 100;
            break;
        case nomTestSumas1:
            neuronas += 100;
            break;  
        default:
            // Código para el caso en que nomJuego no coincida con ningún caso válido
            break;
    }

    return neuronas;
}


export function obtenerSemanaAno(): string {
    var d = new Date();
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
    // Return array of year and week number
    
    return String(d.getUTCFullYear()) + "_" + String(weekNo.toString());
}