import TestMemoria from "./TestMemoriaInicial";
import {palabras}  from '../../../datos/DatosMemPalabras';
import { ArrayDeNumeros } from '../../../utils/ArrayDeNumeros' ;
import { nomTestMemPalabrasInicial } from "../../../datos/nombresBD";
import estiloJuegos from "../../../theme/Juegos.module.css";

const TestMemoriaPalabras = () =>{
    const titulo = "Memorización de palabras"
    const palabraIdsTemporal = ArrayDeNumeros(20,palabras);
    
    

    const getDescripcion = () => {
        return (
            <div className={[estiloJuegos.descripcionJuego,estiloJuegos.descripcionMemoriaNumeros].join(" ")}>
                ¡Reto de palabras activado!
                <br/>
                <h2 style={{fontSize:"2.37vh"}}>Durante 60 segundos, observa y memoriza tantas palabras como puedas.</h2>
            </div>
        );
    }

    return(
        <TestMemoria 
        getDescripcion={getDescripcion}
        idTest={nomTestMemPalabrasInicial}
        nombreTests={titulo}
        datos={palabras}
        datosIds={palabraIdsTemporal}
        cantidadDatos={20}
        inputType="text"
        tituloDatos="Palabra"
        />
    );
};
export default TestMemoriaPalabras;