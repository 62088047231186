import React, { useEffect } from 'react';
import  "./theme/pages.css";
import { compraRealizadaFacebook } from './ConfigPixel';

const RespuestaPixel: React.FC = () =>  {
  
  useEffect(() => {
    compraRealizadaFacebook();              
  }, []);

  return (
    <div id='page'> 
      <div id='toolbar_default'>
        <h1 className="title">PAGO EXITOSO</h1>
      </div>
      <div id='content_default'>

      </div>
		</div>
    
      
  );
}

export default RespuestaPixel;
