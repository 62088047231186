import { truncate } from 'fs';
import ReactPixel, { fbq } from 'react-facebook-pixel';

// Configuración del píxel de Facebook
const options = {
  autoConfig: true,
  debug: process.env.REACT_APP_ENVIRONMENT === 'dedvelopment',
};

// const fbPixelTrackingID = process.env.REACT_APP_FACEBOOK_TRACKING_ID

ReactPixel.init('1378840772884264', null, options);

// export const landingPageOpen = () => {
//     fbq('track','Lead-landing');
// }

export const pageViewFacebook = () => {
    ReactPixel.pageView()
    console.log('View facebook')
}

export const agregarAlCarroFacebook = () => {
    ReactPixel.track('AddToCart')
    console.log('Carrito facebook')
}

export const compraRealizadaFacebook = () => {
    ReactPixel.track('Purchase')
    console.log('Compra facebook')
}