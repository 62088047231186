import style from "../../../../theme/Juego2.module.css"

interface PropResultados{
    userInputs: string[];
    calificaciones: boolean[];
    getPalabraWrapper: (index) => string;
    getObjetos?: any;
    objetoIds?: string[][];
    game?: string;
    digitos?:number;
    tituloPalabras: string;
    height?: string;
 
}

const ResultadosNumPal: React.FC<PropResultados> = (props) => {
    console.log("🚀 ~ file: ResultadosNumPal.tsx:20 ~ props:", props)
    let arraycalificaciones=props.calificaciones;
    const getResultado = (numeroIndice: number, adivina: string) => {    
        if (props.tituloPalabras !=='Palabra') {
            return getResultadoNumero(numeroIndice,adivina);
        }else{
            return getResultadoTexto(numeroIndice,adivina);
        }
        
    }
    const getResultadoTexto = (numeroIndice: number, adivina: string) => { 
        if (props.calificaciones[numeroIndice]) {
            return (
                <div
                    className={style.correcta} style={{fontSize:'1rem',padding:'12px'}}
                >
                    <label>{adivina == ''? "No respondió" : adivina}</label>
                </div>
            );
        }else{
            return (
                <div
                    className={style.incorrecta} style={{fontSize:'1rem',padding:'12px'}}
                >
                    <label>{adivina == ''? "No respondió" : adivina}</label>
                </div>
            );
        }
    } 
    const getResultadoNumero = (numeroIndice: number, adivina: string) => { 
        
        let adivina1 : string;
        if(adivina == ''){
            adivina1 = "~"
        }else{
            adivina1 = adivina
        }
        
        return(
            <>
                <div 
                // className={props.tituloPalabras == 'Palabra'? "5" :"3"}
                 className={arraycalificaciones[numeroIndice] ? style.correcta : style.incorrecta}
                 style={{display:'flex',flexDirection:'row', justifyContent:'center',marginLeft:'0%'}}>
                    <div
                        style={adivina1 !== "~" ? {fontSize:'1.5rem'}:{fontSize:'1rem'}}
                    >
                        {adivina1 === "~" ? 'No Respondió': adivina1} 
                    </div>
                </div>
            </>
        );
    }     
    return (
        <>
            <div className={`${style.grid_resultados} ${style.gridResultadosNumPal}`}>
                <div>
                    <label>
                        <h1 className={style.subtitleRespuestas}>{props.tituloPalabras}</h1>
                    </label>
                </div>
                <div>
                    <label>
                        <h1 className={style.subtitleRespuestas}>Respuesta </h1>
                    </label>
                </div>
                <div style={{padding:'0% 0% 0% 0%',gridColumn:'1 / 4'}}>
                    <div className={style.linea}></div>
                </div>
            </div>
            <div className={[style.contenedor_resultados, style.padding_scrollbar].join(' ')} style={{maxHeight: "initial", height: props.height}}>
                {props.userInputs.map((element, index) => (
                    <>
                        <div className={style.col} style={props.tituloPalabras !== 'Palabra' ? {fontSize:'1.5rem'} : {fontSize:'1rem'}}>
                            {props.getPalabraWrapper(index)}
                        </div>
                        {getResultado(index, element)}
                    </>
                ))}
            </div>
        </>
    );
}

export default ResultadosNumPal;