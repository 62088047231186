import { DocumentData, DocumentSnapshot } from "firebase/firestore";

export interface Asociacion{
    parecido: string;
    frase: string;
    urlFoto?: string;
}

export const aAsociacion = (palabra:string,frase:string) => {
    let Asociacion: Asociacion = {parecido:null, frase:null};
    Asociacion.parecido = palabra;
    Asociacion.frase = frase
    return Asociacion
}

export const toAsociacion = (doc:DocumentSnapshot) =>{
    if(doc.exists()){        
        const retorno = doc.data() as Asociacion;
        retorno.parecido = doc.id
        return retorno
    }
}