import React, { useState } from 'react';
import estiloJuegos from "../../../theme/Juegos.module.css";
import { Link } from 'react-router-dom';

const CalculoFecha = () => {
    const [etapa, setEtapa] = useState<number>(1);
    const getDescripcionJuego = () => {        
        return (
          <div className={estiloJuegos.descripcionJuego}>
              Calcula las fechas
          </div>
        );
    }
    const handlePlay =() => {

    }

  return (
    <div id='page'>
        <div className={etapa === 1 ? estiloJuegos.toolbarCalculoFecha : estiloJuegos.toolbar_default}>
          <div className={etapa !== 1 ? estiloJuegos.contenedor_titulo_default : ''}>
            <Link  className={estiloJuegos.backJuegos} to=".." relative="path">
            </Link>
            <h1 className={`ion-text-center title ${estiloJuegos.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'1.25rem'} :{}}>CÁLCULO DE LA FECHA </h1> 
          </div>
        </div>
        {etapa === 1 && 
          <div className={estiloJuegos.contenedorIconoFecha}>
            <img src='/assets/componentes/Juegos/Fecha.svg' className={estiloJuegos.icono}></img>
          </div>
        }
        <div id='content_default'>
          {etapa === 1 &&
                
            <div className={estiloJuegos.primeraPantalla}>
                {getDescripcionJuego()} 
                <button className={estiloJuegos.buttonEmpezar} onClick={handlePlay}>
                    EMPEZAR
                </button>
                <Link className={estiloJuegos.BordesButtonRanking} to="/my/juegos/ranking">
                    <button  className={estiloJuegos.rankingJuegos} >RANKING</button>
                </Link>                   

            </div>
                
          }
        </div>
    </div>
  );
};

export default CalculoFecha;