import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import estiloPago from '../../theme/pagoPage.module.css'
import { paises } from '../../datos/paises'
import stylePopover from "../../theme/PopoverNeuronas.module.css";
import { useEntrenamiento } from '../../hooks/controlador/useEntrenamiento'

import Checkout from '../../Checkout'
import { useToast } from '../../hooks/useToast'
import { useDato } from '../../auth'
// import { compraEfectivo } from './FunctionPagoPage'

import { useFacturas } from '../../hooks/controlador/useFacturas'
import { ProductoReferencia, TiposProductos, aFactura } from '../../modelos/Facturas'
import { Timestamp } from 'firebase/firestore'
import { Campaña } from '../../modelos/entrenamiento/Campaña'
import { useLocation, useParams } from 'react-router-dom'
import Loading, { Spinner } from '../entrenamiento/componentes/Loading'
import Selector from '../entrenamiento/componentes/Selector'
import compraEfectivo from '../../utils/compraEfectivo';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../firebase';
import CrearProducto from './componentes/CrearProducto';
import { Producto } from '../../modelos/Producto';

type Params = {
  titleEntrenamiento:string;
  codigoParams?:string;
}

const opcionesProductos = {
  "neurobics": {id_referencia: "neurobics", tipo:"entrenamiento"},
  "memoria": {id_referencia: "memoria", tipo:"entrenamiento"},
  "libro pdf": {id_referencia:'Malla_Plan_de_estudios_estndar_2023',tipo:'e-book'},
  "taller": {id_referencia:'',tipo:'taller'},
  "asignacion curso": {id_referencia:'',tipo:'asignacion'},
}

const PagoPage = () => {

  const location = useLocation();

  const { titleEntrenamiento, codigoParams } = useParams<Params>();

  const [codigoTel, setCodigoTel] = useState('+57')
  const [nombre_usuairo, setNombre_usuairo] = useState(null)
  const [telefono, setTelefono] = useState(null)
  const [correo_usuario, setCorreo_usuario] = useState(null)
  const [loading, setLoading] = useState(false)
  const [descuento, setDescuento] = useState(0)
  const [entrenamiento, setEntrenamiento] = useState(null)
  const [costoCompra, setCostoCompra] = useState(0)
  const [montar, setMontar] = useState(false)
  const [openModalConfirmacion, setOpenModalConfirmacion] = useState(false)
  const [openModalCrearProducto, setOpenModalCrearProducto] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)
  const [arrayCompra, setArrayCompra] = useState<ProductoReferencia[]>([]);
  const [currentProducto, setCurrentProducto] = useState<{prod:ProductoReferencia,index:number}>({prod:null,index:null});


  const payUrl = useMemo(()=> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development')  
      return process.env.REACT_APP_EPAYCO_URL_CONFIRMATION_DEV;       
    else if (process.env.REACT_APP_ENVIRONMENT === 'production')
      return process.env.REACT_APP_EPAYCO_URL_CONFIRMATION;

  }, [process.env.REACT_APP_ENVIRONMENT]);

  const {
    state
  } = useDato()

  const {
    presentMensajePersonalizado
  } = useToast();

  const {
    consultarProductoVentas,
    consultarCampaña,
  } = useEntrenamiento();

  const desmontarPopover = () =>{
    setMontar(false)
    setTimeout(() => {
      setOpenPopover(false);
    }, 1000);
  }

  const cambiarLoading = () =>{
    setLoading(prev => !prev);
  }

  const validarEmail = (valor:string):Boolean => {
    var reg:RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var regOficial:RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (reg.test(valor) && regOficial.test(valor)) {
      console.log("válido oficial y extraoficialmente");
      return true
    } else if (reg.test(valor)) {
      console.log("válido extraoficialmente");
      return true
      
    } else {
      console.log("incorrecto");
      return false
    }
  }

  const validarDatos = (): Boolean => {
    if(validarEmail(correo_usuario) && codigoTel !== null && telefono !==null && nombre_usuairo !== null){
      return true
    }else{
      presentMensajePersonalizado("Datos incompletos")
      return false
    }
  }

  function handleSubmit(): void {
    if(validarDatos()){
      setOpenPopover(true);
      setMontar(true)
    }else{
      presentMensajePersonalizado("Datos incompletos")
    }
  }

  async function handleEfectivo():Promise<void> {
    if(validarDatos()){
        console.log("🚀 ~ handleEfectivo ~ validarDatos:", validarDatos())
        setLoading(true)
        try {
          await compraEfectivo(payUrl, 
            {
              id_productos: arrayCompra,
              nombre: nombre_usuairo,
              telefono: telefono,
              email: correo_usuario,
              codigoTelefono: codigoTel,
              userId: null,
              campaña: codigoParams,
              valorPagado: costoCompra,
              tipoModena: "COP",
              urlCompra: location.pathname
            }
          )
          presentMensajePersonalizado("Realizado pago en efectivo")
        }catch(error){
          console.log("🚀 ~ file: PagoPage.tsx:105 ~ handleEfectivo ~ error:", error)
        }   
        setLoading(false)
        setOpenModalConfirmacion(true)
        setArrayCompra([])
        setCurrentProducto({prod:null,index:null})
        setCostoCompra(0)

      }else{
        presentMensajePersonalizado("Datos incompletos")
    }
    
  }

  const codigoInfo = async (codig: string) => {
    const campaña:Campaña = await consultarCampaña(codig)
    let descuento = Number(campaña?.descuento);
    return descuento
  }

  const CalcularCostoTotal = async () => {
    setLoading(true)
    let total = 0
    let arrayPromesas:Promise<Producto>[] = []
    arrayCompra.map((prod) => {
      if(prod.tipo === TiposProductos.tipoEntrenamiento || prod.tipo === TiposProductos.tipoLibroDigital){
        arrayPromesas.push(consultarProductoVentas(prod.id_referencia))
      }
    })
    let arreglodeProductos = await Promise.all(arrayPromesas)
    arreglodeProductos?.map((prod) => {
      total += prod.precio
    })
    setCostoCompra(total)
    setLoading(false)
  }

  const AgregarProducto = (newProduct:ProductoReferencia) => {
    if(currentProducto.prod !== null){
      let copy = [...arrayCompra]
      copy[currentProducto.index] = newProduct
      setArrayCompra(copy)
      setCurrentProducto({prod:null,index:null})
    }else{
      setArrayCompra([...arrayCompra,newProduct])
    }
  }

  const deleteProducto = () => {
    let arr = arrayCompra.filter((_, i) => i !== currentProducto.index);
    console.log("🚀 ~ deleteProducto ~ arr:", arr)
    setArrayCompra(arr);
    setCurrentProducto({prod:null,index: null})
    setOpenModalCrearProducto(false)
  }

  useEffect(() => {
    CalcularCostoTotal()
  }, [arrayCompra])
  
  const HandleEditarProducto = (prod:ProductoReferencia,index:number) => {
    setCurrentProducto({prod:prod,index: index})
    setOpenModalCrearProducto(true)
  }

  const HandleAgregarProducto = () => {
    setCurrentProducto({prod:null,index:null})
    setOpenModalCrearProducto(true)
  }

  return (
    <div id='content_default'>
      {/* <Loading isOpen={loading}/> */}
      <div style={{maxHeight: "94svh", overflow: "auto"}}>
        <h1 className={estiloPago.title}>                
          <span className={estiloPago.entrenamiento}><strong>Compra Administrador</strong></span><br></br>
        </h1>
        <div className={estiloPago.factura}>
          <p>Factura de Compra</p>
          <div className={estiloPago.linea}>
            {Array.from({ length: 16 }, (_, index) => (
              <div key={index}></div>
            ))}
          </div>
          <div className={estiloPago.grid}>
            <p>valor</p>
            <p className={estiloPago.valor}>{costoCompra.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
            <p>descuento</p>
            <p className={estiloPago.valor}>{descuento.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
          </div>
          <div className={`${estiloPago.grid} ${estiloPago.backMorado}`}>
            <p>total</p>
            <p className={estiloPago.valor}>{costoCompra.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
          </div>
        </div>
        <div className={estiloPago.boxInput}>
          <div>
            <p className={estiloPago.campoObligatorio}>Nombre Completo*</p>
            <input type='text' style={{width:'100%'}} placeholder='Ingrese su nombre' value={nombre_usuairo} onChange={(e) => setNombre_usuairo(e.target.value)}></input>
            <div className={estiloPago.telefono}>
              <p className={estiloPago.campoObligatorio}>país</p>
              <p className={estiloPago.campoObligatorio}>número de telefono*</p>
              <div className={estiloPago.selector}>
                <Selector setSelection={setCodigoTel} selection={codigoTel} opciones={paises} showValues={true}/>
              </div>
              <input type='number' style={{width:'100%'}} placeholder='Ejemplo: ' value={telefono} onChange={(e) => setTelefono(e.target.value)} ></input>
            </div>
            <p className={estiloPago.campoObligatorio}>Correo electronico*</p>
            <input type='text' style={{width:'100%'}} placeholder='Ejemplo: example@xmpl.com' value={correo_usuario} onChange={(e) => setCorreo_usuario(e.target.value)} ></input>
            <p className={estiloPago.campo}>*campos obligatorios</p>
          </div>
          <table style={{margin:'0',width:'100%'}}>
            <tr style={{border:'2px solid black'}}>
              <th>id</th>
              <th>tipo</th>
              <th></th>
            </tr>
            {arrayCompra?.map((prod,index) => (
              <tr key={index} style={{alignItems:'center'}}>
                <th>{prod.id_referencia}</th>
                <th>{prod.tipo}</th>
                <button onClick={() => HandleEditarProducto(prod,index)}>Editar</button>
              </tr>
            ))}
          </table>
          {loading? 
            <Spinner style={{marginLeft: "50%", translate: "-50%"}}/>:
            <>
            <div className={estiloPago.divButton}>
              <button onClick={HandleAgregarProducto}>Agregar Producto</button>
            </div>
            <div className={estiloPago.divButton}>
              <button onClick={handleSubmit}>Pagar Epayco</button>
            </div>
            { state?._administrador===true &&
            <div className={estiloPago.divButton}>
              <button onClick={handleEfectivo}>Pagar en Efectivo</button>
            </div>
            }
            </>
          }
          <div className={estiloPago.mediosdePago}>
            <img src='/assets/icon/visa.svg'></img>
            <img src='/assets/icon/mastercard.svg'></img>
            <img src='/assets/icon/paypal.svg'></img>
            <img src='/assets/icon/pse.png'></img>
            <img src='/assets/icon/padaTodo.png'></img>
            <img src='/assets/icon/efecty.png'></img>
          </div>
        </div>
      </div>
      {openModalCrearProducto && <CrearProducto setVisible={setOpenModalCrearProducto} deleteProducto={deleteProducto} pushProducto={AgregarProducto} index={currentProducto.index} initialProducto={currentProducto.prod}></CrearProducto>}
      {openModalConfirmacion && <div className={estiloPago.modal}>
        <div className={estiloPago.content}>
          <label className={estiloPago.title_mensaje}>Bienvenido</label>
          <div className={estiloPago.linea}></div>
          <label className={estiloPago.mensaje}>
            Transaccion existosa ya puedes comenzar
          </label>
          <br></br>
          <button onClick={() => {setCodigoTel('+57');setCorreo_usuario(null);setNombre_usuairo("");setTelefono("");setOpenModalConfirmacion(false)}} className={estiloPago.buttonTestInicial} style={{width:'50%'}}>
            Ok
          </button>
          <br></br>
        </div>
      </div>}
      {openPopover &&
        <div className={[stylePopover.contenedor,montar ? '' : stylePopover.desmontar].join(" ")}>
          <div className={stylePopover.contenido}>
            <div className="contenido_popover">
              <h3 className='confirmacion_pedido'>CONFIRMACIÓN DE PEDIDO</h3>
              <div className='producto'>
                <div> {`Entrenamiento "${entrenamiento?.titulo}"`}</div>
                {/*}
                <div style={{textAlign:"end"}}>${(entrenamiento?.costo - entrenamiento?.costo * descuento).toLocaleString('es-CO')}</div>
                */}
                <div style={{textAlign:"end"}}>{costoCompra.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</div>
              </div>
              <div  className='producto'>
                <div>valor IVA</div>
                <div style={{textAlign:"end"}}>{(entrenamiento?.iva - entrenamiento?.iva * descuento).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })} </div>
              </div>
              <div className='linea_punteada'></div>
              <div className='valor_total'>
                <div>Valor total:</div>
                {/* <div style={{textAlign:"end"}}>${(entrenamiento?.costo - entrenamiento?.costo * descuento).toLocaleString('es-CO')} </div> */}
                <div style={{textAlign:"end"}}>{costoCompra.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })} </div>
              </div>
              {/* 
              costo={(entrenamiento?.costo - entrenamiento?.costo * descuento)} iva={(entrenamiento?.iva - entrenamiento?.iva * descuento)}
              */}
              <Checkout titulo={`Compra realizada por administrador`} 
                description={`Compra realizada desde administrador`} 
                costo={costoCompra}
                setLoading={cambiarLoading}
                userId={null}
                id_productos={arrayCompra}
                rutaRedireccion='/responseFactura'
                email={correo_usuario}
                telefono={telefono}
                codigoTelefono={codigoTel}
                nombre={nombre_usuairo}
                campaña={codigoParams}
                urlCompra={location.pathname}
                />
              <button className={estiloPago.volver} onClick={desmontarPopover} >
                VOLVER
              </button>
            </div>

          </div>
        </div>
      }
    </div>
  )
}

export default PagoPage
