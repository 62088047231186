import React, { useState, useEffect, useRef } from 'react'
import '../../../theme/actividades.css'
import { Actividad } from '../../../modelos/entrenamiento/Actividad';
import styleVistaVideo from "../../../theme/entrenamiento/componentes/VistaVideo.module.css";
import stylePrograma from "../../../theme/admin/programa.module.css";
import estiloJuegos from "../../../theme/Juegos.module.css";
import PopoverNeuronas from '../../programas/componentes/PopoverNeuronas';
import { useAppDispatch, useAppSelector } from '../../../store';
import useRegistroEntrenamiento from '../../../hooks/controlador/useRegistroEntrenamiento';
import { RegistroActividad, registroActividadVacio } from '../../../modelos/entrenamiento/RegitroActividad';
import { sumaNeuronas } from '../../../hooks/reducer/reducerAuth';
import useNeurona from '../../../hooks/controlador/useNeurona';
import { useDato } from '../../../auth';
import Calificador from './components/Calificador';
import VideoConContador from './VideoConContador';


interface Props {
    handleBack:() => Promise<void>;
    setLoading:React.Dispatch<React.SetStateAction<boolean>>;
    videoUrl: string|string[];
    actividad:Actividad;
    firstTime:(0|1|2);
    GuardarRegistro: () => Promise<void>;
}

const VistaDeVideo:React.FC<Props> = (props) => {
    const sesionActual = useAppSelector((state) => state.entrenamiento.current_entrenamiento.sesiones[state.entrenamiento.current_sesion]);
    const indexSesionActual = useAppSelector((state) => state.entrenamiento.current_sesion);
    const userId = useAppSelector((state) => state.auth.userId);
    const currentEntrenamientoRedux = useAppSelector((state) => state.entrenamiento.current_entrenamiento);
    const { crearRegistroActividad, actualizarRating, agregarRating, consultarLike } = useRegistroEntrenamiento();
    const { actualizarNeuronas } = useNeurona();
    const { state } = useDato();
    const [registroActividad, setRegistroActividad] = useState<RegistroActividad>(registroActividadVacio);
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [showNeuronas,setShowNeuronas] = useState<boolean>(false);
    const [neuronasGanadas,setNeuronasGanadas] = useState<number>(0);
    const [mensaje,setMensaje] = useState<string>('¡Completa el video la próxima vez para maximizar tus ganancias!');
    const dispatch = useAppDispatch();
    const [terminoVideo, setTerminoVideo] = useState(props.firstTime === 1);
    const [isAnimatingExit, setIsAnimatingExit] = useState(false);
    const [isAnimatingEntrada, setIsAnimatingEntrada] = useState(false);

    useEffect(() => {
        componentDidMount()
      return () => {
        componentWillUnmount()
      }
    }, [])    
    
    const componentWillUnmount = async() => {
        setShowNeuronas(false)
        const largo = document.getElementById('el-body').clientHeight ;
        const ancho = document.getElementById('el-body').clientWidth ;
        if((largo*3/4) >= ancho){
            document.getElementById('el-body').style.background = '#ffffff' ;
        }else{
            document.getElementById('el-body').style.background = 'linear-gradient(180deg, #45245F 0%, #D74285 100%)' ;
        }
    }
    
    const componentDidMount = async() => {
        let registroActividad = await consultarLike(userId,currentEntrenamientoRedux.id,`${sesionActual?.id}${props.actividad?.id}`)
        setShowNeuronas(false)
        console.log("🚀 ~ componentDidMount ~ registroActividad:", registroActividad)
        if(registroActividad) setRegistroActividad(registroActividad);
    }

    const componenteVideoRef = useRef<VideoConContador | null>(null);

    const llamarFuncionInfo = ():number => {
        if (componenteVideoRef.current) {
            const respuestas = componenteVideoRef.current.capturarInfoVideo()
            console.log("🚀 ~ llamarFuncionInfo ~ respuestas:", respuestas)
            return respuestas
        }else{
            console.log("🚀 ~ llamarFuncionInfo ~ sin respuestas:")
            return 0
        }
    };

    const GenerarRegistrodelLikeInicializar = async(NEstrellasInput?:number) => {
        if(!registroActividad.id){
            let copy = {...registroActividad}
            copy.id = `${sesionActual?.id}${props.actividad?.id}`
            if(copy.like !== NEstrellasInput && typeof(NEstrellasInput) === typeof(0)){
                copy.like = NEstrellasInput
            }
            setRegistroActividad(copy)
            await agregarRating(currentEntrenamientoRedux.id,sesionActual?.id,props.actividad?.id,copy)
            console.log("🚀 ~ GenerarRegistrodelLikeInicializar agregar ~ copy:", copy)
            crearRegistroActividad(userId,currentEntrenamientoRedux.id,copy)
        }else if(registroActividad.like !== NEstrellasInput){
            let antiguoLike = registroActividad.like
            let copy = {...registroActividad}
            copy.like = NEstrellasInput
            setRegistroActividad(copy)
            await actualizarRating(currentEntrenamientoRedux.id,sesionActual?.id,props.actividad?.id,copy,antiguoLike)
            crearRegistroActividad(userId,currentEntrenamientoRedux.id,copy)
            console.log("🚀 ~ GenerarRegistrodelLikeInicializar actualizar ~ copy:", copy)
        }
    }
    
    const GuardarNeuronas = () =>{
        dispatch(sumaNeuronas({cantidad:neuronasGanadas}))
        actualizarNeuronas(userId,neuronasGanadas,state._usuarioDato.nombres)
        return(
            <></>
        )
    }

    useEffect(()=>{
    if(showNeuronas == true){
        GuardarNeuronas()
    }
    },[showNeuronas])

    // const CargarVideos = () => {
    //     if(props.videoUrl){
    //         window.caches.open('video-pre-cache')
    //         .then(async cache => {
    //             const url = (typeof(props.videoUrl) === "string")? props.videoUrl: props.videoUrl[0];
    //             const [fromCache, data]= await fetchOrCache(url, cache);
    //             // console.log(fromCache, data);
    //             if(fromCache){
    //                 const blob = await (data as Response).blob();
    //                 // console.log(blob);
    //                 setVideoUrl(URL.createObjectURL(blob));
    //             }else{
    //                 setVideoUrl(url);
    //             }
    //         });
    //     }
    // }

    // useEffect(() => CargarVideos(), [props.videoUrl])

    const salirOpopover = () => {
        let numero  = llamarFuncionInfo()
        console.log("🚀 ~ salirOpopover ~ numero:", numero)
        if(numero < 90){
            props.setLoading(false)
            setOpenPopover((prev) => !prev)
            setMensaje('¡Completa el video la próxima vez para maximizar tus ganancias!')
        }else{
            props.setLoading(false)
            setMensaje('¡Felicidades! Has ganado 100 neuronas por tu compromiso con el entrenamiento. ¡Sigue así!')
            setOpenPopover((prev) => !prev)
            MostrarNeuronas()
        }
    }
    
    const MostrarNeuronas = ():void => {
        setOpenPopover((prev) => !prev)
        if(props.firstTime === (0|2)){
            setShowNeuronas(true)
        }else{
            FunctionSalir()
        }
      }

    const FunctionSalir = async() => {
        console.log('Funcionb salir')
        props.setLoading(true)
        props.GuardarRegistro();
        setShowNeuronas(false);
        props.setLoading(false)
        return props.handleBack();
    }
    
    const seleccionEstrella = (NEstrellas:number )=>{
        setIsAnimatingEntrada(false);
        setIsAnimatingExit(true);
        GenerarRegistrodelLikeInicializar(NEstrellas);
        setTimeout(() => {
            setIsAnimatingEntrada(true);
            setIsAnimatingExit(false);
        }, 1000);
    } 
    
    const salir = () =>{
        props.setLoading(true)
        if(props.firstTime !== 1){
            salirOpopover()
        }else{
            FunctionSalir()
        }
    }
    
    return (
        <>
            <div className={`${styleVistaVideo.toolbar_Vista_video}`}>
                <div style={{display:'inherit',width:'100%',padding:'4em 2.5em'}}>
                    <button className={`${stylePrograma.backButton} ${styleVistaVideo.backButton}`} style={{height:'100%'}} onClick={salir}/>
                    <h1 className="sesionTitulo" style={{width:'60%'}} >{props.actividad?.titulo ?? `Sesión {indexSesionActual+1}`}</h1>
                </div>
                <div className={estiloJuegos.contenedorVideo} style={{height:'fit-content',background:'#D9D9D9',borderRadius:'23px'}}>
                    <VideoConContador 
                        autoplay={false} 
                        style_contain={{height:'fit-content',position:'relative',display:'flex',justifyContent:'center'}}
                        style_video={{height:'100%',maxWidth:'100%'}}
                        setLoading={props.setLoading} 
                        videoUrl={props.videoUrl} 
                        setNeuronasGanadas={setNeuronasGanadas} 
                        ref={componenteVideoRef}
                        adelantar={true}/>
                </div>
            </div>
            <div className={stylePrograma.content} style={{position:'relative',borderRadius:'0',padding:'0',overflow:'hidden'}}>
                <p className={styleVistaVideo.descripcion} style={{minHeight:'60%'}}>{props.actividad?.descripcion}</p>
                <button className={styleVistaVideo.ButonFinalizar} onClick={salir}>LISTO</button>
                <img className='imagenDificultad' src={`/assets/componentes/ActividadVideo/fondo_neuronas_gris.svg`}></img>
            </div>

            {openPopover === true && <div onClick={() => {setOpenPopover((prev) => !prev)}} className={styleVistaVideo.popover}>
                <div className={styleVistaVideo.content} onClick={(e) => e.stopPropagation()}>
                    <div className={styleVistaVideo.top}>
                        <p>¡Estás a punto de completar!</p>
                        <p>Has visto el {neuronasGanadas} % del video</p>
                        {neuronasGanadas !== 100 && <p>¿Estás seguro de que quieres perder el contenido restante y las neuronas que puedes ganar?</p>}
                    </div>
                    <div className={styleVistaVideo.bot}>
                        {neuronasGanadas !== 100 && <button className={styleVistaVideo.botonRealizarActividadOkRosado} onClick={()=>{MostrarNeuronas()} }>Sí</button>}
                        <button className={styleVistaVideo.botonRealizarActividadOk} onClick={() => setOpenPopover(false)}>No</button>
                    </div>

                </div>
            </div>}

            {/* <IonPopover backdropDismiss onWillDismiss={() => {setOpenPopover( false)}} className='botonRealizarActividadOk' isOpen={true}>
            </IonPopover> */}
            { showNeuronas &&
             <PopoverNeuronas nuevasNeuronas={neuronasGanadas} mensajeInfo={mensaje} nivel={neuronasGanadas} funcionVolver={() => FunctionSalir()} esEntrenamiento={true}/>
            } 
        </>
    )
}

export default VistaDeVideo
