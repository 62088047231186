import { useState, useEffect } from 'react';

import style from "../../../theme/Juego2.module.css";
import stylePal from "../../../theme/memoriaPalabras.module.css";
import estiloJuegos from "../../../theme/Juegos.module.css";
import estiloNeuronas from "../../../theme/PopoverNeuronas.module.css";
import { niveles, titulo } from '../../../datos/DatosMemNumeros';
import Toolbar from '../componentes/Toolbar';
import SegundaPantalla from './componentes/SegundaPantalla';
import TerceraPantalla from './componentes/TerceraPantalla';

import { nomProgMemNumeros } from '../../../datos/nombresBD';
import { useDato } from "../../../auth";
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { useAppDispatch, useAppSelector } from '../../../store';
import { test_general } from '../../../datos/DatosTests';
import { obtenerNeuronas } from '../../../utils/neuronasUtil';
import { initialNeuronas } from '../../../hooks/reducer/reducerAuth';
import ResultadosNumPal from '../resultados/componentes/ResultadosNumPal';
import BotonesJuegos from '../componentes/BotonesJuegos';
import { obtenerMensaje } from '../../../utils/resultadoUtil';
import { changeCondicion, changePrograma, newActivity } from '../../../hooks/reducer/reducerActividad';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { opcion } from '../../entrenamiento/componentes/Selector';
import SelectorNivel from '../componentes/SelectorNivel';
import Loading from '../../entrenamiento/componentes/Loading';
import useRegistroEntrenamiento from '../../../hooks/controlador/useRegistroEntrenamiento';

const MemoriaNumeros = () => {

    const userId = useAppSelector((state) => state.auth.userId);
    const testId = useAppSelector((state) => state.activity.id);
    const esJuego = useAppSelector((state) => state.activity.id == test_general);
    const dispatch = useAppDispatch();
    const neuronas = useAppSelector((state) => state.auth.neuronas);
    const esEntrenamiento = useAppSelector((state) => state.activity.id == "entrenamiento");
    const idEntrenamiento = useAppSelector((state) => state.entrenamiento?.current_entrenamiento?.id);
    const indiceActividad = useAppSelector((state) => state.entrenamiento?.current_actividad);
    const indiceSesion = useAppSelector((state) => state.entrenamiento?.current_sesion);
    const parametro = useAppSelector((state) => state.activity?.tiene_bloqueo);
    const lenghtActividades = useAppSelector((state) => state.entrenamiento.current_actividades?.length);

	const {				
		state,
	} = useDato();

    const location = useLocation();

	const {
        guardarDatosCalificacionTestGeneral,		
        guardarDatosCalificacion,
		getCalificacionGeneral,
        getConfiguracionJuego,
        guardarConfiguracionJuego
    } = useJuegos();


    const navigate = useNavigate();
    const primeraEtapa = 1;
    const segundaEtapa = 2;
    const terceraEtapa = 3;
    const inicialValorNumero = -1;
    const inicialValorArrayString: string[] = [];
    const inicialValorArrayBoolean: boolean[] = [];
    const inicialValorArrayAny: any[] = [];
    const [tiempo, setTiempo] = useState<number>(0);
    const [digitos, setDigitos] = useState<number>(1);
    const [etapa, setEtapa] = useState<number>(1);
    const [nivelLocal, setNivelLocal] = useState<number>(0);
    const [nivelActualBD, setNivelActualBD] = useState<number>(0)
    const [cantidadCorrecta, setCantidadCorrecta] = useState<number>(inicialValorNumero);
    const [cantidadDeNumeros, setCantidadDeNumeros] = useState<number>(inicialValorNumero);
    const [numeroIndice, setNumeroIndice] = useState<number>(inicialValorNumero);
    const [maxTiempo, setMaxTiempo] = useState<number>(inicialValorNumero);
    const [nuevasNeuronas, setNuevasNeuronas] = useState<number>(0); 
    const [numeros, setNumeros] = useState<string[]>(inicialValorArrayString);
    const [userInputs, setUserInputs] = useState<string[]>(inicialValorArrayString);
    const [calificaciones, setCalificaciones] = useState<boolean[]>(inicialValorArrayBoolean);
    const [inputRefs, setInputRefs] = useState<any[]>(inicialValorArrayAny);

    const { actualizarRegistroEntrenamiento } = useRegistroEntrenamiento();
/*
  AYUDANTE FUNCIONES
  */
    
    const crearArrayDeNumeros = (cuenta: number) => {
        let arr: string[] = [];
        for(let i=0;i<cuenta;i++) {
            let num = ""
            for(let j=0;j<digitos;j++){
                num += (getRandNum(9, j===0 ? 1 : 0))
            }
            arr.push(num);
        }
        return arr;
    }

    const getRandNum = (max : number, min : number) => {
	return String(Math.floor(Math.random() * (max - min + 1)) + min);
    }

    const guardaInputAyudante = (target: any) => {
        const indice = Number(target.id.split('-')[1]);

        const userInputsTemporal = userInputs.slice();
        userInputsTemporal[indice] = target.value;
        
        setUserInputs(userInputsTemporal);
        console.log("🚀 ~ guardaInputAyudante ~ userInputsTemporal:", userInputsTemporal)
        
        const inputRefsTemporal = inputRefs.slice();
        inputRefsTemporal[indice] = target;
        setInputRefs(inputRefsTemporal);
    }

    const guardaInput = (target: any) => {
        if (target.name === null || target.name === undefined) {
            console.log("hubo un error, no se reconoce el input");
        } else {
            guardaInputAyudante(target);
        }
    };

    const subirNivel = () => {
        if (nivelLocal < niveles.length - 1) {
            setNivelLocal(nivelLocal + 1);
        } else {
            console.log("no se puede aumentar el nivel mas");
        }
    }

    const checarRespuesta = () => {
        let calificacionesTemporal = Array(userInputs.length).fill(false)
        for (let indice = 0; indice < userInputs.length; indice++) {
            calificacionesTemporal[indice] = userInputs[indice] === numeros[indice]
        }
        return calificacionesTemporal
    }

    const checarRespuestas = () => {
        const calificacionesTemporal = checarRespuesta();
        setCalificaciones(calificacionesTemporal);

        const cantidadCorrectaTemporal = calificacionesTemporal.filter(Boolean).length;
        setCantidadCorrecta(cantidadCorrectaTemporal);

        var nivelCompleto = false;
        const porcentajeCorrectas = cantidadCorrectaTemporal * 100 / cantidadDeNumeros;        
        if(porcentajeCorrectas >= 60){
            subirNivel();
            nivelCompleto = true;
        }

        // Obtener Neuronas
		const neuronasNuevas = obtenerNeuronas(nomProgMemNumeros, nivelLocal, cantidadCorrectaTemporal)
		setNuevasNeuronas(neuronasNuevas);
        const neuronasTotales = neuronas + neuronasNuevas
		dispatch(initialNeuronas({cantidad:neuronasTotales}))

        // Guarda la configuracion cuando es el juego o cuando es un test
        
        if(nivelLocal > nivelActualBD){
            if(testId === test_general){            
                guardarDatosCalificacionTestGeneral(userId, nomProgMemNumeros, neuronasNuevas, nivelLocal, cantidadCorrectaTemporal, tiempo, nivelCompleto, state.nombres, state.pais);
            }else{
                guardarDatosCalificacion(userId, testId, nomProgMemNumeros, neuronasNuevas, null, cantidadCorrectaTemporal, tiempo, true, state.nombres, state.pais);
            }  
        }
               
    }


    /*
      HANDLERS
    */

    const handleTiempo = () => {
        console.log("🚀 ~ handleTiempo ~ handleTiempo:", handleTiempo)
        if (esSegundaEtapa()) {
            construirTerceraPantalla();
        } else if (esTerceraEtapa()) {
            construirCuartaPantalla();
        } else {
            throw new Error("tiempo iniciado fuera de lugar");
        }
    }

    const handleRanking = () => {		
        navigate('/my/juegos/ranking');
      }

    const resetContenido = () => {
        setNumeroIndice(0);
    }

    const rightSubmit = () => {
        if (numeroIndice < cantidadDeNumeros - 1) {
            setNumeroIndice(numeroIndice + 1);
        }
    }

    const leftSubmit = () => {
        if (numeroIndice > 0) {
            setNumeroIndice(numeroIndice - 1);
        }
    }

    /*
      SETTERS
    */
    
    const setMaxTiempoRecordarWrapper = () => {
        setMaxTiempo(niveles[nivelLocal][3]);
    }
    
    const setNumerosWrapper = () => {
        setCantidadDeNumeros(getCantidadDeNumerosCrudo(nivelLocal));
        setNumeroIndice(0);
        setNumeros(crearArrayDeNumeros(getCantidadDeNumerosCrudo(nivelLocal)));
    }

    const TiempoMemorizando = (tiempoMem:number) =>{
        if(etapa==2){
            setTiempo(tiempoMem);
        }
    }
    const guardaDigitos= (digitos:number) =>{
        console.log("🚀 ~ file: MemoriaNumeros.tsx:279 ~ guardaDigitos ~ digitos:", digitos)
        let dig = digitos
        if(digitos<1){
            dig=6;
            
        }else if(digitos>6){
            dig=1;
        }
        setDigitos(dig);
    }
    /*
      GETTERS
    */
    
    const getDescripcion = () => {
        return (
            <div className={[estiloJuegos.descripcionJuego,estiloJuegos.descripcionMemoriaNumeros].join(" ")}>
                ¡Estás en el Nivel {nivelLocal}!<br></br> Prepárate para memorizar {niveles[nivelLocal][1]} números en el siguiente minuto.
                
            </div>
        );
    }

    const getOpciones = () => {
        return (
            <div className={estiloJuegos.contenedorDigitos}>
                <div className={estiloJuegos.itemDigitos}>
                    <label>Dígitos A Mostrar</label>
                    
                </div>
                <div className={estiloJuegos.selectDigitos}>
                    <div onClick={()=> guardaDigitos(digitos+1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                            <path d="M17.0001 8.8849C17.0001 9.19734 16.8581 9.50768 16.5809 9.72837C16.0744 10.131 15.309 10.0801 14.8704 9.61462L8.47499 2.82126L2.13265 9.61147C1.69632 10.0786 0.930908 10.132 0.422722 9.73099C-0.0854646 9.32997 -0.143641 8.62646 0.292681 8.15939L7.55159 0.388363C7.78144 0.142504 8.11624 0.000440694 8.46929 -8.28687e-05C8.82234 -0.000607385 9.15771 0.139883 9.3887 0.385218L16.7041 8.15624C16.9026 8.36698 16.9995 8.62646 16.9995 8.8849L17.0001 8.8849Z" fill="#C0BCC2"/>
                        </svg>
                    </div>
                    <h1> <span className={estiloJuegos.degradadoDigitos} style={{fontSize:'inherit'}}>{digitos.toString()}</span></h1>
                    <div onClick={()=> guardaDigitos(digitos-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                            <path d="M-8.77247e-05 1.1151C-8.77285e-05 0.802661 0.14193 0.492323 0.419123 0.271627C0.925598 -0.130973 1.69101 -0.0801238 2.12962 0.385383L8.52501 7.17874L14.8674 0.388528C15.3037 -0.0785513 16.0691 -0.132022 16.5773 0.269006C17.0855 0.670033 17.1436 1.37354 16.7073 1.84061L9.44841 9.61164C9.21856 9.8575 8.88376 9.99956 8.53071 10.0001C8.17766 10.0006 7.84229 9.86012 7.6113 9.61478L0.295927 1.84376C0.0974426 1.63302 0.000482576 1.37354 0.000482573 1.1151L-8.77247e-05 1.1151Z" fill="#C0BCC2"/>
                        </svg>

                    </div>
                </div>
                
            </div>
        );
    }

    const getCantidadDeNumerosCrudo = (nuevoNivel: number) => {
        
        return niveles[nuevoNivel][1];
        
    }
    const getMensajes = () =>{
        
        let porcentaje = cantidadCorrecta/cantidadDeNumeros;
        let mensajePersonalizado =obtenerMensaje(porcentaje);
        if (porcentaje > 0) {
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p  className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                    <h3 style={{margin:"1vh 0 0 0"}}className = {estiloNeuronas.neuronas}>Ganaste {nuevasNeuronas} neuronas</h3>
                    <h3 className={estiloNeuronas.totalNeuronas}> TOTAL: {neuronas} NEURONAS</h3>
                </div>

            );
        }else{
            return(
                <div className={estiloNeuronas.mensajes}>
                    <p style={{marginBottom:"2vh"}} className={`${estiloNeuronas.mensajePersonalizado} ${estiloNeuronas.respuestasCorrectas}}`}>{mensajePersonalizado}</p>
                    
                </div>

            );
        }
            
        
    }

    const getCantidadDeNumeroGruposCrudo = (nuevoNivel: number) => {
        return Math.ceil(niveles[nuevoNivel][1] / digitos);
    }

    const getNumeroWrapper = (indice : number) => {
	    return numeros[indice];
    }

    const esSegundaEtapa = () => {
        return etapa === segundaEtapa;
    }

    const esTerceraEtapa = () => {
        return etapa === terceraEtapa;
    }

    
    const handlePlay = () => {
		initializarConfig();
		setEtapa(segundaEtapa);
	}

    const initializarConfig = () => {
        setMaxTiempo(niveles[nivelLocal][2]);
        setNumerosWrapper();
        setUserInputs(Array(getCantidadDeNumerosCrudo(nivelLocal)).fill(""));
        setInputRefs(Array(getCantidadDeNumerosCrudo(nivelLocal)).fill(null));
        setCalificaciones(Array(getCantidadDeNumerosCrudo(nivelLocal)).fill(false));
    }

    const construirTerceraPantalla = () => {
        setMaxTiempoRecordarWrapper();
        setTimeout(() => setEtapa(3), 10);
    }

    const construirCuartaPantalla = () => {
        checarRespuestas();
        setTimeout(() => setEtapa(4), 10);
    }

    const constructor = () => {

        getConfiguracionJuego(userId, nomProgMemNumeros).then((configuracion) => {
            setDigitos(configuracion.cantidadDigitos);
        })

        if(testId === test_general){
            getCalificacionGeneral(userId, nomProgMemNumeros).then((calificacion) => {
                console.log("🚀 ~ getCalificacionGeneral ~ calificacion:", calificacion)
                if(calificacion?.completado){
                    setNivelLocal(calificacion?.nivel+1);		
                    setNivelActualBD(calificacion?.nivel+1);		
                }else{
                    setNivelLocal(calificacion?.nivel??1);
                    setNivelActualBD(calificacion?.nivel??1);
                }            
            });            
        }else{
             /**  Carga el nivel 10 por defecto */        
             setNivelLocal(10);     
        }            
        setEtapa(primeraEtapa);           
    }

    useEffect(() => {
        constructor();
    }, []);

    async function handleBackButtonClick(): Promise<void> {
        if(esEntrenamiento && cantidadCorrecta>=parametro){
            actualizarRegistroEntrenamiento(userId,idEntrenamiento,indiceSesion+1,indiceActividad+1);
            dispatch(changeCondicion({condicion:cantidadCorrecta}))
            navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
        }else{
            navigate(-1);
        }
    }
    
    return (
        <div id='page'>
            {nivelLocal === 0 && <Loading isOpen={true}/>}

            <div className={etapa === 1 ? estiloJuegos.toolbarMemoriaNumeros : estiloJuegos.toolbar_default} style={etapa === 1?{}:{padding:'0'}}>
                <div className={etapa !== 1 ? estiloJuegos.contenedor_titulo_default : ''}>
                    {esJuego &&
                    <Link  className={estiloJuegos.backJuegos} to="/my/juegos/Memoria"></Link>}
                    <h1 className={`title ${estiloJuegos.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'1.25rem'} :{}}> {titulo} </h1>
                        
                </div>
            </div>

            {etapa === 1 && 
                <div className={estiloJuegos.contenedorIconoNumeros}>
                    <img src='/assets/componentes/Juegos/Numeral.svg' className={estiloJuegos.icono}></img>
                </div>
            }
            <div id='content_default'>
                {(etapa !== 1) &&
                    <Toolbar
                    esJuego={esJuego}
                    nivel={nivelLocal}
                    etapa={etapa}
                    handleTiempo={handleTiempo}
                    maxTiempo={maxTiempo}
                    setTiempoMemoria={TiempoMemorizando}/>
                }

                {etapa === 1 &&
                    
                    <div className={estiloJuegos.primeraPantalla}>
                           
                        {getDescripcion()}   

                        {/* {nivel !== 0 &&
                        <SelectorNivel type={1} setSelection={setNivelWrapper} selection={nivel} opciones={
							Array.from({ length: nivelActual }, (_, index) => ({name: index + 1,value: index + 1} as opcion))
						}/>} */}

                        {getOpciones()}
                        {<button className={estiloJuegos.buttonEmpezar} onClick={handlePlay}>
                            EMPEZAR
                        </button>}
                    </div>
                
                    
                }
                {etapa === 2 &&
                <div className={stylePal.segundaPantallaPalabras}>
                    <p className={stylePal.mensaje}>Memoriza los siguientes números </p>
                    <SegundaPantalla
                        digitos={digitos}
                        tituloPalabras="Numeros"
                        datoPrincipal={numeros[numeroIndice]}
                        resetContenido={resetContenido}
                        contenidoIdIndice={numeroIndice}
                        cantidadDeValores={cantidadDeNumeros}
                        leftSubmit={leftSubmit}
                        rightSubmit={rightSubmit}
                        aSiguientePantalla={construirTerceraPantalla}
                    />
                </div>
                }
                {etapa === 3 &&
                    <TerceraPantalla
                    esJuego={true}
                    aCuartaPantalla={construirCuartaPantalla}
                    userRespuestas={userInputs}
                    guardaInputs={guardaInput}
                    inputType='number'
                    longitudInput={numeros}
                    />
                }
                {etapa === 4 &&
                    <>
                        {/* <PopoverNeuronas nuevasNeuronas={nuevasNeuronas} inicioMensaje='Tuviste ' finMensaje='respuestas correctas' cantidadCorrecta={`${cantidadCorrecta}/${cantidadDeNumeros}`}/> */}
                    
                        <div className={style.contenido}>
                            {getMensajes()}
                            
                            <ResultadosNumPal
                            userInputs={userInputs}
                            calificaciones={calificaciones}
                            getPalabraWrapper={getNumeroWrapper}
                            game={titulo}
                            digitos={digitos}
                            tituloPalabras='Número'
                            height='45svh'
                            />
                            {cantidadCorrecta>0 ?
                                <div>
                                    <p className={style.textoTuviste}>Lograste Recordar</p>
                                    <p className={style.textoTuviste}><span className={style.resultado}>{`${cantidadCorrecta}/${cantidadDeNumeros}`}</span>  Números</p>
                                </div>
                                :<></>
                            }
                        </div>
                        <BotonesJuegos funcionReiniciar={()=>{setEtapa(primeraEtapa)}} funcionVolver={handleBackButtonClick} esJuego={esJuego}/>
                    </>
                }

            </div>
        </div>
    );
};

export default MemoriaNumeros;
