import {useNavigate } from 'react-router-dom'
const PoliticasPrivacidad: React.FC = () => {
    const navigate  = useNavigate();
    function salir()  {
        navigate(-1);
    }
    return(
    <div id='page' style={{zIndex:'1'}}>
        <div id='toolbar_default'>
            <h1 className="ion-text-center title">Politica de Privacidad</h1>
        </div>
            
        <div id='content_default' style={{overflow:'scroll'}}>
            <div style={{paddingLeft:"5%",paddingRight:"5%",color:"black"}}>
                <h1><strong>Política de Privacidad de SuperCerebros</strong></h1>
                <p>Fecha de última actualización: 01/06/2023</p>

                <h2><strong>1. Introducción</strong></h2>
                <p>En SUPERCEREBROS, valoramos tu privacidad. Esta Política de Privacidad tiene como objetivo informarte sobre cómo recopilamos, utilizamos, protegemos y divulgamos tu información personal. Nos adherimos a la Ley 1581 de 2012 de Colombia y al Reglamento General de Protección de Datos (GDPR) de la Unión Europea.</p>

                <h2><strong>2. Información que recopilamos</strong></h2>
                <p>Recopilamos y almacenamos la siguiente información:</p>
                <ul>
                    <li>Nombres</li>
                    <li>Correo electrónico</li>
                    <li>Fecha de nacimiento</li>
                    <li>Teléfono</li>
                </ul>

                <h2><strong>3. Uso de la información</strong></h2>
                <p>Usamos tu información para:</p>
                <ul>
                    <li>Crear y gestionar tu cuenta.</li>
                    <li>Comunicarnos contigo.</li>
                    <li>Mejorar nuestra aplicación y servicios.</li>
                </ul>
                <p>No compartimos, vendemos ni divulgamos tu información personal a terceros, excepto si la ley lo requiere.</p>

                <h2><strong>4. Tus derechos</strong></h2>
                <p>De acuerdo con el GDPR y la Ley 1581 de 2012, tienes el derecho a:</p>
                <ul>
                    <li>Acceder a tu información personal.</li>
                    <li>Rectificar datos inexactos o incompletos.</li>
                    <li>Solicitar la eliminación de tus datos.</li>
                    <li>Oponerte al tratamiento de tus datos.</li>
                    <li>Limitar el tratamiento de tus datos.</li>
                </ul>
                <p>Para ejercer cualquiera de estos derechos, ponte en contacto con nosotros en <a href="mailto:info@supercerebros.com">info@supercerebros.com</a>.</p>

                <h2><strong>5. Seguridad de los datos</strong></h2>
                <p>Nos comprometemos a proteger tu información personal. Utilizamos medidas de seguridad técnicas y organizativas apropiadas para proteger tus datos contra pérdidas, robos y accesos no autorizados.</p>

                <h2><strong>6. Cambios en la Política de Privacidad</strong></h2>
                <p>Podemos actualizar nuestra Política de Privacidad ocasionalmente. Publicaremos cualquier cambio en esta página y, si son significativos, te proporcionaremos un aviso más prominente.</p>

            </div>
            <button id='button_rosado_default' style={{maxWidth:'90%',margin:'2% 0'}} onClick={salir} >Ok</button>

        
        </div>
      </div>
    );
}
export default PoliticasPrivacidad;
