/** Externa */
import { useState } from 'react';

/** Interna */
import Button from '../../componentes/Button';
import ButtonRow from '../../componentes/ButtonRow';

import style from "../../../theme/programas/calculo/Game1.module.css";
import style2 from "../../../theme/Juego2.module.css";
import estiloJuegos from "../../../theme/Juegos.module.css";
import { buttons } from "../../../utils/Buttons";
import { useDato } from '../../../auth';
import { niveles, saltos } from '../../../datos/DatosSumas1';

import { nomTestSumas1 } from '../../../datos/nombresBD';
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { obtenerNeuronas } from '../../../utils/neuronasUtil';
import { initialNeuronas } from '../../../hooks/reducer/reducerAuth';
import PopoverNeuronas from '../componentes/PopoverNeuronas';
import BotonesJuegos from '../componentes/BotonesJuegos';

/**
 * Juego para sumar numeros
 */	
const TestSumas1 = () => {

	const userId = useAppSelector((state) => state.auth.userId);
	const testId = useAppSelector((state) => state.activity.id);
	const {				
		state,
	} = useDato();

	const {		
		guardarDatosCalificacion
    } = useJuegos();
	const dispatch = useAppDispatch();
	/**
	 * INICIALIZAR
	 */

	const titulo = "Sumas de 1 dígito";
    
	const [etapa, setEtapa] = useState(-1);
	const [nivel, setNivel] = useState(-1);
	const [tiempo, setTiempo] = useState<number>(0);
    const [intentos, setIntentos] = useState(8);
	const [aciertos, setAciertos] = useState<number>(0);
	const [numero, setNumero] = useState(-1);
	const [adivina, setAdivina] = useState("");
	const [suma, setSuma] = useState(-1);
	const [limiteDigitos, setLimiteDigitos] = useState(-1);
	const [maxTiempo, setMaxTiempo] = useState(-1);
	const [cantidadDeNumeros, setCantidadDeNumeros] = useState(-1);
	const [cantidadDeDigitos, setCantidadDeDigitos] = useState(-1);
	const [velocidad, setVelocidad] = useState<number>(-1);
    const [errores, setErrores] = useState(0);
	const [nivelMinimoCompletado, setNivelMinimoCompletado] = useState(0);
	const [nuevasNeuronas, setNuevasNeuronas] = useState<number>(0); 
	const neuronas = useAppSelector((state) => state.auth.neuronas);

	const [respuestaIncorrectaPrimeraVez,setRespuestaIncorrectaPrimeraVez] = useState(false);
	/**
	 * Navegacion atras
	 */
	const navigate = useNavigate();

	const handleBackButtonClick = () => {
		navigate(-1);
	};
	/**
	 * AYUDANTES
	 */

	const crearArrayDeNumeros = (cuenta: number) => {

		let arr: number[] = [];
		let i = 0;
		const max = Math.pow(10, cantidadDeDigitos) - 1;
		const min = Math.pow(10, cantidadDeDigitos - 1);

		let num = null;
		let numAnterior = 0;
		while (i !== cuenta) {
			num = randomNumBetween(min, max);

			if (num !== numAnterior) {
				arr.push(num);
				numAnterior = num;
				i++;
			}
		}
		return arr;
	}
	const TiempoMemorizando = (tiempoMem:number) =>{
        if(etapa==2){
            setTiempo(tiempoMem);
        }
    }
	const mostrarNumeros = (array: number[]) => {
		if (array.length) {
			setNumero(array[0]);
			setTimeout(() => mostrarNumeros(array.slice(1)), velocidad)
		} else {
			pedirRespuesta();
		}

	}

	const randomNumBetween = (min: number, max: number) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}
		

	/**
	 * GETTERS
	 */

	const iniciarJuego = () => {
		const numerosTemporal = crearArrayDeNumeros(cantidadDeNumeros);
		setSuma(numerosTemporal.reduce((a, b) => a + b, 0));
		mostrarNumeros(numerosTemporal);
	}

	const getComparacion = () => {
		return (
			<>
				<div className={adivina === "#" ? style.sin_input : ""}>
					<div className={style.resultado}>{adivina === '#' ? '' : adivina}</div>
				</div>
			</>
		);
	}

	const getResultado = () => {
		let correctaTemporal = getCorrecta();
		if (correctaTemporal) {
			return (
				<div >
					<div>
						<p className={style.textoRespuesta}>respuesta</p>
						<p className={style.correcta}>correcta</p>
					</div>
					<div className={style.numero_principal_respuesta} >
					{getComparacion()}
					</div>
					
				</div>
			);
		} else {
			if (!respuestaIncorrectaPrimeraVez) {
				setRespuestaIncorrectaPrimeraVez(true);
			}
			return (
				<div>
					<div>
						<p className={style.textoRespuesta}>respuesta</p>
						<p className={style.incorrecta}>incorrecta</p>
					</div>
					<div className={style.numero_principal_respuesta}>
						{getComparacion()}
					</div>			
				</div>
			);
		}
	}

	//  <IonIcon className={style.icon} slot="icon-only" icon={checkmarkCircle} />
	//  <IonIcon className={style.icon} slot="icon-only" icon={closeCircle} />

	const getCalculator = () => {
		return (

			<div className={estiloJuegos.calculadoraSumas1}>
				{buttons.map((buttonRow, index) => {

					return (
						<ButtonRow key={index}>
							{buttonRow.map(button => {

								return <Button key={button.value} test={true} value={button.value} special={button.special} send={button?.send} clickEvent={handleCalculator} />;
							})}
						</ButtonRow>
					);
				})}
			</div>

		);

	}

	const getToolbar = () => {
		return (
			<div className={style2.toolbar}>

				{/* {etapa === 2 && !state.administrador ? (<>
					<div style={{marginLeft:'5%',marginRight:'5%',display:'flex',flexDirection:'row',width:'50%'}}>Tiempo: <Tiempo func={enviarWrapper} limite={maxTiempo} etapa ={etapa} setTiempoMemoria={TiempoMemorizando} bandera={true} /></div>
				</>
			  ) : null} */}
			</div>
		);
	}

	/* 
	<div>Nivel: {nivel}</div>

	{etapa !== 2 ? (<>
		<div>Diferencias: {errores}</div>
	</>
	) : null}
	*/

	const checarRespuesta = () => {             
        var nuevoNivel, intentosLocal = intentos, erroresLocal = errores, adivinaLocal = adivina;	
		
		if(errores > 0){         
            intentosLocal = intentosLocal - 1;             
        }
		        
		if (getCorrecta()) {
			setAciertos(aciertos+1);
			setNivelMinimoCompletado(nivel);	
			if(intentosLocal > 0){
				nuevoNivel = obtenerNuevoNivel(true, intentosLocal);
			}	
		}else{
			if(adivina === "" || adivina === "#"){
				adivinaLocal = "0";
			}
				                       
            erroresLocal = erroresLocal + Math.abs(Number(adivinaLocal) - suma);  
			if(intentosLocal > 0){
				nuevoNivel = obtenerNuevoNivel(false, intentosLocal);
			} 
        }
        
		// setear valores para renderizar
		setIntentos(intentosLocal);
		setErrores(erroresLocal);
		
		if(intentosLocal > 0){
			setTimeout(() => setNivelWrapper((nuevoNivel)), 100);
		}else{			

			aQuintaPantalla();			
			guardarDatosCalificacion(userId, testId, nomTestSumas1, 100, nivel, erroresLocal, tiempo, true, state.nombres, state.pais);
			
		}	
			

	}

	const getCorrecta = () => {		
        return Number(adivina) === suma;
	}

    // Primer nivel 10, 20, 30 (saltos de a 10) hasta que se equivoque
    // saltos 10, 8, 6, 4, 3, 2, 1, 1
    const obtenerNuevoNivel = (aumento: boolean, intentosLocal: number) => {        
        
        let nuevoNivel: number = 1;
        
        if (aumento) { 
            nuevoNivel = nivel + saltos[intentosLocal-1]              
            if (nuevoNivel > niveles.length){
                nuevoNivel = niveles.length;
            }
        } else {
            nuevoNivel = nivel - saltos[intentosLocal-1];
            if (nuevoNivel < 1){
                nuevoNivel = 1;
            }
			if (nuevoNivel <= nivelMinimoCompletado){
				nuevoNivel = nivelMinimoCompletado + 1;
			}
        }
       
        
        return nuevoNivel;
    }

	
	const setMaxTiempoWrapper = (nuevoNivel: number) => {
		setMaxTiempo(niveles[nuevoNivel][5]);
	}

	const setCantidadDeNumerosWrapper = (nuevoNivel: number) => {
		setCantidadDeNumeros(niveles[nuevoNivel][2]);
	}

	/**
	 * funcion que se encarga de actualizar los datos de la partida y setear el nivel,
	 * @param nuevoNivel
	 * @param actualizaBD
	 * 		- true: actualiza la base de datos
	 * 		- false: no actualiza la base de datos
	*/
	const setNivelWrapper = (nuevoNivel: number) => {	
		setMaxTiempoWrapper(nuevoNivel);
		setCantidadDeNumerosWrapper(nuevoNivel);
		setCantidadDeDigitosWrapper(nuevoNivel);
		setVelocidadWrapper(nuevoNivel);
		setNivel(nuevoNivel);
	}

	const setLimiteDigitosWrapper = () => {
		setLimiteDigitos(String(9 * cantidadDeNumeros).length);
	}

	const setVelocidadWrapper = (nuevoNivel: number) => {
		setVelocidad(niveles[nuevoNivel][4]);
	}

	const setCantidadDeDigitosWrapper = (nuevoNivel: number) => {
		setCantidadDeDigitos(niveles[nuevoNivel][3]);
	}

	/*

  HANDLERS

  */

	const handlePlay = () => {
		reset();
		iniciarJuego();
		setEtapa(1);
	}

	const enviar = () => {
		setEtapa(3);
		checarRespuesta();
	}
	const aQuintaPantalla = () =>{
		// Obtener Neuronas
		const neuronasNuevas = obtenerNeuronas(nomTestSumas1, nivel,aciertos);
		
		setNuevasNeuronas(neuronasNuevas);
		const neuronasTotales = neuronas + neuronasNuevas;
		dispatch(initialNeuronas({cantidad:neuronasTotales}));
		setEtapa(4);
		

	}

	const enviarWrapper = () => {
		setTimeout(() => enviar(), 0);
	}

	const reset = () => {
		setAdivina("#");
	}

	const backspace = () => {
		setAdivina(adivina.slice(0, -1));
	}

	const handleCalculator = (e: React.SyntheticEvent, operator: string) => {
		if (operator === "Enviar") {
			enviar();
		} else if (operator === "C") {
			reset();
		} else if (operator === "Borrar") {
			backspace();
		} else {
			if (adivina.length < limiteDigitos) {
				if (adivina === "#") {
					setAdivina(operator)
				} else {
					setAdivina(adivina + operator);
				}
			}
		}
	}

	const pedirRespuesta = () => {
		setEtapa(2);
	}

	/*
	  CONSTRUCTOR
	  */

	const constructor = () => {
					
       
        setNivelWrapper(10);        		
        setEtapa(0);
        setAdivina("#");
        setCantidadDeDigitosWrapper(10);
        setLimiteDigitosWrapper();
        setVelocidadWrapper(10);
					
	}

	useState(constructor);

	const handleRedirect = ( ruta:string) => {
        navigate(ruta)
    }

	return (
		<div id='page'>
			<div className={etapa === 0 ? estiloJuegos.toolbarCalculoMatematico : estiloJuegos.toolbar_default}>
				<button onClick={() => handleRedirect("/my/testInicialPage")} slot={etapa === 0 ?'' :'start'}  className={estiloJuegos.backJuegos} style={etapa == 2 ?{top:'22px'}:{}}>
				</button>
				<h1 className={[etapa === 0 ? estiloJuegos.tituloJuegos : ''].join(" ")}  style={etapa !== 0 ? {fontSize:'2.37svh'} :{}}> {titulo} </h1>
				
			</div>
			{etapa === 0 && 
                <div className={estiloJuegos.contenedorIconoMatematico}>
                    <img src='/assets/componentes/Juegos/CÁLCULO MATEMÁTICO.svg' className={estiloJuegos.icono}></img>
                </div>
            }
			<div id='content_default'>

				{getToolbar()}
				{/* {etapa !== 0 && <ProgressBar tamaño={maxAciertos} posicion={aciertos}/>} */}
				{etapa === 0 &&
					
					<div className={estiloJuegos.primeraPantalla}>

						<div className={estiloJuegos.descripcionJuego}>
						¡Desafío de agilidad mental!<br></br> Suma en tiempo real cada número que aparezca y al final introduce el resultado total de la suma. 
						</div>  
						<button className={estiloJuegos.buttonEmpezar} onClick={handlePlay}>
							EMPEZAR
						</button>						               

					</div>
				}

					{/*
	PANTALLA 2
			*/}
				{etapa === 1 &&
					<div className={style.numero_principal} >
						{numero}
					</div>
				}
					{/*
	PANTALLA 3
			*/}

				{etapa === 2 &&

					<div className={[style.numero_principal_respuesta, style.calculator].join(" ")} >
						<div style={{height:'10vh'}}></div>
						<div className={adivina === "#" ? style.sin_input : ""} style={{height:'7rem', marginBottom:'2%',color: 'var(--medium-grey, #868189)'}}>
							{adivina === "#" ? '': adivina}
						</div>

						{getCalculator()}


					</div>
				}
					{/*
	PANTALLA 4
			*/}
				{etapa === 3 &&
					<div className={style.contenido_segundario} > 
						<div style={{height:'5vh'}}></div>
						<div >
							{getResultado()}

						</div>


						<button className={estiloJuegos.buttonEmpezar} style={{height:'auto'}} onClick={handlePlay}>Continuar</button>

						{!getCorrecta() && 
							<h2>Respuesta Correcta: {suma}</h2>
						}
						{respuestaIncorrectaPrimeraVez && 
						<h2>Te faltan {intentos} intentos</h2>}
					</div>

				}

					{/*
	PANTALLA 5
			*/}

				{etapa === 4 &&

					<div className={style.resultado}  style={{padding:'5vh'}}>
						<PopoverNeuronas nuevasNeuronas={nuevasNeuronas} mensajeInfo={`Llegaste al nivel ${nivel}`} nivel={nivel} funcionReiniciar={handlePlay} funcionVolver={handleBackButtonClick} esJuego={false}/>
											
						<br/>
						<h1 style={{marginBottom:'5vh'}}>¡Prueba completada con éxito!</h1>
						<h3 style={{marginBottom:'5vh'}}>Has alcanzado el nivel {nivel}</h3>
						<h4 style={{marginBottom:'5vh'}}>Tu mente es poderosa, y con cada desafío, se vuelve aún más ágil.</h4>
						

						<BotonesJuegos funcionReiniciar={handlePlay} funcionVolver={handleBackButtonClick} esJuego={false}/>
						<br/>
						
					</div>
					}

			</div>
		</div>

	);
};

// <h3>con un total de {errores} diferencias entre las respuestas dadas</h3>

export default TestSumas1;
