// import { IonContent, IonHeader, IonPage, IonToolbar, IonTitle, IonButton, IonLoading, IonLabel, IonIcon } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../store';
import ToolbarLectura from '../lectura/componentes/ToolbarLectura';
import RecorrerCuestionario from './componentes/RecorrerCuestionario';
import style from "../../../theme/Juego2.module.css";
import { useDato } from '../../../auth';
import { useJuegos } from '../../../hooks/controlador/useJuegos';
import { nomProgLecturaVelocidad } from '../../../datos/nombresBD';
import { useLocation, useNavigate } from 'react-router-dom';
// import { playCircleOutline } from 'ionicons/icons';


const Cuestionario = () => {
  const [loading, setLoading] = useState(false);
  const [posicion, setPosicion] = useState(0);
  const [count, setCount] = useState(0)
  const [flag, setFlag] = useState<Boolean>(false);
  const inicialValorNumero = 1;
  const navigate = useNavigate();
  const [tiempo, setTiempo] = useState<number>(0);
  const [tiempoMemorizado, setTiempoMemorizado] = useState<number>(0);
  const [etapa, setEtapa] = useState<number>(inicialValorNumero);
  const testId = useAppSelector((state) => state.activity.id);
  const consulta = useAppSelector((state) => state.cuestionario.preguntas)
  const tamaño = useAppSelector((state) => state.cuestionario.preguntas?.length)
  const userId = useAppSelector((state) => state.auth.userId);
  const valores = useAppSelector((state) => state.cuestionario.valor);

  const {
    guardarDatosCalificacion,
  } = useJuegos();

  const {
    state,
  } = useDato();


  const aSiguientePantalla = (id: number) => {
    console.log(tiempoMemorizado)
      setEtapa(id)
  }

  const aSegundaPantalla = () => {
    aSiguientePantalla(2);
 }

  const getDescripcion = () => {
    return (
        <div>
            <h2>Continúa con el cuestionario</h2> 
            <br/> <br/>
        </div>
    );
  }

  const handleBackButton = () => {
    navigate(-2);
  }
  
  useEffect(() => {
      setEtapa(inicialValorNumero)
      setTiempoMemorizado(0)
      setPosicion(0)
      setTiempo(0)
      setCount(0)
      setFlag(false);
  }, []);
  
  const subirCalificacion = async () => {
    setLoading(true)
    const cuenta = 100/tamaño * count;
    console.log("🚀 ~ file: Cuestionario.tsx:77 ~ subirCalificacion ~ cuenta:", cuenta)
    console.log("🚀 ~ file: Cuestionario.tsx:82 ~ subirCalificacion ~ tiempoMemorizado:", tiempoMemorizado)
    await  guardarDatosCalificacion(userId, testId, nomProgLecturaVelocidad, 0, null, valores[0],valores[1], true, state.nombres, state.pais,cuenta);
    setLoading(false)
  }

  const handleRedirect = ( ruta:string) => {
    navigate(ruta)
  }

  useEffect(() => {
    if(etapa == 3){
      subirCalificacion()
    }
  }, [etapa]);
  
  return (
    <></>
    // <IonPage>
    //     <IonLoading isOpen={loading}/>
    //     <IonHeader>
    //         <IonToolbar>
    //             <h1 className="ion-text-center title">Cuestionario </h1>
    //         </IonToolbar>
    //     </IonHeader>
    //     <IonContent className='ion-padding ion-text-center'>
    //         <ToolbarLectura
    //             flag={flag}
    //             aSiguientePantalla={aSiguientePantalla}
    //             etapa={etapa}
    //             setTiempoMemoria={setTiempoMemorizado}
    //         />
    //         {etapa === 1 &&
    //             <>
    //               <div className="ion-text-center" >
    //                 <IonButton fill="clear" className={style.btnPlay} onClick={aSegundaPantalla}>
    //                   <IonIcon className={style.playIcon} slot="icon-only" icon={playCircleOutline} />
    //                 </IonButton>
    //               </div>
                  
    //               {getDescripcion()}

    //               {testId == "test_general" &&<IonButton onClick={() =>handleRedirect(`/my/juegos/ranking`)}>Ranking</IonButton>}
    //             </>
    //         }
    //         {etapa === 2 &&
    //             <RecorrerCuestionario setFlag={setFlag} tamaño={tamaño} count={count} seleccion={consulta[posicion]} setPosicion={setPosicion} setCount={setCount} aSiguientePantalla={aSiguientePantalla} posicion={posicion} tiempoMemorizado={tiempoMemorizado}/>
    //         }
    //         {etapa === 3 &&
    //             <>
    //                 <IonLabel><h1>Obtuviste {100/tamaño * count} % de comprensión de lectura </h1></IonLabel>
    //                 <br></br>
                    
    //                 <br></br>
    //                 <IonButton onClick={handleBackButton}>Finalizar</IonButton>
    //             </>
    //         }

    //     </IonContent>
    // </IonPage>
  )
}

export default Cuestionario
