
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import style from '../../../theme/web/QuienesSomos.module.css'
import { IsMobile } from '../RoutesWeb';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useAppSelector } from '../../../store';

interface Props{
  mobile?:boolean;
  footPage?:any;
}

const NavbarWeb = ({mobile,footPage}:Props) => {

  const cartLength = useAppSelector((state) => state.shoppingCart.productos.length)

  const toggle = useRef<HTMLDivElement>(null);

  const HandleContact = () =>{
    footPage && footPage.current.scrollIntoView({behavior:'smooth',block:'center'});
    toggle && toggle.current.classList.remove("show");
  }

  return (
    <Navbar expand="lg"variant="dark" className={` ${style.nav}`}>
      <Container style={mobile ? {flexDirection:'row-reverse'}: {}}>
        <Navbar.Brand href="/web/home" className={`${style.link}`}>
          {mobile ? <img src='/assets/images/Logo-blanco.png' style={{height:'4svh'}}/> : 'SUPERCEREBROS'}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={`${style.divNav}`} ref={toggle}>
          <Nav >
            <Link to="/web/home" className={`${style.link} nav-link`}>INICIO</Link>
            {/* <Nav.Link  href="/web/home" className={`${style.link}`}>INICIO</Nav.Link> */}
            <Link to="/web/quienesSomos" className={`${style.link} nav-link`}>¿QUIÉNES SOMOS?</Link>
            {<Link to={IsMobile() ? "/web/home/servicios" :"/web/servicios"} className={`${style.link} nav-link`}>SERVICIOS</Link>}
            <Link to="/web/tienda" className={`${style.link} nav-link`}>TIENDA ONLINE</Link>
            <Link to="#" onClick={HandleContact} className={`${style.link} nav-link`}>CONTACTENOS</Link>
            {cartLength && <Link to="#" className={`${style.link} nav-link`}>Cart</Link>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarWeb
