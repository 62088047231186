import { DocumentSnapshot } from "firebase/firestore";
import { useBaseDeDatos } from "../baseDeDatos/useBaseDeDatos";
import { nomPalabraAsociacionIngles, nomPalabras } from "../../datos/nombresBD";
import { Asociacion, aAsociacion, toAsociacion } from "../../modelos/AsocionTraduccion";
import { tr } from "date-fns/locale";
import { devNull } from "os";

export const usePalabras = () => {

    /**
     * Funciones para acceder a la base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,
        recogerDoc,
        recogerDocs,
    } = useBaseDeDatos();

    const VerificarExistencia = async(palabra:string):Promise<string> => {
        var Doc : DocumentSnapshot;
        Doc = await recogerDoc(nomPalabras + "/" + palabra);
        if(Doc.exists()){
            return Doc.data().ingles
        }
        return null
    }

    const TraerAsociaciones = async(palabra:string) => {
        var Docs : DocumentSnapshot[] = [];
        var retorno: Asociacion[] = [];
        Docs = await recogerDocs(nomPalabras + "/" + palabra + "/" + nomPalabraAsociacionIngles);
        for(const i of Docs){
            let ins = toAsociacion(i)
            retorno.push(ins)
        }
        return retorno
    }

    const VerificarPalabraParecida = async(palabra:string,parecido:string):Promise<Asociacion> => {
        let word: Asociacion = null;
        var Doc : DocumentSnapshot;

        Doc = await recogerDoc(nomPalabras + "/" + palabra + "/" + nomPalabraAsociacionIngles + "/" + parecido);

        if(Doc.exists()){
            word = toAsociacion(Doc)
            return word
        }else{
            return word
        }
    }

    const CrearPalabra = async(palabra:string,traduccion:string,asociacion:Asociacion):Promise<void> => {
        await agregarDoc(nomPalabras + "/" + palabra,{ingles:traduccion});
        await agregarDoc(nomPalabras + "/" + palabra + "/" + nomPalabraAsociacionIngles + "/" + asociacion.parecido,asociacion);
    }

    const CrearAsociacion = async(palabra:string,parecido:string,asociacion:string):Promise<void> => {
        await agregarDoc(nomPalabras + "/" + palabra + "/" + nomPalabraAsociacionIngles + "/" + parecido,{frase:asociacion});
    }

    const AgregarImgUrl = async(ruta:string,urlFoto:string) => {
        var doc = await recogerDoc(ruta)
        const asociacion = toAsociacion(doc)
        asociacion.urlFoto = urlFoto
        agregarDoc(ruta,asociacion)
    }
    
    return {
        VerificarExistencia,
        VerificarPalabraParecida,
        CrearPalabra,
        crearAsociacion: CrearAsociacion,
        TraerAsociaciones,
        AgregarImgUrl,
    }
}
