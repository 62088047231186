import React, { useRef, useState } from 'react'
import styleServicios from '../../theme/web/Servicios.module.css'
import style from '../../theme/web/QuienesSomos.module.css'
import NavbarWeb from './componentes/NavbarWeb'
import VideoPlayerIOs from '../entrenamiento/componentes/VideoPlayerIOs'
import Selector from '../entrenamiento/componentes/Selector'
import { paises } from '../../datos/paises'
import { useToast } from '../../hooks/useToast'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../firebase'
import FooterWebPage from './componentes/FooterWebPage'
import Contactanos from './componentes/Contactanos'

const QuienesSomosMobile = () => {
  const entorno = (process.env.REACT_APP_ENVIRONMENT === 'production')? "produccion": "desarrollo";
  const [loading, setLoading] = useState(false)
  const footPage = useRef(null)
  
  return (
    <div className={style.page}>
        <NavbarWeb mobile={true} footPage={footPage}/>
        <div className={style.fondoMorado}>
            <h1 style={{textAlign:'center'}}>¿Quienes somos?</h1>
        </div>
        <div style={{position:'relative'}}>
            <img src='/assets/componentes/web/CurvaMorada.png' style={{transform:'rotate(180deg)',width:'153%',marginTop:'-2px',position:'absolute',zIndex:'1'}}></img>
            <VideoPlayerIOs videoUrl={`https://firebasestorage.googleapis.com/v0/b/supercerebros-${entorno}.appspot.com/o/publico%2Fneurobics%2Fventa_neurobics.mp4?alt=media`}/>
        </div>
        <p className={style.descripcion}>
            Somos Supercerebros, una empresa líder en entrenamiento mental dedicada a potenciar las capacidades
            cognitivas de atención, concentración y memoria. Nuestro enfoque se centra en fortalecer la salud mental
            y la agilidad intelectual, abriendo caminos para la creatividad y la imaginación. Con una vasta experiencia
            en el campo, nos comprometemos con la excelencia y transformamos vidas al enseñar técnicas efectivas que
            permiten a estudiantes, trabajadores, retirados y cualquier persona interesada mejorar sus capacidades mentales.            
        </p>
        <h1 className={style.purpleText} style={{textAlign:'center'}}>Juan Pablo Duque</h1>
        <div className={`${style.fotoJuan}`}>
            <img src='/assets/images/web/quienesJuanPablo.png' alt='Foto 4'></img>
        </div>
        <p className={style.descripcion}>
            Es un Mente-atleta colombiano, conferencista internacional y experto en
            neuro-entrenamiento. Fue semifinalista del programa SUPERCEREBROS de NatGeo,
            seleccionado entre más de 2000 participantes, e integrante de la selección 
            Colombia de Memoria y Cálculo Mental, logrando la posición 11 en el mundial 
            de Las Vegas 2016. Instructor latinoamericano de Mapas Mentales, En el año 2016
            rompio el récord sudamericano de memoria en la modalidad número PI con 1,111 aproximaciones.
            Con más de 7 años de experiencia, ha participado en eventos académicos y profesionales en Colombia 
            y Latinoamérica, destacándose en aprendizaje, memoria y comunicación.            
        </p>
        <h1 className={style.purpleText} style={{textAlign:'center'}}>David Cantor</h1>
        <div className={`${style.fotoDavid}`}>
            <img src='/assets/images/web/quienesDavidCantor.png' alt='Foto 3' ></img>
        </div>
        <p className={style.descripcion}>
            Es un emprendedor colombiano especializado en Entrenamiento Mental que busca mejorar procesos
            de atención, concentración, memoria, lectura rápida y gimnasia cerebral. Cofundador de SuperCerebros,
            es el entrenador mental más joven de Sudamérica. Integra la selección Colombia de Memoria y Cálculo
            Mental, logrando la posición #12 en el ranking internacional en el mundial de Las Vegas, EE.UU.
            Además, es un conferencista reconocido mundialmente en Neuroentrenamiento, Psicoestimulación,
            Motivación juvenil y Esfuerzo sostenido. 
        </p>
        <h1 className={style.purpleText} style={{textAlign:'center'}}>SuperCerebro</h1>
        <div className={`${style.scNice}`}>
            <img src='/assets/images/supercerebro/sc_nice2.png' alt='Foto 3' ></img>
        </div>
        <p className={style.descripcion}>
            Bro, más conocido como SuperCerebro, fue creado para enfrentar un mundo abrumado por la
            información y el estrés diario. Tiene la misión de combatir las amenazas al bienestar mental como el
            Alzheimer, la amnesia, y especialmente, los desafíos de la desatención y la falta de concentración.
        </p>
        <Contactanos setLoading={setLoading}/>
        <img src='/assets/componentes/web/CurvaMorada.png' style={{width:'100%',marginBottom:'-2px'}} ref={footPage}></img>
        <FooterWebPage/>
    </div>
  )
}

export default QuienesSomosMobile