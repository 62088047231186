import { DocumentSnapshot, DocumentData } from "firebase/firestore";
export interface ConfiguracionJuego {
    /** Fecha y hora de uso del programa */
    cantidadDigitos :number;
}

export const aConfiguracion = (cantidadDigitos :number) : ConfiguracionJuego  => {
    return {
        cantidadDigitos       
    } as ConfiguracionJuego ;        
}

type FireBaseDocument = DocumentSnapshot<DocumentData>
export const toConfiguracion = (doc : FireBaseDocument) : ConfiguracionJuego => {
    return doc.data() as ConfiguracionJuego ;
}
