import React, { useEffect, useRef, useState } from 'react'
import NavbarWeb from './componentes/NavbarWeb'
import styleQuienesSomos from '../../theme/web/QuienesSomos.module.css'
import style from '../../theme/web/Servicios.module.css'
import ShowServicios from './componentes/ShowServicios'
import ConferenciasServicios from './componentes/ConferenciasServicios'
import TallerServicios from './componentes/TallerServicios'
import IconOutlineX from './componentes/IconOutlineX'
import IconOutlineLinkedin from './componentes/IconOutlineLinkedin'
import IconOutlineFacebook from './componentes/IconOutlineFacebook'
import IconOutlineYoutube from './componentes/IconOutlineYoutube'
import IconOutlineInstagram from './componentes/IconOutlineInstagram'
import Selector from '../entrenamiento/componentes/Selector'
import VideoPlayerIOs from '../entrenamiento/componentes/VideoPlayerIOs'
import { paises } from '../../datos/paises'
import Loading from '../entrenamiento/componentes/Loading'
import { useToast } from '../../hooks/useToast'
import { auth, functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Contactanos from './componentes/Contactanos'
import FooterWebPage from './componentes/FooterWebPage'
import { useAppDispatch } from '../../store'
import { addProductoCarrito } from '../../hooks/reducer/reducerShoppingCart'


type Params = {
	Section:string;
}

const Servicios = () => {
    const dispatch = useAppDispatch()
	const {Section} = useParams<Params>();
	const conferencia = useRef<HTMLDivElement>(null);
	const taller = useRef<HTMLDivElement>(null);
	const show = useRef<HTMLDivElement>(null);
    const footPage = useRef(null)

    const [loading, setLoading] = useState(false)

    const entorno = (process.env.REACT_APP_ENVIRONMENT === 'production')? "produccion": "desarrollo";

    

    const IMAGENES = [
        "/img/poster.png",
        "/img/poster.png",
        "/img/poster.png",
        "/img/poster.png",
        "/img/poster.png",
        "/img/poster.png"
      ]


	useEffect(() => {
        // dispatch(addProductoCarrito({}))
		console.log("🚀 ~ section:", Section);
        const config:ScrollIntoViewOptions = { block: "center", behavior: "smooth" };
        if(Section){
            switch(Section){
                case 'taller':
                    taller.current.scrollIntoView(config);
                    break
                case 'conferencias':
                    conferencia.current.scrollIntoView(config);
                    break;
                case 'show':
                    show.current.scrollIntoView(config);
                    break;
                default:
                    break
            }
        }
	}, [])

    return (
        <div className={style.page}>
            <Loading isOpen={loading}/>
            <NavbarWeb footPage={footPage}></NavbarWeb>
            <div className={`${style.fondoMorado} ${style.wrap}`} style={{marginTop:'5svh'}} >
                <h1>Servicios</h1>
                <div className={style.show}>
                    <img src='/assets/componentes/web/show_servicios.svg' style={{width:'70%',maxHeight:'25svh'}}></img>
                    <h2>Show</h2>
                    <p>
                        El "Show de Juegos y Desafíos Mentales" fusiona entretenimiento y aprendizaje para potenciar las
                        habilidades cognitivas del equipo. Comienza en el "Gimnasio Mental", con estaciones interactivas que
                        estimulan diferentes regiones de nuestro cerebro, seguido de un espectáculo de 90 minutos diseñado 
                        para desafiar y enriquecer la capacidad mental de los participantes.
                    </p>
                    <button id='button_rosado_default' onClick={() => {show.current.scrollIntoView({behavior: "smooth", block: "center"})}}>MÁS INFORMACIÓN</button>
                </div>
                <div className={style.show}>
                    <img src='/assets/componentes/web/conferencias_servicios.svg' style={{width:'70%',maxHeight:'25svh'}}></img>
                    <h2>Conferencias</h2>
                    <p>
                        La conferencia "Descubre tu Potencial" potencia las capacidades mentales individuales mediante el 
                        entrenamiento mental. Motiva a los asistentes a descubrir su supercerebro y supermemoria, ofreciendo 
                        ejercicios prácticos para mejorar atención, concentración, memoria, creatividad e imaginación. 
                        Proporciona estrategias aplicables tanto en la parte personal, académica y profesional.
                    </p>
                    <button id='button_rosado_default' onClick={() => {conferencia.current.scrollIntoView({behavior: "smooth", block: "center"})}}>MÁS INFORMACIÓN</button>
                </div>
                <div className={style.show}>
                    <img src='/assets/componentes/web/talleres_servicios.svg' style={{width:'70%',maxHeight:'25svh'}}></img>
                    <h2>Neuro-talleres</h2>
                    <p>
                        Hemos diseñado una serie de Neurotalleres exclusivos que buscan fomentar el desarrollo profesional y
                        personal de sus equipos. A través de técnicas avanzadas de neuro-entrenamiento, nuestros talleres están
                        diseñados para maximizar el rendimiento cognitivo, mejorar habilidades específicas y promover una cultura 
                        de mejora continua en el ambiente laboral.
                    </p>
                    <button id='button_rosado_default' onClick={() => {taller.current.scrollIntoView({behavior: "smooth", block: "center"})}}>MÁS INFORMACIÓN</button>
                </div>
            </div>
            <Show IMAGENES={IMAGENES} show={show} entorno={entorno}/>
            <div style={{width:'100%'}} className={style.lineaBack}>
                <div className={style.linea}></div>
            </div>
            <Conferencias IMAGENES={IMAGENES} conferencia={conferencia} entorno={entorno}/>
            <div style={{width:'100%'}} className={`${style.lineaBack} ${style.linea_izq}`}>
                <div className={style.linea}></div>
            </div>
            <NeuroTaller IMAGENES={IMAGENES} taller={taller} entorno={entorno}/>
            <Contactanos setLoading={setLoading}/>
            <img src='/assets/componentes/web/CurvaMorada.png' ref={footPage}></img>
            <FooterWebPage></FooterWebPage>
        </div>
    )
}

const Show = ({IMAGENES,show,entorno}) => {
    return(
        <>
            <div style={{padding:'7%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexWrap:'wrap',paddingLeft: '22%',gap:'10%'}} ref={show}>
                <p className={style.textDescipcion}>
                    <span>SHOW SUPERCEREBROS</span><br></br>
                    El "Show de Juegos y Desafíos Mentales" es una experiencia única que combina entretenimiento y aprendizaje para fortalecer
                    las habilidades cognitivas de tu equipo. Comienza con el "Gimnasio Mental", una zona interactiva con cuatro estaciones que
                    estimulan distintos lóbulos cerebrales. Tras esta estimulante preparación, los participantes disfrutarán de un espectáculo de
                    90 minutos en el auditorio, diseñado para desafiar y enriquecer su capacidad mental.
                </p>
                <ShowServicios color='var(--color-purple-dark)' style={{height:'100%',width:'auto',maxWidth:'30%'}}/>
            </div>
            <div style={{display:'flex',flexDirection:'row',width:'100%',alignSelf:'center',flexWrap:'wrap',justifyContent:'space-around',gap:'10px',padding:'5%'}}>
                <p className={`${style.titleAlone} ${style.backGaleria}`}>GALERÍA</p>
                <Galeria IMAGENES={IMAGENES}/>
                <div style={{width:'40%',minWidth:'300px'}}>
                    <p className={style.titleAlone} style={{textAlign:'start'}}>TESTIMONIOS</p>
                    <div style={{width:'100%'}}>
                        <VideoPlayerIOs videoUrl={`https://firebasestorage.googleapis.com/v0/b/supercerebros-${entorno}.appspot.com/o/publico%2Fneurobics%2Ftestimonio1.mp4?alt=media`} autoplay={false}/>
                    </div>
                </div>
                <div style={{width:'40%',minWidth:'300px'}}>
                    <p className={style.titleAlone} style={{textAlign:'start'}}>EVENTO</p>
                    <img src='/img/poster.png' alt='testimonio' style={{width:'100%'}}></img>
                    <img src='/assets/images/supercerebro/SuperCerebroChatDePie.png' style={{width:'70%',top: '-10%',position: 'relative',left: '41%'}}></img>
                </div>
            </div>
        </>
    )
}

const Conferencias = ({IMAGENES,conferencia,entorno}) => {
    return(
        <>
            <div style={{padding:'7%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexWrap:'wrap',gap:'10%'}} ref={conferencia}>
                <p className={style.textDescipcion} style={{flex:'1'}}>
                    <span>CONFERENCIAS</span><br></br>
                    La conferencia "Descubre tu Potencial" es una experiencia transformadora diseñada para revelar y potenciar las capacidades
                    mentales innatas de cada individuo. esta sesión se centra en la motivación hacia el entrenamiento mental y en demostrar que
                    todos poseemos un supercerebro y una supermemoria. A través de una combinación de ejercicios prácticos, los asistentes 
                    descubrirán herramientas sencillas pero efectivas para entrenar su mente. Aprenderán a mejorar su atención, concentración,
                    memoria, creatividad e imaginación, y se llevarán estrategias concretas para aplicar en su vida diaria y laboral.
                </p>
                <ConferenciasServicios color='var(--color-purple-dark)' style={{height:'100%',width:'auto',maxWidth:'30%'}}/>
            </div>
            <div style={{display:'flex',flexDirection:'row',width:'100%',alignSelf:'center',flexWrap:'wrap',justifyContent:'space-around',gap:'10px',padding:'5%'}}>
                <p className={style.titleAlone}>GALERÍA</p>
                <Galeria IMAGENES={IMAGENES}/>
                <div style={{width:'40%',minWidth:'300px'}} className={style.backTestimonio}>
                    <p className={style.titleAlone} style={{textAlign:'start'}}>TESTIMONIOS</p>
                    <div style={{width:'100%'}}>
                        <VideoPlayerIOs videoUrl={`https://firebasestorage.googleapis.com/v0/b/supercerebros-${entorno}.appspot.com/o/publico%2Fneurobics%2Ftestimonio1.mp4?alt=media`} autoplay={false}/>
                    </div>
                </div>
                <div style={{width:'40%',minWidth:'300px'}}>
                    <p className={style.titleAlone} style={{textAlign:'start'}}>EVENTO</p>
                    <img src='/img/poster.png' alt='testimonio' style={{width:'100%'}}></img>
                    <img src='/assets/images/supercerebro/SuperCerebroChatDePie.png' style={{width:'70%',top: '-10%',position: 'relative',left: '41%'}}></img>
                </div>
            </div>
        </>
    )
}

const NeuroTaller = ({IMAGENES,taller,entorno}) => {
    
    return(
        <>
            <div style={{padding:'7%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexWrap:'wrap',gap:'10%'}} ref={taller}>
                <p className={style.textDescipcion} style={{flex:'1'}}>
                    <span>NEURO - TALLERES</span><br></br>
                    En Supercerebros, creemos en el poder de la mente para superar límites y alcanzar la excelencia. Es por esto
                    que hemos diseñado una serie de Neurotalleres exclusivos, orientados a empresas que buscan fomentar el desarrollo
                    profesional y personal de sus equipos. A través de técnicas avanzadas de neuro-entrenamiento, nuestros talleres 
                    están diseñados para maximizar el rendimiento cognitivo, mejorar habilidades específicas y promover una cultura 
                    de mejora continua en el ambiente laboral.
                </p>
                <TallerServicios color='var(--color-purple-dark)' style={{height:'100%',width:'auto',maxWidth:'30%'}}/>
            </div>
            <div style={{display:'flex',flexDirection:'row',width:'100%',alignSelf:'center',flexWrap:'wrap',justifyContent:'space-around',gap:'10px',padding:'5%'}}>
                <p className={`${style.titleAlone} ${style.backGaleria}`}>GALERÍA</p>
                <Galeria IMAGENES={IMAGENES}/>
                <div style={{width:'40%',minWidth:'300px'}} className={style.backTestimonio_final}>
                    <p className={style.titleAlone} style={{textAlign:'start'}}>TESTIMONIOS</p>
                    <div style={{width:'100%'}}>
                        <VideoPlayerIOs videoUrl={`https://firebasestorage.googleapis.com/v0/b/supercerebros-${entorno}.appspot.com/o/publico%2Fneurobics%2Ftestimonio1.mp4?alt=media`} autoplay={false}/>
                    </div>
                </div>
                <div style={{width:'40%',minWidth:'300px'}}>
                    <p className={style.titleAlone} style={{textAlign:'start'}}>EVENTO</p>
                    <img src='/img/poster.png' alt='testimonio' style={{width:'100%'}}></img>
                    <img src='/assets/images/supercerebro/SuperCerebroChatDePie.png' style={{width:'70%',top: '-10%',position: 'relative',left: '41%'}}></img>
                </div>
            </div>
        </>
    )
}

const Galeria = ({IMAGENES}) => {
    return(
        <Swiper
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        navigation={true}
        modules={[ Autoplay, Navigation]}
        className={style.mySwiper}
        >
        {IMAGENES.map((img) => 
            <SwiperSlide style={{width:'100%',aspectRatio:'1 / 1',height:'auto',maxHeight:'20svh'}}>
                <img
                    src={img}
                    loading="lazy"
                />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
        )}
        </Swiper>

    )
}
export default Servicios