

//| Nivel | 
// Cantidad de numeros | 
// Tiempo para memorizar (segundos) | 
// tiempo para responder (segundos) |
const niveles = [[0, 0, 0, 0],
[1, 2, 60, 60],
[2, 4, 60, 120],
[3, 6, 60, 180],
[4, 8, 60, 240],
[5, 10, 60, 240],
[6, 12, 60, 240],
[7, 14, 60, 240],
[8, 16, 60, 240],
[9, 18, 60, 240],
[10, 20, 60, 300],
[11, 22, 60, 300],
[12, 24, 60, 300],
[13, 26, 60, 300],
[14, 28, 60, 300],
[15, 30, 60, 300],
[16, 32, 60, 300],
[17, 34, 60, 300],
[18, 36, 60, 300],
[19, 38, 60, 300],
[20, 40, 60, 300],
[21, 42, 60, 300],
[22, 44, 60, 300],
[23, 46, 60, 300],
[24, 48, 60, 300],
[25, 50, 60, 300],
[26, 52, 60, 300],
[27, 54, 60, 300],
[28, 56, 60, 300],
[29, 58, 60, 300],
[30, 60, 60, 300],
[31, 62, 60, 300],
[32, 64, 60, 300],
[33, 66, 60, 300],
[34, 68, 60, 300],
[35, 70, 60, 300],
[36, 72, 60, 300],
[37, 74, 60, 300],
[38, 76, 60, 300],
[39, 78, 60, 300],
[40, 80, 60, 300]];

export const calificacionNumeros = (nroNivel, respuesta) => {
    const respuestaTotal = niveles[nroNivel][1];
    return Math.floor((respuesta * 10) / respuestaTotal);
}

// titulo del juego
const titulo = "Memorización de números"
// nombre en la base de datos
const nombreBaseDatos = "Memoria_Numeros"

export { niveles, titulo, nombreBaseDatos };