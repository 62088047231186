import { Link } from 'react-router-dom';
import { changePrograma, newActivity } from '../../hooks/reducer/reducerActividad';
import { useAppDispatch } from '../../store';
import { test_general } from '../../datos/DatosTests';
import {nomProgMemNombres, nomProgMemNumeros, nomProgMemPalabras,nomProgSuperCerebrosDice, nomProgParejas } from '../../datos/nombresBD';


/* importaciones para css*/
import "../../theme/tiposJuegos.css";
import estiloJuegos from "../../theme/Juegos.module.css";

const Memoria = () => {
    const dispatch = useAppDispatch();
    const inicializarTestJuegos = () =>{
        dispatch(newActivity({id:test_general, name:'Juegos'}))
    }
    function InicializarJuegos (name) {
        inicializarTestJuegos();
        dispatch(changePrograma({id:name}))
    }

    return (
        <div id='page'>
            <div className='toolbar_tipos_juegos'>
                <div className='contenedor_titulo'>
                    <Link  className={estiloJuegos.backJuegos} to=".."></Link>
                    <h1 className={`ion-text-center title ${estiloJuegos.tituloJuegos}`}>
                        MEMORIA
                    </h1>
                </div>
            </div>
            <div id='content_default'>
                <div className='contenedor_cards_memoria'>
                    <div className='contenedor_grid'>
                        <Link to='SuperCerebrosDice' onClick={() =>InicializarJuegos(nomProgSuperCerebrosDice)} className='card_sc_dice'>
                            <h3>Supercerebros dice</h3>
                        </Link>
                        <Link to='Nombres' onClick={() =>InicializarJuegos(nomProgMemNombres)} className='card_memoria_nombres'>
                            <h3>MEMORIA <br /> DE NOMBRES</h3>
                        </Link>
                        <Link to='Palabras' onClick={() =>InicializarJuegos(nomProgMemPalabras)} className='card_memoria_palabra'>
                            <h3>memoria <br />de PALABRAS</h3>
                        </Link>
                        <Link to='Parejas' onClick={() => InicializarJuegos(nomProgParejas)} className='card_parejas'>
                            <h3>Parejas</h3>
                        </Link>

                    </div>
                </div>

            </div>
        </div>
    );
};

{/* <IonCard routerLink="/my/MemoriaNumeros" onClick={() => {
    inicializarTestJuegos();
            dispatch(changePrograma({id:nomProgMemNumeros}))
        }} className='card-juego-button'>
    <IonIcon icon={tennisballOutline} slot="start" />
    <IonLabel>Memorizar números</IonLabel>
        <img src='/assets/icon/memoria_num_icon.png' className='contenedor_imagen_icon'/>

</IonCard> */}
export default Memoria;
