// import { IonButton, IonHeader, IonIcon, IonLabel, IonToolbar } from "@ionic/react";
import { useEffect, useState } from "react";
import { changeCondicion, changePrograma, newTorneo } from "../../hooks/reducer/reducerActividad";
import { useAppDispatch, useAppSelector } from "../../store";
import { Programa } from "../../modelos/Programa";
import { useJuegos } from "../../hooks/controlador/useJuegos";

import { useTests } from "../../hooks/controlador/useTests";
import { useDato } from "../../auth";
import { modTestInicial } from "../../hooks/reducer/reducerAuth";
import { useLocation, useNavigate} from "react-router-dom";
// estilos de diseño
import style from "../../theme/testInicial.module.css"
import estiloJuegos from "../../theme/Juegos.module.css";
// import {chevronBackOutline } from "ionicons/icons";
import { useEntidades } from "../../hooks/controlador/useEntidades";
import { aIntegrante } from "../../modelos/entidad/IntegranteEntidad";
import { nombre_test_inicial, test_inicial } from "../../datos/DatosTests";
import { useParams } from "react-router-dom";
import Loading from "../entrenamiento/componentes/Loading";
import { Link } from "react-router-dom";


type Params = {
    idEntidad: string;
}

const TestInicialPage:React.FC = () => {
    const {
        state,
    } = useDato();

    const {
        crearIntegranteEntidad
    } = useEntidades();

  const {
    consultarProgramasTest,
    consultarRutaPrograma,     
    completarTestInicialUsuario, 
  } = useTests();

  const location = useLocation();
  
  const {
    getCalificacion,
  } = useJuegos();

  const {idEntidad} = useParams<Params>();
  const [programasFinalizados, setProgramasFinalizados] = useState<Programa[]>([])
  const [programasFaltantes, setProgramasFaltantes] = useState<Programa[]>([])
  const [loading,setLoading] = useState(false);
  const [firstTime,setFirstTime] = useState(true);
  const [show,setShow] = useState(false);
  const [complete,setComplete] = useState(true);
  const userId = useAppSelector((state) => state.auth.userId);
  const testId = useAppSelector((state) => state.activity.id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

    const consulta = async () => {
        setShow(false)
        setLoading(true)
        dispatch(newTorneo({ id: test_inicial, name: test_inicial, torneo:false}))
        const respuesta = await consultarProgramasTest(test_inicial);
        console.log("🚀 ~ consulta ~ respuesta:", respuesta)
        setFirstTime(true)
        setComplete(true)        
        await Promise.all(respuesta.map(async (element) => {
            setProgramasFinalizados([]);
            setProgramasFaltantes([]);
            const programa = await consultarRutaPrograma(element.id);
            const respuesta = await getCalificacion(userId, test_inicial, element.id);
            if (respuesta) {
                setFirstTime(false);
                programa.ruta = `/my/ResultsPage/${programa.nombre}`
                setProgramasFinalizados((prevState) => [
                    ...prevState,
                    programa
                ]);
            } else {
                setComplete(false)
                setProgramasFaltantes((prevState) => [
                    ...prevState,
                    programa
                ]);
            }
        }));    
        setLoading(false)
        setShow(true)
    }
    
    useEffect(() => {
        consulta();
    }, []);

    const handleReduxCompletado = (complete: boolean, firstTime:boolean) =>{
        if( complete && !firstTime ){
            dispatch(modTestInicial({condicion:true}))
        }
    }

    const handleReduxProgram = (id:string, ruta:string) => {
        dispatch(changePrograma({id}));
        navigate(ruta);
    }

    const guardarTestTrue = () =>{
        completarTestInicialUsuario(userId);
        dispatch(modTestInicial({condicion:true}));
        return(
            <></>
        )
    }

    const currentEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento)
    const idSesion = useAppSelector((state) => state.entrenamiento.current_entrenamiento.sesiones[state.entrenamiento.current_sesion].id)
    const volver = async() =>{
        await dispatch(changeCondicion({condicion:10}))
        navigate(`/my/entrenamiento/${currentEntrenamiento?.id}/${idSesion}/${idEntidad}`);
    }

    const handleRedirect = ( ruta:string) => {
        navigate(ruta)
    }

    return(
        <div id='page'>
            <Loading isOpen={loading}/>
            <div className='toolbar_tipos_juegos'>
                <div className='contenedor_titulo'>
                    <div  onClick={volver} className={estiloJuegos.backJuegos} ></div>
                    <h1 className={`ion-text-center title ${estiloJuegos.tituloJuegos}`}>
                        Test Inicial
                    </h1>
                </div>
            </div>
            {show ? <div id='content_default' style={{marginTop:'0'}}>
                <div className="contenedor_cards_juegos">
                    {complete &&  
                        <>
                            {guardarTestTrue()}
                            <p className={style.felicidades}>¡Excelente esfuerzo!</p>
                            <p className={style.parrafoResultados}>Click para explorar tus resultados.</p>
                            
                            {programasFinalizados.map((programa) => {
                                let clase = "";
                                if (programa.id==="test_memoria_numeros_inicial"){
                                    clase = "card_calculo_matematico";
                                }else if(programa.id==="test_memoria_palabras_inicial"){
                                    clase = 'card_neural_trainer';
                                }
                                else if(programa.id==="memoria_nombres"){
                                    clase = style.card_memoria_nombres;
                                }
                                return(                              
                                    <div key={programa.id} onClick={() => handleReduxProgram(programa.id,programa.ruta)} className={clase} style={{aspectRatio:'2.26'}}>
                                        <h3>{programa.nombre}</h3>   
                                    </div>
                                )}
                            )}
                        </>}

                    {firstTime && !complete && 
                        <>                        
                            <p className={style.parrafoUno}> ¡Te damos la bienvenida al comienzo de tu viaje hacia una mente más ágil y potente! </p>
                            <p className={style.titulo}>TEST INICIAL</p>
                            <button className={style.buttonPlay} onClick={() => handleRedirect(programasFaltantes[0]?.ruta)}>
                                <img className={style.icon}src="/assets/icon/icon_play.svg"></img>
                            </button>                       
                            <p className={style.parrafoTres}>
                                Recuerda que este test no es solo una medida de dónde te encuentras ahora, 
                                sino una oportunidad para descubrir las áreas en las que puedes crecer y fortalecer.
                            </p>
                            
                        </>}

                    {!complete && !firstTime &&
                        <>                            
                            {programasFinalizados.map((programa) => {
                                let clase = "";
                                if (programa.id==="test_memoria_numeros_inicial"){
                                    clase = "card_calculo_matematico";
                                }else if(programa.id==="test_memoria_palabras_inicial"){
                                    clase = 'card_neural_trainer';
                                }
                                else if(programa.id==="memoria_nombres"){
                                    clase = style.card_memoria_nombres;
                                }
                                return(                                   
                                    <div key={programa.id} onClick={() => handleReduxProgram(programa.id,programa.ruta)} className={clase} style={{aspectRatio:'2.26'}}>
                                        <h3>{programa.nombre}</h3>   
                                    </div>
                                )}
                            )}                            
                            {programasFaltantes.map((programa) => {
                                    let clase = "";
                                    if (programa.id==="test_memoria_numeros_inicial"){
                                        clase = "card_calculo_matematico";
                                    }else if(programa.id==="test_memoria_palabras_inicial"){
                                        clase = 'card_neural_trainer';
                                    }
                                    else if(programa.id==="memoria_nombres"){
                                        clase = style.card_memoria_nombres;
                                    }
                                    return(                                        
                                        <div key={programa.id} onClick={() => handleReduxProgram(programa.id,programa.ruta)} className={`${clase} ${style.card_block}`} style={{aspectRatio:'2.26'}}>
                                            <h3>{programa.nombre}</h3>   
                                        </div>
                                    )
                                } 
                                
                            )}  
                        </>}
                </div>
            </div>: <div id='content_default'></div>}
        </div>
    )
}


export default TestInicialPage;