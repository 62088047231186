import React, { useEffect, useRef } from 'react';

import { useDato } from '../auth';
import { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, GoogleAuthProvider, FacebookAuthProvider, AuthProvider, getRedirectResult, sendPasswordResetEmail } from "firebase/auth";

import { auth } from '../firebase';

import { useToast } from '../hooks/useToast';

import { useAppDispatch, useAppSelector } from '../store';
import { initialNeuronas, loadAuth, login } from '../hooks/reducer/reducerAuth';
import  "../theme/Login-registro.css"; 
import useNeurona from '../hooks/controlador/useNeurona';
import { Navigate, useNavigate } from 'react-router-dom';
import Loading from './entrenamiento/componentes/Loading';
import LoginLoading from '../vista/Loading'

const LoginPage: React.FC = () => {
	
	const { presentCorreoMandado, presentNoUsuario, presentEntrarCorreoRecuperar, presentCorreoInvalido } = useToast();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [status, setStatus] = useState({ loading: false, error: false });
	const [isOpen, setIsOpen] = useState(false);
	const [correoRecuperar, setCorreoRecuperar] = useState('');
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
    const usuarioCargado = useRef(true);
	const loggedIn = useAppSelector((state) => state.auth.loggedIn);
	const userId = useAppSelector(state => state.auth.userId);
	// variable nombre de tipo string	
	let correo = '';
	
	const {
		state,
		recogerDatos,
		guardarDatos,
	} = useDato();

	const {
		consultarNeuronas,
	} = useNeurona()
	
	
	const Guardar = async () => {
		if (userId && usuarioCargado.current) {
			dispatch(loadAuth());
			usuarioCargado.current = false;
			await recogerDatos(userId);
			const neuronas = await consultarNeuronas(userId)
			dispatch(initialNeuronas({cantidad:neuronas.total}))
			dispatch(loadAuth());
		}
	}

	useEffect(() => {
		console.log("loggedIn:", loggedIn, "state:", state);
		if(loggedIn){
			Guardar();
		}
	}, [loggedIn])

	useEffect(() => {
		console.log("loggedIn:", loggedIn, "state:", state);
		if(loggedIn){
			if(state.datosGuardado) navigate("/my/home")
			else navigate("/datos");
		}
	}, [state])

	/**
	 *
	 * Funciones
	 *
	 */

	/** Manejadores */

	const signInWithProvider = async (provider: AuthProvider) => {
		try {
			setStatus({ loading: true, error: false });
			const credentials = await signInWithPopup(auth, provider);
			console.log("credentials ", credentials);	
			if(credentials?.user?.email){
				correo = credentials.user.email;				
			}
			await dispatch(login({userId:credentials.user.uid, email:credentials.user.email}));
		} catch (error) {
			setStatus({ loading: false, error: true });
			console.log("error con google sign-in: ", error);
		}
	}

	const handleLoginGoogle = async () => {
		const provider = new GoogleAuthProvider();
		provider.addScope("email");
		await signInWithProvider(provider)
	}

	const handleLoginFacebook = async (): Promise<void> => {
		const provider = new FacebookAuthProvider();
		await signInWithProvider(provider)	
	}
	
	const handleLogin = async () => {
		console.log("trying handleLogin");
		try {
			setStatus({ loading: true, error: false });
			const credentials = await signInWithEmailAndPassword(auth, email, password);
			await dispatch(login({userId:credentials.user.uid, email:credentials.user.email}));
			correo = email;
		} catch (error) {
			setStatus({ loading: false, error: true });
			console.log("error: ", error);
		}
	};

	const handleKey = (event) => {
		if (event.code === "Enter") {
			handleLogin();
		}
	}

	const handleKeyRecuperar = (event) => {
		if (event.code === "Enter") {
			recuperarContrasena();
		}
	}

	const recuperarContrasena = () => {
		if (correoRecuperar.length === 0) {
			presentEntrarCorreoRecuperar();
			return;
		}
		sendPasswordResetEmail(auth, correoRecuperar)
			.then(() => {
				presentCorreoMandado();
				setIsOpen(false)
				setCorreoRecuperar('')
			})
			.catch((error) => {
				setCorreoRecuperar('')
				if (error.code === 'auth/user-not-found') {
					presentNoUsuario();
				} else if (error.code === 'auth/invalid-email') {
					presentCorreoInvalido();
				} else {
					console.error("error no identificado, error: ", error);
				}
			});
		}
		
		
		return (
			<>
			{
			loggedIn? 
			<LoginLoading/>:
			<div id='page' style={{height:'100svh'}}>
				<Loading isOpen={status.loading} />
				<div id='toolbar_default' className='toolbar-registro'>
					<h1 className="title title-registro-login" style={{margin:'5% 0 !important'}}>Iniciar Sesión</h1>
				</div>
				<div id='content_default' style={{padding:'10.82% 3.84%'}}>
						
					<div className='item-correo'>
						<label>Correo Electrónico</label>
						<input type="email" placeholder="SuperCerebros@example.com"  value={email} 
							onChange={(event) => setEmail(event.target.value!)}
						/>
					</div>
					<br style={{height:'20px'}}></br>
					<div className={`item-contraseña`}>
						<label >Contraseña</label>
						<input type="password" placeholder="•••••••••••" value={password}
						className={status.error ? 'error' : ''}
						onChange={(event) => setPassword(event.target.value!)}
						onKeyDownCapture={(event) => handleKey(event)}
						/>
					</div>
					

					{status.error &&
						<label className='error' color="danger">El correo o contraseña son invalidos</label>
					}
					<button className='button-contraseña' onClick={() => setIsOpen(true)}>
						¿Olvidaste la Contraseña?
					</button>

					<button className="button-principal" onClick={handleLogin} >INICIAR SESIÓN</button>
					<div className='registrate'>
						<label onClick={() => navigate("/register")}>¿No tienes una cuenta? <span>Regístrate</span> </label>
						
					</div>
					<div className='linea-circulo'>
						<div className='linea1'></div>
						<div className='circulo'>o</div>
						<div  className='linea2'></div>
					</div>
					
					<button className="button-blanco google"   onClick={handleLoginGoogle} >
						<img src="assets/icon/google.png"  alt="Icono de Google" />
						<label>Ingresa con Google</label>
					</button>
					
					<button className="button-blanco"  onClick={handleLoginFacebook}>
						<img src="assets/icon/facebook.png"  alt="Icono de Facebook" />
						<label>Ingresa con Facebook</label>
					</button>


					{isOpen && <div id="modal_cortina_default" onClick={() => setIsOpen(false)}>
						<div id="modal_default" style={{height:'70vh'}} onClick={(e) => e.stopPropagation()}>
							<div id='toolbar_default' style={{padding:'5%'}}>
								<h1 className="title"
								style={{width:'70%',textAlign:'center'}}
								>Recuperar contraseña</h1>
								
								<button 
								style={{
									background:'transparent',
									color:'#FFFFFF'
								}}
								onClick={() => {
									setIsOpen(false)
									setCorreoRecuperar('')
								}}>Close</button>	
							</div>
							<div id='content_default' style={{padding:'5%'}}>
								<input type="email" value={correoRecuperar}
									placeholder="digita tu correo"
									onChange={(event) => setCorreoRecuperar(event.target.value!)}
									onKeyDownCapture={(event) => handleKeyRecuperar(event)}
								/>
								
								<div style={{width:'90%',margin:'5%'}}>
									<button id='button_rosado_default' style={{height:'7vh'}} onClick={recuperarContrasena} >Enviar correo</button>
								</div>
							</div>
						</div>
					</div>}
				</div>
			</div>
			}
			</>

	);

}

export default LoginPage;
