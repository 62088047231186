
import { DocumentSnapshot, collection } from 'firebase/firestore';
// Hooks
import { useBaseDeDatos } from '../baseDeDatos/useBaseDeDatos';

import { nomEntidades, nomEntidadIntegrante } from '../../datos/nombresBD';

// Modelos
import { Entidad, toEntidad } from '../../modelos/entidad/Entidad';
import { IntegranteEntidad, toIntegrante } from '../../modelos/entidad/IntegranteEntidad';


export const useEntidades = () => {

    /**
     * Funciones para acceder el base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,    
        recogerDocs,
        recogerDocsOrdenados,
        recogerDocsFiltrados, 
        agregarCollection,           
    } = useBaseDeDatos();

     
    const ConsultarEntidades = async (): Promise<string[]> => {        
        const arrEntidades : string[] = [];
        var arrDoc : DocumentSnapshot[] = [];
        arrDoc = await recogerDocs(nomEntidades);
        for (const doc of  arrDoc) {             
            arrEntidades.push(doc.id);  
        }
        return arrEntidades;
    }

    const ConsultarGrupos = async (idEntidad:string): Promise<string[]> => {        
        const arrEntidades : string[] = [];
        var arrDoc : DocumentSnapshot[] = [];
        arrDoc = await recogerDocs(nomEntidades + "/" + idEntidad + "/" + "grupos");
        for (const doc of  arrDoc) {             
            arrEntidades.push(doc.id);  
        }
        return arrEntidades;
    }


    // const consultarAreas = async (idEntidad: string): Promise<Area[]> => {        
    //     const arrAreas : Area[] = [];
    //     var arrDoc : DocumentSnapshot[] = [];        
    //     arrDoc = await recogerDocs(nomEntidades + "/" + idEntidad + "/" + nomEntidadAreas);               
    //     for (const doc of  arrDoc) {             
    //         arrAreas.push(toArea(doc));  
    //     }
    //     return arrAreas;
    // }

    const consultarIntegrantes = async (idEntidad: string, idArea: string): Promise<IntegranteEntidad[]> => {        
        const arrIngegrantes : IntegranteEntidad[] = [];
        var arrDoc : DocumentSnapshot[] = [];        
        arrDoc = await recogerDocs(nomEntidades + "/" + idEntidad + "/" + nomEntidadIntegrante );
             
        for (const doc of  arrDoc) {             
            arrIngegrantes.push(toIntegrante(doc));  
        }
        return arrIngegrantes;
    }

    const crearEntidad = async (entidad: Entidad) => {
        await agregarCollection(nomEntidades, entidad);
    }

    // const crearArea = async (idEntidad: string, area: Area): Promise<string> => {
    //     return await agregarCollection(nomEntidades + "/" + idEntidad + "/" + nomEntidadAreas, area);
    // }

    const crearIntegranteEntidad = async (idEntidad: string, idIntegrante: string, integrante: IntegranteEntidad) : Promise <void> => {        
        await agregarDoc(nomEntidades + "/" + idEntidad + "/" + nomEntidadIntegrante + "/" + idIntegrante , integrante);        
    }

    return {
        consultarEntidades: ConsultarEntidades,
        crearEntidad,
        crearIntegranteEntidad,
        consultarIntegrantes,
        ConsultarGrupos,
    }
}
