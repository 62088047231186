import React, { useEffect, useState } from 'react'
import Loading from '../../entrenamiento/componentes/Loading';
import style from "../../../theme/codigoPalabras.module.css"; 
import style2 from "../../../theme/Juego2.module.css"; 
import estiloJuegos from "../../../theme/Juegos.module.css";
import styleRecorrerValores from "../../../theme/programas/cuestionarios/recorrerValores.module.css"; 
import MostrarResultados from '../resultados/componentes/MostrarResultados';
import BotonesJuegos from '../componentes/BotonesJuegos';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store';
import Toolbar from '../componentes/Toolbar';
import TiempoWrapper from '../componentes/TiempoWrapper';
import BotonBordeDegradado from '../../componentes/BotonBordeDegradado';

enum DiasSemana{
    DOMINGO = 0,
    LUNES = 1,
    MARTES = 2,
    MIERCOLES = 3,
    JUEVES = 4,
    VIERNES = 5,
    SABADO = 6
}

interface preguntaFecha{
    fecha: Date;
    opcionCorrecta: DiasSemana;
    inputUsuario: DiasSemana|null;
}

const JuegoFechas = () => {
    let dias = ['domingo','lunes','martes','miercoles','jueves','viernes','sabado']
    let parametro = 0

    const navigate = useNavigate()
    const userId = useAppSelector((state) => state.auth.userId)

    const [tiempoMemoria, setTiempoMemoria] = useState(0)
    const [correctas, setCorrectas] = useState(0)
    const [indice, setIndice] = useState(-1)
    const [etapa, setEtapa] = useState(0)
    const [loading, setLoading] = useState(false)
    const [preguntas,setPreguntas] = useState<preguntaFecha[]>([])

    function getRandomDateIn2024(): Date {
        const start = new Date(2024, 0, 1); // 1 de enero de 2024
        const end = new Date(2024, 11, 31); // 31 de diciembre de 2024
        const randomTime = start.getTime() + Math.random() * (end.getTime() - start.getTime());
        return new Date(randomTime);
    }

    function obtenerDiaDeLaSemana(fecha: Date): DiasSemana {
        const diaSemana = fecha.getDay();
        return diaSemana as DiasSemana;
    }

    const HandleSelectDia = (input:DiasSemana) => {
        console.log("🚀 ~ HandleSelectDia ~ input:", input)
        let copyPreguntas = preguntas
        copyPreguntas[indice].inputUsuario = input
        console.log("🚀 ~ HandleSelectDia ~ copyPreguntas:", copyPreguntas)
        setPreguntas(copyPreguntas)
        if(input === copyPreguntas[indice].opcionCorrecta){
            setCorrectas((prev) => prev+1)
            console.log(correctas)
        }
        CrearNuevaPregunta()
    }

    const CrearNuevaPregunta = () => {
        let fecha = getRandomDateIn2024()
        const newPregunta = {
            fecha,
            opcionCorrecta: obtenerDiaDeLaSemana(fecha),
            inputUsuario: null
        } as preguntaFecha
        console.log("🚀 ~ CrearNuevaPregunta ~ newPregunta:", newPregunta)
        setPreguntas([...preguntas,newPregunta])
        setIndice((prev) => prev+1)
    }

    const GenerarArrayResultadosAMostrar = ():string[][] => {
        const fechasPregunta = preguntas.map((e) => e.fecha.toLocaleDateString('es-CO'));
        const arrayFechas = ['Fecha', ...fechasPregunta];
        const arrayDia = preguntas.map((e) => dias[e.opcionCorrecta]);
        const arrayDay = ['Correcta', ...arrayDia];
        const arrayInputs = preguntas.map((e) => {return e.inputUsuario !== null ? dias[e.inputUsuario].toString() : '#'});
        const arrayInputUser = ['Respuesta', ...arrayInputs];
        let array = [arrayFechas,arrayDay,arrayInputUser]
        return array
    }

    const GenerarArrayCalificaciones = () => {
        let array = preguntas.map((p) => {return p.inputUsuario === p.opcionCorrecta})
        array.unshift(null)
        return array
    }

    
    const HandleBackButtonClick = async() => {
        return navigate(-1);
    }

    const HandleTiempo = () => {
        if (etapa === 1) {
            setEtapa(2);
        } else {
            throw new Error("tiempo iniciado fuera de lugar");
        }
    }

    const Reiniciar = () => {
        setEtapa(0)
        setIndice(-1)
        setCorrectas(0)
        setPreguntas([])
    }

    const Constructor = () => {
        if(etapa === 0) {
            CrearNuevaPregunta()
            setEtapa(1)
        }
    }

    useEffect(() => {
    }, [])
    

    return (
    <div id='page'>
        <Loading isOpen={loading}/>
        <div className={etapa === 0 ? estiloJuegos.toolbarCalculoFecha : estiloJuegos.toolbar_default}>
            <h1 className={`title ${estiloJuegos.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'2.37svh'} :{}}> Calculo Fecha </h1>        
        </div>
        {etapa === 0 && 
            <div className={estiloJuegos.contenedorIconoFecha}>
                <img src='/assets/componentes/Juegos/SC.svg' className={estiloJuegos.icono}></img>
            </div>
        }          
        <div id='content_default' style={{height:'90%',padding:'2%'}}>
            {etapa === 1 && <TiempoWrapper
                handleTiempo={HandleTiempo}
                maxTiempo={60}
                setTiempoMemoria={setTiempoMemoria}
                bandera={etapa === 1}
                ascendente={false}
                />}
            {etapa === 0 && 
                <div className={estiloJuegos.primeraPantalla}>
                    <div className={[estiloJuegos.descripcionJuego,estiloJuegos.descripcionMemoriaNumeros].join(" ")}>
                        Tienes un minuto para responder la mayor cantidad de fechas que puedas.
                    </div>
                    <button className={estiloJuegos.buttonEmpezar} onClick={Constructor}>
                        EMPEZAR
                    </button>
                </div>}
            {etapa === 1 && indice>=0 &&
                <div className={style.contenedorCuestionario} style={{padding:'20% 10% 0'}}>  
                    <div className={style.pregunta} style={{marginBottom:'7.23%',width:'fit-content',fontSize:'3.5em'}}>
                        <p>{preguntas[indice].fecha.toLocaleDateString('es', {day: 'numeric',month: 'short',year: 'numeric'})}</p>
                    </div>
                    <div className={style.contenedorRespuestas} 
                    style={{padding:'0',display:'flex',flexDirection:'row',flexWrap:'wrap',gap:'2em',justifyContent:'center'}}>
                        <BotonBordeDegradado onClick={() => HandleSelectDia(DiasSemana.LUNES)} style={{width:'8em'}}>
                            <span className={estiloJuegos.degradadoTexto}>LUNES</span>
                        </BotonBordeDegradado>
                        <BotonBordeDegradado onClick={() => HandleSelectDia(DiasSemana.MARTES)} style={{width:'8em'}}>
                            <span className={estiloJuegos.degradadoTexto}>MARTES</span>
                        </BotonBordeDegradado>
                        <BotonBordeDegradado onClick={() => HandleSelectDia(DiasSemana.MIERCOLES)} style={{width:'8em'}}>
                            <span className={estiloJuegos.degradadoTexto}>MIERCOLES</span>
                        </BotonBordeDegradado>
                        <BotonBordeDegradado onClick={() => HandleSelectDia(DiasSemana.JUEVES)} style={{width:'8em'}}>
                            <span className={estiloJuegos.degradadoTexto}>JUEVES</span>
                        </BotonBordeDegradado>
                        <BotonBordeDegradado onClick={() => HandleSelectDia(DiasSemana.VIERNES)} style={{width:'8em'}}>
                            <span className={estiloJuegos.degradadoTexto}>VIERNES</span>
                        </BotonBordeDegradado>
                        <BotonBordeDegradado onClick={() => HandleSelectDia(DiasSemana.SABADO)} style={{width:'8em'}}>
                            <span className={estiloJuegos.degradadoTexto}>SABADO</span>
                        </BotonBordeDegradado>
                        <BotonBordeDegradado onClick={() => HandleSelectDia(DiasSemana.DOMINGO)} style={{width:'8em'}}>
                            <span className={estiloJuegos.degradadoTexto}>DOMINGO</span>
                        </BotonBordeDegradado>
                    </div>
                </div>
            }
            {etapa === 2 &&
                <>
                <div className={style2.contenido} style={{ padding: `0 0 ${correctas<=0? "2%": ""}`, display: "flex", flexDirection: "column", fontFamily: 'Montserrat'}}>
                    <p className={`${correctas >=7 ?
                    styleRecorrerValores.resultado2 : correctas >=5 ?
                    styleRecorrerValores.resultado1 : styleRecorrerValores.resultado0
                    } ${styleRecorrerValores.mensajePersonalizado}`} style={{fontSize:'3.64svh'}}>
                    { correctas>=parametro ? '¡FELICITACIONES!' : '¡BUEN INTENTO!'} 
                    </p>
                    <p className={styleRecorrerValores.mensajePersonalizado} 
                    style={{ color: 'var(--color-Medium-Grey)',fontSize:'2.5svh' }}>
                    {correctas >= parametro ? (
                        'PUEDES AVANZAR AL SIGUIENTE CURSO'
                    ) : (
                        <>
                        OBTÉN MINIMO <span style={{ color: 'var(--desired-color)' }}>{parametro}</span> ACIERTOS PARA AVANZAR
                        </>
                    )}
                    </p>
                    <MostrarResultados
                    arrayAMostrar={GenerarArrayResultadosAMostrar()} 
                    calificaciones={GenerarArrayCalificaciones()}
                    styleContenedorTabla={{maxHeight:'50svh',margin:'4% 2%'}}
                    />
                    <div style={{margin: "0svh auto"}}>
                    <p className={style2.textoTuviste}>TUVISTE</p>
                    <p className={style2.resultado} style={{margin:'0'}}>{`${correctas}/${preguntas.length}`}</p>
                    </div>
                    {!userId ? <button id='button_rosado_default' style={{width:'fit-content',alignSelf:'center'}} onClick={Reiniciar}>Jugar de nuevo</button> :
                    <>
                    {(parametro!==0 && correctas>=parametro) ? <button id='button_rosado_default' className={style.finalizar} onClick={HandleBackButtonClick}>Volver</button>:
                    <BotonesJuegos funcionReiniciar={Reiniciar} funcionVolver={HandleBackButtonClick} esJuego={false} esEntrenamiento={false}/>}
                    <div className={style.contenido} style={{ padding: `0 0 ${correctas<=0? "5%": ""}`, height: "85%", flexGrow: 1, display: "flex", flexDirection: "column"}}></div>
                    </>}
                </div>
                </>}
        </div>
    </div>
    )
}

export default JuegoFechas