// mensajesUtil.ts

function obtenerResultado(resultado: number): string {
    if(resultado >= 0.6) {
        return Resultado.ALTO;
    } else if(resultado >= 0.3) {
        return Resultado.MEDIO;
    } else {
        return Resultado.BAJO;
    }    
}

export function obtenerMensaje(resultadoNumber: number): string {
    let resultado:string = obtenerResultado(resultadoNumber);    
    let mensajes: string[] = [];

    switch (resultado) {
        case Resultado.ALTO:
            mensajes = mensajesAlto;
            break;
        case Resultado.MEDIO:
            mensajes = mensajesMedio;
            break;
        case Resultado.BAJO:
            mensajes = mensajesBajo;
            break;
    }

    const indexAleatorio = Math.floor(Math.random() * mensajes.length);
    return mensajes[indexAleatorio];
}


enum Resultado {
    ALTO = 'ALTO',
    MEDIO = 'MEDIO',
    BAJO = 'BAJO'
}

const mensajesAlto: string[] = [
    "¡Excelente trabajo!",
    "¡Impresionante desempeño!",
    "¡Sigue así!",
    "¡Brillante actuación!",
    "¡Sobresaliente!",
    "¡Te has superado!",
    "¡Destacado esfuerzo!",
    "¡Increíble progreso! ",
    "¡En la cima del juego!",
    "¡Impresionante!",
    "¡Gran esfuerzo!",
    "¡Eres increíble!",
    "¡Excelente trabajo!",
    "¡Felicitaciones!"
];

const mensajesMedio: string[] = [
    "Estás en el camino correcto",
    "Buen trabajo",
    "¡Sigue esforzándote!",
    "¡Buen ritmo!",
    "¡Sigue adelante!",
    "¡Vas mejorando!",
    "¡Sigues en la lucha!",
    "¡Estás en el camino correcto!",
    "¡Cada vez más cerca del objetivo!",
    "¡Avanzando a pasos firmes!",
    "Cada desafío te hace más fuerte mentalmente",
    "Cada día eres más rápido y preciso",
];

const mensajesBajo: string[] = [
    "No te rindas, sigue intentando",
    "La práctica hace al maestro, sigue adelante",
    "Cada error es una oportunidad de aprendizaje",
    "¡Cada intento cuenta!",
    "¡El aprendizaje está en el proceso!",
    "¡La perseverancia es clave!",
    "¡A veces se gana, a veces se aprende!",
];