import style from '../../theme/web/QuienesSomos.module.css'
import VideoPlayerIOs from '../entrenamiento/componentes/VideoPlayerIOs';
import NavbarWeb from './componentes/NavbarWeb';
import FooterWebPage from './componentes/FooterWebPage';
import { useRef } from 'react';
import VideoPlayerYoutube from '../entrenamiento/componentes/VideoPlayerYoutube';

const QuienesSomos: React.FC = () => {

	const footPage = useRef(null);

	return (
		<div className={style.page}>
			<NavbarWeb footPage={footPage}></NavbarWeb>
			<br style={{height:'5svh'}}></br>
			<div className={`${style.fondoMorado} ${style.grid3Column}`}>
				<h1 style={{textAlign:'center',color:'inherit'}}>¿Quienes somos?</h1>
			</div>
			<h1 className={style.titulo}>SUPERCERBROS</h1>
			<div className={style.video}>
				<VideoPlayerIOs videoUrl={''} autoplay={false} preload='auto' poster='/img/poster.png'/>
			</div>
			<div className={style.bloque}>
				<p className={style.parrafo}>
					Somos Supercerebros, una empresa líder en entrenamiento mental dedicada a potenciar las capacidades
					cognitivas de atención, concentración y memoria. Nuestro enfoque se centra en fortalecer la salud mental
					y la agilidad intelectual, abriendo caminos para la creatividad y la imaginación. Con una vasta experiencia
					en el campo, nos comprometemos con la excelencia y transformamos vidas al enseñar técnicas efectivas que
					permiten a estudiantes, trabajadores, retirados y cualquier persona interesada mejorar sus capacidades mentales.
				</p>
				<div className={style.poster}>
					<img src='/img/poster.png' alt='Foto 1' ></img>
				</div>
				<div className={`${style.poster} ${style.rotate}`}>
					<img src='/img/poster.png' alt='Foto 1' ></img>
				</div>
				<p className={style.parrafo}>
					Nuestro proyecto tiene como objetivo revolucionar el proceso de aprendizaje, proporcionando herramientas y
					desarrollando habilidades cognitivas necesarias para adquirir y aplicar conocimientos de manera efectiva.
					Nos enfocamos en resolver problemas con claridad de objetivos y metas definidas, contribuyendo así al aumento
					del nivel de inteligencia y promoviendo un aprendizaje continuo y adaptativo en todos los ámbitos de la vida.
				</p>
			</div>
			<div className={style.bloque}>
				<p className={style.parrafo}>
				<span>Juan Pablo Duque</span><br></br>
					Es un Mente-atleta colombiano, conferencista internacional y experto en
					neuro-entrenamiento. Fue semifinalista del programa SUPERCEREBROS de NatGeo,
					seleccionado entre más de 2000 participantes, e integrante de la selección 
					Colombia de Memoria y Cálculo Mental, logrando la posición 11 en el mundial 
					de Las Vegas 2016. Instructor latinoamericano de Mapas Mentales, En el año 2016
					rompio el récord sudamericano de memoria en la modalidad número PI con 1,111 aproximaciones.
					Con más de 7 años de experiencia, ha participado en eventos académicos y profesionales en Colombia 
					y Latinoamérica, destacándose en aprendizaje, memoria y comunicación.				
				</p>
				<div className={style.fotoDavid}>
					<img src='/assets/images/web/quienesDavidCantor.png' alt='Foto 3' ></img>
				</div>
				<div className={style.fotoJuan}>
					<img src='/assets/images/web/quienesJuanPablo.png' alt='Foto 4'></img>
				</div>
				<p className={style.parrafo}>
				<span>David Cantor</span><br></br>
					Es un emprendedor colombiano especializado en Entrenamiento Mental que busca mejorar procesos
					de atención, concentración, memoria, lectura rápida y gimnasia cerebral. Cofundador de SuperCerebros,
					es el entrenador mental más joven de Sudamérica. Integra la selección Colombia de Memoria y Cálculo
					Mental, logrando la posición #12 en el ranking internacional en el mundial de Las Vegas, EE.UU.
					Además, es un conferencista reconocido mundialmente en Neuroentrenamiento, Psicoestimulación,
					Motivación juvenil y Esfuerzo sostenido. 

				</p>
			</div>
			<div className={style.bloque}>
				<p className={style.parrafo}>
					<span>SUPERCEREBRO</span><br></br>
					Bro, más conocido como SuperCerebro, fue creado para enfrentar un mundo abrumado por la
					información y el estrés diario. Tiene la misión de combatir las amenazas al bienestar mental como el
					Alzheimer, la amnesia, y especialmente, los desafíos de la desatención y la falta de concentración.
				</p>
				<img src='/assets/images/supercerebro/scDePieMirandoAlFrente.png' style={{width:'calc(100% + 20svh)',aspectRatio:'1/1',marginTop:'-10svh'}}></img>
			</div>
			<h1 className={`${style.purpleText}`} style={{textAlign:'center'}}>ENTREVISTAS</h1>
			<div className={style.carrilGrid}>				
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=HrEmfqxjWVI&t=864s'} />
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=veX34_j_e4E'} />
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=YpQqWbN8vLw&t=293s'} />
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=fR3DAtgJJLo'} />
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=jFXZy0lvtaI'} />
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=MiliuU3oCJQ'} />
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=zk2JFvNCK20'} />
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=GsmJ7iZdM6U'} />
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=I5NCwYPdVHQ'} />
				<VideoPlayerYoutube style_video={{minHeight:'15svh',minWidth:'30svh'}} urlVideo={'https://www.youtube.com/watch?v=yetYR6iFzCM'} />
			</div>
			<h1 className={`${style.purpleText}`} style={{textAlign:'center'}}>PERIODICOS</h1>
			<div className={style.NoticiasEmbed} onClick={()=>{window.open('https://www.eltiempo.com/archivo/documento/CMS-14061535')}}>
				<p>También se aprende memorizando</p>
				<p>https://www.eltiempo.com/archivo/documento/CMS-14061535</p>
				<p>www.eltiempo.com</p>
			</div>
			<br></br>
			<div className={style.NoticiasEmbed} onClick={()=>{window.open('https://www.eltiempo.com/archivo/documento/CMS-16372892')}}>
				<p>Diez consejos para mejorar la memoria</p>
				<p>https://www.eltiempo.com/archivo/documento/CMS-16372892</p>
				<p>www.eltiempo.com</p>
			</div>
			<img src='/assets/componentes/web/CurvaMorada.png' ref={footPage}></img>
			<FooterWebPage />
		</div>
	);
}

export default QuienesSomos;