/** Interna */
import DatosComponent from './componentes/DatosComponent';
import { useDato } from '../../auth';
import {createUsuario, USUARIO_DATO_VACIO } from '../../modelos/usuario'
import  "../../theme/Login-registro.css"; 
import { useAppDispatch, useAppSelector } from '../../store';
import { logout } from '../../hooks/reducer/reducerAuth';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

/**
 * Pagina para entrar datos requisitos
 *
 * @author [Aiden Cullo](https://github.com/cullo7)
 */
const DatosPage: React.FC = () => {

	/**
	 * Funciones para acceder el estado de autenticacion
	 * @type {Objecto}
	 * @const
	 */
	// const loggedIn = useAppSelector(state => state.auth.loggedIn);
	const userId = useAppSelector(state => state.auth.userId);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	/**
	 * Funciones para cambiar estado de aplicacion, y recoger datos
	 * @type {Objecto}
	 * @const
	 */
	const {
		state,
		logOutCuenta,
		guardarDatos
	} = useDato();
	

	const logOut = async () => {
		console.log('out')
		logOutCuenta();
		dispatch(logout());
		guardarDatos(userId, createUsuario(false,false, false, USUARIO_DATO_VACIO));
		navigate(-1)
	}
	
	useEffect(() => {
		if(location.pathname === '/datos' && state.datosGuardado){
			navigate('/my/home');
		}
	}, [state])

	return (
		<div id='page' style={{height:'100svh'}}> 
			<div id='toolbar_default' >
				<h1 className="title_default">Datos Personales</h1>
			</div>
			<div id='content_default' style={{padding:'4%'}}>
				<DatosComponent firstTime={true}/>
				<br></br>
				<button className="button-principal" onClick={logOut}>Logout</button>	
			</div>
		</div>
	);
}

export default DatosPage;
