import React, { useContext, useState } from 'react';
import { auth as firebaseAuth } from './firebase';
import { Aplicacion } from './modelos/usuario';

/**
 * Manejo de autenticacion y todos los contextos
 */

/**
 * Interfaz para autenticacion
 */
interface Auth {
    loggedIn : boolean;
    creatingUsers: boolean;
    userId? : string;
}

/**
 * Interfaz para autenticacion initializacion
 */
interface AuthInit {
    loading : boolean;
    auth? : Auth;
}

export interface DatoInfo {
    dato : boolean;
    init : boolean;
}

export const AuthContext = React.createContext({ loggedIn : false, creatingUsers: false},);
export const DatoContext = React.createContext(null);

export function useAuth() : Auth {
    return useContext(AuthContext);
}

export function useDato() {
    return useContext(DatoContext);
}
 
/**
//  * Initializar y manejar autenticacion
//  * @function
//  * @returns {AuthInit} - datos de la autenticacion
//  */
// export function useAuthInit () : AuthInit {
//     const [authInit, setAuthInit] = useState<AuthInit>({ loading : true});
//     const {creatingUsers, loggedIn, userId} = useAuth()
//     useEffect(() => {
//         return firebaseAuth.onAuthStateChanged((firebaseUser) => {
//             const auth = firebaseUser ?
//             { loggedIn : true, creatingUsers:creatingUsers, userId : firebaseUser.uid } :
//             { loggedIn : false, creatingUsers: creatingUsers};
//             if(creatingUsers===false){
//                 setAuthInit({ loading : false, auth });
//             }else{
//                 setAuthInit({loading:false})
//             }
//         });
//     }, []);

//     return authInit;
// }


// const MofidyCreatingUsers = () => {
//     const [authInit, setAuthInit] = useState<AuthInit>({ loading : true});
//     const {creatingUsers, loggedIn, userId} = useAuth()
//     const auth:Auth = {loggedIn:loggedIn, creatingUsers:!creatingUsers, userId:userId}
//     setAuthInit({loading:false, auth})
//     return authInit
// }

// Utilizar reducers para modifica el creatingUsers


