import React, { useState } from 'react';
import { useDato } from '../../auth';

import { auth } from '../../firebase';

import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useToast } from '../../hooks/useToast';
import { useAppSelector } from '../../store';
import { useNavigate } from 'react-router-dom'
import Loading from '../entrenamiento/componentes/Loading';

const RegisterPage: React.FC = () => {

	console.log("RegisterPage.tsx")

	const loggedIn = useAppSelector(state => state.auth.loggedIn);
	const navigate = useNavigate();
	const { presentCorreoMandado, presentNoUsuario, presentEntrarCorreoRecuperar, presentCorreoInvalido } = useToast();

	const {
		initializarDatos,
		logOutCuenta
	} = useDato();

	const [email, setEmail] = useState('');
	const [send,setSend] = useState(false);
	const [status, setStatus] = useState({ loading: false, error: false, mensaje: "" });

	const handleRegister = async () => {
		setStatus({ loading: true, error: false, mensaje: "" });
		createUserWithEmailAndPassword(auth, email, "SuperCerebros")
			.then((credential) => {
				console.log(credential)
				const resp = credential.user.uid;
				verifyEmail(resp);
			})
			.catch((error) => {
				if (error.code === "auth/email-already-in-use") {
					setStatus({ loading: false, error: true, mensaje: "ya hay una cuenta con este correo" });
				} else {
					setStatus({ loading: false, error: true, mensaje: "registro fallido" });
				}
			});
		}
		
	const verifyEmail = async(uid: string) =>{
		if (email.length === 0) {
			presentEntrarCorreoRecuperar();
			return;
		}
		sendPasswordResetEmail(auth, email)
			.then(() => {
				presentCorreoMandado();
				initializarDatos(uid);			
				setStatus({ loading: false, error: true, mensaje: "Te enviamos un correo para que establezcas tu contraseña." })
				logOutCuenta();
				setSend(true);
			})
			.catch((error) => {
				setEmail('')
				if (error.code === 'auth/user-not-found') {
					presentNoUsuario();
				} else if (error.code === 'auth/invalid-email') {
					presentCorreoInvalido();
				} else {
					console.error("error no identificado, error: ", error);
				}
			});
	}

	const handleRedirect = ( ruta:string) => {
        navigate(ruta)
    }

	return (
		<div id='page'>
			<Loading isOpen={status.loading} />
			<div id='toolbar_default'>
				<h1 className="title">Register</h1>
			</div>
			<div id='content_default' style={{padding:'10.5% 5%',height:'100svh'}}>
				<div className='item-contraseña'>
					<label >Email</label>
					<input type="email" value={email}
					className={status.error ? 'error' : ''}
					onChange={(event) => setEmail(event.target.value!)}
					/>
				</div>
				{status.error &&
					<label className='error'>{status.mensaje}</label>
				}
				{!send ? <button id='button_rosado_default' style={{width:'80%',height:'7vh',margin:'5%'}} onClick={handleRegister}>Create Account</button>:
				<button id='button_rosado_default' style={{width:'80%',height:'7vh',margin:'5%'}} onClick={() => handleRedirect("/login")}>Ok</button>}
				<button className='button-contraseña' onClick={() => handleRedirect("/login")}>
					Already have an account?
				</button>

			</div>
		</div>

	);

}

export default RegisterPage;
