import { useEffect, useState } from 'react';
import style from '../../theme/Modal.module.css';

interface Props{
    fillPercentage?: number;
    cerrarPosicion?: {
        top:string,
        left:string,
        width:string,
        height:string
    };
    href?: string;
    imagen?: string;
    onCerrar?: () => void;
    onEvento?: () => void;
}

const Publicidad = ({fillPercentage = 100, cerrarPosicion = {top:"0", left:"0", width: "10%", height: "10%"}, href="", imagen="", onCerrar, onEvento}:Props) => {
    const [estiloCartel, setEstiloCartel] = useState(style.cartelOculto);

    const HandleCerrar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        setEstiloCartel(style.cartelOculto);
        if(onCerrar) setTimeout(onCerrar, 500);
    }
    const HandleEvento = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if(onEvento) onEvento();
    }

    useEffect(() => {
        setTimeout(() => setEstiloCartel(""), 500);
    }, [])

    return(
    <div className={style.contenedor} onClick={HandleCerrar}>
        <div className={style.infoCartel + " " + estiloCartel}
        style={(fillPercentage > 98)?
            {width: `${fillPercentage}%`,padding: "10px"}:
            {
                width: `${fillPercentage}%`,
                border: "5px solid var(--color-purple-dark)",
                borderRadius: "15px",
                backgroundColor: "white",
                padding: "5px"
            }
        }
        onClick={HandleEvento}>
            <a href={href}>
                <img src={imagen} alt='evento'
                style={(fillPercentage > 98)?{ width: "100%" }:{ width: "100%", borderRadius: "5px" }}/>
            </a>
            <div  style={{...cerrarPosicion, position: "absolute", cursor: "pointer"}} onClick={HandleCerrar}></div>
        </div>

    </div>
    );
}
export default Publicidad;
